import { Component, ElementRef, Input, NgZone, OnChanges, ViewChild } from '@angular/core';

import { TinyColor } from '@ctrl/tinycolor';
import { Store } from '@ngrx/store';
import { Chart } from 'chart.js/auto';
import { map } from 'lodash-es';
import { filter } from 'rxjs/operators';

import { ChartDataModel } from '../../../../typings/components';

import { GenericComponent } from '../../generic/generic.component';

import * as fromAppConfig from '../../../../store/selectors/app-config/index';
@Component({
  selector: 'smd-line-chart',
  templateUrl: './line-chart.component.html'
})
export class LineChartComponent extends GenericComponent implements OnChanges {
  private chartData: ChartDataModel;

  chart: Chart;
  chartColors: string[];

  @Input() differentSecondColor: string;

  @Input()
  set data(chartData: ChartDataModel) {
    this.chart?.destroy();
    this.chartData = chartData;
  }

  @ViewChild('chartCanvas', { static: true }) chartCanvas: ElementRef;

  get secondColor(): string {
    return this.differentSecondColor || null;
  }

  constructor(
    private ngZone: NgZone,
    private store: Store<fromAppConfig.State>
  ) {
    super();
  }

  ngOnChanges() {
    this.addUniqueSubscription(
      'rgbColorsSubscription',
      this.store
        .select(fromAppConfig.getGeneralInfoAppInfoRgbColorNumbers)
        .pipe(filter(() => !!this.chartData))
        .subscribe((chartsColorPrimary) => {
          if (this.chartData) {
            const color = new TinyColor(chartsColorPrimary);
            const alphaNumbers = [1, 0.2, 0.4, 0.6, 0.8];

            const colors = map(alphaNumbers, (alpha) => {
              color.setAlpha(alpha);
              return color.toRgbString();
            });

            this.ngZone.run(() => {
              this.chartColors = colors.slice(0, this.chartData.chartData.data.length);

              if (this.secondColor) {
                this.chartColors[this.chartColors.length - 1] = this.secondColor;
              }

              const chartCanvas = this.chartCanvas?.nativeElement;

              if (chartCanvas) {
                this.chart = new Chart(chartCanvas, {
                  data: {
                    datasets: [
                      {
                        borderColor: this.chartColors,
                        data: this.chartData.chartData.data,
                        fill: false,
                        tension: 0.1
                      }
                    ],
                    labels: this.chartData.chartData.labels
                  },
                  options: {
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false
                      }
                    }
                  },
                  type: 'line'
                });
              }
            });
          }
        })
    );
  }
}
