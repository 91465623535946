import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import * as parseActions from '../store/actions/parse/parse-implementation';
import * as fromAppConfig from '../store/selectors/app-config';
import * as fromAppSettings from '../store/selectors/app-settings';

import { PlatformDetectService } from '../common/services/platform-detect/platform-detect.service';

@Injectable()
export class PfFirebaseService {
  private firebaseToken: string;
  private isMobilePlatform = true;

  constructor(
    private store: Store<fromAppConfig.State>,
    private platformDetectService: PlatformDetectService
  ) {
    this.platformDetectService.getIsMobileSubscription().subscribe((isMobilePlatform) => {
      this.isMobilePlatform = isMobilePlatform;
    });
  }

  fetchFirebaseToken() {
    return new Promise((resolve, reject) => {
      FCM.getToken().then(
        (token) => {
          this.firebaseToken = token;
          resolve(token);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  onTokenRefresh() {
    return new Observable((observer) => {
      FCM.onTokenRefresh().subscribe((token) => {
        this.firebaseToken = token;
        observer.next(token);
      });
    });
  }

  requestPushPermission() {
    return FCM.requestPushPermission({
      ios9Support: {
        interval: 0.3,
        timeout: 10
      }
    });
  }

  isTokenUpdated(firebaseInstallationIds: string[]) {
    let matches = 0;
    firebaseInstallationIds.forEach((id) => {
      if (id === this.firebaseToken) {
        matches++;
      }
    });
    return matches > 0;
  }

  updateFireBaseInstallationIdsIfNeeded() {
    if (!this.isMobilePlatform) {
      return;
    } else {
      this.store
        .select(fromAppSettings.getFirebaseInstallationIds)
        .pipe(
          filter((firebaseInstallationIds) => !!firebaseInstallationIds),
          take(1)
        )
        .subscribe((firebaseInstallationIds) => {
          if (!this.isTokenUpdated(firebaseInstallationIds)) {
            this.store.dispatch(new parseActions.UpdateUserInstallationId({ installationId: this.firebaseToken }));
          }
        });
    }
  }
}
