import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { containerName } from '../../../../environments/environment';
import { CalculatorPagePaths } from '../../../enums/calculators';
import { MoreMenuItem } from '../../../typings/ui';

import * as fromAppConfig from '../../../store/selectors/app-config';
import * as fromAppSettings from '../../../store/selectors/app-settings';
import * as fromMonetizationSelectors from '../../../store/selectors/monetization.selectors';

import { PlatformDetectService } from '../platform-detect/platform-detect.service';

@Injectable({
  providedIn: 'root'
})
export class MorePageService {
  private moreMenu: BehaviorSubject<MoreMenuItem[]> = new BehaviorSubject<MoreMenuItem[]>([
    {
      paths: ['home', 'more', 'settings'],
      title: 'Settings'
    },
    {
      paths: ['home', 'more', 'contact-info'],
      title: 'Share Contact Info'
    },
    {
      link:
        containerName === 'ort'
          ? 'https://www.oldrepublictitle.com/or-touch'
          : 'https://redshedtechnology.com/sdtutorials/',
      pageName: 'tutorials',
      title: 'Tutorials'
    },
    {
      link: 'https://redshedtechnology.com/sdtutorials/privacy-policy.html',
      pageName: 'privacy-policy',
      title: 'Privacy Policy'
    }
  ]);

  constructor(
    private store: Store<fromAppSettings.State | fromAppConfig.State>,
    private platformDetectService: PlatformDetectService
  ) {}

  init() {
    combineLatest([
      this.store.select(fromAppConfig.getRepDataIsNetSheetEnabled),
      this.store.select(fromMonetizationSelectors.selectIsMonetizationEnabled),
      this.store.select(fromMonetizationSelectors.selectPrimarySubscriptionIsActive),
      this.platformDetectService.getIsMobileSubscription()
    ])
      .pipe(
        map(([isNetSheetEnabled, isMonetizationEnabled, isPrimarySubscriptionPlanActive, isMobilePlatform]) => {
          let menuItems = cloneDeep(this.moreMenu.value);

          this.handleMenuItem({
            isItemActive: isNetSheetEnabled,
            itemConfig: {
              paths: ['home', CalculatorPagePaths.Root],
              title: 'Calculators'
            },
            menuItems,
            position: 0
          });

          this.handleMenuItem({
            isItemActive: isMonetizationEnabled,
            itemConfig: {
              paths: ['payment', 'store'],
              title: 'Store'
            },
            menuItems,
            position: 1
          });

          if (!isMobilePlatform) {
            this.handleMenuItem({
              isItemActive: isPrimarySubscriptionPlanActive,
              itemConfig: {
                paths: ['home', 'more', 'subscriptions'],
                title: 'Subscriptions'
              },
              menuItems,
              position: 3
            });
          }

          return menuItems;
        })
      )
      .subscribe((menuItems) => {
        this.moreMenu.next(menuItems);
      });
  }

  getMoreMenu() {
    return this.moreMenu.asObservable();
  }

  private handleMenuItem(options: {
    isItemActive: boolean;
    itemConfig: MoreMenuItem;
    menuItems: MoreMenuItem[];
    position?: number;
  }) {
    const itemIndex = options.menuItems.findIndex((menuItem) => menuItem.title === options.itemConfig.title);

    if (options.isItemActive && itemIndex < 0) {
      if (!isNaN(options.position)) {
        options.menuItems.splice(options.position, 0, options.itemConfig);
      } else {
        options.menuItems.push(options.itemConfig);
      }
    } else if (!options.isItemActive && itemIndex >= 0) {
      options.menuItems.splice(itemIndex, 1);
    }
  }
}
