import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { FormGroupDirective, FormsModule } from '@angular/forms';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AgmCoreModule } from '@agm/core';
import { ActionSheet } from '@awesome-cordova-plugins/action-sheet/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { CameraPreview } from '@awesome-cordova-plugins/camera-preview/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { DeviceOrientation } from '@awesome-cordova-plugins/device-orientation/ngx';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { Purchases } from '@awesome-cordova-plugins/purchases/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NetsheetCalculatorModule } from '@redshed/netsheet-calculator';
import { ArcElement, Chart, DoughnutController, LineController } from 'chart.js';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { NgxStripeModule } from 'ngx-stripe';

import { ENV, NET_SHEET_CONFIG, STRIPE_PUBLIC_KEY } from '../environments/environment';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './common/components/components.module';

import { AppConfigEffects } from './store/effects/app-config';
import { AppSettingsEffects } from './store/effects/app-settings';
import { CalculatorsEffects } from './store/effects/calculators-and-reports';
import { ConfigEffects } from './store/effects/config.effects';
import { PropertyFarmEffects } from './store/effects/farm';
import { MapEffects } from './store/effects/map.effects';
import { MonetizationEffects } from './store/effects/monetization.effects';
import { ParseEffects } from './store/effects/parse';
import { PropertyProfileEffects } from './store/effects/property-profile';
import { ReportsEffects } from './store/effects/reports';
import { metaReducers, reducers } from './store';

import { DomManipulationProvider } from './providers/dom-manipulation.service';
import { LongPressManager } from './providers/map-utils/long-press-manager';
import { MapUtils } from './providers/map-utils/map-utils.service';
import { PfCustomErrorsHandlerInterceptor } from './providers/pf-interceptor/pf-custom-errors-handler.interceptor';
import { ProvidersModule } from './providers/providers.module';
import { KeyRefreshInterceptor } from './common/interceptors/key-refresh.interceptor';
import { SetupService } from './common/services/config/setup.service';
import { LoadingInterceptor } from './common/services/loading/loading-interceptor.service';
import { GlobalErrorHandler } from './common/services/logger/global-error-handler.service';
import { LoggerService } from './common/services/logger/logger.service';

import 'chartjs-adapter-moment';

Chart.register(ArcElement, DoughnutController, LineController);
Chart.defaults.maintainAspectRatio = false;
Chart.defaults.interaction.mode = 'nearest';

export function initialLoadFactory(setupService: SetupService) {
  return function factory(): Promise<void> {
    return setupService.initFactory();
  };
}

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL }
  };
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      swipeBackEnabled: false
    }),
    IonicStorageModule.forRoot({
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage],
      name: '__smartDirectDB'
    }),
    AppRoutingModule,
    ComponentsModule,
    CommonModule,
    StoreModule.forRoot(reducers, { metaReducers, runtimeChecks: { strictStateImmutability: false } }),
    ProvidersModule,
    StoreDevtoolsModule.instrument({
      logOnly: ENV.production
    }),
    HttpClientModule,
    HammerModule,
    EffectsModule.forRoot([
      ParseEffects,
      AppSettingsEffects,
      AppConfigEffects,
      PropertyProfileEffects,
      ReportsEffects,
      PropertyFarmEffects,
      CalculatorsEffects,
      MapEffects,
      MonetizationEffects,
      ConfigEffects
    ]),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDm2snPa1Met5vh60HU6Tybp88pGrL86fo',
      apiVersion: '3.57',
      libraries: ['places', 'drawing']
    }),
    NetsheetCalculatorModule.forRoot(NET_SHEET_CONFIG),
    NgxStripeModule.forRoot(STRIPE_PUBLIC_KEY)
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Device,
    AppVersion,
    DomManipulationProvider,
    FormsModule,
    FormGroupDirective,
    InAppBrowser,
    Geolocation,
    MapUtils,
    LongPressManager,
    ActionSheet,
    File,
    SocialSharing,
    LaunchNavigator,
    Keyboard,
    CameraPreview,
    DeviceOrientation,
    PhotoViewer,
    EmailComposer,
    Purchases,
    { deps: [], provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
    {
      deps: [SetupService],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: initialLoadFactory
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: KeyRefreshInterceptor
    },
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: PfCustomErrorsHandlerInterceptor },
    { deps: [LoggerService], provide: ErrorHandler, useClass: GlobalErrorHandler }
  ]
})
export class AppModule {}
