import { combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { has, isEmpty, map, pick } from 'lodash-es';

import { DETAILS_CHARACTERISTICS_LABELS, TRANSACTION_LABELS } from '../../../../constants/property-profile.mappings';
import {
  DetailsAndCharacteristics,
  FormattedComparableSale,
  NeighborhoodInfo,
  RawTransactionHistory,
  SaleHistory
} from '../../../typings/property-profile';

import * as fromPropertyProfileExport from '../../reducers/property-data/export-property-profile';
import * as fromPropertyProfileHistory from '../../reducers/property-data/profile-history';
import * as fromPropertyProfile from '../../reducers/property-data/property-profile';

export interface PropertyDataState {
  exportPropertyProfile: fromPropertyProfileExport.State;
  profileHistory: fromPropertyProfileHistory.State;
  propertyProfile: fromPropertyProfile.State;
}

export interface State {
  propertyData: PropertyDataState;
}

export const reducer = combineReducers({
  exportPropertyProfile: fromPropertyProfileExport.reducer,
  profileHistory: fromPropertyProfileHistory.reducer,
  propertyProfile: fromPropertyProfile.reducer
});

export const getPropertyDataState = createFeatureSelector<PropertyDataState>('propertyData');

export const getPropertyProfileState = createSelector(
  getPropertyDataState,
  (state: PropertyDataState) => state.propertyProfile
);

export const getProfileData = createSelector(getPropertyProfileState, fromPropertyProfile.getProfileData);
export const getPropertyProfileTitle = createSelector(getPropertyProfileState, fromPropertyProfile.getProfileTitle);
export const getPropertyProfileDetailsCharacteristicsData = createSelector(
  getPropertyProfileState,
  fromPropertyProfile.getDetailsCharacteristicsData
);
export const getPropertyProfileTransactionsData = createSelector(
  getPropertyProfileState,
  fromPropertyProfile.getTransactionsData
);
export const getPropertyProfileSalesInfoData = createSelector(
  getPropertyProfileState,
  fromPropertyProfile.getSalesInfoData
);
export const getPropertyProfileComparableSalesData = createSelector(
  getPropertyProfileState,
  fromPropertyProfile.getComparableSalesData
);

export const getComparableWith = createSelector(getPropertyProfileState, fromPropertyProfile.getComparableWith);

export const getPropertyProfileComparableSalesMarkers = createSelector(
  getPropertyProfileState,
  fromPropertyProfile.getComparableSalesMarkers
);
export const getPropertyProfilePlatMapData = createSelector(
  getPropertyProfileState,
  fromPropertyProfile.getPlatMapData
);
export const getPropertyProfileAdditionalData = createSelector(
  getPropertyProfileState,
  fromPropertyProfile.getProfileAdditionalData
);

export const getProfileScrollPosition = createSelector(
  getPropertyProfileState,
  fromPropertyProfile.getProfileScrollPosition
);

export const getNetSheetAddressDataFromLoadedProperty = createSelector(
  getPropertyProfileState,
  fromPropertyProfile.getNetSheetAddressDataFromLoadedProperty
);

export const getPropertyProfileSucceeded = createSelector(getPropertyProfileState, fromPropertyProfile.getSucceeded);

export const getPropertyProfileData = createSelector(getProfileData, (data) => {
  let result: NeighborhoodInfo[] | null = null;

  if (data) {
    result = [
      {
        contentDescription: {
          avgPrice: {
            format: 'currency',
            label: 'Last Selling Price',
            value: data.propertyInfo.SalesPrice
          },
          bathrooms: data.propertyInfo.Baths,
          bedrooms: data.propertyInfo.Bedrooms,
          date: data.propertyInfo.reportDate,
          descriptionRows: [
            {
              label: null,
              value: data.propertyInfo.title
            },
            {
              label: 'Parcel Number',
              value: data.propertyInfo.APN
            },
            {
              label: 'Owner',
              value: data.propertyInfo.PrimaryOwnerName
            }
          ]
        },
        detailsTitle: 'This Property'
      },
      {
        contentDescription: {
          avgPrice: {
            format: 'currency',
            label: 'Price',
            value: data.areaSales.MedianValue
          },
          bathrooms: data.areaSales.MedianNumBaths,
          bedrooms: data.areaSales.MedianNumBeds,
          date: '',
          descriptionRows: []
        },
        detailsTitle: 'Area Sales'
      }
    ];
  }

  return result;
});

export const getSalesInfoSaleHistory = createSelector(getPropertyProfileSalesInfoData, (data) => {
  let result: SaleHistory | null = null;

  if (data) {
    result = {
      descriptionRows: [
        {
          format: 'number',
          label: 'Number of Sales',
          value: data.TotalAreaSales
        }
      ],
      detailsTitle: 'sale history',
      infoRows: [
        {
          avg: {
            format: 'currency',
            label: 'Median Price',
            value: data.MedianValue
          },
          rates: {
            highest: {
              format: 'currency',
              label: 'Highest Sale Price',
              value: data.PriceRangeMax
            },
            lowest: {
              format: 'currency',
              label: 'Lowest Sale Price',
              value: data.PriceRangeMin
            }
          }
        },
        {
          avg: {
            format: 'currency',
            label: 'Median Price per Sq Ft',
            value: data.MedianPricePerSqFt
          },
          rates: {
            highest: {
              format: 'currency',
              label: 'Highest For Sq Ft',
              value: data.MaxPricePerSqFt
            },
            lowest: {
              format: 'currency',
              label: 'Lowest For Sq Ft',
              value: data.MinPricePerSqFt
            }
          }
        }
      ]
    };
  }

  return result;
});
export const getSalesInfoNeighborhoodInfo = createSelector(getPropertyProfileSalesInfoData, (data) => {
  let result: NeighborhoodInfo | null = null;

  if (data) {
    result = {
      contentDescription: {
        avgPrice: {
          format: 'number',
          label: 'Median FSF',
          suffix: 'SqFt',
          value: data.MedianLivingArea
        },
        bathrooms: data.MedianNumBaths,
        bedrooms: data.MedianNumBeds,
        date: '',
        descriptionRows: []
      },
      detailsTitle: 'Neighborhood Snapshot'
    };
  }

  return result;
});
export const getSalesInfoComparableSales = createSelector(getPropertyProfileComparableSalesData, (comparableSales) => {
  const result: FormattedComparableSale[] = [];

  if (comparableSales) {
    comparableSales.forEach((item) => {
      const siteUnitType = item.SiteUnitType ? item.SiteUnitType.toLowerCase() : '';
      const search = `${item.SiteAddress} ${siteUnitType} ${item.SiteUnit}, ${item.SiteCity} ${item.siteState} ${item.siteZip}`;

      result.push({
        additionalInfo: composeComparableSalesAdditionalInfo(item.SiteUnit, siteUnitType, item.Bedrooms, item.Baths),
        address: `${item.SiteAddress} ${item.SiteCity}`,
        buildingInfo: [
          {
            label: 'Building Area',
            value: item.bldgSize
          },
          {
            label: 'Lot Size',
            value: item.lotSize
          }
        ],
        date: item.RecordingDate,
        price: {
          format: 'currency-no-decimal',
          value: item.SalePrice
        },
        pricePerSqFt: {
          format: 'currency-no-decimal',
          value: item.PricePerSQFT
        },
        profileData: {
          city: item.SiteCity,
          fullAddress: `${item.SiteAddress} ${siteUnitType} ${item.SiteUnit}`,
          state: item.siteState,
          zip: item.siteZip
        },
        search,
        shortAddress: item.SiteAddress
      });
    });
  }

  return result;
});

export const getDetailsCharacteristicsPropertyDetails = createSelector(
  getPropertyProfileDetailsCharacteristicsData,
  (data) => {
    const arr = [];
    for (const prop in data.propertyDetails) {
      if (has(data.propertyDetails, prop)) {
        arr.push({
          label: DETAILS_CHARACTERISTICS_LABELS[`${prop}`],
          value: data.propertyDetails[`${prop}`] || '-'
        });
      }
    }
    return arr;
  }
);
export const getDetailsCharacteristicsOwnerInfo = createSelector(
  getPropertyProfileDetailsCharacteristicsData,
  (data) => {
    let resultData: DetailsAndCharacteristics[] = [];

    if (!isEmpty(data.ownerInfo)) {
      const ownerName = `${data.ownerInfo.PrimaryOwnerName} ${data.ownerInfo.SecondaryOwnerName}`;
      const mailingAddress = `${data.ownerInfo.mailingAddress} ${data.ownerInfo.mailingUnit}, ${data.ownerInfo.mailingCity}, ${data.ownerInfo.mailingState} ${data.ownerInfo.mailingZip}`;

      resultData = [
        {
          label: 'Owner Name',
          value: ownerName
        },
        {
          label: 'Mailing Address',
          value: mailingAddress
        }
      ];
    }

    return resultData;
  }
);
export const getDetailsCharacteristicsPropertyCharacteristics = createSelector(
  getPropertyProfileDetailsCharacteristicsData,
  (data) => {
    const arr = [];
    for (const prop in data.propertyCharacteristics) {
      if (has(data.propertyCharacteristics, prop)) {
        arr.push({
          label: DETAILS_CHARACTERISTICS_LABELS[`${prop}`],
          value: data.propertyCharacteristics[`${prop}`] || '-'
        });
      }
    }
    return arr;
  }
);

export const getTransactionHistory = createSelector(getPropertyProfileTransactionsData, (data) =>
  map(data, (element) => {
    const convertedDate = new Date(element.RecordingDate).toLocaleDateString('en-US');

    return {
      cmtIdentifier: element.cmtIdentifier,
      date: convertedDate === 'Invalid Date' ? 'Unknown Recording Date' : convertedDate,
      details: composeDetails(element),
      type: element.DocumentType
    };
  })
);
function composeDetails(data: RawTransactionHistory) {
  const selected = pick(data, ['DocumentNum', 'TypeOfSale', 'Price', 'FirstTD', 'SellerName', 'BuyerName']);
  return Object.keys(selected).map((i) => ({ label: TRANSACTION_LABELS[i], value: selected[i] }));
}
function composeComparableSalesAdditionalInfo(unit, unitType, beds, baths): string[] {
  const arr = [];
  if (unit) {
    arr.push(`${unitType} ${unit}`);
  }
  beds ? arr.push(`${beds} Bed`) : arr.push('0 Bed');
  baths ? arr.push(`${baths} BA`) : arr.push('0 BA');
  return arr;
}

export const getPropertyProfileHistoryState = createSelector(getPropertyDataState, (state) => state.profileHistory);

export const getProfileHistoryLoading = createSelector(
  getPropertyProfileHistoryState,
  fromPropertyProfileHistory.getProfileHistoryLoading
);
export const getSelectedProfileId = createSelector(
  getPropertyProfileHistoryState,
  fromPropertyProfileHistory.getSelectedProfileId
);

export const getProfileHistoryItems = createSelector(
  getPropertyProfileHistoryState,
  fromPropertyProfileHistory.getProfileHistoryItems
);

export const getSelectedProfile = createSelector(
  getPropertyProfileHistoryState,
  getSelectedProfileId,
  (profiles, profileId) => {
    return profiles.entities[profileId];
  }
);

export const getSendDocumentApiData = createSelector(
  getPropertyProfileDetailsCharacteristicsData,
  getPropertyProfileAdditionalData,
  (detailsCharacteristics, additionalData) => {
    return {
      apn: detailsCharacteristics.propertyDetails.APN,
      city: detailsCharacteristics.propertyDetails.SiteCity,
      fips: additionalData.fips,
      stateCode: detailsCharacteristics.propertyDetails.SiteState,
      streetAddress: detailsCharacteristics.propertyDetails.SiteAddress
    };
  }
);

export const getExportPropertyProfilePdfState = createSelector(
  getPropertyDataState,
  (state) => state.exportPropertyProfile
);

export const getExportPropertyProfilePdfData = createSelector(
  getExportPropertyProfilePdfState,
  fromPropertyProfileExport.getPropertyProfilePdfData
);
