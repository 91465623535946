import { FilterPfCheckbox, OwnerStatus } from '../../../typings/walking-farm';

import * as reportFiltersActions from '../../actions/walking-farm/walking-farm-report-filters';

export interface State {
  alertsShown: OwnerStatus;
  leadStatus: {
    list: FilterPfCheckbox[];
    selected: string[];
  };
  ownerStatus: OwnerStatus;
  sortBy: OwnerStatus;
}

export const initialState: State = {
  alertsShown: {
    data: [
      { title: 'All', value: 'all' },
      { title: 'Unread', value: 'unread' }
    ],
    selectedTab: 'all'
  },
  leadStatus: {
    list: [
      { title: 'lead', value: 'lead' },
      { title: 'prospect', value: 'prospect' },
      { title: 'on market', value: 'on market' },
      { title: 'not interested', value: 'not interested' },
      { title: 'not home', value: 'not home' },
      { title: 'not contacted', value: 'not contacted' }
    ],
    selected: []
  },
  ownerStatus: {
    data: [
      { title: 'All', value: 'all' },
      { title: 'Owner Occupied', value: 'occupied' },
      { title: 'Non Owner Occupied', value: 'unoccupied' },
      {
        hint: 'Only show properties with owners from a different state.',
        title: 'Out Of State Owners',
        value: 'outOfState'
      },
      {
        hint: 'Only show properties with sale activity in last 25 years.',
        title: 'Empty Nesters',
        value: 'emptyNesters'
      },
      {
        hint: 'Only show properties that are above the median years of ownership.',
        title: 'Years in Home',
        value: 'yearsInHome'
      }
    ],
    selectedTab: 'all'
  },
  sortBy: {
    data: [
      { title: 'Parcel Number', value: 'parcelNumber' },
      { title: 'Lead Status', value: 'leadStatus' },
      { title: 'Street Name', value: 'streetName' },
      { title: 'Unread Alerts', value: 'unreadAlerts' }
    ],
    selectedTab: 'parcelNumber'
  }
};

export function reducer(state = initialState, action: reportFiltersActions.ReportFiltersActions) {
  switch (action.type) {
    case reportFiltersActions.ReportFiltersActionTypes.SetReportFilters: {
      return {
        ...state,
        alertsShown: {
          ...state.alertsShown,
          selectedTab: action.payload.selectedAlertsShown || state.alertsShown.selectedTab
        },
        leadStatus: {
          ...state.leadStatus,
          selected: action.payload.selectedLeadStatus || state.leadStatus.selected
        },
        ownerStatus: {
          ...state.ownerStatus,
          selectedTab: action.payload.selectedOwner || state.ownerStatus.selectedTab
        },
        sortBy: {
          ...state.sortBy,
          selectedTab: action.payload.selectedSort || state.sortBy.selectedTab
        }
      };
    }

    case reportFiltersActions.ReportFiltersActionTypes.ResetReportFilters: {
      return {
        alertsShown: {
          ...initialState.alertsShown
        },
        leadStatus: {
          ...state.leadStatus,
          selected: []
        },
        ownerStatus: {
          ...initialState.ownerStatus
        },
        sortBy: {
          ...initialState.sortBy
        }
      };
    }

    default: {
      return state;
    }
  }
}

export const getReportFiltersSortBy = (state: State) => state.sortBy;
export const getReportFiltersOwnerStatus = (state: State) => state.ownerStatus;
export const getReportFiltersLeadStatus = (state: State) => state.leadStatus.list;
export const getReportFiltersLeadStatusSelected = (state: State) => state.leadStatus.selected;
export const getReportFiltersAlertsShown = (state: State) => state.alertsShown;
