export enum CalculatorPagePaths {
  Affordability = 'affordability',
  Amortization = 'amortization',
  CalculatorReports = 'reports',
  NetSheet = 'net-sheet',
  Piti = 'piti',
  Root = 'calculator'
}

export enum CalculatorType {
  Affordability = 'affordability',
  Amortization = 'amortization',
  NetSheet = 'net-sheet',
  Piti = 'piti'
}
