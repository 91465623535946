import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'smd-counter',
  templateUrl: './counter.component.html'
})
export class YearsCounterComponent implements OnInit {
  private valueValue: number = 1;

  valueDescription = '';

  @Input() description: string;
  @Input() defaultValue: number = 0;
  @Output() valueChanges: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  set value(value: number) {
    this.valueValue = value;
    this.valueChanges.next(this.valueValue);
    this.updateDescription();
  }

  get value() {
    return this.valueValue;
  }

  constructor() {}

  ngOnInit() {
    this.updateDescription();
  }

  increment() {
    this.value++;
  }

  decrement() {
    this.value--;
  }

  reset() {
    this.valueDescription = '';
    this.value = this.defaultValue;
  }

  private updateDescription() {
    if (this.description) {
      this.valueDescription = this.value > 1 ? `${this.description}s` : `${this.description}`;
    }
  }
}
