import { Injectable } from '@angular/core';

import { MapperInterface } from './mapper.interface';

@Injectable()
export class MappingStrategyService {
  private mapper: MapperInterface;
  constructor() {}

  setStrategy(mapper) {
    this.mapper = mapper;
  }

  toDTO(data) {
    return this.mapper.map(data || []);
  }
}
