import { Injectable } from '@angular/core';

@Injectable()
export class RadiusService {
  private pointsNumber = 0;
  private mapObj: google.maps.Map;
  private circle: google.maps.Circle;
  private polygon: google.maps.Polygon;
  private polyline: google.maps.Polyline;

  private drawOptions = {
    circleOptions: {
      clickable: false,
      draggable: false,
      editable: false,
      fillColor: '#3E6787',
      fillOpacity: 0.4,
      radius: 160.934,
      strokeColor: '#3E6787',
      strokeOpacity: 1,
      strokeWeight: 2,
      zIndex: 1
    },
    polygonOptions: {
      draggable: false,
      editable: false,
      fillColor: '#0879D1',
      fillOpacity: 0,
      strokeColor: '#3E6787',
      strokeOpacity: 0,
      strokeWeight: 2,
      zIndex: 1
    },
    polylineOptions: {
      geodesic: true,
      strokeColor: '#3E6787',
      strokeOpacity: 0,
      strokeWeight: 2,
      zIndex: 4
    }
  };

  setMapObj(map: google.maps.Map) {
    this.mapObj = map;
  }

  initializeCircle(centerLat: number, centerLng: number) {
    this.circle = new google.maps.Circle({
      ...this.drawOptions.circleOptions,
      center: { lat: centerLat, lng: centerLng }
    });

    this.polyline = new google.maps.Polyline(this.drawOptions.polylineOptions);
    this.polygon = new google.maps.Polygon(this.drawOptions.polygonOptions);

    this.circle.setMap(this.mapObj);
    this.polyline.setMap(this.mapObj);
    this.polygon.setMap(this.mapObj);
  }

  setCircleRadius(radiusInMeters: number) {
    if (!this.circle) {
      return;
    }

    this.circle.setRadius(radiusInMeters);
  }

  getCircleRadiusPath() {
    this.clearPolyLinePoints();
    this.setPolyPath();
    this.polygon.setPath(this.polyline.getPath().getArray());
    return this.polygon.getPath();
  }

  clearPolyLinePoints() {
    if (this.polyline) {
      this.polyline.getPath().clear();
    }
  }

  clearCircle() {
    this.mapObj = null;

    if (this.polyline) {
      this.polyline.getPath().clear();
      this.polyline.setMap(null);
      this.polyline = null;
    }
    if (this.polygon) {
      this.polygon.setMap(null);
      this.polygon = null;
    }
    if (this.circle) {
      this.circle.setMap(null);
      this.circle = null;
    }
  }

  setPolyPath() {
    const center = this.circle.getCenter();
    const northEast = this.circle.getBounds().getNorthEast();
    const southWest = this.circle.getBounds().getSouthWest();
    const radius = northEast.lat() - center.lat();

    const halfOfRadius = radius / 2;
    const sixthOfRadius = radius / 6;

    // add North Point
    this.insertPolyPoint(northEast.lat(), center.lng());

    // add Northeast Point 1
    this.insertPolyPoint(northEast.lat() - sixthOfRadius, northEast.lng() - halfOfRadius);

    // add Northeast Point 2
    this.insertPolyPoint(northEast.lat() - halfOfRadius, northEast.lng() - sixthOfRadius);

    // add East Point
    this.insertPolyPoint(center.lat(), northEast.lng());

    // add Southeast Point 1
    this.insertPolyPoint(southWest.lat() + halfOfRadius, northEast.lng() - sixthOfRadius);

    // add Southeast Point 2
    this.insertPolyPoint(southWest.lat() + sixthOfRadius, northEast.lng() - halfOfRadius);

    // add South Point
    this.insertPolyPoint(southWest.lat(), center.lng());

    // add Southwest Point 1
    this.insertPolyPoint(southWest.lat() + sixthOfRadius, southWest.lng() + halfOfRadius);

    // add Southwest Point 2
    this.insertPolyPoint(southWest.lat() + halfOfRadius, southWest.lng() + sixthOfRadius);

    // add West Point
    this.insertPolyPoint(center.lat(), southWest.lng());

    // add Northwest Point 1
    this.insertPolyPoint(northEast.lat() - halfOfRadius, southWest.lng() + sixthOfRadius);

    // add Northwest Point 2
    this.insertPolyPoint(northEast.lat() - sixthOfRadius, southWest.lng() + halfOfRadius);
  }

  insertPolyPoint(insertLat: number, insertLng: number) {
    this.polyline.getPath().insertAt(this.pointsNumber, new google.maps.LatLng(insertLat, insertLng));
    this.pointsNumber++;
  }
}
