import { createAction, props } from '@ngrx/store';

export const loadConfig = createAction('[Config] Load Config.');

export const loadConfigSuccess = createAction(
  '[Config] Load Config Success.',
  props<{
    term: {
      description: string;
      revisionDate: number;
      version: number;
    };
  }>()
);

export const loadConfigFailure = createAction('[Config] Load Config Failure.', props<{ error: string }>());
