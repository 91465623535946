import { concat, differenceWith, isEqual } from 'lodash-es';

import { MapDrawnPoint } from '../../../typings/map';

import * as polygonActions from '../../actions/walking-farm/polygon';

export interface State {
  points: MapDrawnPoint[] | null;
  polygonCompleted: boolean;
  polygonStarted: boolean;
}

export const initialState: State = {
  points: null,
  polygonCompleted: false,
  polygonStarted: false
};

export function reducer(state = initialState, action: polygonActions.PolygonActions) {
  switch (action.type) {
    case polygonActions.PolygonActionTypes.SavePolygonPoints: {
      let points = state.points || [];

      if (action.payload.points?.length) {
        points = concat(points, action.payload.points);
      }

      return {
        ...state,
        points
      };
    }

    case polygonActions.PolygonActionTypes.RemovePolygonPoints: {
      let points = state.points || [];

      if (action.payload.points?.length) {
        points = differenceWith(points, action.payload.points, isEqual);
      }

      return {
        ...state,
        points
      };
    }

    case polygonActions.PolygonActionTypes.UpdatePolygonStartedFlag: {
      return {
        ...state,
        polygonStarted: action.payload.polygonStarted
      };
    }

    case polygonActions.PolygonActionTypes.UpdatePolygonCompletedFlag: {
      return {
        ...state,
        polygonCompleted: action.payload.polygonCompleted
      };
    }

    case polygonActions.PolygonActionTypes.ResetPolygon: {
      return {
        ...state,
        points: initialState.points,
        polygonCompleted: initialState.polygonCompleted,
        polygonStarted: initialState.polygonStarted
      };
    }

    default: {
      return state;
    }
  }
}

export const getFarmPolygonPoints = (state: State) => state.points;
export const getPolygonStartedFlag = (state: State) => state.polygonStarted;
export const getPolygonCompletedFlag = (state: State) => state.polygonCompleted;
