import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { cloneDeep, findIndex, forEach } from 'lodash-es';

import { FarmData, PropertyData } from '../../../typings/walking-farm';

import * as fromParseActions from '../../actions/parse/parse-implementation';
import * as fromFarmActions from '../../actions/walking-farm/farm';
import { sortByDate } from '../../entities-util';
import { initState, ViewState } from '../../init-state';

export interface State extends ViewState<FarmData> {}

export const adapter: EntityAdapter<FarmData> = createEntityAdapter<FarmData>({
  selectId: (farms) => farms.id,
  sortComparer: sortByDate
});

export const initialState: State = initState(adapter);

export function reducer(state = initialState, action: fromFarmActions.FarmActions | fromParseActions.ParseActions) {
  switch (action.type) {
    case fromParseActions.ParseActionTypes.DeleteFarmReport:
    case fromParseActions.ParseActionTypes.GetSavedFarmReports:
    case fromParseActions.ParseActionTypes.UpdateFarmReportProperties: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }

    case fromParseActions.ParseActionTypes.GetSavedFarmReportsSuccess: {
      return {
        ...adapter.upsertMany(action.payload, state),
        loaded: true,
        loading: false
      };
    }

    case fromParseActions.ParseActionTypes.CreateFarmReportSuccess: {
      const toUpsert = { id: action.payload.id };

      for (const [key, value] of Object.entries(action.payload.changes)) {
        toUpsert[key] = value;
      }

      return {
        ...adapter.upsertOne(toUpsert as FarmData, state),
        selectedId: action.payload.id
      };
    }

    case fromFarmActions.FarmActionTypes.UpdateSavedFarm: {
      let farmProperties: PropertyData[];

      if (action.payload.savedFarm) {
        farmProperties = cloneDeep(action.payload.savedFarm.properties);

        forEach(action.payload.changes.properties, (updatedProperty) => {
          const updatedPropertyIndex = findIndex(
            farmProperties,
            (property) => property.A000_PropertyNumber === updatedProperty.A000_PropertyNumber
          );

          if (updatedPropertyIndex > -1) {
            farmProperties[updatedPropertyIndex] = updatedProperty;
          }
        });
      } else {
        farmProperties = cloneDeep(action.payload.changes.properties);
      }

      return {
        ...adapter.updateOne(
          {
            changes: {
              properties: farmProperties
            },
            id: action.payload.id
          },
          state
        )
      };
    }

    case fromFarmActions.FarmActionTypes.ClearAllSavedFarms: {
      return {
        ...adapter.removeAll(state),
        error: null,
        loaded: false,
        loading: false
      };
    }

    case fromFarmActions.FarmActionTypes.ResetSelectedFarm: {
      return {
        ...state,
        error: null,
        loaded: false,
        loading: false,
        selectedId: null
      };
    }

    case fromParseActions.ParseActionTypes.GetFarmReport: {
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
        selectedId: action.payload
      };
    }

    case fromParseActions.ParseActionTypes.GetFarmReportSuccess: {
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false
      };
    }

    case fromParseActions.ParseActionTypes.DeleteFarmReportSuccess: {
      return {
        ...adapter.removeOne(action.payload, state),
        error: null,
        loaded: true,
        loading: false
      };
    }

    case fromParseActions.ParseActionTypes.GetSavedFarmReportsFail:
    case fromParseActions.ParseActionTypes.UpdateFarmReportPropertiesFail: {
      return {
        ...state,
        error: action.payload,
        loaded: false,
        loading: false
      };
    }

    case fromParseActions.ParseActionTypes.DeleteFarmReportFail: {
      return {
        ...adapter.removeOne(action.payload.farmReportId, state),
        error: action.payload.error,
        loaded: false,
        loading: false
      };
    }

    case fromParseActions.ParseActionTypes.ResetParseCallsState: {
      return {
        ...state,
        error: null,
        loaded: false,
        loading: false
      };
    }

    default:
      return state;
  }
}

export const { selectAll: SelectAllFarms } = adapter.getSelectors();

export const getSelectedFarmId = (state: State) => state.selectedId;
export const getFarmLoading = (state: State) => state.loading;
export const getFarmLoaded = (state: State) => state.loaded;
