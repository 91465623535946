import { Action } from '@ngrx/store';

export enum SearchInfoActionTypes {
  ResetCounty = '[Config Search Info] Reset County',
  ResetState = '[Config Search Info] Reset State',
  SetInitialCounty = '[Config Search Info] Set Initial County',
  SetInitialState = '[Config Search Info] Set Initial State',
  SetSearchKey = '[Config Search Info] Set Search Key',
  SetSearchValue = '[Config Search Info] Set Search Value',
  UpdateCounty = '[Config Search Info] Update County',
  UpdateState = '[Config Search Info] Update State'
}

export class SetSearchKey implements Action {
  readonly type = SearchInfoActionTypes.SetSearchKey;

  constructor(public payload: string) {}
}
export class SetSearchValue implements Action {
  readonly type = SearchInfoActionTypes.SetSearchValue;

  constructor(public payload: string) {}
}
export class SetInitialState implements Action {
  readonly type = SearchInfoActionTypes.SetInitialState;

  constructor(public payload: string) {}
}
export class SetInitialCounty implements Action {
  readonly type = SearchInfoActionTypes.SetInitialCounty;

  constructor(public payload: string) {}
}
export class UpdateState implements Action {
  readonly type = SearchInfoActionTypes.UpdateState;

  constructor(public payload: string) {}
}
export class UpdateCounty implements Action {
  readonly type = SearchInfoActionTypes.UpdateCounty;

  constructor(public payload: string) {}
}
export class ResetState implements Action {
  readonly type = SearchInfoActionTypes.ResetState;

  constructor() {}
}
export class ResetCounty implements Action {
  readonly type = SearchInfoActionTypes.ResetCounty;

  constructor() {}
}

export type SearchInfoAction =
  | SetSearchValue
  | SetSearchKey
  | SetInitialState
  | SetInitialCounty
  | UpdateState
  | UpdateCounty
  | ResetState
  | ResetCounty;
