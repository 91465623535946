import { Component, Input } from '@angular/core';

import { CardItemInfo, NeighborhoodInfo, SaleHistory } from '../../../typings/property-profile';

@Component({
  selector: 'smd-property-profile-item',
  templateUrl: './property-profile-item.component.html'
})
export class PropertyProfileItemComponent {
  propertyProfile: {
    contentDescription?: {
      avgPrice?: CardItemInfo;
      bathrooms?: string;
      bedrooms?: string;
      date?: string;
      descriptionRows: CardItemInfo[];
    };
    detailsTitle: string;
  };

  @Input() set neighborhoodData(data: NeighborhoodInfo) {
    this.propertyProfile = {
      ...data
    };
  }

  @Input() set saleHistoryData(data: SaleHistory) {
    this.propertyProfile = {
      detailsTitle: data.detailsTitle
    };
  }

  dateFormatRule = 'MM/DD/YYYY';

  constructor() {}
}
