import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';

import { PolygonService } from '../common/services/map/polygon.service';
import { RadiusService } from '../common/services/map/radius.service';
import { GeocodeService } from './geocode-helper.service';
import { MapUtils } from './map-utils/map-utils.service';
import { MappingStrategyService } from './mappers/mapping-strategy.service';
import { PfFirebaseService } from './pf-firebase-service.service';
import { PfHelperService } from './pf-helper-service.service';
import { AppDeviceProvider } from './plugins-services/app-version.service';

import { WalkOrderManagerProvider } from './map-utils/walk-order-manager';

@NgModule({
  providers: [
    AppDeviceProvider,
    MappingStrategyService,
    PfHelperService,
    PfFirebaseService,
    GeocodeService,
    WalkOrderManagerProvider,
    PolygonService,
    RadiusService,
    MapUtils,
    CurrencyPipe,
    PercentPipe,
    DecimalPipe,
    { provide: ErrorHandler, useClass: ErrorHandler }
  ]
})
export class ProvidersModule {}
