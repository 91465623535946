import { AppUserData, UserFarmAlertSettings } from '../../../typings/user';

import * as userProfileActions from '../../actions/app-settings/user-profile';
import * as parseImplementationActions from '../../actions/parse/parse-implementation';

export interface State {
  changedPassword: boolean;
  error: Error;
  expiredPassword: boolean;
  farmAlertSettings: UserFarmAlertSettings;
  firebaseInstallationIds: string[];
  googleAnalytics: boolean;
  pending: boolean;
  succeeded: boolean;
  user: AppUserData;
}

export const initialState: State = {
  changedPassword: false,
  error: null,
  expiredPassword: false,
  farmAlertSettings: {
    autoSubscribeFarmAlerts: null,
    receiveEmailNotifications: null,
    receivePushNotifications: null
  },
  firebaseInstallationIds: [],
  googleAnalytics: false,
  pending: false,
  succeeded: false,
  user: {
    badgerBucks: 0,
    company: '',
    dataProviderUsername: '',
    deviceId: '',
    email: '',
    enableFarmAlertAutoSubscriptions: false,
    firstName: '',
    isDeleted: false,
    lastName: '',
    phone: '',
    repCode: '',
    tosVersion: 0
  }
};

export function reducer(
  state = initialState,
  action: userProfileActions.UserProfileAction | parseImplementationActions.ParseActions
) {
  switch (action.type) {
    case userProfileActions.UserProfileActionTypes.SetAppUserData: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    }

    case userProfileActions.UserProfileActionTypes.SetDeviceId: {
      return {
        ...state,
        user: {
          ...state.user,
          deviceId: action.payload
        }
      };
    }

    case userProfileActions.UserProfileActionTypes.ResetPassword: {
      return {
        ...state,
        changedPassword: false,
        error: null,
        pending: true,
        succeeded: false
      };
    }

    case userProfileActions.UserProfileActionTypes.ResetPasswordSuccess: {
      return {
        ...state,
        changedPassword: true,
        error: null,
        pending: false,
        succeeded: true
      };
    }

    case userProfileActions.UserProfileActionTypes.ResetPasswordFail: {
      return {
        ...state,
        changedPassword: false,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }

    case userProfileActions.UserProfileActionTypes.VerifyUserSuccess: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    }
    case userProfileActions.UserProfileActionTypes.VerifyUserFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }
    case userProfileActions.UserProfileActionTypes.AuthenticateUser: {
      return {
        ...state,
        error: null,
        pending: true,
        succeeded: false
      };
    }
    case userProfileActions.UserProfileActionTypes.AuthenticateUserSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
        succeeded: true,
        user: {
          ...state.user,
          dataProviderUsername: action.payload.username
        }
      };
    }
    case userProfileActions.UserProfileActionTypes.AuthenticateUserFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }

    case userProfileActions.UserProfileActionTypes.ChangeExpiredPassword: {
      return {
        ...state,
        changedPassword: false,
        error: null,
        expiredPassword: action.payload,
        pending: false,
        succeeded: false
      };
    }

    case userProfileActions.UserProfileActionTypes.UpdateSettingsInfoFromInternalStorage: {
      return {
        ...state,
        error: null,
        pending: false,
        succeeded: false,
        user: action.payload.user || state.user
      };
    }

    case userProfileActions.UserProfileActionTypes.ResetUserProfile: {
      return {
        ...state,
        error: null,
        googleAnalytics: false,
        pending: false,
        succeeded: false,
        user: {
          ...initialState.user,
          deviceId: state.user.deviceId
        }
      };
    }

    case userProfileActions.UserProfileActionTypes.ResetUserProfileErrors: {
      return {
        ...state,
        error: null,
        pending: false,
        succeeded: false
      };
    }

    case parseImplementationActions.ParseActionTypes.UpdateUserSuccess: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    }

    case parseImplementationActions.ParseActionTypes.GetUserSuccess: {
      return {
        ...state,
        firebaseInstallationIds: action.payload.firebaseInstallationIds
      };
    }

    case parseImplementationActions.ParseActionTypes.UpdateUserFarmAlertSettings:
    case parseImplementationActions.ParseActionTypes.GetFarmAlertSettingsSuccess: {
      return {
        ...state,
        farmAlertSettings: action.payload
      };
    }

    case parseImplementationActions.ParseActionTypes.DeleteUserDataSuccess:
      return {
        ...state,
        user: {
          ...state.user,
          isDeleted: true
        }
      };

    default: {
      return state;
    }
  }
}

export const getIfPasswordIsExpired = (state: State) => state.expiredPassword;

export const getSucceeded = (state: State) => state.succeeded;
export const getError = (state: State) => state.error;

export const getUserData = (state: State) => state?.user;
export const getUserEmail = (state: State) => state?.user.email;
export const getDeviceId = (state: State) => state.user.deviceId;
export const getBadgerBucks = (state: State) => state.user.badgerBucks;

export const getFarmAlertSettings = (state: State) => state?.farmAlertSettings;
export const getFarmAlertSettingsAutoSubscribe = (state: State) => state?.farmAlertSettings.autoSubscribeFarmAlerts;

export const getFirebaseInstallationIds = (state: State) => state.firebaseInstallationIds;

export const getUserIsDeleted = (state: State) => state.user.isDeleted;
