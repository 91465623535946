import * as appConfigActions from '../../actions/app-config/search-info';

export interface State {
  error: Error;
  pending: boolean;
  searchKey: string;
  searchValue: string;
  selectedCounty: string;
  selectedState: string;
  succeeded: boolean;
}

export const initialState: State = {
  error: null,
  pending: false,
  searchKey: '',
  searchValue: '',
  selectedCounty: '',
  selectedState: '',
  succeeded: false
};

export function reducer(state = initialState, action: appConfigActions.SearchInfoAction) {
  switch (action.type) {
    case appConfigActions.SearchInfoActionTypes.ResetState: {
      return {
        ...state,
        error: null,
        pending: false,
        selectedState: initialState.selectedState,
        succeeded: false
      };
    }
    case appConfigActions.SearchInfoActionTypes.ResetCounty: {
      return {
        ...state,
        error: null,
        pending: false,
        selectedCounty: initialState.selectedCounty,
        succeeded: false
      };
    }

    case appConfigActions.SearchInfoActionTypes.SetSearchKey: {
      return {
        ...state,
        searchKey: action.payload
      };
    }
    case appConfigActions.SearchInfoActionTypes.SetSearchValue: {
      return {
        ...state,
        searchValue: action.payload
      };
    }

    case appConfigActions.SearchInfoActionTypes.SetInitialState: {
      return {
        ...state,
        selectedState: action.payload
      };
    }
    case appConfigActions.SearchInfoActionTypes.SetInitialCounty: {
      return {
        ...state,
        selectedCounty: action.payload
      };
    }

    case appConfigActions.SearchInfoActionTypes.UpdateState: {
      return {
        ...state,
        selectedState: action.payload
      };
    }
    case appConfigActions.SearchInfoActionTypes.UpdateCounty: {
      return {
        ...state,
        selectedCounty: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
