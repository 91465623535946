import { createAction, props } from '@ngrx/store';

import { DrawFarmModes } from '../../enums/walking-farm';

export const resetMapCenterSetting = createAction('[Map] Reset Map Center Setting.');

export const setMapCenterSetting = createAction('[Map] Set Map Center Setting.', props<{ isMapCentered: boolean }>());

export const resetSavedFarmLocation = createAction('[Map] Reset Saved Farm Location.');

export const setSavedFarmLocation = createAction(
  '[Map] Set Saved Farm Location.',
  props<{
    isInitialCoordinates?: boolean;
    savedFarmLocation: google.maps.LatLngLiteral;
  }>()
);

export const setDrawMode = createAction(
  '[Map] Set Draw Mode.',
  props<{
    drawMode: DrawFarmModes.Polygon | DrawFarmModes.Radius;
  }>()
);
