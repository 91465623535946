import { Injectable } from '@angular/core';

import {
  AffordabilityInputData,
  AffordabilityOutputData,
  AffordabilitySummaryData,
  MonthlyPaymentInputData,
  MonthlyPaymentOutputData,
  PaymentSummaryData
} from '../typings/calculator';

@Injectable({
  providedIn: 'root'
})
export class CalculatorsProvider {
  calculatePayment(data: Partial<MonthlyPaymentInputData>): MonthlyPaymentOutputData {
    const downPayment = this.calculateDownPayment(data.downPayments, data.homePrice);
    const loan = data.homePrice - downPayment;
    const monthlyData = this.calculateMonthlyPayment({ ...data, downPayments: downPayment }, loan, data.homePrice);

    return {
      buyerName: data.buyerName,
      downPayment,
      interestRate: data.interestRate,
      monthlyHomeownersInsurance: monthlyData.monthlyHomeownersInsurance,
      monthlyPayment: monthlyData.monthlyTotal,
      mortgageAmount: loan,
      mortgageTerm: data.mortgageTerms,
      parseUserId: data.userId,
      principalAndInterest: monthlyData.monthlyPrincipalInterest,
      privateMortgageInsurance: monthlyData.monthlyMortgageInsurance,
      propertyTax: monthlyData.monthlyPropertyTaxes,
      purchasePrice: data.homePrice
    };
  }

  calculateAffordability(data: Partial<AffordabilityInputData>): AffordabilityOutputData {
    const monthlyInterestRate = this.getMonthlyInterestRate(data.interestRate);

    const numberOfPayments = data.mortgageTerms * 12;
    const totalAmount = this.calculateAffordabilityAmount(monthlyInterestRate, numberOfPayments, data.monthlyPayments);
    const downPayment = this.calculateDownPayment(data.downPayments, totalAmount);

    const adjustedTaxRate = downPayment / totalAmount;
    const tmpPmt = data.monthlyPayments * (0.61 + adjustedTaxRate);

    const totalEstimatedAmount = this.round(
      this.calculateAffordabilityAmount(monthlyInterestRate, numberOfPayments, tmpPmt)
    );

    const monthlyTaxes = this._calculateAffordabilityMonthlyTaxes(data.annualPropertyTaxes, totalEstimatedAmount);
    const monthlyInsurance = this.calculateAffordabilityMonthlyInsurance(
      data.annualOwnersInsurance,
      totalEstimatedAmount
    );

    let adjustedPayment = data.monthlyPayments - monthlyTaxes - monthlyInsurance;
    let adjustAmount = this.round(
      this.calculateAffordabilityAmount(monthlyInterestRate, numberOfPayments, adjustedPayment)
    );
    let finalAmount = adjustAmount + downPayment;

    const percentCalculated = adjustAmount / finalAmount;
    let pmiMonthly = 0;

    if (percentCalculated > 0.8) {
      // LTV is more than 80% of the house's value
      const percentOfMaxLTV = ((percentCalculated - 0.8) * 100) / 20; // 20 is the range from 80% LTV to 100% LTV
      const pmiRate = Math.max(0.3, 1.15 * percentOfMaxLTV) / 100.0; // from looking online, the PMI is roughly .3% up to 1.15%
      const pmiAnnual = totalEstimatedAmount * pmiRate;
      pmiMonthly = this.round(pmiAnnual / 12);

      adjustedPayment -= pmiMonthly;

      adjustAmount = this.round(
        this.calculateAffordabilityAmount(monthlyInterestRate, numberOfPayments, adjustedPayment)
      );
    }

    finalAmount = adjustAmount + downPayment;

    return {
      buyerName: data.buyerName,
      downPayment,
      interestRate: data.interestRate,
      monthlyHomeownersInsurance: this.round(monthlyInsurance),
      monthlyPayment: this.round(adjustedPayment + monthlyInsurance + monthlyTaxes + pmiMonthly),
      mortgageAmount: this.round(adjustAmount),
      mortgageTerm: data.mortgageTerms,
      parseUserId: data.userId,
      principalAndInterest: this.round(adjustedPayment),
      privateMortgageInsurance: this.round(pmiMonthly),
      propertyTax: this.round(monthlyTaxes),
      purchasePrice: this.round(finalAmount)
    };
  }

  private calculateDownPayment(downPayments: number, homePrice: number) {
    return downPayments < 100 ? homePrice * (downPayments / 100) : downPayments;
  }

  private calculateMonthlyPayment(
    data: Partial<MonthlyPaymentInputData | AffordabilityInputData>,
    loan: number,
    homePrice: number
  ) {
    const monthlyPrincipalInterest = this.round(
      this.calculatePrincipalInterest(loan, data.interestRate, data.mortgageTerms)
    );
    const annualPropertyTaxes = this.getAnnualPropertyTaxes(data.annualPropertyTaxes, homePrice);
    const monthlyPropertyTaxes = this.round(this.getMonthlyAmount(annualPropertyTaxes));
    const monthlyHomeownersInsurance = this.round(this.getMonthlyAmount(data.annualOwnersInsurance));
    const monthlyMortgageInsurance = this.round(this.calculatePMI(homePrice, data.downPayments, loan));
    const monthlyTotal =
      monthlyPrincipalInterest + monthlyPropertyTaxes + monthlyHomeownersInsurance + monthlyMortgageInsurance;

    return {
      monthlyHomeownersInsurance,
      monthlyMortgageInsurance,
      monthlyPrincipalInterest,
      monthlyPropertyTaxes,
      monthlyTotal
    };
  }

  private getAnnualPropertyTaxes(annualAmount: number, homePrice: number) {
    return annualAmount < 100 ? (annualAmount / 100) * homePrice : annualAmount;
  }

  private getMonthlyAmount(amount: number) {
    return amount / 12 || 0;
  }

  private getMonthlyInterestRate(interestRatePercent: number) {
    const interestRate = interestRatePercent / 100;
    return interestRate / 12;
  }

  private getInterestPowerNum(monthlyInterestRate: number, mortgageTerm: number) {
    const totalPayments = mortgageTerm * 12;
    return Math.pow(1 + monthlyInterestRate, totalPayments);
  }

  private calculatePrincipalInterest(loanAmount: number, interestRate: number, mortgageTerm: number) {
    const monthlyInterestRate = this.getMonthlyInterestRate(interestRate);
    const interestPowerNum = this.getInterestPowerNum(monthlyInterestRate, mortgageTerm);
    const factor1 = monthlyInterestRate * interestPowerNum;
    const factor2 = interestPowerNum - 1;
    return (loanAmount * factor1) / factor2;
  }

  private calculatePMI(salePrice: number, downPayment: number, loan: number) {
    if (downPayment < salePrice * 0.2) {
      const percentCalculated = loan / salePrice;
      const percentOfMaxLTV = ((percentCalculated - 0.8) * 100) / 20;

      const pmiRate = Math.max(0.3, 1.15 * percentOfMaxLTV) / 100;

      const pmiAnnual = loan * pmiRate;
      return pmiAnnual / 12;
    }

    return 0;
  }

  private calculateAffordabilityAmount(rate: number, numberOfPayments: number, pmt: number) {
    return (pmt / rate) * (1 - Math.pow(1 + rate, -numberOfPayments));
  }

  private _calculateAffordabilityMonthlyTaxes(propertyTax: number, totalEstimatedAmount: number) {
    let annualTaxes;
    let annualTaxRate = 0;
    if (propertyTax === 0) {
      // use the default tax rate because we didn't get anything
      annualTaxRate = 0.0;
      annualTaxes = totalEstimatedAmount * annualTaxRate;
    } else if (propertyTax < 100) {
      // property tax < 100 means it is some other percentage
      annualTaxRate = propertyTax / 100;
      annualTaxes = totalEstimatedAmount * annualTaxRate;
    } else if (propertyTax >= 100) {
      // property tax >= 100 means a whole dollar amount
      annualTaxes = propertyTax;
    } else {
      // use the default tax rate because we didn't get anything - just in case
      annualTaxRate = 1.3 / 100.0;
      annualTaxes = totalEstimatedAmount * annualTaxRate;
    }

    return this.round(annualTaxes / 12);
  }

  private calculateAffordabilityMonthlyInsurance(homeOwnersInsurance: number, totalEstimatedAmount: number) {
    let annualHomeOwnersInsurance: number;

    if (homeOwnersInsurance === 0) {
      // use the default tax rate because we didn't get anything
      annualHomeOwnersInsurance = 0;
    } else if (homeOwnersInsurance > 0) {
      annualHomeOwnersInsurance = homeOwnersInsurance;
    } else {
      // use the default tax rate because we didn't get anything
      annualHomeOwnersInsurance = (totalEstimatedAmount / 1000.0) * 3.5;
    }

    return annualHomeOwnersInsurance / 12;
  }

  public formatPaymentReportData(data: AffordabilityOutputData) {
    let result: PaymentSummaryData | null = null;

    if (data) {
      const initialGraphicData = [
        data.principalAndInterest,
        data.privateMortgageInsurance,
        data.propertyTax,
        data.monthlyHomeownersInsurance
      ];
      const initialGraphicLabels = ['P&I', 'PMI', 'Taxes', 'Insurance'];

      const checkedPaymentSummaryData = this.computePaymentGraphicData(initialGraphicData, initialGraphicLabels);

      result = {
        downPayment: data.downPayment,
        homeValue: data.purchasePrice,
        summary: {
          format: 'currency',
          graphicData: checkedPaymentSummaryData.graphicData,
          graphicLabels: checkedPaymentSummaryData.graphicLabels,
          graphicLegend: checkedPaymentSummaryData.graphicLegend,
          icon: 'icon-payment-results',
          label: 'Monthly Payment',
          value: data.monthlyPayment
        }
      };
    }

    return result;
  }

  formatAffordabilityReportData(data: AffordabilityOutputData) {
    let result: AffordabilitySummaryData | null = null;

    if (data) {
      result = {
        summary: {
          additionalInfo: [
            {
              label: 'Term: ',
              unit: ` year${data.mortgageTerm === 1 ? '' : 's'}`,
              value: data.mortgageTerm
            },
            {
              label: 'Rate: ',
              unit: '%',
              value: data.interestRate
            }
          ],
          format: 'currency',
          graphicData: [data.mortgageAmount, data.downPayment],
          graphicLabels: ['Mor Amount', 'Down Pay'],
          graphicLegend: [
            {
              label: 'Mortgage Amount ',
              value: data.mortgageAmount
            },
            {
              label: 'Down Payment ',
              value: data.downPayment
            }
          ],
          icon: 'icon-affordability-results',
          label: 'You could afford a home up to',
          value: data.purchasePrice
        }
      };
    }

    return result;
  }

  computePaymentGraphicData(graphicData: number[], graphicLabels: string[]) {
    const graphicDataArr: number[] = [];
    const graphicLabelsArr: string[] = [];
    const graphicLegendArr: { label: string; value: number }[] = [];

    graphicData.forEach((data, i) => {
      if (data > 0) {
        graphicDataArr.push(data);
        graphicLabelsArr.push(graphicLabels[i]);
        graphicLegendArr.push({
          label: `${graphicLabels[i]} `,
          value: data
        });
      }
    });

    return {
      graphicData: graphicDataArr,
      graphicLabels: graphicLabelsArr,
      graphicLegend: graphicLegendArr
    };
  }

  private round(value: number, decimals: number = 2) {
    const roundValue = Number(value + 'e' + decimals);
    return Number(Math.round(roundValue) + 'e-' + decimals);
  }
}
