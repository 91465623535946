import { CurrencyPipe, PercentPipe } from '@angular/common';
import { Directive, ElementRef, Injector, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

import { isNull } from 'lodash-es';
import { timer } from 'rxjs';
import { distinctUntilChanged, filter, throttleTime } from 'rxjs/operators';

const options = {
  amount: {
    currency: 'USD',
    symbol: '$'
  },
  rate: {
    maxValue: 99,
    symbol: '%'
  }
};

@Directive({
  providers: [CurrencyPipe, PercentPipe],
  selector: '[smdRateAmount]'
})
export class RateAmountDirective implements OnInit {
  @Input('smdRateAmount') type: '' | 'rate' | 'amount' | 'rateAmount' = '';

  private inputField: HTMLInputElement;

  constructor(
    private injector: Injector,
    private ngControl: NgControl,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.updateValues(this.ngControl?.control.value?.toString());

    this.ngControl?.control.valueChanges
      .pipe(distinctUntilChanged(), throttleTime(300, undefined, { leading: true, trailing: true }))
      .subscribe((value) => {
        const formattedValue = value?.toString().replace(/([$,%])/g, '') || '';

        this.inputField = this.elementRef.nativeElement.querySelector('input');

        this.updateValues(formattedValue);
      });
  }

  private updateValues(value: string) {
    const lastCharacter = value?.substring(value.length - 1, value.length);

    if (lastCharacter === '.') {
      this.ngControl?.valueAccessor.writeValue(value);
    } else if (lastCharacter === '0' && value?.includes('.')) {
      this.ngControl?.valueAccessor.writeValue(parseFloat(value).toFixed(value.split('.')[1].length));
    } else {
      const formattedValue = parseFloat(value);

      let modelValue = this.getModelValue(formattedValue);
      const viewValue = this.getViewValue(formattedValue);

      if (this.type === 'rateAmount' && this.isRateValue(formattedValue)) {
        modelValue = Number(viewValue.replace(/%/g, ''));
      }

      this.ngControl?.control.setValue(modelValue);
      this.ngControl?.valueAccessor.writeValue(viewValue);

      if (['rate', 'rateAmount'].includes(this.type) && this.isRateValue(formattedValue)) {
        timer(1)
          .pipe(filter(() => !!formattedValue.toString().length))
          .subscribe(() => {
            this.inputField?.setSelectionRange(formattedValue.toString().length, formattedValue.toString().length);
          });
      }
    }
  }

  private getModelValue(value: number) {
    let result = value;

    if (!isNull(value) && !isNaN(value)) {
      const valueLength = value.toString().length;

      if (value < 0) {
        result = 0;
      } else if (
        this.type === 'rate' &&
        value.toString().substring(valueLength - 1, valueLength) !== '.' &&
        value > options.rate.maxValue
      ) {
        result = options.rate.maxValue;
      }
    }

    return result;
  }

  private getViewValue(value: number) {
    let result = '';

    if (!isNull(value) && !isNaN(value)) {
      let rawResult: string;
      let formattedValue: number;

      switch (this.type) {
        case 'amount':
          result = this.getAmountViewValue(value);
          break;

        case 'rateAmount':
          if (this.isRateValue(value)) {
            result = this.getRateViewValue(value);
          } else {
            result = this.getAmountViewValue(value);
          }
          break;

        case 'rate':
          rawResult = this.getRateViewValue(value);
          formattedValue = parseFloat(rawResult);
          rawResult = formattedValue > options.rate.maxValue ? `${options.rate.maxValue}%` : rawResult;
          result = rawResult !== '0%' ? rawResult : '';
          break;

        default:
          result = this.getRateViewValue(value);
          break;
      }
    }

    return result;
  }

  private isRateValue(value: number) {
    const modelValue = this.getModelValue(value);
    return ['rate', 'rateAmount'].includes(this.type) && modelValue <= options.rate.maxValue;
  }

  private getAmountViewValue(value: number) {
    let result = '';

    if (!isNull(value) && !isNaN(value)) {
      const formattedView = value.toString();
      result = formattedView;

      if (formattedView.substring(formattedView.length - 1, formattedView.length) !== '.') {
        result = this.injector.get(CurrencyPipe).transform(value, options.amount.currency, 'symbol', '1.0-3');
      }
    }

    return result;
  }

  private getRateViewValue(value: number) {
    let result = '';

    if (!isNull(value) && !isNaN(value)) {
      const formattedView = value.toString();
      result = formattedView;

      if (formattedView.substring(formattedView.length - 1, formattedView.length) !== '.') {
        result = this.injector.get(PercentPipe).transform(Number(value) / 100, '1.0-3');
      }
    }

    return result;
  }
}
