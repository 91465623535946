import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { UserRoles } from '../../enums/user';

import * as fromAppConfigSelectors from '../../store/selectors/app-config';

import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  userRole = UserRoles.Guest;

  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store
  ) {
    this.store.select(fromAppConfigSelectors.getIsAuthenticated).subscribe((isAuthenticated) => {
      this.userRole = isAuthenticated ? UserRoles.LoggedUser : UserRoles.Guest;
    });
  }

  /**
   * Main guard method that handles the canActivate functionality.
   */
  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve) => {
      resolve(this.handleUserRoleValidation(route));
    });
  }

  private handleUserRoleValidation(route: ActivatedRouteSnapshot) {
    this.authService.setUserRole(this.userRole);
    const shouldHaveAccess = this.shouldHaveAccess(route);
    return this.redirectUser(shouldHaveAccess);
  }

  private shouldHaveAccess(route: ActivatedRouteSnapshot) {
    return route?.data?.role === this.userRole;
  }

  private redirectUser(shouldHaveAccess: boolean) {
    let result: boolean | UrlTree = shouldHaveAccess;

    if (!shouldHaveAccess) {
      switch (this.userRole) {
        case UserRoles.Guest:
          result = this.router.createUrlTree(['/', 'welcome']);
          break;

        case UserRoles.LoggedUser:
          result = this.router.createUrlTree(['/', 'home']);
          break;

        default:
          result = this.router.createUrlTree(['/']);
          break;
      }
    }

    return result;
  }
}
