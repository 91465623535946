import { Injectable, NgZone } from '@angular/core';

import { orderBy } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

import { PfLocation } from '../../typings/app';

@Injectable()
export class WalkOrderManagerProvider {
  private mapObj: google.maps.Map;
  private polyline: google.maps.Polyline;
  private polyLines: google.maps.Polyline[];
  private markers = [];
  private initialMarkers = [];
  private orderedMarkers = [];
  private numberOfLines = 0;

  private numberOfLinesSubject: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(private ngZone: NgZone) {
    this.polyLines = [];
  }

  setMapObj(map: google.maps.Map) {
    this.mapObj = map;
  }

  setMarkers(markers: PfLocation[]) {
    this.markers = markers || [];
    this.initialMarkers = markers || [];
  }

  initialize() {
    this.disableMapActions();
    const that = this;
    google.maps.event.addListener(this.mapObj, 'mousedown', function () {
      that.drawFreeHand();
    });
  }

  disableMapActions() {
    this.mapObj.setOptions({
      disableDoubleClickZoom: false,
      draggable: false,
      scrollwheel: false
    });
  }

  getNumberOfLines() {
    return this.numberOfLinesSubject.asObservable();
  }

  drawFreeHand() {
    this.polyline = new google.maps.Polyline({
      clickable: false,
      geodesic: true,
      map: this.mapObj,
      strokeColor: '#f4ee43',
      strokeOpacity: 1,
      strokeWeight: 10,
      zIndex: 1000
    });
    const that = this;

    const move = google.maps.event.addListener(this.mapObj, 'mousemove', function (e) {
      that.polyline.getPath().push(e.latLng);
    });

    google.maps.event.addListenerOnce(this.mapObj, 'mouseup', function () {
      google.maps.event.removeListener(move);
      const path = that.polyline.getPath();
      const ployPoints = path.getArray();

      const foundMarkers = [];

      ployPoints.forEach((point) => {
        const foundMarkersForPoint = [];
        const markersLeftToSort = [];

        that.markers.forEach((marker) => {
          const markerLatLng = new google.maps.LatLng(marker.latitude, marker.longitude);
          const distance = google.maps.geometry.spherical.computeDistanceBetween(point, markerLatLng);

          if (distance < 15) {
            marker = Object.assign({}, marker, { distance });
            foundMarkersForPoint.push(marker);
          } else {
            markersLeftToSort.push(marker);
          }
        });

        that.markers = markersLeftToSort;

        const sortedMarkers = that.sortByDistance(foundMarkersForPoint);
        foundMarkers.push(...sortedMarkers);
      });

      that.orderedMarkers = [...foundMarkers, ...that.orderedMarkers];

      google.maps.event.clearListeners(that.mapObj.getDiv(), 'mousedown');
      that.ngZone.run(() => {
        that.finishLine();
      });
    });
  }

  sortByDistance(arr: PfLocation[]) {
    return orderBy(arr, ['distance'], ['asc']);
  }

  getFinalOrder() {
    return [...this.orderedMarkers, ...this.markers];
  }

  finishLine() {
    this.polyLines.push(this.polyline);
    this.setNumberOfLines();
  }

  setNumberOfLines() {
    this.numberOfLines = this.polyLines.length;
    this.numberOfLinesSubject.next(this.numberOfLines);
  }

  clearWalkOrderLines() {
    this.polyLines.forEach((line) => {
      if (!!line && !!line.getPath()) {
        line.getPath().clear();
      }
    });
  }

  clearWalkOrder() {
    this.ngZone.run(() => {
      this.clearWalkOrderLines();
      this.polyLines = [];
      this.setNumberOfLines();
      this.markers = this.initialMarkers || [];
      this.clearMarkersDistance();
      this.orderedMarkers = [];
    });
  }

  clearMarkersDistance() {
    this.orderedMarkers.forEach((marker) => {
      marker.distance = null;
    });
  }
}
