import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TabsChangeData, TabsItem } from '../../../typings/tabs';

@Component({
  selector: 'smd-content-tabs',
  templateUrl: './content-tabs.component.html'
})
export class ContentTabsComponent {
  selectedTab: string;
  tabsData: TabsItem<string>[];

  @Input() labelCSSClass = '';

  @Input()
  set tabs(tabs: TabsItem<string>[]) {
    if (tabs) {
      this.selectedTab = tabs.find((tabElement) => tabElement.isActive)?.value;
      this.tabsData = tabs;
    }
  }

  @Output() tabChange?: EventEmitter<TabsChangeData<string>> = new EventEmitter<TabsChangeData<string>>();

  constructor() {}

  tabChanged(event?) {
    const activeTab = event?.detail?.value || this.selectedTab;

    const updatedTabs = this.tabsData.map((tab) => {
      if (tab.value === activeTab) {
        tab.isActive = true;
        this.selectedTab = activeTab;
      } else {
        tab.isActive = false;
      }

      return tab;
    });

    this.tabChange.emit({
      activeTab,
      updatedTabs
    });
  }
}
