export const adminPortalEndpoints = {
  calculators: 'Calculators',
  farms: 'Farms',
  inAppPurchases: 'InAppPurchases',
  locations: 'Locations',
  logs: 'Logs',
  mobileUsers: 'MobileUsers',
  propertyProfiles: 'PropertyProfiles',
  reports: 'Reports',
  stripe: 'Stripe',
  subscriptions: 'Subscriptions',
  systemConfigurations: 'SystemConfigurations'
};

export const adminPortalDependencies = {
  error: {
    calculatorsAmortization: {
      message: 'A problem occurred while calculating the amortization details.',
      title: 'Oops!'
    },
    mobileUsersDelete: {
      message:
        'A problem occurred while deleting your personal data.' +
        'If this problem persists please contact support@redshedtechnology.',
      title: 'Oops!'
    },
    subscriptionDetails: {
      message: 'A problem occurred while loading the subscription details.',
      title: 'Oops!'
    },
    systemConfigurations: {
      message: 'No debug information available',
      subtitle: 'Failed to load the application configuration',
      title: 'Initialization Issue'
    }
  }
};
