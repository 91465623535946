export const usaStates = [
  {
    abbreviation: 'AL',
    contiguous: true,
    name: 'Alabama',
    territory: false
  },
  {
    abbreviation: 'AK',
    contiguous: false,
    name: 'Alaska',
    territory: false
  },
  {
    abbreviation: 'AS',
    contiguous: false,
    name: 'American Samoa',
    territory: true
  },
  {
    abbreviation: 'AZ',
    contiguous: true,
    name: 'Arizona',
    territory: false
  },
  {
    abbreviation: 'AR',
    contiguous: true,
    name: 'Arkansas',
    territory: false
  },
  {
    abbreviation: 'CA',
    contiguous: true,
    name: 'California',
    territory: false
  },
  {
    abbreviation: 'CO',
    contiguous: true,
    name: 'Colorado',
    territory: false
  },
  {
    abbreviation: 'CT',
    contiguous: true,
    name: 'Connecticut',
    territory: false
  },
  {
    abbreviation: 'DE',
    contiguous: true,
    name: 'Delaware',
    territory: false
  },
  {
    abbreviation: 'DC',
    contiguous: true,
    name: 'District Of Columbia',
    territory: false
  },
  {
    abbreviation: 'FL',
    contiguous: true,
    name: 'Florida',
    territory: false
  },
  {
    abbreviation: 'GA',
    contiguous: true,
    name: 'Georgia',
    territory: false
  },
  {
    abbreviation: 'GU',
    contiguous: false,
    name: 'Guam',
    territory: true
  },
  {
    abbreviation: 'HI',
    contiguous: false,
    name: 'Hawaii',
    territory: false
  },
  {
    abbreviation: 'ID',
    contiguous: true,
    name: 'Idaho',
    territory: false
  },
  {
    abbreviation: 'IL',
    contiguous: true,
    name: 'Illinois',
    territory: false
  },
  {
    abbreviation: 'IN',
    contiguous: true,
    name: 'Indiana',
    territory: false
  },
  {
    abbreviation: 'IA',
    contiguous: true,
    name: 'Iowa',
    territory: false
  },
  {
    abbreviation: 'KS',
    contiguous: true,
    name: 'Kansas',
    territory: false
  },
  {
    abbreviation: 'KY',
    contiguous: true,
    name: 'Kentucky',
    territory: false
  },
  {
    abbreviation: 'LA',
    contiguous: true,
    name: 'Louisiana',
    territory: false
  },
  {
    abbreviation: 'ME',
    contiguous: true,
    name: 'Maine',
    territory: false
  },
  {
    abbreviation: 'MD',
    contiguous: true,
    name: 'Maryland',
    territory: false
  },
  {
    abbreviation: 'MA',
    contiguous: true,
    name: 'Massachusetts',
    territory: false
  },
  {
    abbreviation: 'MI',
    contiguous: true,
    name: 'Michigan',
    territory: false
  },
  {
    abbreviation: 'MN',
    contiguous: true,
    name: 'Minnesota',
    territory: false
  },
  {
    abbreviation: 'MS',
    contiguous: true,
    name: 'Mississippi',
    territory: false
  },
  {
    abbreviation: 'MO',
    contiguous: true,
    name: 'Missouri',
    territory: false
  },
  {
    abbreviation: 'MT',
    contiguous: true,
    name: 'Montana',
    territory: false
  },
  {
    abbreviation: 'NE',
    contiguous: true,
    name: 'Nebraska',
    territory: false
  },
  {
    abbreviation: 'NV',
    contiguous: true,
    name: 'Nevada',
    territory: false
  },
  {
    abbreviation: 'NH',
    contiguous: true,
    name: 'New Hampshire',
    territory: false
  },
  {
    abbreviation: 'NJ',
    contiguous: true,
    name: 'New Jersey',
    territory: false
  },
  {
    abbreviation: 'NM',
    contiguous: true,
    name: 'New Mexico',
    territory: false
  },
  {
    abbreviation: 'NY',
    contiguous: true,
    name: 'New York',
    territory: false
  },
  {
    abbreviation: 'NC',
    contiguous: true,
    name: 'North Carolina',
    territory: false
  },
  {
    abbreviation: 'ND',
    contiguous: true,
    name: 'North Dakota',
    territory: false
  },
  {
    abbreviation: 'MP',
    contiguous: false,
    name: 'Northern Mariana Islands',
    territory: true
  },
  {
    abbreviation: 'OH',
    contiguous: true,
    name: 'Ohio',
    territory: false
  },
  {
    abbreviation: 'OK',
    contiguous: true,
    name: 'Oklahoma',
    territory: false
  },
  {
    abbreviation: 'OR',
    contiguous: true,
    name: 'Oregon',
    territory: false
  },
  {
    abbreviation: 'PW',
    contiguous: false,
    name: 'Palau',
    territory: true
  },
  {
    abbreviation: 'PA',
    contiguous: true,
    name: 'Pennsylvania',
    territory: false
  },
  {
    abbreviation: 'PR',
    contiguous: false,
    name: 'Puerto Rico',
    territory: true
  },
  {
    abbreviation: 'RI',
    contiguous: true,
    name: 'Rhode Island',
    territory: false
  },
  {
    abbreviation: 'SC',
    contiguous: true,
    name: 'South Carolina',
    territory: false
  },
  {
    abbreviation: 'SD',
    contiguous: true,
    name: 'South Dakota',
    territory: false
  },
  {
    abbreviation: 'TN',
    contiguous: true,
    name: 'Tennessee',
    territory: false
  },
  {
    abbreviation: 'TX',
    contiguous: true,
    name: 'Texas',
    territory: false
  },
  {
    abbreviation: 'UT',
    contiguous: true,
    name: 'Utah',
    territory: false
  },
  {
    abbreviation: 'VT',
    contiguous: true,
    name: 'Vermont',
    territory: false
  },
  {
    abbreviation: 'VI',
    contiguous: false,
    name: 'Virgin Islands',
    territory: true
  },
  {
    abbreviation: 'VA',
    contiguous: true,
    name: 'Virginia',
    territory: false
  },
  {
    abbreviation: 'WA',
    contiguous: true,
    name: 'Washington',
    territory: false
  },
  {
    abbreviation: 'WV',
    contiguous: true,
    name: 'West Virginia',
    territory: false
  },
  {
    abbreviation: 'WI',
    contiguous: true,
    name: 'Wisconsin',
    territory: false
  },
  {
    abbreviation: 'WY',
    contiguous: true,
    name: 'Wyoming',
    territory: false
  }
];
