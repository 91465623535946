import { Action } from '@ngrx/store';

import { MonetizationIapPhoneAndEmailCsvError } from '../../../typings/monetization';

export enum FarmReportDetailsActionTypes {
  ExportCSV = '[FarmReportDetails] Export CSV',
  ExportCSVFail = '[FarmReportDetails] Export CSV Fail',
  ExportCSVSuccess = '[FarmReportDetails] Export CSV Success',
  ExportIapPhoneAndEmailCSV = '[FarmReportDetails] Export IAP Phone And Email CSV',
  ExportIapPhoneAndEmailCSVFailure = '[FarmReportDetails] Export IAP Phone And Email CSV Failure',
  ExportIapPhoneAndEmailCSVSuccess = '[FarmReportDetails] Export IAP Phone And Email CSV Success',
  ExportPdfInAveryLabels = '[FarmReportDetails] ExportPdfInAveryLabels',
  ExportPdfInAveryLabelsFail = '[FarmReportDetails] Export Pdf In Avery Labels Fail',
  ExportPdfInAveryLabelsSuccess = '[FarmReportDetails] Export Pdf In Avery Labels Success',
  ExportPdfInPages = '[FarmReportDetails] Export Pdf In Pages',
  ExportPdfInPagesFail = '[FarmReportDetails] Export Pdf In Pages Fail',
  ExportPdfInPagesSuccess = '[FarmReportDetails] Export Pdf In Pages Success',
  ResetExportCSV = '[FarmReportDetails] Reset Export CSV',
  ResetExportPdfInAveryLabels = '[FarmReportDetails] Reset Export Pdf In Avery Labels',
  ResetIapPhoneAndEmailCSVState = '[FarmReportDetails] Reset IAP Phone And Email CSV State'
}

export class ExportPdfInPages implements Action {
  readonly type = FarmReportDetailsActionTypes.ExportPdfInPages;

  constructor(public payload: { exportData: { propertiesPerPage: number; reportId: string } }) {}
}
export class ExportPdfInPagesSuccess implements Action {
  readonly type = FarmReportDetailsActionTypes.ExportPdfInPagesSuccess;

  constructor(public payload: Blob) {}
}
export class ExportPdfInPagesFail implements Action {
  readonly type = FarmReportDetailsActionTypes.ExportPdfInPagesFail;

  constructor(public payload: Error) {}
}

export class ExportPdfInAveryLabels implements Action {
  readonly type = FarmReportDetailsActionTypes.ExportPdfInAveryLabels;

  constructor(public payload: string) {}
}
export class ExportPdfInAveryLabelsSuccess implements Action {
  readonly type = FarmReportDetailsActionTypes.ExportPdfInAveryLabelsSuccess;

  constructor(public payload: string) {}
}
export class ExportPdfInAveryLabelsFail implements Action {
  readonly type = FarmReportDetailsActionTypes.ExportPdfInAveryLabelsFail;

  constructor(public payload: Error) {}
}
export class ResetExportPdfInAveryLabels implements Action {
  readonly type = FarmReportDetailsActionTypes.ResetExportPdfInAveryLabels;

  constructor() {}
}

export class ExportCSV implements Action {
  readonly type = FarmReportDetailsActionTypes.ExportCSV;

  constructor(public payload: string) {}
}
export class ExportCSVSuccess implements Action {
  readonly type = FarmReportDetailsActionTypes.ExportCSVSuccess;

  constructor(public payload: string) {}
}
export class ExportCSVFail implements Action {
  readonly type = FarmReportDetailsActionTypes.ExportCSVFail;

  constructor(public payload: Error) {}
}
export class ResetExportCSV implements Action {
  readonly type = FarmReportDetailsActionTypes.ResetExportCSV;

  constructor() {}
}

export class ExportIapPhoneAndEmailCSV implements Action {
  readonly type = FarmReportDetailsActionTypes.ExportIapPhoneAndEmailCSV;

  constructor(
    public payload: {
      farmId: string;
    }
  ) {}
}
export class ExportIapPhoneAndEmailCSVSuccess implements Action {
  readonly type = FarmReportDetailsActionTypes.ExportIapPhoneAndEmailCSVSuccess;

  constructor(
    public payload: {
      csvResult: string;
    }
  ) {}
}
export class ExportIapPhoneAndEmailCSVFailure implements Action {
  readonly type = FarmReportDetailsActionTypes.ExportIapPhoneAndEmailCSVFailure;

  constructor(
    public payload: {
      error: MonetizationIapPhoneAndEmailCsvError;
    }
  ) {}
}
export class ResetIapPhoneAndEmailCSVState implements Action {
  readonly type = FarmReportDetailsActionTypes.ResetIapPhoneAndEmailCSVState;

  constructor() {}
}

export type FarmReportDetailsActions =
  | ExportPdfInPages
  | ExportPdfInPagesSuccess
  | ExportPdfInPagesFail
  | ExportPdfInAveryLabels
  | ExportPdfInAveryLabelsSuccess
  | ExportPdfInAveryLabelsFail
  | ResetExportPdfInAveryLabels
  | ExportCSV
  | ExportCSVSuccess
  | ExportCSVFail
  | ResetExportCSV
  | ExportIapPhoneAndEmailCSV
  | ExportIapPhoneAndEmailCSVSuccess
  | ExportIapPhoneAndEmailCSVFailure
  | ResetIapPhoneAndEmailCSVState;
