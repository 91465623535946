import { Injectable } from '@angular/core';

import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { BehaviorSubject } from 'rxjs';

import { KeyboardSettingsModel } from '../../../typings/keyboard';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {
  private keyboardSettingsHandler = new BehaviorSubject<KeyboardSettingsModel>({
    isVisible: false
  });

  constructor(private keyboard: Keyboard) {
    this.subscribeToEvents();
  }

  getHandlerSettings() {
    return this.keyboardSettingsHandler.asObservable();
  }

  setHandlerSettings(newSettings: KeyboardSettingsModel) {
    this.keyboardSettingsHandler.next({
      ...this.keyboardSettingsHandler.value,
      ...newSettings
    });
  }

  private subscribeToEvents() {
    this.keyboard.onKeyboardShow().subscribe(() => {
      this.keyboard.disableScroll(true);

      this.setHandlerSettings({
        isVisible: true
      });
    });

    this.keyboard.onKeyboardHide().subscribe(() => {
      this.keyboard.disableScroll(false);

      this.setHandlerSettings({
        isVisible: false
      });
    });
  }
}
