import { Action } from '@ngrx/store';

import { MapDrawnPoint } from '../../../typings/map';

export enum PolygonActionTypes {
  RemovePolygonPoints = '[Polygon] Remove Polygon Points',
  ResetPolygon = '[Polygon] Reset Polygon',
  SavePolygonPoints = '[Polygon] Save Polygon Points',
  UpdatePolygonCompletedFlag = '[Polygon] Update Polygon Completed Flag',
  UpdatePolygonStartedFlag = '[Polygon] Update Polygon Started Flag'
}

export class SavePolygonPoints implements Action {
  readonly type = PolygonActionTypes.SavePolygonPoints;

  constructor(public payload: { points: MapDrawnPoint[] }) {}
}
export class RemovePolygonPoints implements Action {
  readonly type = PolygonActionTypes.RemovePolygonPoints;

  constructor(public payload: { points: MapDrawnPoint[] }) {}
}
export class UpdatePolygonStartedFlag implements Action {
  readonly type = PolygonActionTypes.UpdatePolygonStartedFlag;

  constructor(public payload: { polygonStarted: boolean }) {}
}
export class UpdatePolygonCompletedFlag implements Action {
  readonly type = PolygonActionTypes.UpdatePolygonCompletedFlag;

  constructor(public payload: { polygonCompleted: boolean }) {}
}
export class ResetPolygon implements Action {
  readonly type = PolygonActionTypes.ResetPolygon;

  constructor() {}
}

export type PolygonActions =
  | SavePolygonPoints
  | RemovePolygonPoints
  | UpdatePolygonStartedFlag
  | UpdatePolygonCompletedFlag
  | ResetPolygon;
