import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DropdownItem } from '../../../typings/components';

@Component({
  selector: 'smd-dropdown-menu',
  templateUrl: './dropdown-menu.component.html'
})
export class DropdownMenuComponent {
  @Input() dataToDisplay: DropdownItem[];
  @Output() clickDropDownItem: EventEmitter<DropdownItem> = new EventEmitter<DropdownItem>();

  constructor() {}

  triggerAction(event: DropdownItem) {
    this.clickDropDownItem.emit(event);
  }
}
