import { ErrorHandler, Injectable } from '@angular/core';

import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private loggerService: LoggerService) {
    this.loggerService.init();
  }

  handleError(error) {
    this.loggerService.logPapertrailError(error);
    throw error;
  }
}
