import { Action } from '@ngrx/store';

import { PfLocation } from '../../../typings/app';
import { AdminPortalLocationCounty } from '../../../typings/location';
import { SearchAddress } from '../../../typings/map';
import { PropertyProfileData } from '../../../typings/property-profile';
import { SearchByOwnerData, SearchByParcelNumberData } from '../../../typings/search';

export enum SearchTypesActionTypes {
  GetCountiesForState = '[Search Types] Get Counties For State',
  GetCountiesList = '[Search Types] Get Counties List',
  GetSearchResultsFail = '[Search Types] Get Search Results Fail',
  GetSearchResultsSuccess = '[Search Types] Get Search Results Success',
  GetSingleAddressMarker = '[Search Types] Get Single Address Marker',
  GetSingleAddressMarkerFail = '[Search Types] Get Single Address Marker Fail',
  ResetMarkers = '[Search Types] Reset Markers',
  ResetSearchAddress = '[Search Types] Reset Search Address',
  ResetSearchError = '[Search Types] Reset Error',
  ResetSearchTypes = '[Search Types] Reset Search Types',
  SearchByAddress = '[Search Types] Search By Address',
  SearchByOwner = '[Search Types] Search By Owner',
  SearchByParcelNumber = '[Search Types] Search By Parcel Number',
  SetCountiesList = '[Search Types] Set Counties List',
  SetMarkers = '[Search Types] Set Markers',
  SetSearchResultRawData = '[Search Types] Get Search Result Raw Data',
  SetSearchValue = '[Search Types] Set Search Value',
  SetSelectedCounty = '[Search Types] Set Selected County',
  SetSelectedState = '[Search Types] Set Selected State',
  SetStatesList = '[Search Types] Set States List',
  UpdateMapAfterSearch = '[Search Types] Update Map After Search'
}

export class SetSearchValue implements Action {
  readonly type = SearchTypesActionTypes.SetSearchValue;

  constructor(public payload: string) {}
}
export class SearchByAddress implements Action {
  readonly type = SearchTypesActionTypes.SearchByAddress;

  constructor(
    public payload: { address: string; location: { latitude: number; longitude: number } | null; zip: string }
  ) {}
}
export class ResetSearchAddress implements Action {
  readonly type = SearchTypesActionTypes.ResetSearchAddress;

  constructor() {}
}
export class SearchByOwner implements Action {
  readonly type = SearchTypesActionTypes.SearchByOwner;

  constructor(public payload: Partial<SearchByOwnerData>) {}
}
export class SearchByParcelNumber implements Action {
  readonly type = SearchTypesActionTypes.SearchByParcelNumber;

  constructor(public payload: Partial<SearchByParcelNumberData>) {}
}
export class SetSearchResultRawData implements Action {
  readonly type = SearchTypesActionTypes.SetSearchResultRawData;

  constructor(public payload: { d: PropertyProfileData }) {}
}
export class GetSearchResultsSuccess implements Action {
  readonly type = SearchTypesActionTypes.GetSearchResultsSuccess;

  constructor(public payload: SearchAddress[]) {}
}
export class GetSearchResultsFail implements Action {
  readonly type = SearchTypesActionTypes.GetSearchResultsFail;

  constructor(public payload: Error) {}
}
export class ResetSearchTypes implements Action {
  readonly type = SearchTypesActionTypes.ResetSearchTypes;

  constructor() {}
}
export class SetMarkers implements Action {
  readonly type = SearchTypesActionTypes.SetMarkers;

  constructor(public payload: PfLocation[]) {}
}
export class ResetMarkers implements Action {
  readonly type = SearchTypesActionTypes.ResetMarkers;

  constructor() {}
}

export class ResetSearchError implements Action {
  readonly type = SearchTypesActionTypes.ResetSearchError;

  constructor() {}
}

export class UpdateMapAfterSearch implements Action {
  readonly type = SearchTypesActionTypes.UpdateMapAfterSearch;

  constructor(public payload: { location: object | null; results: SearchAddress[] }) {}
}

export class GetCountiesForState implements Action {
  readonly type = SearchTypesActionTypes.GetCountiesForState;

  constructor(public payload: string) {}
}

export class GetSingleAddressMarker implements Action {
  readonly type = SearchTypesActionTypes.GetSingleAddressMarker;

  constructor(public payload: { address: SearchAddress[]; onSuccess(markers: PfLocation[]): Action[] }) {}
}

export class GetSingleAddressMarkerFail implements Action {
  readonly type = SearchTypesActionTypes.GetSingleAddressMarkerFail;

  constructor(public payload: string) {}
}

export class SetStatesList implements Action {
  readonly type = SearchTypesActionTypes.SetStatesList;

  constructor() {}
}

export class SetSelectedState implements Action {
  readonly type = SearchTypesActionTypes.SetSelectedState;

  constructor(public payload: { selectedState: string }) {}
}

export class GetCountiesList implements Action {
  readonly type = SearchTypesActionTypes.GetCountiesList;

  constructor(public payload: { selectedState: string }) {}
}

export class SetCountiesList implements Action {
  readonly type = SearchTypesActionTypes.SetCountiesList;

  constructor(public payload: { countiesList: AdminPortalLocationCounty[] }) {}
}

export class SetSelectedCounty implements Action {
  readonly type = SearchTypesActionTypes.SetSelectedCounty;

  constructor(public payload: { selectedCounty: string }) {}
}

export type SearchTypesActions =
  | SetSearchValue
  | SearchByAddress
  | ResetSearchAddress
  | SearchByOwner
  | SearchByParcelNumber
  | SetSearchResultRawData
  | GetSearchResultsSuccess
  | GetSearchResultsFail
  | ResetSearchTypes
  | SetMarkers
  | ResetMarkers
  | ResetSearchError
  | GetSingleAddressMarker
  | GetSingleAddressMarkerFail
  | GetCountiesForState
  | SetStatesList
  | GetCountiesList
  | SetCountiesList
  | SetSelectedState
  | SetSelectedCounty;
