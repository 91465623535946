import { Injectable } from '@angular/core';

import { TinyColor } from '@ctrl/tinycolor';
import { each, has } from 'lodash-es';

import { DefaultColors } from '../../../../constants/default-colors.constants';
import { Colors } from '../../../typings/theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  colors: Colors = {
    danger: DefaultColors.DANGER,
    dark: DefaultColors.DARK,
    light: DefaultColors.LIGHT,
    medium: DefaultColors.MEDIUM,
    primary: DefaultColors.PRIMARY,
    secondary: DefaultColors.SECONDARY,
    success: DefaultColors.SUCCESS,
    warn: DefaultColors.WARN
  };

  setColors(colors: Colors) {
    each(colors, (value, key) => {
      if (has(this.colors, key)) {
        const color = new TinyColor(value);
        if (color.isValid) {
          this.colors[key] = color.toHexString();
        }
      }
    });
    this.updateTheme();
  }

  updateTheme() {
    each(
      {
        danger: 'danger',
        dark: 'dark',
        light: 'light',
        medium: 'medium',
        primary: 'primary',
        secondary: 'secondary',
        success: 'success',
        tertiary: 'secondary',
        warning: 'warn'
      },
      (value, key) => {
        this.updateThemeColor(key, this.colors[value]);
      }
    );
  }

  updateThemeColor(name: string, value: string) {
    const color = new TinyColor(value);
    let contrast = new TinyColor('#000');
    this.setDOMColor(`--ion-color-${name}`, color.toHexString());
    this.setDOMColor(`--ion-color-${name}-rgb`, color.toRgbString());
    this.setDOMColor(`--ion-color-${name}-shade`, color.darken(10).toHexString());
    this.setDOMColor(`--ion-color-${name}-tint`, color.lighten(10).toHexString());
    if (color.isDark()) {
      contrast = new TinyColor('#FFF');
    }
    this.setDOMColor(`--ion-color-${name}-contrast`, contrast.toHexString());
    this.setDOMColor(`--ion-color-${name}-contrast-rgb`, contrast.toRgbString());
  }

  setDOMColor(name: string, value: string) {
    document.documentElement.style.setProperty(name, value);
  }
}
