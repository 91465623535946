import { Action } from '@ngrx/store';

import { PfLocation } from '../../../typings/app';
import { PropertyProfileData } from '../../../typings/property-profile';

export enum FastFarmActionTypes {
  GetFastFarmData = '[FastFarm] Get Fast Farm Data',
  GetMapMarker = '[FastFarm] Get Map Marker',
  ResetMapMarker = '[FastFarm] Reset Map Marker',
  ResetRadius = '[FastFarm] Reset Fast Farm Radius',
  SetRadius = '[FastFarm] Set Fast Farm Radius'
}

export class GetMapMarker implements Action {
  readonly type = FastFarmActionTypes.GetMapMarker;

  constructor(public payload: PfLocation) {}
}

export class GetFastFarmData implements Action {
  readonly type = FastFarmActionTypes.GetFastFarmData;

  constructor(public payload: PropertyProfileData) {}
}

export class ResetMapMarker implements Action {
  readonly type = FastFarmActionTypes.ResetMapMarker;

  constructor() {}
}

export class SetRadius implements Action {
  readonly type = FastFarmActionTypes.SetRadius;
  constructor(public payload: number) {}
}

export class ResetRadius implements Action {
  readonly type = FastFarmActionTypes.ResetRadius;
  constructor() {}
}

export type FastFarmActions = GetMapMarker | GetFastFarmData | ResetMapMarker | SetRadius | ResetRadius;
