import { Component, OnDestroy } from '@angular/core';

import { each } from 'lodash-es';
import { Subscription } from 'rxjs';

@Component({
  template: ''
})
export class GenericComponent implements OnDestroy {
  protected subscriptions: Subscription[] = [];
  protected uniqueSubscriptions: { [key: string]: Subscription } = {};

  addUniqueSubscription(key: string, sub: Subscription) {
    this.closeSubscription(this.uniqueSubscriptions[key]);
    this.uniqueSubscriptions[key] = sub;
  }

  closeSubscription(subscription?: Subscription) {
    if (subscription && !subscription.closed) {
      subscription.unsubscribe();
    }
  }

  cancelAllSubscriptions() {
    each(this.subscriptions, (subscription) => {
      this.closeSubscription(subscription);
    });

    each(this.uniqueSubscriptions, (subscription) => {
      this.closeSubscription(subscription);
    });
  }

  ngOnDestroy() {
    this.cancelAllSubscriptions();
  }

  ionViewWillLeave() {
    this.cancelAllSubscriptions();
  }
}
