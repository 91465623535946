export const DETAILS_CHARACTERISTICS_LABELS = {
  APN: 'Parcel Number',
  AssessedValue: 'Assessed Value',
  Basement: 'Basement',
  Baths: 'Bathrooms',
  Bedrooms: 'Bedrooms',
  BuildingArea: 'Building Area',
  CityMuniTwp: 'City Muni TWP',
  Fireplace: 'Fireplace',
  GarageNumCars: 'Garage Num. Cars',
  GarageType: 'Garage Type',
  LegalBriefDescription: 'Legal Brief Description',

  LotSize: 'Lot Size',
  NumUnits: 'Number of Units',
  Pool: 'Pool',
  SiteAddress: 'Address',
  SiteCity: 'City',
  SiteState: 'State',
  SiteUnit: 'Unit',
  SiteUnitType: 'Unit Type',
  SiteZip: 'ZIP',
  TaxAmount: 'Tax Amount',
  TotalRooms: 'Total Rooms',
  UseCode: 'Use Code',
  YearBuilt: 'Year Built',
  Zoning: 'Zoning'
};

export const TRANSACTION_LABELS = {
  BuyerName: 'Buyer Name',
  DocumentNum: 'Document No',
  FirstTD: 'First TD',
  Price: 'Price',
  SellerName: 'Seller Name',
  TypeOfSale: 'Type of Sale'
};
