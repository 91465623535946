import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'smd-accordion-item',
  templateUrl: './accordion-item.component.html'
})
export class AccordionItemComponent implements OnInit {
  accordionIconName = '';

  @Input() itemTitle: string;
  @Input() hasLabelTitle: boolean;
  @Input() resetAlignment: boolean;
  @Input() isExpanded = false;
  @Input() hasData = true;
  @Output() expandList: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() {
    this.generateIcon();

    if (this.isExpanded) {
      this.expandList.emit(this.hasData);
    }
  }

  clickItem() {
    this.isExpanded = !this.isExpanded;
    this.generateIcon();

    if (this.isExpanded) {
      this.expandList.emit(this.hasData);
    }
  }

  private generateIcon() {
    this.accordionIconName = this.isExpanded ? 'ion-md-icon-minus' : 'ion-md-icon-plus';
  }
}
