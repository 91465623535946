import * as appSettingsActions from '../../actions/app-settings/app-support';

export interface State {
  supportEmail: string;
}

export const initialState: State = {
  supportEmail: ''
};

export function reducer(state = initialState, action: appSettingsActions.AppSupportActions) {
  switch (action.type) {
    case appSettingsActions.AppSupportActionTypes.GetSupportEmail: {
      return {
        ...state,
        supportEmail: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
