import { InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser';

export const IN_APP_BROWSER_OPTIONS: InAppBrowserOptions = {
  allowInlineMediaPlayback: 'no', // iOS only
  clearcache: 'yes',
  clearsessioncache: 'yes',
  closebuttoncaption: 'Close', // iOS only
  enableViewportScale: 'no', // iOS only
  hidden: 'no', // Or  'yes'
  location: 'no', // 'yes' Or 'no'
  mediaPlaybackRequiresUserAction: 'no',
  shouldPauseOnSuspend: 'no', // Android only
  toolbar: 'yes', // iOS only
  zoom: 'no' // Android only ,shows browser zoom controls
};
