import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { STANDARD_PIN_SVG } from '../../../constants/map.constants';
import { MarkerIcon } from '../../typings/app';
import { LocationMarker, SearchAddress } from '../../typings/map';

@Injectable()
export class MapUtils {
  constructor() {}

  getMarker(address: string, markerIcon: MarkerIcon) {
    const geocoder = new google.maps.Geocoder();

    return new Observable<{ icon: MarkerIcon; latitude: number; longitude: number }>((observer) => {
      geocoder.geocode({ address }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          observer.next({
            icon: markerIcon,
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng()
          });
          observer.complete();
        } else {
          observer.error('Cannot determine address at this location');
        }
      });
    });
  }

  async validateLocation(searchResults: SearchAddress[]) {
    let index = 0;
    let geocoderResult: LocationMarker;

    while (index < searchResults.length) {
      try {
        geocoderResult = await this.getGeocoderLocation(searchResults[index].search);
      } catch (error) {
        /* empty */
      }

      if (geocoderResult) {
        break;
      } else {
        index += 1;
      }
    }

    if (!geocoderResult) {
      throw new Error('Cannot determine address at this location');
    }

    return geocoderResult;
  }

  getGeocoderLocation(address: string) {
    const geocoder = new google.maps.Geocoder();

    return new Promise<LocationMarker>((resolve, reject) => {
      geocoder.geocode({ address }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          resolve({
            icon: {
              scaledSize: {
                height: 45,
                width: 27
              },
              url: STANDARD_PIN_SVG.red
            },
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng()
          });
        } else {
          reject('Cannot determine address at this location');
        }
      });
    });
  }
}
