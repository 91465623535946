export const markerTypes = {
  lead: 'leadStatus',
  occupancy: 'occupied'
};

export const mapLocationData = {
  initial: {
    lat: 33.685909,
    lng: -117.824722
  }
};
