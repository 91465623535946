<ion-tabs>
  <ion-footer slot="bottom">
    <ion-tab-bar class="tw-h-16" *ngIf="viewTabLinks">
      <ion-grid class="ion-no-padding">
        <ion-row class="tw-justify-center lg:tw-justify-end">
          <ng-container *ngFor="let tab of tabs; trackBy: trackByActiveTab">
            <ion-col size="3" *ngIf="tab.isVisible" class="ion-no-padding">
              <ion-tab-button
                class="ion-no-padding"
                (click)="handleTabNavigation(tab.route)"
                [ngClass]="{ 'smd-active-nav-tab': tab.isActive }"
              >
                <ng-content *ngTemplateOutlet="tab.isSvg ? svgIconTemplate : ionicIconTemplate; context: { tab: tab }">
                </ng-content>
                <ion-label color="light" [innerHTML]="tab.title"></ion-label>
              </ion-tab-button>
            </ion-col>
          </ng-container>
        </ion-row>
      </ion-grid>
    </ion-tab-bar>
  </ion-footer>
</ion-tabs>

<ng-template #ionicIconTemplate let-tab="tab">
  <ion-icon color="light" [ngClass]="[tab.icon, 'tw-h-6', 'tw-w-8', 'tw-text-2xl']"></ion-icon>
</ng-template>

<ng-template #svgIconTemplate let-tab="tab">
  <ion-icon class="smd-svg-icon-color tw-h-6 tw-w-8 tw-text-2xl" [src]="tab.icon"></ion-icon>
</ng-template>
