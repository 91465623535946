import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { ENV } from './environments/environment';

import { AppModule } from './app/app.module';

import 'hammerjs';

if (ENV.production) {
  enableProdMode();
}

const mapsReadyInterval = setInterval(() => {
  if (window['isGoogleMapsReady']) {
    clearInterval(mapsReadyInterval);
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  }
}, 50);
