import { Component, EventEmitter, HostListener, Input, NgZone, OnInit, Output } from '@angular/core';

import { filter, withLatestFrom } from 'rxjs/operators';

import { GenericComponent } from '../generic/generic.component';

import { KeyboardService } from '../../services/keyboard/keyboard.service';
import { PlatformDetectService } from '../../services/platform-detect/platform-detect.service';

@Component({
  selector: 'smd-footer-pagination',
  styleUrls: ['./footer-pagination.component.scss'],
  templateUrl: './footer-pagination.component.html'
})
export class FooterPaginationComponent extends GenericComponent implements OnInit {
  private leftArrowCodes = ['ArrowLeft'];
  private rightArrowCodes = ['ArrowRight'];

  hasPrev = false;
  hasNext = false;
  isMobileKeyboardOpened = false;

  @Input() shouldEmit = true;
  @Input() current;
  @Input() lastItem;
  @Input() showDetails = false;
  @Output() previousItem: EventEmitter<number> = new EventEmitter<number>();
  @Output() nextItem: EventEmitter<number> = new EventEmitter<number>();

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.showDetails) {
      const key = event.key;

      if (this.leftArrowCodes.includes(key) && this.hasPrev) {
        this.prev();
      } else if (this.rightArrowCodes.includes(key) && this.hasNext) {
        this.next();
      }
    }
  }

  constructor(
    private ngZone: NgZone,
    private platformDetectService: PlatformDetectService,
    private keyboardService: KeyboardService
  ) {
    super();
  }

  ngOnInit() {
    this.setActions();

    this.addUniqueSubscription(
      'keyboardSettingsSubscription',
      this.keyboardService
        .getHandlerSettings()
        .pipe(
          withLatestFrom(this.platformDetectService.getIsMobileSubscription()),
          filter((result) => result[1])
        )
        .subscribe(([keyboardSettings]) => {
          this.ngZone.run(() => {
            this.isMobileKeyboardOpened = keyboardSettings?.isVisible;
          });
        })
    );
  }

  prev() {
    if (!this.shouldEmit) {
      return;
    }

    if (this.hasPrev) {
      this.current--;
      this.setActions();
      this.previousItem.emit(this.current);
    }
  }

  next() {
    if (!this.shouldEmit) {
      return;
    }

    if (this.hasNext) {
      this.current++;
      this.setActions();
      this.nextItem.emit(this.current);
    }
  }

  private setActions() {
    this.ngZone.run(() => {
      this.hasNext = this.current < this.lastItem;
      this.hasPrev = this.current > 1;
    });
  }
}
