import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { has } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { ResetModules } from '../../../enums/data-reset';
import { PropertySearchBarModel } from '../../../typings/search';

import * as fromAppConfigActions from '../../../store/actions/app-config/map-data';
import * as fromSearchTypesActions from '../../../store/actions/property-search/search-types';

import { ResetService } from '../reset/reset.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private propertySearchBarHandler = new BehaviorSubject<PropertySearchBarModel>({
    isFilled: false
  });

  constructor(
    private store: Store,
    private resetService: ResetService
  ) {
    this.propertySearchBarHandler.subscribe((settings) => {
      if (!settings.isFilled) {
        if (!has(settings, 'resetLocation') || settings.resetLocation) {
          this.store.dispatch(new fromAppConfigActions.ResetLocation());
        }

        if (settings.resetTarget) {
          this.resetService.updateResetHandlerSettings({
            targets: [settings.resetTarget]
          });
        } else {
          this.resetService.updateResetHandlerSettings({
            targets: [ResetModules.DefaultsSearch]
          });
        }
      }
    });
  }

  init() {
    this.store.dispatch(new fromSearchTypesActions.SetStatesList());
  }

  updatePropertySearchBarSettings(newSettings: PropertySearchBarModel) {
    this.propertySearchBarHandler.next({
      ...this.propertySearchBarHandler.value,
      ...newSettings
    });
  }
}
