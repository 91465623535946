import { combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromSearchTypes from '../../reducers/property-search/search-types';

export interface PropertySearchState {
  searchTypes: fromSearchTypes.State;
}

export interface State {
  propertySearch: PropertySearchState;
}

export const reducer = combineReducers({
  searchTypes: fromSearchTypes.reducer
});

export const getPropertySearchState = createFeatureSelector<PropertySearchState>('propertySearch');

export const getPropertySearchTypesState = createSelector(getPropertySearchState, (state) => state.searchTypes);

export const getPropertySearchMarkers = createSelector(getPropertySearchTypesState, fromSearchTypes.getMarkers);
export const getPropertySearchKey = createSelector(getPropertySearchTypesState, fromSearchTypes.getSearchKey);
export const getPropertySearchResultRawData = createSelector(
  getPropertySearchTypesState,
  fromSearchTypes.getSearchResultRawData
);
export const getPropertySearchResults = createSelector(getPropertySearchTypesState, fromSearchTypes.getSearchResults);

export const getStatesList = createSelector(getPropertySearchTypesState, fromSearchTypes.getStatesList);
export const getSelectedState = createSelector(getPropertySearchTypesState, fromSearchTypes.getSelectedState);
export const getCountiesList = createSelector(getPropertySearchTypesState, fromSearchTypes.getCountiesList);
export const getSelectedCounty = createSelector(getPropertySearchTypesState, fromSearchTypes.getSelectedCounty);
