import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';

import { TinyColor } from '@ctrl/tinycolor';
import { Store } from '@ngrx/store';
import { Chart } from 'chart.js';
import { map } from 'lodash-es';
import { take } from 'rxjs/operators';

import { ChartDataModel, ChartOptions } from '../../../../typings/components';

import { GenericComponent } from '../../generic/generic.component';

import * as fromAppConfig from '../../../../store/selectors/app-config/index';

@Component({
  selector: 'smd-doughnut-chart',
  templateUrl: './doughnut-chart.component.html'
})
export class DoughnutChartComponent extends GenericComponent implements OnInit {
  chart: Chart;
  chartColors: string[];

  @Input() data: ChartDataModel;
  @Input() options: ChartOptions = {
    cutout: '84%',
    rotation: 80
  };
  @Input() differentSecondColor: string;
  @Input() isCentered = false;

  @ViewChild('chartCanvas', { static: true }) chartCanvas: ElementRef;

  get displayMiddleInfo(): boolean {
    return !!this.data.middleInfo;
  }

  get hasCustomLegend(): boolean {
    return this.data.hasCustomLegend;
  }

  get secondColor(): string {
    return this.differentSecondColor || null;
  }

  constructor(
    private ngZone: NgZone,
    private store: Store<fromAppConfig.State>
  ) {
    super();
  }

  ngOnInit() {
    this.addUniqueSubscription(
      'rgbColorsSubscription',
      this.store
        .select(fromAppConfig.getGeneralInfoAppInfoRgbColorNumbers)
        .pipe(take(1))
        .subscribe((chartsColorPrimary) => {
          if (this.data) {
            const color = new TinyColor(chartsColorPrimary);
            const alphaNumbers = [1, 0.2, 0.4, 0.6, 0.8];

            const colors = map(alphaNumbers, (alpha) => {
              color.setAlpha(alpha);
              return color.toRgbString();
            });

            this.ngZone.run(() => {
              this.chartColors = colors.slice(0, this.data.chartData.data.length);

              if (this.secondColor) {
                this.chartColors[this.chartColors.length - 1] = new TinyColor(this.secondColor).toRgbString();
              }

              let chartCanvas = this.chartCanvas?.nativeElement;

              if (chartCanvas) {
                this.chart = new Chart(chartCanvas, {
                  data: {
                    datasets: [
                      {
                        backgroundColor: this.chartColors,
                        borderWidth: 1,
                        data: this.data.chartData.data
                      }
                    ],
                    labels: this.data.chartData.labels
                  },
                  options: {
                    plugins: {
                      legend: {
                        display: false
                      }
                    },
                    ...this.options
                  },
                  type: 'doughnut'
                });
              }
            });
          }
        })
    );
  }
}
