import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DomManipulationProvider {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  resetScrollContentBottomMargin() {
    const elemList = this.document.querySelectorAll('ion-content.content-md .scroll-content.no-margin-bottom');
    if (!!elemList && elemList.length > 0) {
      elemList.forEach((elem) => {
        elem.classList.remove('no-margin-bottom');
      });
    }
  }

  removeScrollContentBottomMargin() {
    const elemList = this.document.querySelectorAll('ion-content.content-md .scroll-content');
    if (!!elemList && elemList.length > 0) {
      elemList.forEach((elem) => {
        elem.classList.add('no-margin-bottom');
      });
    }
  }
}
