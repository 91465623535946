import { Directive, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

import { timer } from 'rxjs';

import { phoneNumberPattern } from '../../constants/data';

@Directive({
  selector: '[smdFormPhoneNumber]'
})
export class FormPhoneNumberDirective implements OnInit {
  constructor(private ngControl: NgControl) {}

  ngOnInit() {
    this.valueUpdate(this.ngControl?.control.value);

    this.ngControl?.control.valueChanges.subscribe((value: string | null) => {
      this.valueUpdate(value);
    });
  }

  private valueUpdate(value: string) {
    const sanitizedValue = value?.replace(/[^0-9]/g, '');

    if (sanitizedValue?.length > 0 && sanitizedValue?.length <= 10) {
      const formattedValue = sanitizedValue.replace(/(^.{3})(.{3})(.{4})/, (...args) =>
        [args[1], args[2], args[3]].join('-')
      );

      if (formattedValue !== value && formattedValue.match(phoneNumberPattern)) {
        timer(0).subscribe(() => {
          this.ngControl?.control.setValue(formattedValue);
          this.ngControl?.valueAccessor.writeValue(formattedValue);
        });
      }
    }
  }
}
