import { combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import { EMAIL_VALIDATION_REGEX } from '../../../../constants/main.constants';

import * as fromGeneralInfo from '../../reducers/app-config/general-info';
import * as fromMapData from '../../reducers/app-config/map-data';

export interface AppConfigState {
  generalInfo: fromGeneralInfo.State;
  mapData: fromMapData.State;
}

export interface State {
  appConfig: AppConfigState;
}

export const reducer = combineReducers({
  generalInfo: fromGeneralInfo.reducer,
  mapData: fromMapData.reducer
});

export const getAppConfigState = createFeatureSelector<AppConfigState>('appConfig');

export const getAppConfigGeneralInfoState = createSelector(getAppConfigState, (state) => state.generalInfo);
export const getIsAuthenticated = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getIsAuthenticated);

export const getIsPaymentsLoaded = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getIsPaymentsLoaded);

export const getGeneralInfoAppInfo = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getAppInfo);

export const getRepDataSuccess = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getRepDataSuccess);

export const getGeneralInfoAppInfoLogoUrl = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getAppInfoLogoUrl
);
export const getAppInfoColors = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getAppInfoColors);
export const getGeneralInfoAppInfoRgbColorNumbers = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getAppInfoRgbColorNumbers
);
export const getAppInfoCompanyShortName = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getAppInfoCompanyShortName
);
export const getAppInfoCompanyAlternateIconUrl = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getAppInfoCompanyAlternateIconUrl
);
export const getAppInfoCompanyAlternateIcon = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getAppInfoCompanyAlternateIcon
);
export const getAppInfoShowTransactionHistory = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getAppInfoShowTransactionHistory
);
export const getAppInfoShowComparableSales = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getAppInfoShowComparableSales
);
export const getAppInfoShowAreaSales = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getAppInfoShowAreaSales
);
export const getAppInfoFarmAlertsEnabled = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getAppInfoFarmAlertsEnabled
);
export const getAppInfoAutoSubscribeFarmAlerts = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getAppInfoAutoSubscribeFarmAlerts
);
export const getAppInfoParseUrlType = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getAppInfoParseUrlType
);
export const getAppInfoConsolidatedParseAppKey = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getAppInfoConsolidatedParseAppKey
);

export const getGeneralInfoDeviceData = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getDeviceData);
export const getGeneralInfoDeviceDataAppName = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getDeviceDataAppName
);
export const getGeneralInfoAuthData = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getAuthData);
export const getAuthDataUserObjectId = createSelector(
  getGeneralInfoAuthData,
  (authDataState) => authDataState.userObjectId
);
export const getCurrentAppIcon = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getCurrentAppIcon);

export const getGeneralInfoSucceeded = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getSucceeded);
export const getGeneralInfoError = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getError);

export const getFarmKey = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getFarmKey);
export const getPropertyKey = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getPropertyKey);
export const getPropertyFarm = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getPropertyFarm);
export const getPropertyProfile = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getPropertyProfile);

export const getNewPropertyKeyTermsToAccept = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getNewPropertyKeyTermsToAccept
);

export const getNewFarmKeyTermsToAccept = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getNewFarmKeyTermsToAccept
);

export const getGeneralInfoRepData = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getRepData);
export const getRepDataProviderId = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getRepDataProviderId);
export const getRepDataPortalName = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getRepDataPortalName);
export const getRepDataCalculatorsEnabled = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getRepDataCalculatorsEnabled
);
export const getRepDataWalkingFarmEnabled = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getRepDataWalkingFarmEnabled
);
export const getRepDataIsNetSheetEnabled = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getRepDataIsNetSheetEnabled
);
export const getRepDataHideContactCard = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getRepDataHideContactCard
);

export const getRepDataShowDocLinks = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getRepDataShowDocLinks
);
export const getRepDataOrderTitleEmail = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getRepDataOrderTitleEmail
);
export const getRepDataOrderTitleEnabled = createSelector(
  getRepDataOrderTitleEmail,
  (email) => !!email && EMAIL_VALIDATION_REGEX.test(email)
);

export const getDataPortalInfo = createSelector(getRepDataPortalName, (name) => ({
  dataPortalName: name
}));

export const getInternalStorageGeneralInfoData = createSelector(
  getIsAuthenticated,
  getIsPaymentsLoaded,
  getGeneralInfoAuthData,
  getGeneralInfoRepData,
  getPropertyFarm,
  getPropertyProfile,
  getGeneralInfoAppInfo,
  getGeneralInfoDeviceData,
  (isAuthenticated, isPaymentsLoaded, authData, repData, propertyFarm, propertyProfile, appInfo, deviceData) => {
    return {
      appInfo,
      authData,
      deviceData,
      isAuthenticated,
      isPaymentsLoaded,
      propertyFarm,
      propertyProfile,
      repData
    };
  }
);

export const isUserFinishHisAuthentication = () =>
  createSelector(getInternalStorageGeneralInfoData, (storage) => storage.isAuthenticated);

export const getEnableFarmAlertAutoSubscriptions = createSelector(
  getAppInfoFarmAlertsEnabled,
  getAppInfoAutoSubscribeFarmAlerts,
  (alertsEnabled, autoSubscribed) => {
    return alertsEnabled && autoSubscribed;
  }
);

export const getAppConfigCreateFarmData = createSelector(
  getAppInfoConsolidatedParseAppKey,
  getAppInfoFarmAlertsEnabled,
  getFarmKey,
  getGeneralInfoDeviceData,
  (appName, farmAlertsEnabled, farmKey, deviceData) => {
    return {
      appName,
      deviceData,
      farmKey,
      hasFarmAlerts: farmAlertsEnabled
    };
  }
);

export const getAppConfigMapDataState = createSelector(getAppConfigState, (state) => state.mapData);

export const getMapDataMapType = createSelector(getAppConfigMapDataState, fromMapData.getMapType);
export const getMapDataZoomValue = createSelector(getAppConfigMapDataState, fromMapData.getZoomValue);
export const getMapDataLocation = createSelector(getAppConfigMapDataState, fromMapData.getLocation);

export const getInAppProducts = createSelector(getAppConfigGeneralInfoState, fromGeneralInfo.getInAppProducts);
export const getPhoneAndEmailPurchaseFlag = createSelector(
  getAppConfigGeneralInfoState,
  fromGeneralInfo.getPhoneAndEmailPurchase
);
