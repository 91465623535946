import { combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromCalculators from '../../reducers/calculators/calculators-and-reports';

export interface CalculatorsState {
  calculatorsAndReports: fromCalculators.State;
}

export interface State {
  calculators: CalculatorsState;
}

export const reducer = combineReducers({
  calculatorsAndReports: fromCalculators.reducer
});

export const getCalculatorsState = createFeatureSelector<CalculatorsState>('calculators');

export const getCalculatorsAndReportsState = createSelector(
  getCalculatorsState,
  (state: CalculatorsState) => state.calculatorsAndReports
);

export const getPdfBlobData = createSelector(getCalculatorsAndReportsState, fromCalculators.getPdfBlobData);

export const getSelectedCalculator = createSelector(
  getCalculatorsAndReportsState,
  fromCalculators.getSelectedCalculator
);
export const getPitiData = createSelector(getCalculatorsAndReportsState, fromCalculators.getPitiData);
export const getAffordabilityData = createSelector(getCalculatorsAndReportsState, fromCalculators.getAffordabilityData);
export const getAmortizationData = createSelector(getCalculatorsAndReportsState, fromCalculators.getAmortizationData);
export const getRawCalculatorDataObject = createSelector(
  getCalculatorsAndReportsState,
  fromCalculators.getRawCalculatorData
);
