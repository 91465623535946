import * as fromFarmActions from '../../actions/walking-farm/farm';

export interface State {
  error: Error;
  pending: boolean;
  succeeded: boolean;
}

export const initialState: State = {
  error: null,
  pending: false,
  succeeded: false
};

export function reducer(state = initialState, action: fromFarmActions.FarmActions) {
  switch (action.type) {
    case fromFarmActions.FarmActionTypes.GetFarmCount: {
      return {
        ...state,
        error: null,
        pending: true,
        succeeded: false
      };
    }

    case fromFarmActions.FarmActionTypes.GetFarmCountSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
        succeeded: true
      };
    }

    case fromFarmActions.FarmActionTypes.ResetFarmCountSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
        succeeded: false
      };
    }

    case fromFarmActions.FarmActionTypes.GetFarmCountFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getFarmCountSucceeded = (state: State) => state.succeeded;

export const getFarmCountPending = (state: State) => state.pending;
