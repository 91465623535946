import * as fromGeneralInfo from './reducers/app-config/general-info';
import * as fromMapData from './reducers/app-config/map-data';
import * as fromSearchInfo from './reducers/app-config/search-info';
import * as fromAppSupport from './reducers/app-settings/app-support';
import * as fromSalesInfo from './reducers/app-settings/sales-info';
import * as fromUserProfile from './reducers/app-settings/user-profile';
import * as fromCalculatorsAndReports from './reducers/calculators/calculators-and-reports';
import * as fromParseCreateFarmReport from './reducers/parse/create-farm-report';
import * as fromParseImplementation from './reducers/parse/parse-implementation';
import * as fromPropertyProfileExport from './reducers/property-data/export-property-profile';
import * as fromProfileHistory from './reducers/property-data/profile-history';
import * as fromPropertyProfile from './reducers/property-data/property-profile';
import * as fromSearchTypes from './reducers/property-search/search-types';
import * as fromExportInAveryLabels from './reducers/walking-farm/export-in-avery-labels';
import * as fromExportInCSV from './reducers/walking-farm/export-in-csv';
import * as fromFarmAlerts from './reducers/walking-farm/farm-alerts';
import * as fromFastFarm from './reducers/walking-farm/fast-farm';
import * as fromGetFarmCount from './reducers/walking-farm/get-farm-count';
import * as fromPolygonData from './reducers/walking-farm/polygon-data';
import * as fromReportSummary from './reducers/walking-farm/report-summary';
import * as fromSavedFarms from './reducers/walking-farm/saved-farms';
import * as fromSearchInMap from './reducers/walking-farm/search-in-map';
import * as fromViewFarm from './reducers/walking-farm/view-farm';
import * as fromWalkingFarmFilters from './reducers/walking-farm/walking-farm-filters';
import * as fromWalkingFarmReportFilters from './reducers/walking-farm/walking-farm-report-filters';

interface StoreState {
  appConfig: {
    generalInfo: fromGeneralInfo.State;
    mapData: fromMapData.State;
    searchInfo: fromSearchInfo.State;
  };
  appParse: {
    createFarmReport: fromParseCreateFarmReport.State;
    parse: fromParseImplementation.State;
  };
  appSettings: {
    salesInfo: fromSalesInfo.State;
    support: fromAppSupport.State;
    userProfile: fromUserProfile.State;
  };
  calculators: {
    calculatorsAndReports: fromCalculatorsAndReports.State;
  };
  propertyData: {
    exportPropertyProfile: fromPropertyProfileExport.State;
    profileHistory: fromProfileHistory.State;
    propertyProfile: fromPropertyProfile.State;
  };
  propertySearch: {
    searchTypes: fromSearchTypes.State;
  };
  walkingFarm: {
    averyLabelsReport: fromExportInAveryLabels.State;
    farmAlerts: fromFarmAlerts.State;
    farmReportCsv: fromExportInCSV.State;
    fastFarm: fromFastFarm.State;
    filters: fromWalkingFarmFilters.State;
    getFarmCount: fromGetFarmCount.State;
    polygon: fromPolygonData.State;
    reportFilters: fromWalkingFarmReportFilters.State;
    reportSummary: fromReportSummary.State;
    savedFarms: fromSavedFarms.State;
    searchInMap: fromSearchInMap.State;
    viewFarm: fromViewFarm.State;
  };
}

export const storeInitialState: StoreState = {
  appConfig: {
    generalInfo: fromGeneralInfo.initialState,
    mapData: fromMapData.initialState,
    searchInfo: fromSearchInfo.initialState
  },
  appParse: {
    createFarmReport: fromParseCreateFarmReport.initialState,
    parse: fromParseImplementation.initialState
  },
  appSettings: {
    salesInfo: fromSalesInfo.initialState,
    support: fromAppSupport.initialState,
    userProfile: fromUserProfile.initialState
  },
  calculators: {
    calculatorsAndReports: fromCalculatorsAndReports.initialState
  },
  propertyData: {
    exportPropertyProfile: fromPropertyProfileExport.initialState,
    profileHistory: fromProfileHistory.initialState,
    propertyProfile: fromPropertyProfile.initialState
  },
  propertySearch: {
    searchTypes: fromSearchTypes.initialState
  },
  walkingFarm: {
    averyLabelsReport: fromExportInAveryLabels.initialState,
    farmAlerts: fromFarmAlerts.initialState,
    farmReportCsv: fromExportInCSV.initialState,
    fastFarm: fromFastFarm.initialState,
    filters: fromWalkingFarmFilters.initialState,
    getFarmCount: fromGetFarmCount.initialState,
    polygon: fromPolygonData.initialState,
    reportFilters: fromWalkingFarmReportFilters.initialState,
    reportSummary: fromReportSummary.initialState,
    savedFarms: fromSavedFarms.initialState,
    searchInMap: fromSearchInMap.initialState,
    viewFarm: fromViewFarm.initialState
  }
};
