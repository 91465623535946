import { NgModule } from '@angular/core';

import { DashIfEmptyPipe } from './dash-if-empty.pipe';
import { NaIfEmptyPipe } from './na-if-empty.pipe';
import { NumberFormatterPipe } from './number-formatter.pipe';
import { SafePipe } from './safe.pipe';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';

export const pipes = [SafePipe, SanitizeHtmlPipe, NumberFormatterPipe, NaIfEmptyPipe, DashIfEmptyPipe];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes]
})
export class PipesModule {}
