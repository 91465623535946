export enum ResetModules {
  CalculatorAffordability = 'calculator-affordability',
  CalculatorAmortization = 'calculator-amortization',
  CalculatorPiti = 'calculator-piti',
  CalculatorReports = 'calculator-reports',
  DefaultsSearch = 'default-search',
  PropertyMapView = 'property-map-view',
  PropertySearch = 'property-search',
  WalkingFarm = 'walking-farm',
  WalkingFarmDrawMode = 'walking-farm-draw-mode',
  WalkingFarmFastFarm = 'walking-farm-fast-farm',
  WalkingFarmFilters = 'walking-farm-filters',
  WalkingFarmReportFilters = 'walking-farm-report-filters',
  WalkingFarmReportSummary = 'walking-farm-report-summary',
  WalkingFarmSearch = 'walking-farm-search'
}
