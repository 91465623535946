import { Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';

import { Action } from '@ngrx/store';
import { find, forEach, forIn, has, mapValues, pick } from 'lodash-es';

import { DeviceData } from '../typings/device';
import { PropertyData } from '../typings/walking-farm';

import { NumberFormatService } from '../common/services/number-formatter/number-format.service';

@Injectable()
export class PfHelperService {
  actionAfterTermsAccepted: Action;

  constructor(
    private alertController: AlertController,
    private navController: NavController,
    private numberFormatService: NumberFormatService
  ) {}

  deMaskProps(obj) {
    return mapValues(obj, (value) => {
      const formattedValue = this.numberFormatService.formatValue(value, false);
      return Number(formattedValue);
    });
  }

  getObjectProps(obj, propsArr) {
    return pick(obj, propsArr);
  }

  onCheckboxChange(model) {
    const selectedArray = [];
    forEach(model, (value, key) => {
      if (value) {
        selectedArray.push(key);
      }
    });
    return selectedArray;
  }

  createCheckModel(checkBoxes: Partial<{ value: string }>[]) {
    const obj = {};
    checkBoxes.forEach((item) => {
      obj[item.value] = false;
    });

    return obj;
  }

  checkMarkedProps(selectedArray, model) {
    forEach(selectedArray, (key) => {
      if (has(model, key)) {
        model[key] = true;
      }
    });

    return model;
  }

  resetMarkedProps(model) {
    forEach(model, (value, key) => {
      model[key] = false;
    });

    return model;
  }

  getComputeDeviceData(data: DeviceData) {
    let deviceDataString = '';
    forIn(data, (value: string, key: string) => {
      deviceDataString += `${key.toUpperCase()}|${value},`;
    });
    return deviceDataString;
  }

  findObjectByPropInCollection(collection, propName, value, valueToReturn): string {
    const val = find(collection, (o) => (o[`${propName}`][0] === value ? o[`${valueToReturn}`] : ''));
    return val ? val[`${valueToReturn}`] : '';
  }

  async showSystemError(message = 'Something went wrong. Please try again later.', handler?, title?: string) {
    if (message.match('\\bpassword has expired\\b')) {
      return;
    }

    const config = {
      buttons: [],
      message,
      title
    };

    if (handler) {
      config.buttons.push({
        handler,
        text: 'OK'
      });
    } else {
      config.buttons.push({
        text: 'OK'
      });
    }

    const alert = await this.alertController.create(config);
    await alert.present();
  }

  getEndPoint(url: string) {
    return url.substring(url.lastIndexOf('/') + 1);
  }

  populateMainInputAfterSearch(screenData) {
    const searchValue = [];
    for (const field in screenData) {
      if (screenData[field]) {
        searchValue.push(screenData[field]);
      }
    }
    return searchValue.join(', ');
  }

  async handlePlatMapDataTrace(displayEmail: string, handler) {
    const alert = await this.alertController.create({
      buttons: [
        {
          handler,
          text: 'OK'
        },
        {
          text: 'Cancel'
        }
      ],
      header: 'Plat Map',
      message: `Your plat map will be delivered via email to the following address: \n\n ${displayEmail} \n\n. If this email address is incorrect, you can change it in the "Personal Details" section of Settings.`
    });

    await alert.present();
  }

  getPropertyAddress(property: Partial<PropertyData>) {
    const houseNumber = property.A002_HouseNumber;
    const streetName = property.A004_StreetName;
    const unitNumber = property.A005_UnitNumber;
    const city = property.A046_City;
    const state = property.A047_State;
    const zip = property.A008_Zip4 ? property.A008_Zip4 : property.A007_ZipCode;

    let address = '';

    if (houseNumber) {
      address += houseNumber + ' ';
    }

    if (streetName) {
      address += streetName + ' ';
    }

    if (unitNumber) {
      address += unitNumber + ', ';
    }

    if (city) {
      address += city + ', ';
    }

    if (state) {
      address += state + ' ';
    }

    if (zip) {
      address += zip;
    }

    return address;
  }

  async showUserFailureMessage(email) {
    const config = {
      buttons: [
        {
          role: 'cancel',
          text: 'Try Again'
        },
        {
          handler: () => {
            this.navController.navigateRoot(['/', 'terms-of-service'], {
              skipLocationChange: true,
              state: {
                withButtons: true
              }
            });
          },
          text: 'Create New Account'
        }
      ],
      cssClass: 'registration-alert',
      header: 'Registration Not Found',
      message: `We could not find an existing user registration for ${email}. Do you want to try again or create a new account?`
    };
    const alert = await this.alertController.create(config);
    await alert.present();
  }
}
