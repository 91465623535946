<ng-container *ngIf="dataToDisplay && dataToDisplay.length">
  <ion-list>
    <ion-item
      *ngFor="let item of dataToDisplay"
      (click)="triggerAction(item)"
      lines="none"
      class="tw-cursor-pointer tw-pl-6 tw-pr-4 tw-text-sm tw-text-gray-700"
    >
      <ion-icon *ngIf="item.icon" slot="start" class="ion-md-{{ item.icon }} tw-text-2xl tw-h-6 tw-w-7"></ion-icon>
      <ion-label>{{ item.title }}</ion-label>
    </ion-item>
  </ion-list>
</ng-container>
