import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { isBoolean } from 'lodash-es';
import { forkJoin, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { STANDARD_PIN_SVG } from '../../../constants/map.constants';
import { ADMIN_PORTAL_CONFIG } from '../../../environments/environment';
import { adminPortalEndpoints } from '../../constants/admin-portal';

import { ApiProvider } from '../../providers/api.service';
import { MapUtils } from '../../providers/map-utils/map-utils.service';
import { PfHelperService } from '../../providers/pf-helper-service.service';
import { ApiService } from '../../common/services/api/api.service';
import { PlatformDetectService } from '../../common/services/platform-detect/platform-detect.service';
import { SecureStorageService } from '../../common/services/secure-storage/secure-storage.service';

import * as generalInfoAction from '../actions/app-config/general-info';
import * as appConfigGeneralInfoAction from '../actions/app-config/general-info';
import * as fromMonetizationActions from '../actions/monetization.actions';
import * as parseAction from '../actions/parse/parse-implementation';
import * as propertyDataAction from '../actions/property-data/property-profile';
import * as walkingFarmActions from '../actions/walking-farm/farm';
import * as walkingFarmAction from '../actions/walking-farm/farm';
import * as fastFarmActions from '../actions/walking-farm/fast-farm';
import * as fromAppConfig from '../selectors/app-config';
import * as fromAppSettings from '../selectors/app-settings';
import * as fromWalkingFarm from '../selectors/walking-farm';
import * as walkingFarmSelectors from '../selectors/walking-farm';

@Injectable()
export class ParseEffects {
  getPropertyProfileFromParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.GetPropertyProfile>(parseAction.ParseActionTypes.GetPropertyProfile)).pipe(
      withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
      switchMap(([action, parseUrlType]) =>
        this.apiService
          .sendRequest({
            method: 'GET',
            requestOptions: {
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.propertyProfiles}/${action.payload}`
          })
          .pipe(
            switchMap((result) => {
              const dispatchActions: Action[] = [];

              if (result.reportContent.d) {
                dispatchActions.push(new parseAction.GetPropertyProfileSuccess(result));
                dispatchActions.push(new fastFarmActions.GetFastFarmData(result.reportContent.d));
              } else {
                dispatchActions.push(new propertyDataAction.GetPropertyProfileDataSuccess(result.reportContent));
                dispatchActions.push(new fastFarmActions.GetFastFarmData(result.reportContent));
              }
              return dispatchActions;
            }),
            catchError((error) => of(new parseAction.GetPropertyProfileFail(error)))
          )
      )
    )
  );

  getFastFarmData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fastFarmActions.GetFastFarmData>(fastFarmActions.FastFarmActionTypes.GetFastFarmData),
      switchMap((action) => {
        const result = action.payload;
        const address = `${result.SiteAddress} ${result.SiteUnitType} ${result.SiteUnit}, ${result.SiteCity} ${result.SiteState} ${result.SiteZip}`;

        return this.mapUtils
          .getMarker(address, {
            scaledSize: {
              height: 45,
              width: 27
            },
            url: STANDARD_PIN_SVG.red
          })
          .pipe(
            filter((location) => !!location?.latitude && !!location?.longitude),
            switchMap((location) => {
              return of(new fastFarmActions.GetMapMarker(location));
            }),
            catchError((error) => {
              this.pfHelperService.showSystemError(error);
              return of(error);
            })
          );
      })
    )
  );

  savePropertyProfileInParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.SavePropertyProfile>(parseAction.ParseActionTypes.SavePropertyProfile)).pipe(
      withLatestFrom(
        this.store.select(fromAppConfig.getAppInfoParseUrlType),
        this.store.select(fromAppConfig.getAppInfoConsolidatedParseAppKey),
        this.store.select(fromAppSettings.getAppSettingsUserProfileData)
      ),
      map(([action, parseUrlType, appName, user]) => ({
        appName,
        email: user.email,
        parseUrlType,
        propertyProfileData: action.payload.d
      })),
      switchMap(({ propertyProfileData, parseUrlType, appName, email }) => {
        const requestBody = {
          address: `${propertyProfileData.SiteAddress} ${propertyProfileData.SiteUnitType} ${propertyProfileData.SiteUnit}, ${propertyProfileData.SiteCity}, ${propertyProfileData.SiteState} ${propertyProfileData.SiteZip}`,
          appName,
          displayCity: propertyProfileData.SiteCity,
          displayOwnerNames: `${propertyProfileData.PrimaryOwnerName}`,
          displayStateCode: propertyProfileData.SiteState,
          displayStreetAddress: `${propertyProfileData.SiteAddress} ${propertyProfileData.SiteUnitType} ${propertyProfileData.SiteUnit}`,
          email: email.toLocaleLowerCase(),
          reportContent: {
            d: propertyProfileData
          },
          reportVersion: 1
        };

        return this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              body: requestBody,
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.propertyProfiles}`
          })
          .pipe(
            map(
              (result) =>
                new parseAction.SavePropertyProfileSuccess({
                  changes: {
                    address: requestBody.address,
                    displayCity: requestBody.displayCity,
                    displayOwnerNames: requestBody.displayOwnerNames,
                    displayStateCode: requestBody.displayStateCode,
                    displayStreetAddress: requestBody.displayStreetAddress,
                    id: result.id,
                    reportDate: new Date(Date.now()).toISOString()
                  },
                  id: result.id
                })
            ),
            catchError((error) => of(new parseAction.SavePropertyProfileFail(error)))
          );
      })
    )
  );

  getProfileHistoryFromParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.GetProfileHistory>(parseAction.ParseActionTypes.GetProfileHistory)).pipe(
      withLatestFrom(
        this.store.select(fromAppConfig.getAppInfoParseUrlType),
        this.store.select(fromAppConfig.getAppInfoConsolidatedParseAppKey),
        this.store.select(fromAppSettings.getAppSettingsUserProfileData)
      ),
      map(([_action, parseUrlType, appName, user]) => [parseUrlType, appName, user.email]),
      switchMap(([parseUrlType, appName, email]) =>
        this.apiService
          .sendRequest({
            method: 'GET',
            requestOptions: {
              params: {
                appName,
                email,
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.propertyProfiles}/History`
          })
          .pipe(
            map((result) => new parseAction.GetProfileHistorySuccess(result)),
            catchError((error) => of(new parseAction.GetProfileHistoryFail(error)))
          )
      )
    )
  );

  deletePropertyProfileFromParse$ = createEffect(() =>
    this.actions$
      .pipe(ofType<parseAction.DeletePropertyProfile>(parseAction.ParseActionTypes.DeletePropertyProfile))
      .pipe(
        withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
        map(([action, parseUrlType]) => [action.payload, parseUrlType]),
        switchMap(([propertyProfileId, parseUrlType]) =>
          this.apiService
            .sendRequest({
              method: 'DELETE',
              requestOptions: {
                params: {
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.propertyProfiles}/${propertyProfileId}`
            })
            .pipe(
              map(() => new parseAction.DeletePropertyProfileSuccess(propertyProfileId)),
              catchError((error) =>
                of(
                  new parseAction.DeletePropertyProfileFail({
                    error,
                    id: propertyProfileId
                  })
                )
              )
            )
        )
      )
  );

  getFarmReportFromParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.GetFarmReport>(parseAction.ParseActionTypes.GetFarmReport)).pipe(
      withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
      switchMap(([action, parseUrlType]) =>
        this.apiService
          .sendRequest({
            method: 'GET',
            requestOptions: {
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/${action.payload}`
          })
          .pipe(
            switchMap((result) => [
              new parseAction.GetFarmReportSuccess({ ...result, id: action.payload }),
              new walkingFarmActions.UpdateSavedFarm({
                changes: {
                  properties: result.properties
                },
                id: action.payload
              }),
              new walkingFarmActions.LoadFarm({
                ...result,
                id: action.payload
              }),
              fromMonetizationActions.setInAppPurchasesCurrentFarm({ farm: result })
            ]),
            catchError((error) => of(new parseAction.GetFarmReportFail(error)))
          )
      )
    )
  );

  getSavedFarmReportsFromParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.GetSavedFarmReports>(parseAction.ParseActionTypes.GetSavedFarmReports)).pipe(
      withLatestFrom(
        this.store.select(fromAppConfig.getAppInfoParseUrlType),
        this.store.select(fromAppConfig.getAppInfoConsolidatedParseAppKey),
        this.store.select(fromAppSettings.getAppSettingsUserProfileData)
      ),
      map(([_action, parseUrlType, appName, user]) => [parseUrlType, appName, user.email]),
      switchMap(([parseUrlType, appName, email]) =>
        this.apiService
          .sendRequest({
            method: 'GET',
            requestOptions: {
              params: {
                appName,
                email,
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/History`
          })
          .pipe(
            switchMap((result) => [new parseAction.GetSavedFarmReportsSuccess(result)]),
            catchError((error) => of(new parseAction.GetSavedFarmReportsFail(error)))
          )
      )
    )
  );

  createFarmReportInParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.CreateFarmReport>(parseAction.ParseActionTypes.CreateFarmReport)).pipe(
      withLatestFrom(
        this.store.select(fromAppConfig.getAppConfigCreateFarmData),
        this.store.select(fromAppSettings.getAppSettingsUserProfileData),
        this.store.select(fromAppSettings.getFarmAlertSettingsAutoSubscribe),
        this.store.select(fromWalkingFarm.getWalkingFarmTurnOver)
      ),
      map((results) => ({
        appName: results[1].appName,
        deviceData: this.pfHelperService.getComputeDeviceData(results[1].deviceData),
        email: results[2].email.toLowerCase(),
        filters: results[0].payload.filterJson,
        hasFarmAlerts: isBoolean(results[3]) ? results[3] : !!results[1].hasFarmAlerts,
        key: results[1].farmKey,
        name: results[0].payload.name.replace(/\//g, '-'),
        parseUrlType: results[1].parseUrlType,
        shapeCoordinates: results[0].payload.shapeJson,
        turnoverRate: results[4].turnOverPercent
      })),
      switchMap((data) =>
        this.oldApiProvider
          .getData(
            'GetFarmReportV2',
            {
              deviceData: data.deviceData,
              filterJson: JSON.stringify(data.filters),
              key: data.key,
              shapeJson: JSON.stringify(data.shapeCoordinates)
            },
            'FARM-KEY-REFRESH'
          )
          .pipe(
            withLatestFrom(this.store.select(fromWalkingFarm.getWalkingFarmRawCoordinates)),
            switchMap(([farmReportResponse, rawFarmCoordinates]) => {
              const requestBody = {
                appName: data.appName,
                email: data.email,
                filters: data.filters,
                hasFarmAlerts: data.hasFarmAlerts,
                isPurchased: false,
                name: data.name,
                properties: farmReportResponse['d'].Properties || [],
                reportCaveats: farmReportResponse['d'].Message || [],
                reportVersion: 1,
                shapeCoordinates: rawFarmCoordinates,
                turnoverRate: data.turnoverRate,
                webServiceId: farmReportResponse['d'].ReportId
              };

              return forkJoin({
                requestData: of(requestBody),
                response: this.apiService.sendRequest({
                  method: 'POST',
                  requestOptions: {
                    body: requestBody,
                    params: {
                      parseType: data.parseUrlType
                    }
                  },
                  url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}`
                })
              });
            }),
            switchMap(({ requestData, response }) => [
              new parseAction.CreateFarmReportSuccess({
                changes: {
                  appName: requestData.appName,
                  email: requestData.email,
                  filters: {
                    ...requestData.filters,
                    MinBathrooms: Number(requestData.filters.MinBathrooms),
                    MinBedrooms: Number(requestData.filters.MinBedrooms)
                  },
                  hasFarmAlerts: requestData.hasFarmAlerts,
                  id: response.id,
                  isDeleted: false,
                  isPurchased: false,
                  name: requestData.name,
                  propertyCount: requestData.properties.length,
                  reportDate: new Date(Date.now()).toISOString(),
                  shapeCoordinates: requestData.shapeCoordinates,
                  turnoverRate: requestData.turnoverRate,
                  updateDate: new Date(Date.now()).toISOString(),
                  webServiceId: requestData.webServiceId
                },
                id: response.id
              }),
              new parseAction.GetFarmReport(response.id)
            ]),
            catchError((error) => {
              return of(new parseAction.CreateFarmReportFail(error));
            })
          )
      )
    )
  );

  deleteFarmReportFromParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.DeleteFarmReport>(parseAction.ParseActionTypes.DeleteFarmReport)).pipe(
      withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
      map(([action, parseUrlType]) => [action.payload, parseUrlType]),
      switchMap(([farmReportId, parseUrlType]) =>
        this.apiService
          .sendRequest({
            method: 'DELETE',
            requestOptions: {
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/${farmReportId}`
          })
          .pipe(
            map(() => new parseAction.DeleteFarmReportSuccess(farmReportId)),
            catchError((error) =>
              of(
                new parseAction.DeleteFarmReportFail({
                  error,
                  farmReportId
                })
              )
            )
          )
      )
    )
  );

  updateFarmReportPropertiesInParse$ = createEffect(() =>
    this.actions$
      .pipe(ofType<parseAction.UpdateFarmReportProperties>(parseAction.ParseActionTypes.UpdateFarmReportProperties))
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getAppInfoParseUrlType),
          this.store.select(walkingFarmSelectors.getWalkingFarmData)
        ),
        map(([action, parseUrlType, savedFarm]) => ({
          farmId: action.payload.farmId,
          parseUrlType,
          properties: action.payload.properties,
          savedFarm
        })),
        switchMap(({ properties, parseUrlType, farmId, savedFarm }) =>
          this.apiService
            .sendRequest({
              method: 'PATCH',
              requestOptions: {
                body: {
                  properties
                },
                params: {
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/${farmId}/Properties`
            })
            .pipe(
              switchMap(() => [
                new walkingFarmActions.UpdateViewedFarmProperties({
                  isSelectiveUpdate: true,
                  properties
                }),
                new walkingFarmActions.UpdateSavedFarm({
                  changes: { properties },
                  id: farmId,
                  savedFarm
                }),
                new walkingFarmActions.FilterViewedFarm({
                  farmId: savedFarm.id
                }),
                new parseAction.UpdateFarmReportPropertiesSuccess(),
                new walkingFarmAction.LoadFarm(savedFarm)
              ]),
              catchError((error) => of(new parseAction.UpdateFarmReportPropertiesFail(error)))
            )
        )
      )
  );

  getFarmAlertsForFarmFromParse$ = createEffect(() =>
    this.actions$
      .pipe(ofType<parseAction.GetFarmAlertsForFarm>(parseAction.ParseActionTypes.GetFarmAlertsForFarm))
      .pipe(
        withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
        map(([action, parseUrlType]) => [action.payload, parseUrlType]),
        switchMap(([farmReportId, parseUrlType]) =>
          this.apiService
            .sendRequest({
              method: 'GET',
              requestOptions: {
                params: {
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/${farmReportId}/Alerts`
            })
            .pipe(
              map(
                (result) =>
                  new parseAction.GetFarmAlertsForFarmSuccess(
                    result?.map((alert) => ({
                      ...alert,
                      isRead: alert.isRead || false
                    })) || []
                  )
              ),
              catchError((error) => of(new parseAction.GetFarmAlertsForFarmFail(error)))
            )
        )
      )
  );

  markFarmAlertAsReadInParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.MarkFarmAlertAsRead>(parseAction.ParseActionTypes.MarkFarmAlertAsRead)).pipe(
      withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
      map(([action, parseUrlType]) => [action.payload, parseUrlType]),
      switchMap(([farmAlertId, parseUrlType]) =>
        this.apiService
          .sendRequest({
            method: 'PATCH',
            requestOptions: {
              body: {
                ids: [farmAlertId]
              },
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/Alert/Read`
          })
          .pipe(
            map(() => new parseAction.MarkFarmAlertAsReadSuccess(farmAlertId)),
            catchError((error) => of(new parseAction.MarkFarmAlertAsReadFail(error)))
          )
      )
    )
  );

  markMultipleFarmAlertsAsReadInParse$ = createEffect(() =>
    this.actions$
      .pipe(ofType<parseAction.MarkMultipleFarmAlertsAsRead>(parseAction.ParseActionTypes.MarkMultipleFarmAlertsAsRead))
      .pipe(
        withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
        map(([action, parseUrlType]) => ({ farmAlertIds: action.payload, parseUrlType })),
        switchMap(({ farmAlertIds, parseUrlType }) =>
          this.apiService
            .sendRequest({
              method: 'PATCH',
              requestOptions: {
                body: {
                  ids: farmAlertIds
                },
                params: {
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/Alert/Read`
            })
            .pipe(
              map(() => new parseAction.MarkMultipleFarmAlertsAsReadSuccess(farmAlertIds)),
              catchError((error) => of(new parseAction.MarkMultipleFarmAlertsAsReadFail(error)))
            )
        )
      )
  );

  toggleFarmAlertSubscriptionFromParse$ = createEffect(() =>
    this.actions$
      .pipe(ofType<parseAction.ToggleFarmAlertSubscription>(parseAction.ParseActionTypes.ToggleFarmAlertSubscription))
      .pipe(
        withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
        map(([action, parseUrlType]) => ({
          data: action.payload,
          parseUrlType
        })),
        switchMap(({ data, parseUrlType }) =>
          this.apiService
            .sendRequest({
              method: 'PATCH',
              requestOptions: {
                body: {
                  hasFarmAlerts: data.hasFarmAlerts
                },
                params: {
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/${data.farmObjectId}`
            })
            .pipe(
              map((result) => new parseAction.ToggleFarmAlertSubscriptionSuccess(result)),
              catchError((error) => of(new parseAction.ToggleFarmAlertSubscriptionFail(error)))
            )
        )
      )
  );

  getUserFromParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.GetUser>(parseAction.ParseActionTypes.GetUser)).pipe(
      withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
      map(([action, parseUrlType]) => ({ data: action.payload, parseUrlType })),
      switchMap(({ data, parseUrlType }) =>
        this.apiService
          .sendRequest({
            method: 'GET',
            requestOptions: {
              params: {
                appName: data.appName,
                email: data.email,
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}`
          })
          .pipe(
            map((result) => {
              this.secureStorageService.updateInternalStorage({
                firebaseInstallationIds: result.firebaseInstallationIds,
                user: result
              });

              return new parseAction.GetUserSuccess(result);
            }),
            catchError((error) => of(new parseAction.GetUserFail(error)))
          )
      )
    )
  );

  getRegisteredUserFromParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.GetRegisteredUser>(parseAction.ParseActionTypes.GetRegisteredUser)).pipe(
      withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
      map(([action, parseUrlType]) => ({ data: action.payload, parseUrlType })),
      switchMap(({ data, parseUrlType }) =>
        this.apiService
          .sendRequest({
            method: 'GET',
            requestOptions: {
              params: {
                appName: data.appName,
                email: data.email,
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}`
          })
          .pipe(
            switchMap((result) => [
              new parseAction.UpdateUserSuccess(result),
              new parseAction.GetRegisteredUserSuccess(result),
              new appConfigGeneralInfoAction.ShouldSaveDataInInternalStorage(),
              new appConfigGeneralInfoAction.PaymentsFlowShouldRefresh()
            ]),
            catchError((error) => {
              this.pfHelperService.showUserFailureMessage(data.email);
              return of(new parseAction.GetRegisteredUserFail(error.error.message));
            })
          )
      )
    )
  );

  createUserInParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.CreateUser>(parseAction.ParseActionTypes.CreateUser)).pipe(
      withLatestFrom(
        this.store.select(fromAppSettings.getAppSettingsUserProfileData),
        this.store.select(fromAppConfig.getAppInfoConsolidatedParseAppKey),
        this.store.select(fromAppConfig.getAppInfoParseUrlType),
        this.store.select(fromAppConfig.getEnableFarmAlertAutoSubscriptions),
        this.platformDetectService.getIsMobileSubscription()
      ),
      switchMap(([_action, user, appName, parseUrlType, enableFarmAlertAutoSubscriptions, isMobilePlatform]) => {
        const requestBody = {
          appName,
          autoSubscribeFarmAlerts: enableFarmAlertAutoSubscriptions,
          company: user.company,
          deviceIds: [user.deviceId],
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
          providerUsername: user.dataProviderUsername,
          securityCode: user.repCode,
          tosVersion: user.tosVersion
        };

        if (!isMobilePlatform) {
          requestBody.deviceIds = [Math.random().toString(36).substring(7)];
        }

        return this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              body: requestBody,
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}`
          })
          .pipe(
            switchMap((result) => [
              new parseAction.CreateUserSuccess({ id: result.id }),
              new appConfigGeneralInfoAction.ShouldSaveDataInInternalStorage(),
              new appConfigGeneralInfoAction.PaymentsFlowShouldRefresh()
            ]),
            catchError((error) => of(new parseAction.CreateUserFail(error)))
          );
      })
    )
  );

  updateUserInParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.UpdateUser>(parseAction.ParseActionTypes.UpdateUser)).pipe(
      withLatestFrom(
        this.store.select(fromAppConfig.getAuthDataUserObjectId),
        this.store.select(fromAppConfig.getAppInfoParseUrlType)
      ),
      switchMap(([action, userId, parseUrlType]) =>
        this.apiService
          .sendRequest({
            method: 'PATCH',
            requestOptions: {
              body: {
                company: action.payload.company,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                phone: action.payload.phone
              },
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${userId}`
          })
          .pipe(
            switchMap(() => [
              new parseAction.UpdateUserSuccess({
                company: action.payload.company,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                phone: action.payload.phone
              }),
              new appConfigGeneralInfoAction.ShouldSaveDataInInternalStorage()
            ]),
            catchError((error) => of(new parseAction.UpdateUserFail(error)))
          )
      )
    )
  );

  updateUserInstallationIdInParse$ = createEffect(() =>
    this.actions$
      .pipe(ofType<parseAction.UpdateUserInstallationId>(parseAction.ParseActionTypes.UpdateUserInstallationId))
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getAuthDataUserObjectId),
          this.store.select(fromAppConfig.getAppInfoParseUrlType),
          this.store.select(fromAppSettings.getAppSettingsUserEmail),
          this.store.select(fromAppConfig.getAppInfoConsolidatedParseAppKey),
          this.store.select(fromAppSettings.getDeviceId)
        ),
        switchMap(([action, userId, parseUrlType, userEmail, appName, deviceId]) =>
          this.apiService
            .sendRequest({
              method: 'PATCH',
              requestOptions: {
                body: {
                  deviceIds: [deviceId],
                  firebaseInstallationIds: action.payload.installationId ? [action.payload.installationId] : []
                },
                params: {
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${userId}`
            })
            .pipe(
              map(() => {
                this.store.dispatch(
                  new parseAction.GetUser({
                    appName,
                    email: userEmail
                  })
                );

                return new parseAction.UpdateUserInstallationIdSuccess(userId);
              }),
              catchError((error) => of(new parseAction.UpdateUserInstallationIdFail(error)))
            )
        )
      )
  );

  updateUserTokenInParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.UpdateUserToken>(parseAction.ParseActionTypes.UpdateUserToken)).pipe(
      withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
      map(([action, parseUrlType]) => [action.payload, parseUrlType]),
      switchMap(([userObjectId, parseUrlType]) =>
        this.apiService
          .sendRequest({
            method: 'PATCH',
            requestOptions: {
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${userObjectId}/Activity`
          })
          .pipe(
            map(() => new parseAction.UpdateUserTokenSuccess(userObjectId)),
            catchError((error) => of(new parseAction.UpdateUserTokenFail(error)))
          )
      )
    )
  );

  onShouldSaveDataInInternalStorage$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<generalInfoAction.ShouldSaveDataInInternalStorage>(
          generalInfoAction.GeneralConfigActionTypes.ShouldSaveDataInInternalStorage
        )
      )
      .pipe(switchMap(() => [new generalInfoAction.SaveDataInInternalStorage()]))
  );

  getFarmAlertSettings$ = createEffect(() =>
    this.actions$
      .pipe(ofType<parseAction.GetFarmAlertSettings>(parseAction.ParseActionTypes.GetFarmAlertSettings))
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getAppInfoParseUrlType),
          this.store.select(fromAppSettings.getAppSettingsUserProfileData),
          this.store.select(fromAppConfig.getAppInfoConsolidatedParseAppKey)
        ),
        switchMap(([_action, parseUrlType, user, appName]) =>
          this.apiService
            .sendRequest({
              method: 'GET',
              requestOptions: {
                params: {
                  appName,
                  email: user.email,
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}`
            })
            .pipe(
              map(
                (result) =>
                  new parseAction.GetFarmAlertSettingsSuccess({
                    autoSubscribeFarmAlerts: isBoolean(result.autoSubscribeFarmAlerts)
                      ? result.autoSubscribeFarmAlerts
                      : null,
                    receiveEmailNotifications: isBoolean(result.farmAlertEmailNotifications)
                      ? result.farmAlertEmailNotifications
                      : null,
                    receivePushNotifications: isBoolean(result.farmAlertPushNotifications)
                      ? result.farmAlertPushNotifications
                      : null
                  })
              ),
              catchError((error) => {
                this.pfHelperService.showSystemError(error.message);
                return of(new parseAction.GetFarmAlertSettingsFail(error));
              })
            )
        )
      )
  );

  updateUserFarmAlertSettings$ = createEffect(() =>
    this.actions$
      .pipe(ofType<parseAction.UpdateUserFarmAlertSettings>(parseAction.ParseActionTypes.UpdateUserFarmAlertSettings))
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getAuthDataUserObjectId),
          this.store.select(fromAppConfig.getAppInfoParseUrlType)
        ),
        switchMap(([action, userId, parseUrlType]) =>
          this.apiService
            .sendRequest({
              method: 'PATCH',
              requestOptions: {
                body: {
                  autoSubscribeFarmAlerts: action.payload.autoSubscribeFarmAlerts,
                  farmAlertEmailNotifications: action.payload.receiveEmailNotifications,
                  farmAlertPushNotifications: action.payload.receivePushNotifications
                },
                params: {
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${userId}`
            })
            .pipe(
              map(() => new parseAction.UpdateUserFarmAlertSettingsSuccess()),
              catchError((error) => {
                this.pfHelperService.showSystemError(error.message);
                return of(new parseAction.UpdateUserFarmAlertSettingsFail(error));
              })
            )
        )
      )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private oldApiProvider: ApiProvider,
    private pfHelperService: PfHelperService,
    private secureStorageService: SecureStorageService,
    private mapUtils: MapUtils,
    private apiService: ApiService,
    private platformDetectService: PlatformDetectService
  ) {}
}
