export enum MonetizationPrimarySubscriptionPlans {
  PlanA = 'PLAN_A',
  PlanATest = 'PLAN_A_TEST',
  PlanB = 'PLAN_B',
  PlanBTest = 'PLAN_B_TEST',
  PlanC = 'PLAN_C',
  PlanCTest = 'PLAN_C_TEST'
}

export enum MonetizationAlertTypes {
  UnsubscribedCreateNewFarm = 'unsubscribedCreateNewFarm',
  UnsubscribedOpenSavedFarm = 'unsubscribedOpenSavedFarm',
  UnsubscribedOpenSavedFarmList = 'unsubscribedOpenSavedFarmList'
}

export enum MonetizationPaymentRedirectTypes {
  Cancel = 'cancel',
  Success = 'success'
}

export enum MonetizationFarmPropertiesPhoneAndEmailsInAppPurchaseTypes {
  FarmPropertiesPhoneAndEmailsL = 'FARM_PROPERTIES_PHONE_AND_EMAILS_L',
  FarmPropertiesPhoneAndEmailsM = 'FARM_PROPERTIES_PHONE_AND_EMAILS_M',
  FarmPropertiesPhoneAndEmailsS = 'FARM_PROPERTIES_PHONE_AND_EMAILS_S'
}

export enum MonetizationInAppPurchaseTypes {
  FarmPropertiesPhoneAndEmailsL = 'FARM_PROPERTIES_PHONE_AND_EMAILS_L',
  FarmPropertiesPhoneAndEmailsM = 'FARM_PROPERTIES_PHONE_AND_EMAILS_M',
  FarmPropertiesPhoneAndEmailsS = 'FARM_PROPERTIES_PHONE_AND_EMAILS_S'
}
