import { Action } from '@ngrx/store';

import { FarmFilters } from '../../../typings/walking-farm';

export enum FiltersActionTypes {
  ResetFilters = '[Filters] Reset Filters',
  ResetPropertyTypes = '[Filters] Reset PropertyTypes',
  SelectPropertyTypes = '[Filters] Select PropertyTypes',
  SetFilters = '[Filters] Set Filters',
  SetSavedFarmListIndex = '[Filters] Set Saved Farm List Index'
}

export class SelectPropertyTypes implements Action {
  readonly type = FiltersActionTypes.SelectPropertyTypes;

  constructor(public payload: string[]) {}
}

export class ResetPropertyTypes implements Action {
  readonly type = FiltersActionTypes.ResetPropertyTypes;

  constructor() {}
}

export class ResetFilters implements Action {
  readonly type = FiltersActionTypes.ResetFilters;

  constructor() {}
}

export class SetFilters implements Action {
  readonly type = FiltersActionTypes.SetFilters;

  constructor(public payload: Partial<FarmFilters>) {}
}

export class SetSavedFarmListIndex implements Action {
  readonly type = FiltersActionTypes.SetSavedFarmListIndex;

  constructor(public payload: number) {}
}

export type FiltersActions =
  | SelectPropertyTypes
  | ResetPropertyTypes
  | ResetFilters
  | SetFilters
  | SetSavedFarmListIndex;
