<ion-modal [isOpen]="isModalOpen" (willDismiss)="onPhoneAndEmailModalDismiss()">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="onSelectPhoneAndEmailPropertiesCancel()">Cancel</ion-button>
        </ion-buttons>
        <ion-title class="tw-text-center">Select Properties</ion-title>
        <ion-buttons slot="end">
          <ion-button
            type="primary"
            [disabled]="isConfirmButtonDisabled"
            (click)="onSelectPhoneAndEmailPropertiesConfirm()"
          >
            Confirm
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <hr class="tw-border-gray-300" />

      <ion-toolbar>
        <ion-grid class="ion-no-padding tw-px-2">
          <ion-row>
            <ion-col [size]="12" [sizeLg]="isPhoneAndEmailToggleVisible ? 9 : 12" class="ion-no-padding">
              <smd-search-bar
                class="tw-w-full"
                placeholder="Property Address"
                (searchInput)="onPhoneAndEmailPropertySearchInput($event)"
                (clearInput)="onPhoneAndEmailPropertySearchInputClear($event)"
              ></smd-search-bar>
            </ion-col>
            <ion-col
              *ngIf="isPhoneAndEmailToggleVisible"
              size="12"
              size-lg="3"
              class="ion-no-padding tw-mb-2 lg:tw-mb-0"
            >
              <ion-item lines="none" class="smd-phone-email-toggle-wrapper ion-no-padding lg:tw-h-full tw-flex">
                <ion-label class="tw-text-right tw-flex-initial lg:tw-flex-auto">Entire Farm</ion-label>
                <ion-toggle
                  [slot]="isMobileView ? '' : 'end'"
                  color="success"
                  class="tw-px-2"
                  [(ngModel)]="isPhoneAndEmailPropertiesToggleActive"
                  (ionChange)="onPhoneAndEmailPropertiesSelectAllToggleChange($event)"
                ></ion-toggle>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div
        class="tw-my-3.5 tw-flex tw-w-full tw-cursor-pointer"
        *ngFor="let property of phoneAndEmailPropertyList"
        (click)="updatePhoneAndEmailModel([property.id])"
      >
        <ion-checkbox
          class="tw-my-auto"
          slot="start"
          [checked]="phoneAndEmailPropertiesModel[property.id]"
        ></ion-checkbox>
        <ion-label class="tw-ml-2 tw-text-base tw-text-gray-600"> {{ property.address }}</ion-label>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>
