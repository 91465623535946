import { MonetizationPrimarySubscriptionPlans } from '../enums/monetization';
import { MonetizationPlanRules } from '../typings/monetization';

export const paymentPlanRules: MonetizationPlanRules[] = [
  {
    alias: MonetizationPrimarySubscriptionPlans.PlanA,
    entitlement: 'plan_a_features',
    offering: 'plan_a_access'
  },
  {
    accessAlias: MonetizationPrimarySubscriptionPlans.PlanA,
    alias: MonetizationPrimarySubscriptionPlans.PlanATest,
    entitlement: 'plan_a_features',
    offering: 'plan_a_access_test'
  },
  {
    alias: MonetizationPrimarySubscriptionPlans.PlanB,
    entitlement: 'plan_b_features',
    offering: 'plan_b_access'
  },
  {
    accessAlias: MonetizationPrimarySubscriptionPlans.PlanB,
    alias: MonetizationPrimarySubscriptionPlans.PlanBTest,
    entitlement: 'plan_b_features',
    offering: 'plan_b_access_test'
  },
  {
    alias: MonetizationPrimarySubscriptionPlans.PlanC,
    entitlement: 'plan_c_features',
    offering: 'plan_c_access'
  },
  {
    accessAlias: MonetizationPrimarySubscriptionPlans.PlanC,
    alias: MonetizationPrimarySubscriptionPlans.PlanCTest,
    entitlement: 'plan_c_features',
    offering: 'plan_c_access_test'
  }
];
