import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  cloneDeep,
  filter,
  find,
  has,
  lowerCase,
  map as lodashMap,
  mapValues,
  orderBy,
  partialRight,
  pick
} from 'lodash-es';
import moment from 'moment';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { LEAD_STATUS_LIST } from '../../../constants/lead-status.constants';
import { REP_DATA_RESPONSE_PROVIDER_ID } from '../../../constants/main.constants';
import {
  COLOR_PIN_SVG,
  COLOR_PIN_WITH_ALERT_SVG,
  PROPERTY_OCCUPANCY_MARKER_SVG,
  STANDARD_PIN_SVG
} from '../../../constants/map.constants';
import { markerTypes } from '../../constants/map';
import { PolygonFarmCoordinates, PropertyData } from '../../typings/walking-farm';

import * as fromMapActions from '../../store/actions/map.actions';

import { ApiProvider } from '../../providers/api.service';
import { PfHelperService } from '../../providers/pf-helper-service.service';

import * as walkingFarmAction from '../actions/walking-farm/farm';
import * as fromReportFiltersActions from '../actions/walking-farm/walking-farm-report-filters';
import * as fromAppConfig from '../selectors/app-config/index';
import * as fromWalkingFarm from '../selectors/walking-farm/index';

@Injectable()
export class PropertyFarmEffects {
  getFarmCount$ = createEffect(() =>
    this.actions$.pipe(ofType<walkingFarmAction.GetFarmCount>(walkingFarmAction.FarmActionTypes.GetFarmCount)).pipe(
      withLatestFrom(
        this.store.select(fromAppConfig.getFarmKey),
        this.store.select(fromAppConfig.getGeneralInfoDeviceData)
      ),
      switchMap(([action, farmKey, deviceData]) => {
        const { countPayload, rawFarmCoordinates } = action.payload;
        const { shapeJson, filterJson } = mapValues(countPayload, (f) => JSON.stringify(f));

        return this.apiService
          .getData(
            'GetFarmCount',
            {
              deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
              filterJson,
              key: farmKey,
              shapeJson
            },
            'FARM-KEY-REFRESH'
          )
          .pipe(
            map(
              (result) =>
                new walkingFarmAction.GetFarmCountSuccess({
                  farmCoordinates: countPayload.shapeJson as PolygonFarmCoordinates,
                  rawFarmCoordinates,
                  turnOver: {
                    count: result['d'].Count,
                    message: result['d'].Message,
                    turnOverPercent: result['d'].TurnoverPercent
                  }
                })
            ),
            catchError((error) => {
              if (['no records', 'max exceeded'].indexOf(error.message) !== -1) {
                const message =
                  error.message === 'no records'
                    ? 'Your request did not return any records.'
                    : 'Your request has exceeded the max number of records (1000). Please refine your search.';
                const title =
                  error.message === 'no records' ? 'No records found' : 'Exceeded the maximum number of records';
                this.pfHelperService.showSystemError(message, null, title);
              } else {
                this.pfHelperService.showSystemError(error.message);
              }

              return of(new walkingFarmAction.GetFarmCountFail(error));
            })
          );
      })
    )
  );

  getFarmCredits$ = createEffect(() =>
    this.actions$.pipe(ofType<walkingFarmAction.GetFarmCredits>(walkingFarmAction.FarmActionTypes.GetFarmCredits)).pipe(
      withLatestFrom(
        this.store.select(fromAppConfig.getFarmKey),
        this.store.select(fromAppConfig.getGeneralInfoDeviceData),
        this.store.select(fromAppConfig.getRepDataProviderId)
      ),
      switchMap(([_action, farmKey, deviceData, providerId]) => {
        if (providerId !== REP_DATA_RESPONSE_PROVIDER_ID) {
          return [];
        }

        return this.apiService
          .getData(
            'GetFarmCredits',
            {
              deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
              key: farmKey
            },
            'FARM-KEY-REFRESH'
          )
          .pipe(
            map((result) => new walkingFarmAction.GetFarmCreditsSuccess(result['d'].Count)),
            catchError((error) => of(new walkingFarmAction.GetFarmCreditsFail(error)))
          );
      })
    )
  );

  getFilteredViewedFarm = createEffect(() =>
    this.actions$
      .pipe(ofType<walkingFarmAction.FilterViewedFarm>(walkingFarmAction.FarmActionTypes.FilterViewedFarm))
      .pipe(
        switchMap((action) => {
          return of(action.payload.farmId).pipe(
            withLatestFrom(
              this.store.select(fromWalkingFarm.getWalkingFarmReportSelectedFilters),
              this.store.select(fromWalkingFarm.getFarmById(action.payload.farmId)),
              this.store.select(fromWalkingFarm.getWalkingFarmAlertsData)
            ),
            switchMap(([_id, filters, farm, alerts]) => {
              const viewedFarm = { ...farm };

              let properties = this.sortProperties([...(viewedFarm?.properties || [])], filters.selectedSortBy);

              if (!action.payload.resetFilters) {
                properties =
                  filter(cloneDeep(properties), (property) =>
                    this.checkOwnerStatusFilter(property, filters.selectedOwnerStatus)
                  ) || [];

                properties =
                  filter(cloneDeep(properties), (property) =>
                    this.checkLeadStatusFilter(property, filters.selectedLeadStatus)
                  ) || [];

                if (filters.selectedAlertsShown.selectedTab === 'unread') {
                  properties =
                    filter(
                      cloneDeep(properties),
                      (property) =>
                        filter(alerts, (alert) => alert.propertyNumber == property.A000_PropertyNumber && !alert.isRead)
                          .length > 0
                    ) || [];
                }
              }

              if (!properties.length && !action.payload.resetFilters) {
                throw new Error('No properties are matching the selected filters');
              }

              const updatedFarmData = {
                ...viewedFarm,
                properties
              };

              const actions: Action[] = [
                new walkingFarmAction.UpdateViewedFarmProperties({
                  isSelectiveUpdate: false,
                  properties: updatedFarmData.properties,
                  propertyCount: updatedFarmData.properties.length
                }),
                new walkingFarmAction.LoadFarm(updatedFarmData)
              ];

              if (action.payload.resetFilters) {
                actions.push(new fromReportFiltersActions.ResetReportFilters());
              }

              return actions;
            }),
            catchError((error) => {
              this.pfHelperService.showSystemError(error.message, null, 'No records found');
              return of({
                payload: error.message,
                type: 'NO_RECORDS'
              });
            })
          );
        })
      )
  );

  includeMarkers$ = createEffect(() =>
    this.actions$.pipe(ofType<walkingFarmAction.LoadFarm>(walkingFarmAction.FarmActionTypes.LoadFarm)).pipe(
      withLatestFrom(
        this.store.select(fromWalkingFarm.getSelectedFarmAlertsPropertyIds),
        this.store.select(fromWalkingFarm.getMarkerType)
      ),
      switchMap(([action, ids, markerType]) => {
        const properties = action.payload.properties;

        const mapMarkersData = lodashMap(
          properties,
          partialRight(pick, [
            'A041_Latitude',
            'A042_Longitude',
            'Status',
            'A000_PropertyNumber',
            'A002_HouseNumber',
            'A004_StreetName',
            'A005_UnitNumber',
            'A046_City',
            'A047_State',
            'A008_Zip4',
            'A007_ZipCode',
            'A009_OwnerName2',
            'A040_OwnerOccupied',
            'A035_LotArea',
            'A025_Bathrooms',
            'A024_Bedrooms'
          ])
        );

        const leadStatusKeys = Object.keys(LEAD_STATUS_LIST);

        const propertyMapMarkers = lodashMap(mapMarkersData, (item: Partial<PropertyData>) => {
          const currentLeadStatus = find(
            leadStatusKeys,
            (leadStatus: string) => leadStatus.toLowerCase() === item.Status?.toLowerCase()
          )?.toLowerCase();

          const markerPinKey = LEAD_STATUS_LIST[currentLeadStatus]?.markerPin;
          let markerIcon = has(COLOR_PIN_SVG, markerPinKey) ? COLOR_PIN_SVG[markerPinKey] : STANDARD_PIN_SVG.red;

          let occupancyMarkerIcon =
            item.A040_OwnerOccupied === 'N'
              ? PROPERTY_OCCUPANCY_MARKER_SVG.unoccupied
              : PROPERTY_OCCUPANCY_MARKER_SVG.occupied;

          if (ids.indexOf(item.A000_PropertyNumber.toString()) !== -1) {
            markerIcon =
              !!currentLeadStatus && has(COLOR_PIN_WITH_ALERT_SVG, markerPinKey)
                ? COLOR_PIN_WITH_ALERT_SVG[markerPinKey]
                : STANDARD_PIN_SVG.red;

            occupancyMarkerIcon =
              item.A040_OwnerOccupied === 'N'
                ? PROPERTY_OCCUPANCY_MARKER_SVG.unoccupiedWithAlert
                : PROPERTY_OCCUPANCY_MARKER_SVG.occupiedWithAlert;
          }

          const getIconUrl = () => {
            switch (markerType) {
              case markerTypes.lead:
                return `/assets/svg/map-markers/${markerIcon}`;
              case markerTypes.occupancy:
              default:
                return `/assets/svg/map-markers/${occupancyMarkerIcon}`;
            }
          };

          return {
            data: {
              leadStatusMarker: `/assets/svg/map-markers/${markerIcon}`,
              occupancyMarker: `/assets/svg/map-markers/${occupancyMarkerIcon}`
            },
            icon: {
              scaledSize: {
                height: 45,
                width: 27
              },
              url: getIconUrl()
            },
            latitude: Number(item.A041_Latitude),
            longitude: Number(item.A042_Longitude),
            propertyInfo: {
              address: this.pfHelperService.getPropertyAddress(item),
              area: `${item.A035_LotArea} Sq Ft`,
              bathrooms: `${item.A025_Bathrooms} BA`,
              bedrooms: `${item.A024_Bedrooms} BR`,
              id: item.A000_PropertyNumber,
              occupied: item.A040_OwnerOccupied,
              owner: item.A009_OwnerName2,
              status: item.Status ? item.Status.toLowerCase() : 'N/A'
            }
          };
        });

        let propertyWithValidLocationIndex = 0;
        while (
          !propertyMapMarkers[propertyWithValidLocationIndex].latitude &&
          !propertyMapMarkers[propertyWithValidLocationIndex].longitude &&
          propertyWithValidLocationIndex < propertyMapMarkers.length
        ) {
          propertyWithValidLocationIndex++;
        }

        return [
          fromMapActions.setSavedFarmLocation({
            isInitialCoordinates: true,
            savedFarmLocation: {
              lat: propertyMapMarkers[propertyWithValidLocationIndex].latitude,
              lng: propertyMapMarkers[propertyWithValidLocationIndex].longitude
            }
          }),
          new walkingFarmAction.IncludeFarmPropertiesMarkers(propertyMapMarkers)
        ];
      })
    )
  );

  private sortProperties(properties: PropertyData[], filterKey: string) {
    let leadSortOrder: string[];

    switch (filterKey) {
      case 'parcelNumber':
        properties = orderBy(properties, ['A005_UnitNumber'], ['asc']);
        break;
      case 'leadStatus':
        leadSortOrder = ['lead', 'prospect', 'not home', 'not contacted', 'not interested', 'on market'];

        properties.sort((previous, next) => {
          const previousStatus = lowerCase(previous.Status);
          const nextStatus = lowerCase(next.Status);

          return previousStatus == nextStatus
            ? previousStatus.localeCompare(nextStatus)
            : leadSortOrder.indexOf(previousStatus) - leadSortOrder.indexOf(nextStatus);
        });
        break;
      case 'streetName':
        properties = orderBy(properties, ['A004_StreetName', 'A002_HouseNumber', 'A005_UnitNumber'], ['asc']);
        break;
      case 'unreadAlerts':
        properties = orderBy(properties, ['unreadFarmAlerts'], ['desc']);
        break;
      default:
        break;
    }

    return properties;
  }

  private checkOwnerStatusFilter(property: PropertyData, filterKey: string) {
    let totalYearsInHome: number;
    let ownershipYearsMedian: number;
    let totalPropertiesWithYearsInHome: number;
    let match: RegExpMatchArray;
    let saleDate: moment.Moment;
    let check = true;

    switch (filterKey) {
      case 'occupied':
        check = property.A040_OwnerOccupied === 'Y';
        break;
      case 'unoccupied':
        check = property.A040_OwnerOccupied === 'N';
        break;
      case 'outOfState':
        match = property.A018_MailAddressCityState?.match(/\s(\w+)$/);
        check = property.A047_State && match?.length ? property.A047_State !== match[1] : false;
        break;
      case 'emptyNesters':
        saleDate = moment(property.A030_SaleDate, 'MM/DD/YYYY');
        check =
          property.A040_OwnerOccupied === 'Y' && saleDate.isValid() ? moment().diff(saleDate, 'year') <= 25 : false;
        break;
      case 'yearsInHome':
        totalPropertiesWithYearsInHome = 0;
        totalYearsInHome = 0;

        saleDate = moment(property.A030_SaleDate, 'MM/DD/YYYY');
        if (saleDate.isValid()) {
          totalPropertiesWithYearsInHome++;
          totalYearsInHome += moment().diff(saleDate, 'year');
        }

        ownershipYearsMedian = totalYearsInHome / totalPropertiesWithYearsInHome;
        check = property.A040_OwnerOccupied === 'Y' && moment().diff(saleDate, 'year') >= ownershipYearsMedian;

        break;
      default:
        break;
    }

    return check;
  }

  checkLeadStatusFilter(property: PropertyData, selectedLeadStatus: string[]) {
    let check = true;

    if (selectedLeadStatus.length) {
      check = selectedLeadStatus.includes(lowerCase(property.Status));
    }

    return check;
  }

  constructor(
    private store: Store<fromWalkingFarm.State>,
    private actions$: Actions,
    private apiService: ApiProvider,
    private pfHelperService: PfHelperService
  ) {}
}
