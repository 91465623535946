import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { has } from 'lodash';

import { ProfileHistoryItem } from '../../../typings/property-profile';

import * as parseActions from '../../actions/parse/parse-implementation';
import * as propertyProfileActions from '../../actions/property-data/property-profile';
import { sortByDate } from '../../entities-util';
import { initState, ViewState } from '../../init-state';

export interface State extends ViewState<ProfileHistoryItem> {}

export const adapter: EntityAdapter<ProfileHistoryItem> = createEntityAdapter<ProfileHistoryItem>({
  selectId: (historyItem: ProfileHistoryItem) =>
    has(historyItem, 'id') && has(historyItem, 'changes') ? historyItem.changes.id : historyItem.id,
  sortComparer: sortByDate
});

export const initialState: State = initState(adapter);

export function reducer(
  state: State = initialState,
  action: parseActions.ParseActions | propertyProfileActions.PropertyProfileActions
) {
  switch (action.type) {
    case parseActions.ParseActionTypes.GetProfileHistory:
    case parseActions.ParseActionTypes.SavePropertyProfile:
    case parseActions.ParseActionTypes.DeletePropertyProfile: {
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true
      };
    }

    case parseActions.ParseActionTypes.GetProfileHistorySuccess: {
      return {
        ...adapter.upsertMany(action.payload, state),
        error: null,
        loaded: true,
        loading: false
      };
    }

    case parseActions.ParseActionTypes.SavePropertyProfileSuccess: {
      return {
        ...adapter.upsertOne(action.payload as ProfileHistoryItem, state),
        error: null,
        loaded: true,
        loading: false,
        selectedId: action.payload.id
      };
    }

    case parseActions.ParseActionTypes.DeletePropertyProfileSuccess: {
      return {
        ...adapter.removeOne(action.payload, state),
        error: null,
        loaded: true,
        loading: false
      };
    }

    case parseActions.ParseActionTypes.GetProfileHistoryFail:
    case parseActions.ParseActionTypes.SavePropertyProfileFail: {
      return {
        ...state,
        error: action.payload,
        loaded: false,
        loading: false,
        selectedId: null
      };
    }

    case parseActions.ParseActionTypes.GetPropertyProfileFail: {
      return {
        ...state,
        error: action.payload,
        selectedId: null
      };
    }

    case parseActions.ParseActionTypes.DeletePropertyProfileFail: {
      return {
        ...adapter.removeOne(action.payload.id, state),
        error: action.payload.error,
        loaded: false,
        loading: false
      };
    }

    case parseActions.ParseActionTypes.GetPropertyProfileSuccess: {
      return {
        ...state,
        loaded: false,
        loading: true,
        selectedId: action.payload.id
      };
    }

    case propertyProfileActions.PropertyProfileActionTypes.ResetSelectedProfile: {
      return {
        ...state,
        error: null,
        loaded: false,
        loading: false,
        selectedId: null
      };
    }

    case propertyProfileActions.PropertyProfileActionTypes.ClearAllProfiles: {
      return {
        ...adapter.removeAll(state),
        error: null,
        loaded: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}

export const { selectAll: getProfileHistoryItems } = adapter.getSelectors();

export const getSelectedProfileId = (state: State) => state.selectedId;
export const getProfileHistoryLoading = (state: State) => state.loading;
