import * as appSettingsUserProfileAction from '../../actions/app-settings/user-profile';
import { storeInitialState } from '../../view-state';

export const clearState = (reducer) => (state, action) => {
  if (action.type === appSettingsUserProfileAction.UserProfileActionTypes.LogoutUser) {
    state = {
      ...storeInitialState,
      config: state.config
    };
  }

  return reducer(state, action);
};
