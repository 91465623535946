import { MonetizationIapPhoneAndEmailCsvError } from '../../../typings/monetization';

import * as fromFarmReportsActions from '../../actions/walking-farm/farm-report-details';

export interface State {
  error: MonetizationIapPhoneAndEmailCsvError | null;
  iapPhoneAndEmailsCsv: string | null;
  pending: boolean | null;
  success: boolean | null;
}

export const initialState: State = {
  error: null,
  iapPhoneAndEmailsCsv: null,
  pending: null,
  success: null
};

export function reducer(state = initialState, action: fromFarmReportsActions.FarmReportDetailsActions) {
  switch (action.type) {
    case fromFarmReportsActions.FarmReportDetailsActionTypes.ExportIapPhoneAndEmailCSV:
      return {
        ...state,
        error: null,
        pending: true,
        success: null
      };

    case fromFarmReportsActions.FarmReportDetailsActionTypes.ExportIapPhoneAndEmailCSVSuccess:
      return {
        ...state,
        iapPhoneAndEmailsCsv: action.payload.csvResult,
        pending: false,
        success: true
      };

    case fromFarmReportsActions.FarmReportDetailsActionTypes.ExportIapPhoneAndEmailCSVFailure:
      return {
        ...state,
        error: action.payload.error,
        pending: false,
        success: true
      };

    case fromFarmReportsActions.FarmReportDetailsActionTypes.ResetIapPhoneAndEmailCSVState:
      return {
        ...initialState
      };

    default:
      return state;
  }
}

export const getIapPhoneAndEmailCsvData = (state: State) => state.iapPhoneAndEmailsCsv;

export const getIapPhoneAndEmailCsvError = (state: State) => state.error;
