import { Component } from '@angular/core';

import { LayoutService } from '../../services/layout/layout.service';

@Component({
  selector: 'smd-the-oops-component',
  styleUrls: ['./the-oops-component.component.scss'],
  templateUrl: './the-oops-component.component.html'
})
export class TheOopsComponentComponent {
  constructor(private layoutService: LayoutService) {}

  goHome() {
    this.layoutService.goHome();
  }
}
