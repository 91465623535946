import { Action } from '@ngrx/store';

import {
  AffordabilityData,
  AffordabilityOutputData,
  AffordabilityReportData,
  AmortizationData,
  AmortizationReportData,
  MonthlyPaymentOutputData,
  PitiData,
  PitiReportData
} from '../../../typings/calculator';

export enum CalculatorsReportsActionTypes {
  GetAmortizationData = '[Calculators] Get Amortization Calculator Data',
  GetAmortizationDataFail = '[Calculators] Failed to Get Amortization Calculator Data',
  GetAmortizationDataSuccess = '[Calculators] Successfully fetched Amortization Calculator Data',
  GetPdfReportDataFail = '[Calculators & Reports] Get Pdf Report Data Fail',
  GetPdfReportDataSuccess = '[Calculators & Reports] Get Pdf Report Data Success',
  ResetAffordabilityData = '[Calculators] Reset Affordability Calculator Data',
  ResetAmortizationData = '[Calculators] Reset Amortization Calculator Data',
  ResetPdfReportData = '[Calculators & Reports] Reset Pdf Report Data',
  ResetPitiData = '[Calculators] Reset PITI Calculator Data',
  ResetRawCalculatorData = '[Calculators] Reset Raw Calculator Data',
  ResetSelectedCalculator = '[Calculators] Reset selected calculator type',
  SetAffordabilityData = '[Calculators] Store Affordability Calculator Data',
  SetPdfReportData = '[Calculators & Reports] Set Pdf Report Data',
  SetPitiData = '[Calculators] Store PITI Calculator Data',
  SetRawCalculatorData = '[Calculators] Store Raw Calculator Data',
  SetSelectedCalculator = '[Calculators] Set selected calculator type'
}

export class SetPdfReportData implements Action {
  readonly type = CalculatorsReportsActionTypes.SetPdfReportData;

  constructor(
    public payload: {
      calculatorData: PitiReportData | AffordabilityReportData | AmortizationReportData;
      reportType: string;
    }
  ) {}
}
export class GetPdfReportDataSuccess implements Action {
  readonly type = CalculatorsReportsActionTypes.GetPdfReportDataSuccess;

  constructor(public payload: Blob) {}
}
export class GetPdfReportDataFail implements Action {
  readonly type = CalculatorsReportsActionTypes.GetPdfReportDataFail;

  constructor(public payload: Error) {}
}
export class ResetPdfReportData implements Action {
  readonly type = CalculatorsReportsActionTypes.ResetPdfReportData;

  constructor() {}
}

export class SetSelectedCalculator implements Action {
  readonly type = CalculatorsReportsActionTypes.SetSelectedCalculator;

  constructor(public payload: string) {}
}
export class ResetSelectedCalculator implements Action {
  readonly type = CalculatorsReportsActionTypes.ResetSelectedCalculator;

  constructor() {}
}
export class SetPitiData implements Action {
  readonly type = CalculatorsReportsActionTypes.SetPitiData;

  constructor(public payload: MonthlyPaymentOutputData) {}
}
export class ResetPitiData implements Action {
  readonly type = CalculatorsReportsActionTypes.ResetPitiData;

  constructor() {}
}
export class SetAffordabilityData implements Action {
  readonly type = CalculatorsReportsActionTypes.SetAffordabilityData;

  constructor(public payload: AffordabilityOutputData) {}
}
export class ResetAffordabilityData implements Action {
  readonly type = CalculatorsReportsActionTypes.ResetAffordabilityData;

  constructor() {}
}
export class GetAmortizationData implements Action {
  readonly type = CalculatorsReportsActionTypes.GetAmortizationData;

  constructor(public payload: Partial<{ amount: number; interestRate: number; term: number }>) {}
}
export class GetAmortizationDataSuccess implements Action {
  readonly type = CalculatorsReportsActionTypes.GetAmortizationDataSuccess;

  constructor(public payload: AmortizationReportData) {}
}
export class GetAmortizationDataFail implements Action {
  readonly type = CalculatorsReportsActionTypes.GetAmortizationDataFail;

  constructor() {}
}
export class ResetAmortizationData implements Action {
  readonly type = CalculatorsReportsActionTypes.ResetAmortizationData;

  constructor() {}
}
export class SetRawCalculatorData implements Action {
  readonly type = CalculatorsReportsActionTypes.SetRawCalculatorData;

  constructor(public payload: Partial<AffordabilityData | AmortizationData | PitiData>) {}
}

export class ResetRawCalculatorData implements Action {
  readonly type = CalculatorsReportsActionTypes.ResetRawCalculatorData;

  constructor() {}
}

export type CalculatorsReportsActions =
  | SetPdfReportData
  | GetPdfReportDataSuccess
  | GetPdfReportDataFail
  | ResetPdfReportData
  | SetSelectedCalculator
  | ResetSelectedCalculator
  | GetAmortizationDataSuccess
  | ResetAmortizationData
  | SetPitiData
  | ResetPitiData
  | SetAffordabilityData
  | ResetAffordabilityData
  | SetRawCalculatorData
  | ResetRawCalculatorData;
