import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { UserRoles } from './enums/user';

import { AuthGuard } from './common/guards/auth.guard';
import { TermsOfServiceGuard } from './common/guards/terms-of-service.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'welcome'
  },
  {
    canActivate: [AuthGuard],
    data: {
      role: UserRoles.Guest
    },
    loadChildren: () => import('./pages/authentication/welcome/welcome.module').then((m) => m.WelcomePageModule),
    path: 'welcome',
    runGuardsAndResolvers: 'always'
  },
  {
    canActivate: [AuthGuard],
    data: {
      role: UserRoles.Guest
    },
    loadChildren: () =>
      import('./pages/authentication/registration/registration.module').then((m) => m.RegistrationPageModule),
    path: 'registration'
  },
  {
    canActivate: [AuthGuard],
    data: {
      role: UserRoles.Guest
    },
    loadChildren: () =>
      import('./pages/authentication/already-registered/already-registered.module').then(
        (m) => m.AlreadyRegisteredPageModule
      ),
    path: 'registered'
  },
  {
    canActivate: [AuthGuard],
    data: {
      role: UserRoles.Guest
    },
    loadChildren: () => import('./pages/authentication/login/login.module').then((m) => m.LoginPageModule),
    path: 'login'
  },
  {
    loadChildren: () =>
      import('./pages/authentication/change-icon/change-icon.module').then((m) => m.ChangeIconPageModule),
    path: 'change-icon'
  },
  {
    canActivate: [AuthGuard],
    data: {
      role: UserRoles.LoggedUser
    },
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
    path: 'home'
  },
  {
    canActivate: [TermsOfServiceGuard],
    loadChildren: () =>
      import('./pages/authentication/terms-of-service/terms-of-service.module').then((m) => m.TermsOfServicePageModule),
    path: 'terms-of-service'
  },
  {
    loadChildren: () =>
      import('./pages/direct-to-app-store/direct-to-app-store.module').then((m) => m.DirectToAppStorePageModule),
    path: 'app-store'
  },
  {
    loadChildren: () =>
      import('./pages/monetization/monetization-paywall.module').then((m) => m.MonetizationPaywallPageModule),
    path: 'payment'
  },
  {
    canActivate: [AuthGuard],
    data: {
      role: UserRoles.LoggedUser
    },
    loadChildren: () =>
      import('./pages/authentication/new-terms-of-service/new-terms-of-service.module').then(
        (m) => m.NewTermsOfServicePageModule
      ),
    path: 'new-terms-of-service'
  },
  {
    loadChildren: () =>
      import('./pages/authentication/reset-password/reset-password.module').then((m) => m.ResetPasswordPageModule),
    path: 'reset-password'
  },
  {
    loadChildren: () =>
      import('./common/pages/the-oops-page/the-oops-page.module').then((m) => m.TheOopsPagePageModule),
    path: 'oops'
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
      useHash: true
    })
  ]
})
export class AppRoutingModule {}
