<div class="tw-flex">
  <div class="tw-flex" [ngClass]="{ 'tw-m-auto': isCentered }">
    <div class="tw-relative tw-h-36 tw-w-36">
      <canvas #chartCanvas class="tw-block"></canvas>

      <div *ngIf="displayMiddleInfo" class="tw-absolute tw-h-full tw-w-full tw-top-0 tw-flex tw-flex-col">
        <ion-text class="tw-text-center tw-m-auto">
          <span class="tw-block tw-text-small">
            {{ data.middleInfo.title }}
          </span>
          <span class="tw-block tw-text-small">
            {{ data.middleInfo.description }}
          </span>
        </ion-text>
      </div>
    </div>

    <div
      class="tw-flex tw-flex-col tw-ml-4 tw-my-auto"
      *ngIf="hasCustomLegend && data.legend.data && data.legend.data.length > 0"
    >
      <ng-container *ngFor="let item of data.legend.data; let i = index">
        <ion-text class="tw-flex tw-items-center">
          <span class="tw-h-2.5 tw-w-2.5" [ngStyle]="{ 'background-color': chartColors[i] }"></span>
          <span class="tw-ml-2 tw-text-sm md:tw-text-base">
            {{ item.label }} <span class="tw-font-bold">{{ item.value }}</span>
          </span>
        </ion-text>
      </ng-container>

      <ng-container *ngIf="data.legend.additionalInfo as additionalInfo">
        <ng-container *ngFor="let info of additionalInfo">
          <ion-text class="tw-block tw-text-sm md:tw-text-base">
            {{ info.label }}: <span class="tw-font-bold">{{ info.value }}{{ info.unit }}</span>
          </ion-text>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
