import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { UserRoles } from '../../enums/user';

import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TermsOfServiceGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable((observer) => {
      const navigationState = this.router.getCurrentNavigation()?.extras?.state || {};

      this.authService.getUserRole().subscribe((userRole) => {
        switch (userRole) {
          case UserRoles.Guest:
            observer.next(!!navigationState.withButtons);
            break;

          case UserRoles.LoggedUser:
            observer.next(!navigationState.withButtons);
            break;

          default:
            observer.next(false);
            break;
        }
      });
    });
  }
}
