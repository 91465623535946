<div class="tw-mt-1.5 tw-flex">
  <ion-text class="tw-my-auto tw-block tw-h-5 tw-w-20 tw-px-1 md:tw-w-28">
    {{ value > 0 ? value : 'All' }} {{ valueDescription }}
  </ion-text>

  <div class="tw-w-36">
    <ion-button class="tw-h-6" color="light" (click)="decrement()">
      <ion-icon class="ion-md-icon-minus"></ion-icon>
    </ion-button>

    <ion-button class="tw-ml-2 tw-h-6" color="light" (click)="increment()">
      <ion-icon class="ion-md-icon-plus"></ion-icon>
    </ion-button>
  </div>
</div>
