import { uniq } from 'lodash-es';

import { FarmPropertyAlert } from '../../../typings/walking-farm';

import * as fromParseActions from '../../actions/parse/parse-implementation';
import * as fromWalkingFarm from '../../actions/walking-farm/farm';

export interface State {
  alerts: FarmPropertyAlert[];
  error: Error;
  lastAlertDate: string;
  pending: boolean;
  succeeded: boolean;
}

export const initialState: State = {
  alerts: [],
  error: null,
  lastAlertDate: '',
  pending: false,
  succeeded: false
};

export function reducer(state = initialState, action: fromParseActions.ParseActions | fromWalkingFarm.FarmActions) {
  switch (action.type) {
    case fromParseActions.ParseActionTypes.GetFarmAlertsForFarm: {
      return {
        ...state,
        error: null,
        pending: true,
        succeeded: false
      };
    }

    case fromParseActions.ParseActionTypes.GetFarmAlertsForFarmSuccess: {
      let latestDate = '';
      if (action.payload.length) {
        const dates = uniq(action.payload.map((alert) => alert.date));
        latestDate = dates
          .map((date) => new Date(date))
          .sort()[0]
          .toLocaleDateString('en-US');
      }

      return {
        ...state,
        alerts: action.payload,
        error: null,
        lastAlertDate: latestDate,
        pending: false,
        succeeded: true
      };
    }

    case fromParseActions.ParseActionTypes.GetFarmAlertsForFarmFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }

    case fromWalkingFarm.FarmActionTypes.ResetFarmAlerts: {
      return initialState;
    }

    case fromWalkingFarm.FarmActionTypes.UpdateReadFarmAlertsByIds:
    case fromParseActions.ParseActionTypes.MarkMultipleFarmAlertsAsReadSuccess: {
      return {
        ...state,
        alerts: state.alerts.map((alert) => {
          const tempData = {
            ...alert
          };

          if (!!tempData && action.payload.indexOf(tempData.id) !== -1) {
            tempData.isRead = true;
          }

          return tempData;
        })
      };
    }

    default: {
      return state;
    }
  }
}

export const getFarmAlertsData = (state: State) => state.alerts;
export const getFarmAlertsLatestAlertDate = (state: State) => state.lastAlertDate;
export const getFarmAlertsPending = (state: State) => state.pending;
