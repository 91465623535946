import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Store } from '@ngrx/store';
import { has } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

import { IN_APP_BROWSER_OPTIONS } from '../../../../constants/app-plugins.constants';
import { UserRoles } from '../../../enums/user';
import { LoginData, LTPLoginData } from '../../../typings/auth';

import * as appSettingsActions from '../../../store/actions/app-settings/user-profile';
import * as fromUserProfileAction from '../../../store/actions/app-settings/user-profile';
import * as fromAppConfig from '../../../store/selectors/app-config/index';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private options: InAppBrowserOptions = IN_APP_BROWSER_OPTIONS;
  private userRoleHandler = new BehaviorSubject(UserRoles.Guest);

  constructor(
    private platform: Platform,
    private iab: InAppBrowser,
    private store: Store<fromAppConfig.State>
  ) {}

  getUserRole() {
    return this.userRoleHandler.asObservable();
  }

  setUserRole(newUserRole: UserRoles) {
    this.userRoleHandler.next(newUserRole);
  }

  login(loginData: LoginData | LTPLoginData) {
    this.store.dispatch(
      new fromUserProfileAction.VerifyUser({
        email: loginData.email,
        password: has(loginData, 'password') ? btoa((loginData as LoginData).password) : '',
        username: has(loginData, 'username') ? (loginData as LoginData).username : ''
      })
    );
  }

  logout() {
    this.store.dispatch(new appSettingsActions.LogoutUser());
  }

  help() {
    if (this.platform.is('android')) {
      this.options.location = 'yes';
    }

    this.iab.create('https://www.TitlePro247.com', '_blank', this.options);
  }
}
