import { Action } from '@ngrx/store';

export enum MapDataActionTypes {
  ChangeMapType = '[Config Map Data] Change Map Type',
  ChangeZoomValue = '[Config Map Data] Change Zoom Value',
  GetInitialLocation = '[Config Map Data] Get Initial Location',
  GetInitialLocationFail = '[Config Map Data] Get Initial Location Fail',
  GetInitialLocationSuccess = '[Config Map Data] Get Initial Location Success',
  ResetLocation = '[Config Map Data] Reset Location',
  ResetZoomValue = '[Config Map Data] Reset Zoom Value',
  UpdateLocation = '[Config Map Data] Update Location'
}

export class ChangeMapType implements Action {
  readonly type = MapDataActionTypes.ChangeMapType;

  constructor() {}
}
export class ChangeZoomValue implements Action {
  readonly type = MapDataActionTypes.ChangeZoomValue;

  constructor(public payload: number) {}
}
export class ResetZoomValue implements Action {
  readonly type = MapDataActionTypes.ResetZoomValue;

  constructor() {}
}
export class GetInitialLocation implements Action {
  readonly type = MapDataActionTypes.GetInitialLocation;

  constructor() {}
}
export class GetInitialLocationSuccess implements Action {
  readonly type = MapDataActionTypes.GetInitialLocationSuccess;

  constructor(public payload: google.maps.LatLngLiteral) {}
}
export class GetInitialLocationFail implements Action {
  readonly type = MapDataActionTypes.GetInitialLocationFail;

  constructor(public payload: Error) {}
}

export class UpdateLocation implements Action {
  readonly type = MapDataActionTypes.UpdateLocation;

  constructor(public payload: google.maps.LatLngLiteral) {}
}

export class ResetLocation implements Action {
  readonly type = MapDataActionTypes.ResetLocation;

  constructor() {}
}

export type MapDataAction =
  | ChangeMapType
  | ChangeZoomValue
  | ResetZoomValue
  | GetInitialLocation
  | GetInitialLocationSuccess
  | GetInitialLocationFail
  | UpdateLocation
  | ResetLocation;
