import { Action } from '@ngrx/store';

import { FarmReportFilters } from '../../../typings/walking-farm';

export enum ReportFiltersActionTypes {
  ResetReportFilters = '[Report Filters] Reset Report Filters',
  SetReportFilters = '[Report Filters] Set Report Filters'
}

export class ResetReportFilters implements Action {
  readonly type = ReportFiltersActionTypes.ResetReportFilters;

  constructor() {}
}

export class SetReportFilters implements Action {
  readonly type = ReportFiltersActionTypes.SetReportFilters;

  constructor(public payload: Partial<FarmReportFilters>) {}
}

export type ReportFiltersActions = ResetReportFilters | SetReportFilters;
