import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlatformDetectService {
  private isMobile = new BehaviorSubject<boolean>(true);

  constructor(private platform: Platform) {
    this.platform.ready().then(() => {
      if (!this.isMobilePlatform()) {
        this.setIsMobileSubscription(false);
      }
    });
  }

  init() {
    const isMobilePlatform = this.isMobilePlatform();
    this.setIsMobileSubscription(isMobilePlatform);
  }

  getIsMobileSubscription() {
    return this.isMobile.asObservable();
  }

  private setIsMobileSubscription(check: boolean) {
    this.isMobile.next(check);
  }

  private isMobilePlatform() {
    return this.platform.is('mobile') || this.platform.is('android');
  }
}
