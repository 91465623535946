import { CustomerInfo, PurchasesOfferings } from '@awesome-cordova-plugins/purchases';
import { createAction, props } from '@ngrx/store';

import {
  MonetizationPackage,
  MonetizationRepCodeInAppProducts,
  MonetizationRevenueCatWebOfferings,
  MonetizationRevenueCatWebPurchaserInfo,
  MonetizationStoreProduct,
  MonetizationStripeProduct,
  MonetizationStripeSubscription,
  VirtualCurrencyPreview,
  VirtualCurrencyPurchaseResponse
} from '../../typings/monetization';
import { FarmData } from '../../typings/walking-farm';

import { MonetizationState } from '../reducers/monetization.reducer';

export const loadProductsSuccess = createAction(
  '[Monetization] Load Products Success',
  props<{
    products: MonetizationRepCodeInAppProducts;
  }>()
);

export const loadPurchaserInfo = createAction('[Monetization] Load Purchaser Info');

export const loadOfferingsInfo = createAction('[Monetization] Load Offerings Info');

export const loadWebPurchaserInfo = createAction('[Monetization] Load Web Purchaser Info');

export const loadWebPurchaserInfoSuccess = createAction(
  '[Monetization] Load Web Purchaser Info Success',
  props<{ purchaserInfo: MonetizationRevenueCatWebPurchaserInfo }>()
);

export const loadWebPurchaserInfoFailure = createAction(
  '[Monetization] Load Web Purchaser Info Failure',
  props<{ error: Error }>()
);

export const loadWebOfferingsInfo = createAction('[Monetization] Load Web Offerings Info');

export const loadWebOfferingsInfoSuccess = createAction(
  '[Monetization] Load Web Offerings Info Success',
  props<{ inAppProducts: MonetizationRepCodeInAppProducts; offerings: MonetizationRevenueCatWebOfferings }>()
);

export const loadWebOfferingsInfoFailure = createAction(
  '[Monetization] Load Web Offerings Info Failure',
  props<{ error: Error }>()
);

export const loadMobilePurchaserInfo = createAction('[Monetization] Load Mobile Purchaser Info');

export const loadMobilePurchaserInfoSuccess = createAction(
  '[Monetization] Load Mobile Purchaser Info Success',
  props<{ customerInfo: CustomerInfo }>()
);

export const loadMobilePurchaserInfoFailure = createAction(
  '[Monetization] Load Mobile Purchaser Info Failure',
  props<{ error: Error }>()
);

export const loadMobileOfferingsInfo = createAction('[Monetization] Load Mobile Offerings Info');

export const loadMobileOfferingsInfoSuccess = createAction(
  '[Monetization] Load Mobile Offerings Info Success',
  props<{ inAppProducts: MonetizationRepCodeInAppProducts; purchasesOfferings: PurchasesOfferings }>()
);

export const loadMobileOfferingsInfoFailure = createAction(
  '[Monetization] Load Mobile Offerings Info Failure',
  props<{ error: Error }>()
);

export const loadPrimarySubscriptionPackagePlatformProductDetails = createAction(
  '[Monetization] Load Primary Subscription Platform Product Details'
);

export const loadPrimarySubscriptionPackageWebPlatformProductDetails = createAction(
  '[Monetization] Load Primary Subscription Web Platform Product Details'
);

export const loadPrimarySubscriptionPackageWebPlatformProductDetailsSuccess = createAction(
  '[Monetization] Load Primary Subscription Web Platform Product Details Success',
  props<{ monetizationStripeProduct: MonetizationStripeProduct }>()
);

export const loadPrimarySubscriptionPackageWebPlatformProductDetailsFailure = createAction(
  '[Monetization] Load Primary Subscription Web Platform Product Details Failure',
  props<{ error: Error }>()
);

export const loadPrimarySubscriptionPackageMobilePlatformProductDetails = createAction(
  '[Monetization] Load Primary Subscription Mobile Platform Product Details'
);

export const loadPrimarySubscriptionPackageCommonPlatformProductDetails = createAction(
  '[Monetization] Load Primary Subscription Common Platform Product Details'
);

export const loadPrimarySubscriptionPackageCommonPlatformProductDetailsSuccess = createAction(
  '[Monetization] Load Primary Subscription Common Platform Product Details Success',
  props<{ description: string }>()
);

export const loadPrimarySubscriptionPackageCommonPlatformProductDetailsFailure = createAction(
  '[Monetization] Load Primary Subscription Common Platform Product Details Failure',
  props<{ error: Error }>()
);

export const purchaseSubscription = createAction(
  '[Monetization] Purchase Subscription',
  props<{ monetizationPackage: MonetizationPackage; redirectType: string }>()
);

export const purchaseWebSubscription = createAction(
  '[Monetization] Purchase Web Subscription',
  props<{ monetizationPackage: MonetizationPackage; redirectType: string }>()
);

export const purchaseWebSubscriptionFailure = createAction(
  '[Monetization] Purchase Web Subscription Failure',
  props<{ error: Error }>()
);

export const purchaseMobileSubscription = createAction(
  '[Monetization] Purchase Mobile Subscription',
  props<{ monetizationPackage: MonetizationPackage }>()
);

export const purchaseMobileSubscriptionSuccess = createAction(
  '[Monetization] Purchase Mobile Subscription Success',
  props<{ customerInfo: CustomerInfo }>()
);

export const purchaseMobileSubscriptionFailure = createAction(
  '[Monetization] Purchase Mobile Subscription Failure',
  props<{ error: Error }>()
);

export const purchaseProduct = createAction(
  '[Monetization] Purchase Product',
  props<{ monetizationPackage: MonetizationPackage; redirectType: string }>()
);

export const purchaseWebProduct = createAction(
  '[Monetization] Purchase Web Product',
  props<{ monetizationPackage: MonetizationPackage; redirectType: string }>()
);

export const purchaseWebProductFailure = createAction(
  '[Monetization] Purchase Web Product Failure',
  props<{ error: Error }>()
);

export const purchaseMobileProduct = createAction(
  '[Monetization] Purchase Mobile Product',
  props<{ monetizationPackage: MonetizationPackage }>()
);

export const purchaseMobileProductSuccess = createAction(
  '[Monetization] Purchase Mobile Product Success',
  props<{ customerInfo: CustomerInfo }>()
);

export const purchaseMobileProductFailure = createAction(
  '[Monetization] Purchase Mobile Product Failure',
  props<{ error: Error }>()
);

export const loadWebSubscriptions = createAction('[Monetization] Load Web Subscriptions');

export const loadWebSubscriptionsSuccess = createAction(
  '[Monetization] Load Web Subscriptions Success',
  props<{ stripeSubscriptions: MonetizationStripeSubscription[] }>()
);

export const loadWebSubscriptionsFailure = createAction(
  '[Monetization] Load Web Subscriptions Failure',
  props<{ error: Error }>()
);

export const cancelWebSubscription = createAction('[Monetization] Cancel Web Subscription', props<{ id: string }>());

export const cancelWebSubscriptionSuccess = createAction('[Monetization] Cancel Web Subscription Success');

export const cancelWebSubscriptionFailure = createAction(
  '[Monetization] Cancel Web Subscription Failure',
  props<{ error: Error }>()
);

export const setInAppPurchasesCurrentFarm = createAction(
  '[Monetization] Set In App Purchases Current Farm',
  props<{ farm: FarmData }>()
);

export const restoreMonetization = createAction(
  '[Monetization] Restore Monetization',
  props<{ state: MonetizationState | undefined }>()
);

export const loadStoreProducts = createAction('[Monetization] Load Store Products');

export const loadWebStoreProducts = createAction('[Monetization] Load Web Store Products');

export const loadWebStoreProductsSuccess = createAction(
  '[Monetization] Load Web Store Products Success',
  props<{ inAppProducts: MonetizationRepCodeInAppProducts; stripeProducts: MonetizationStripeProduct[] }>()
);

export const loadWebStoreProductsFailure = createAction(
  '[Monetization] Load Web Store Products Failure',
  props<{ error: Error }>()
);

export const loadMobileStoreProducts = createAction('[Monetization] Load Mobile Store Products');

export const loadMobileStoreProductsSuccess = createAction('[Monetization] Load Mobile Store Products Success');

export const loadMobileStoreProductsFailure = createAction(
  '[Monetization] Load Mobile Store Products Failure',
  props<{ error: Error }>()
);

export const loadProductDetails = createAction('[Monetization] Load Product Details', props<{ productId: string }>());

export const loadWebProductDetails = createAction(
  '[Monetization] Load Web Product Details',
  props<{ productId: string }>()
);

export const loadWebProductDetailsSuccess = createAction(
  '[Monetization] Load Web Product Details Success',
  props<{
    monetizationStripeProduct: MonetizationStripeProduct;
    storeProductsData: Partial<MonetizationStoreProduct>[];
  }>()
);

export const loadWebProductDetailsFailure = createAction(
  '[Monetization] Load Web Product Details Failure',
  props<{ error: Error }>()
);

export const loadMobileProductDetails = createAction(
  '[Monetization] Load Mobile Product Details',
  props<{ productId: string }>()
);

export const loadBadgerBucks = createAction(
  '[Monetization] Load Badger Bucks',
  props<{ internalIdentifier: string }>()
);

export const loadBadgerBucksSuccess = createAction('[Monetization] Load Badger Bucks Success');

export const loadBadgerBucksFailure = createAction(
  '[Monetization] Load Badger Bucks Failure',
  props<{ productId: string }>()
);

export const setIsRevenueCatUserLoggedIn = createAction(
  '[Monetization] Set Is RevenueCat User Logged In',
  props<{ isRevenueCatUserLoggedIn: boolean }>()
);

export const loadVirtualCurrencyPreview = createAction(
  '[Monetization] Load Virtual Currency Preview',
  props<{
    propertyIds: string[];
  }>()
);

export const loadVirtualCurrencyPreviewSuccess = createAction(
  '[Monetization] Load Virtual Currency Preview Success',
  props<{
    preview: VirtualCurrencyPreview;
  }>()
);

export const loadVirtualCurrencyPreviewFailure = createAction('[Monetization] Load Virtual Currency Preview Failure');

export const resetVirtualCurrencyPreview = createAction('[Monetization] Reset Virtual Currency Preview');

export const loadVirtualCurrencyPurchase = createAction(
  '[Monetization] Load Virtual Currency Purchase',
  props<{
    propertyIds: string[];
  }>()
);

export const loadVirtualCurrencyPurchaseSuccess = createAction(
  '[Monetization] Load Virtual Currency Purchase Success',
  props<VirtualCurrencyPurchaseResponse>()
);

export const loadVirtualCurrencyPurchaseFailure = createAction('[Monetization] Load Virtual Currency Purchase Failure');
