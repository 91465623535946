import { createFeatureSelector, createSelector } from '@ngrx/store';

import { configFeatureKey, State } from '../reducers/config.reducer';

const selectConfigFeature = createFeatureSelector<State>(configFeatureKey);

export const selectConfig = createSelector(selectConfigFeature, (state: State) => state);

export const selectConfigLoadError = createSelector(selectConfigFeature, (state: State) => state.error);

export const selectTerm = createSelector(selectConfigFeature, (state: State) => state.term);
