import { MetaReducer } from '@ngrx/store';

import * as fromConfig from './reducers/config.reducer';
import * as fromMap from './reducers/map.reducer';
import { clearState } from './reducers/meta-reducers';
import * as fromMonetization from './reducers/monetization.reducer';
import * as fromAppConfig from './selectors/app-config';
import * as fromAppSettings from './selectors/app-settings';
import * as fromCalculators from './selectors/calculators';
import * as fromParse from './selectors/parse';
import * as fromPropertyData from './selectors/property-data';
import * as fromPropertySearch from './selectors/property-search';
import * as fromWalkingFarm from './selectors/walking-farm';

export interface State {
  appConfig: fromAppConfig.AppConfigState;
  appParse: fromParse.ParseState;
  appSettings: fromAppSettings.AppSettingsState;
  calculators: fromCalculators.CalculatorsState;
  config: fromConfig.State;
  mapView: fromMap.State;
  monetization: fromMonetization.MonetizationState;
  propertyData: fromPropertyData.PropertyDataState;
  propertySearch: fromPropertySearch.PropertySearchState;
  walkingFarm: fromWalkingFarm.WalkingFarmState;
}

export const reducers = {
  appConfig: fromAppConfig.reducer,
  appParse: fromParse.reducer,
  appSettings: fromAppSettings.reducer,
  calculators: fromCalculators.reducer,
  config: fromConfig.reducer,
  mapView: fromMap.reducer,
  monetization: fromMonetization.reducer,
  propertyData: fromPropertyData.reducer,
  propertySearch: fromPropertySearch.reducer,
  walkingFarm: fromWalkingFarm.reducer
};

export const metaReducers: MetaReducer<State>[] = [clearState];
