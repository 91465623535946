import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumberFormatService {
  constructor(private decimal: DecimalPipe) {}

  formatValue(value: string | number, hasSpaces = true) {
    let result = value?.toString() || '';
    result = result?.replace(/[^0-9.]/g, '');
    const number = Number(result);

    let formatRule = '1.0-0';

    if (result.includes('.')) {
      const valueSegments = result.split('.');
      const decimalValueLength = valueSegments[1].length;
      formatRule = `1.0-${decimalValueLength}`;
    }

    if (!isNaN(number)) {
      result = this.decimal.transform(number, formatRule).replace(/,/g, hasSpaces ? ' ' : '');
    }

    return result;
  }
}
