import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'smd-list-item',
  templateUrl: 'list-item.component.html'
})
export class ListItemComponent {
  @Input() hasArrow: boolean;
  @Input() classes: string;
  @Input() arrowText = '';
  @Output() clickListItem?: EventEmitter<PointerEvent | MouseEvent> = new EventEmitter<PointerEvent | MouseEvent>();

  constructor() {}

  clickItem(event: PointerEvent | MouseEvent) {
    this.clickListItem.emit(event);
  }
}
