import { filter, map } from 'lodash-es';

import { usaStates } from '../../../constants/search-fields-data.constants';
import { PfLocation } from '../../../typings/app';
import { LocationState } from '../../../typings/location';
import { SearchAddress } from '../../../typings/map';
import { PropertyProfileData } from '../../../typings/property-profile';
import { SearchByAddressData, SearchByOwnerData, SearchByParcelNumberData } from '../../../typings/search';

import * as searchTypesActions from '../../actions/property-search/search-types';

export interface State {
  countiesList: { text: string; value: string }[];
  error: Error;
  markers: PfLocation[] | null;
  pending: boolean;
  searchByAddressData: SearchByAddressData | null;
  searchByOwnerData: SearchByOwnerData | null;
  searchByParcelNumberData: SearchByParcelNumberData | null;
  searchKey: string;
  searchResultRawData: { d: PropertyProfileData } | null;
  searchResults: SearchAddress[];
  selectedCounty: string | null;
  selectedState: string | null;
  statesList: LocationState[];
  succeeded: boolean;
}

export const initialState: State = {
  countiesList: [],
  error: null,
  markers: [],
  pending: false,
  searchByAddressData: null,
  searchByOwnerData: null,
  searchByParcelNumberData: {
    apn: '',
    county: '',
    state: ''
  },
  searchKey: '',
  searchResultRawData: null,
  searchResults: [],
  selectedCounty: null,
  selectedState: null,
  statesList: [],
  succeeded: false
};

export function reducer(state = initialState, action: searchTypesActions.SearchTypesActions) {
  switch (action.type) {
    case searchTypesActions.SearchTypesActionTypes.SetSearchValue: {
      return {
        ...state,
        searchKey: action.payload
      };
    }

    case searchTypesActions.SearchTypesActionTypes.SetSearchResultRawData: {
      return {
        ...state,
        searchResultRawData: action.payload
      };
    }
    case searchTypesActions.SearchTypesActionTypes.GetSearchResultsSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
        searchResults: action.payload,
        succeeded: true
      };
    }
    case searchTypesActions.SearchTypesActionTypes.GetSearchResultsFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }

    case searchTypesActions.SearchTypesActionTypes.SearchByAddress: {
      return {
        ...state,
        error: null,
        pending: true,
        searchByAddressData: action.payload,
        searchResultRawData: null,
        succeeded: false
      };
    }

    case searchTypesActions.SearchTypesActionTypes.ResetSearchAddress: {
      return {
        ...state,
        searchByAddressData: {
          ...initialState.searchByAddressData
        }
      };
    }

    case searchTypesActions.SearchTypesActionTypes.SearchByOwner: {
      return {
        ...state,
        error: null,
        pending: true,
        searchByOwnerData: action.payload,
        searchResultRawData: null,
        succeeded: false
      };
    }

    case searchTypesActions.SearchTypesActionTypes.SearchByParcelNumber: {
      return {
        ...state,
        error: null,
        pending: true,
        searchByParcelNumberData: action.payload,
        searchResultRawData: null,
        succeeded: false
      };
    }

    case searchTypesActions.SearchTypesActionTypes.SetMarkers: {
      return {
        ...state,
        markers: action.payload
      };
    }
    case searchTypesActions.SearchTypesActionTypes.ResetMarkers: {
      return {
        ...state,
        markers: []
      };
    }

    case searchTypesActions.SearchTypesActionTypes.ResetSearchError: {
      return {
        ...state,
        error: null,
        pending: false,
        searchKey: '',
        succeeded: false
      };
    }

    case searchTypesActions.SearchTypesActionTypes.SetStatesList: {
      if (state.statesList.length) {
        return state;
      }

      return {
        ...state,
        statesList: map(
          filter(usaStates, (state) => !state.territory),
          (state) => ({
            text: state.name,
            value: state.abbreviation
          })
        )
      };
    }

    case searchTypesActions.SearchTypesActionTypes.SetSelectedState: {
      return {
        ...state,
        selectedCounty: null,
        selectedState: action.payload.selectedState
      };
    }

    case searchTypesActions.SearchTypesActionTypes.SetCountiesList: {
      return {
        ...state,
        countiesList:
          map(action.payload.countiesList, (county) => ({
            text: county.countyName,
            value: county.countyName
          })) || []
      };
    }

    case searchTypesActions.SearchTypesActionTypes.SetSelectedCounty: {
      return {
        ...state,
        selectedCounty: action.payload.selectedCounty
      };
    }

    case searchTypesActions.SearchTypesActionTypes.ResetSearchTypes: {
      return {
        ...state,
        markers: initialState.markers,
        searchByOwnerData: initialState.searchByOwnerData,
        searchByParcelNumberData: initialState.searchByParcelNumberData,
        searchKey: initialState.searchKey,
        searchResultRawData: initialState.searchResultRawData,
        searchResults: initialState.searchResults
      };
    }

    default: {
      return state;
    }
  }
}

export const getMarkers = (state: State) => state.markers;
export const getSearchKey = (state: State) => state.searchKey;
export const getSearchResults = (state: State) => state.searchResults;
export const getSearchResultRawData = (state: State) => state.searchResultRawData;

export const getStatesList = (state: State) => state.statesList;
export const getSelectedState = (state: State) => state.selectedState;
export const getCountiesList = (state: State) => state.countiesList;
export const getSelectedCounty = (state: State) => state.selectedCounty;
