import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { SecureStorageService } from '../../common/services/secure-storage/secure-storage.service';

import * as fromMapActions from '../actions/map.actions';
import { mapFeatureKey } from '../reducers/map.reducer';
import { initialState as mapInitialState } from '../reducers/map.reducer';

@Injectable()
export class MapEffects {
  setMapCenterSetting$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMapActions.setMapCenterSetting),
        map((action) =>
          this.secureStorageService.updateInternalStorage({
            [mapFeatureKey]: {
              isMapCentered: action.isMapCentered
            }
          })
        )
      ),
    { dispatch: false }
  );

  resetMapCenterSetting$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMapActions.resetMapCenterSetting),
        map(() =>
          this.secureStorageService.updateInternalStorage({
            [mapFeatureKey]: {
              isMapCentered: mapInitialState.isMapCentered
            }
          })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private secureStorageService: SecureStorageService
  ) {}
}
