import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { ResetModules } from '../../../enums/data-reset';
import { DrawFarmModes } from '../../../enums/walking-farm';
import { ResetHandler } from '../../../typings/app';

import * as mapConfigAction from '../../../store/actions/app-config/map-data';
import * as calculatorsActions from '../../../store/actions/calculators/calculators-and-reports';
import * as fromMapActions from '../../../store/actions/map.actions';
import * as fromPropertyProfileActions from '../../../store/actions/property-data/property-profile';
import * as fromSearchTypesActions from '../../../store/actions/property-search/search-types';
import * as farmActions from '../../../store/actions/walking-farm/farm';
import * as fromFastFarmActions from '../../../store/actions/walking-farm/fast-farm';
import * as polygonActions from '../../../store/actions/walking-farm/polygon';
import * as fromSearchInMapActions from '../../../store/actions/walking-farm/search-in-map';
import * as filtersActions from '../../../store/actions/walking-farm/walking-farm-filters';
import * as fromReportFiltersActions from '../../../store/actions/walking-farm/walking-farm-report-filters';

@Injectable({
  providedIn: 'root'
})
export class ResetService {
  private resetHandler = new BehaviorSubject<ResetHandler>({
    targets: null
  });

  constructor(private store: Store) {
    this.resetHandler.subscribe((settings) => {
      if (settings.targets) {
        settings.targets.forEach((resetTarget) => {
          this.doReset(resetTarget);
        });
      }
    });
  }

  updateResetHandlerSettings(newSettings: ResetHandler) {
    this.resetHandler.next({
      ...this.resetHandler.value,
      ...newSettings
    });
  }

  private doReset(resetTarget: string) {
    switch (resetTarget) {
      case ResetModules.PropertySearch:
        this.store.dispatch(new fromPropertyProfileActions.ResetSelectedProfile());
        this.store.dispatch(new fromSearchTypesActions.ResetSearchAddress());
        this.store.dispatch(new fromSearchTypesActions.ResetSearchTypes());
        break;

      case ResetModules.PropertyMapView:
        this.store.dispatch(new mapConfigAction.ResetZoomValue());
        this.store.dispatch(new mapConfigAction.ResetLocation());
        break;

      case ResetModules.WalkingFarm:
        this.store.dispatch(new fromFastFarmActions.ResetMapMarker());
        break;

      case ResetModules.WalkingFarmSearch:
        this.store.dispatch(new fromSearchInMapActions.ResetSearch());
        break;

      case ResetModules.WalkingFarmFilters:
        this.store.dispatch(new filtersActions.ResetFilters());
        break;

      case ResetModules.WalkingFarmDrawMode:
        this.store.dispatch(fromMapActions.setDrawMode({ drawMode: DrawFarmModes.Polygon }));
        this.store.dispatch(new polygonActions.ResetPolygon());
        break;

      case ResetModules.WalkingFarmFastFarm:
        this.store.dispatch(new fromFastFarmActions.ResetRadius());
        this.store.dispatch(new fromFastFarmActions.ResetMapMarker());
        break;

      case ResetModules.WalkingFarmReportSummary:
        this.store.dispatch(new farmActions.ResetViewedFarm());
        this.store.dispatch(new farmActions.ResetSelectedFarm());
        this.store.dispatch(new fromReportFiltersActions.ResetReportFilters());
        this.store.dispatch(fromMapActions.resetMapCenterSetting());
        this.store.dispatch(fromMapActions.resetSavedFarmLocation());
        break;

      case ResetModules.WalkingFarmReportFilters:
        this.store.dispatch(new fromReportFiltersActions.ResetReportFilters());
        break;

      case ResetModules.CalculatorPiti:
        this.store.dispatch(new calculatorsActions.ResetPitiData());
        break;

      case ResetModules.CalculatorAffordability:
        this.store.dispatch(new calculatorsActions.ResetAffordabilityData());
        break;

      case ResetModules.CalculatorAmortization:
        this.store.dispatch(new calculatorsActions.ResetAmortizationData());
        break;

      case ResetModules.CalculatorReports:
        this.store.dispatch(new calculatorsActions.ResetRawCalculatorData());
        break;

      case ResetModules.DefaultsSearch:
        this.store.dispatch(new fromPropertyProfileActions.ResetSelectedProfile());
        this.store.dispatch(new fromSearchTypesActions.ResetSearchTypes());
        this.store.dispatch(new fromSearchInMapActions.ResetSearch());
        break;

      default:
        break;
    }
  }
}
