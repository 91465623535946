import { Injectable } from '@angular/core';

import { Coordinate, GeometryFactory } from 'jsts/org/locationtech/jts/geom';
import { GeometryGraph } from 'jsts/org/locationtech/jts/geomgraph';
import { IsSimpleOp, valid } from 'jsts/org/locationtech/jts/operation';
import { assignIn, indexOf } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

import { POLYGON_POINT_MARKER_SVG } from '../../../../constants/map.constants';

@Injectable()
export class PolygonService {
  private googleMapsObject: google.maps.Map;
  private polyline: google.maps.Polyline;
  private pointsNumber = 0;
  private markers = [];
  private polygonStatus = new BehaviorSubject<string>(null);

  private pointIcon: google.maps.Icon = {
    anchor: new google.maps.Point(10, 10),
    origin: new google.maps.Point(0, 0),
    scaledSize: new google.maps.Size(20, 20),
    url: `/assets/svg/map-markers/${POLYGON_POINT_MARKER_SVG.fullColor}`
  };

  private drawOptions = {
    drawingControl: false,
    drawingControlOptions: {
      drawingModes: ['polygon', 'polyline']
    },
    drawingMode: google.maps.drawing.OverlayType.POLYGON,
    polygonOptions: {
      draggable: false,
      editable: false,
      fillColor: '#0879D1',
      fillOpacity: 0.4,
      strokeColor: '#3E6787',
      strokeOpacity: 1,
      strokeWeight: 2,
      zIndex: 1
    },
    polylineOptions: {
      geodesic: true,
      strokeColor: '#3E6787',
      strokeOpacity: 1,
      strokeWeight: 2,
      zIndex: 4
    }
  };

  polygon: google.maps.Polygon;

  constructor() {}

  getPolygonStatus() {
    return this.polygonStatus.asObservable();
  }

  setMapObj(mapObject: google.maps.Map) {
    this.googleMapsObject = mapObject;
  }

  initializePolygon() {
    this.polyline = new google.maps.Polyline(this.drawOptions.polylineOptions);
    this.polyline.getPath().addListener('remove_at', this.removeMarker.bind(this));
    this.polyline.setMap(this.googleMapsObject);
  }

  insertPoint(latitude: number, longitude: number) {
    if (!isNaN(latitude) && !isNaN(longitude)) {
      this.polyline.getPath().insertAt(this.pointsNumber, new google.maps.LatLng(latitude, longitude));
      this.pointsNumber++;

      const marker: google.maps.Marker = new google.maps.Marker({
        icon: this.pointIcon,
        map: this.googleMapsObject,
        position: new google.maps.LatLng(latitude, longitude),
        zIndex: 10
      });

      this.markers.push(marker);
      assignIn(marker, { markerIndex: indexOf(this.markers, marker) });

      google.maps.event.addListener(marker, 'click', () => {
        this.onMarkerClicked(marker);
      });
    }
  }

  resetPolygon() {
    this.deletePolyline();
    this.deletePolygon();
  }

  deletePolygon() {
    if (this.polygon) {
      this.polygonStatus.next(null);
      this.polygon.getPath().clear();
      this.polygon.setMap(null);
      this.polygon = null;
    }
  }

  deletePolyline() {
    if (this.polyline) {
      this.polyline.getPath().clear();
      this.markers = [];
      this.pointsNumber = 0;
    }
  }

  undoPolygonPoint(polygonCompleted: boolean) {
    if (!polygonCompleted) {
      this.polyline.getPath().removeAt(this.polyline.getPath().getLength() - 1);
    }

    this.deletePolygon();
    this.refreshClickEvents();
  }

  finishPolygon() {
    const intersects = this.findSelfIntersects(this.polyline.getPath());

    if (intersects && intersects.length) {
      this.polygonStatus.next('intersect');
      alert('You have not drawn a valid search area. Please make sure that the lines do not cross.');
    } else {
      this.markers.forEach((marker) => google.maps.event.clearListeners(marker, 'click'));

      this.polygon = new google.maps.Polygon({
        ...this.drawOptions.polygonOptions,
        map: this.googleMapsObject,
        paths: this.polyline.getPath().getArray()
      });

      this.polygonStatus.next('completed');
    }
  }

  private removeMarker() {
    const lastIndex = this.markers.length - 1;
    this.markers[lastIndex]?.setMap(null);
    this.markers.pop();
    this.pointsNumber--;
  }

  private onMarkerClicked(marker) {
    if (this.markers.length > 2) {
      if (marker.markerIndex === 0 || marker.markerIndex === this.markers.length - 1) {
        this.finishPolygon();
      }
    } else {
      alert('A polygon should consist of at least 3 points.');
    }
  }

  private findSelfIntersects(googlePolygonPath: google.maps.MVCArray<google.maps.LatLng>) {
    const coordinates = this.googleMaps2JTS(googlePolygonPath);
    const geometryFactory = new GeometryFactory();
    const shell = geometryFactory.createLinearRing(coordinates);
    const JSTSPolygon = geometryFactory.createPolygon(shell);

    // if the geometry is already a simple linear ring, do not
    // try to find self intersection points.
    const validator = new IsSimpleOp(JSTSPolygon);
    if (validator.isSimpleLinearGeometry(JSTSPolygon)) {
      return;
    }

    const res: number[][] = [];
    const graph = new GeometryGraph(0, JSTSPolygon);
    const cat = new valid.ConsistentAreaTester(graph);
    const r = cat.isNodeConsistentArea();

    if (!r) {
      const pt = cat.getInvalidPoint();
      res.push([pt.x, pt.y]);
    }

    return res;
  }

  private googleMaps2JTS(boundaries: google.maps.MVCArray<google.maps.LatLng>) {
    const coordinates: { x: number; y: number; z: number }[] = [];

    for (let i = 0; i < boundaries.getLength(); i++) {
      coordinates.push(new Coordinate(boundaries.getAt(i).lat(), boundaries.getAt(i).lng()));
    }

    coordinates.push(coordinates[0]);
    return coordinates;
  }

  private refreshClickEvents() {
    this.markers.forEach((marker) => {
      google.maps.event.clearListeners(marker, 'click');

      google.maps.event.addListener(marker, 'click', () => {
        this.onMarkerClicked(marker);
      });
    });
  }
}
