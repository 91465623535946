import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { ADMIN_PORTAL_CONFIG, NETCALCS_REPORTS_CONFIG } from '../../../../environments/environment';
import { netCalcsReportsDependencies } from '../../../constants/netcalcs-reports';
import { RequestOptions } from '../../../typings/api';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private showErrorAlert = false;

  constructor(
    private http: HttpClient,
    private alertController: AlertController
  ) {}

  sendRequest(options: RequestOptions, apiName?: string) {
    let headers = new HttpHeaders()
      .set('x-app-id', ADMIN_PORTAL_CONFIG.appId)
      .set('x-app-secret', ADMIN_PORTAL_CONFIG.appSecret);

    if (apiName === netCalcsReportsDependencies.apiName) {
      headers = new HttpHeaders()
        .set('accept', 'application/json')
        .set('Content-Type', 'application/json; charset=utf-8')
        .set('NETCALCS-SECURITY-KEY', NETCALCS_REPORTS_CONFIG.appId);
    }

    return this.http.request(options.method, options.url, {
      ...(options.requestOptions || {}),
      headers
    });
  }

  sendRequestPromise(options: RequestOptions) {
    return this.sendRequest(options).toPromise();
  }

  async displayAlert(message: string, title?: string) {
    if (!this.showErrorAlert) {
      this.showErrorAlert = true;

      const alert = await this.alertController.create({
        buttons: [
          {
            handler: () => {
              this.showErrorAlert = false;
            },
            role: 'cancel',
            text: 'OK'
          }
        ],
        header: title,
        message
      });

      await alert.present();
    }
  }
}
