<ion-card class="ion-no-margin tw-flex tw-h-full tw-flex-col lg:tw-bg-transparent lg:tw-shadow-none">
  <ion-card-header class="ion-text-uppercase tw-max-h-6 tw-px-4 tw-py-1 tw-text-sm lg:tw-pl-0">
    {{ searchResults.length > 1 ? 'Results' : 'Result' }}
  </ion-card-header>

  <ion-card-content class="tw-h-full tw-overflow-auto tw-px-4 tw-pb-0 lg:tw-pl-0">
    <ion-list class="ion-no-padding lg:tw-bg-transparent">
      <ion-item *ngIf="showIfMultipleResults && searchResults && searchResults.length > 1" class="ion-no-padding">
        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col>
              <ion-text class="ion-text-uppercase tw-mb-0 tw-mt-3 tw-text-sm">
                Multiple Addresses Found ({{ searchResults.length }})
              </ion-text>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-button fill="clear" class="smd-profile-link ion-text-uppercase tw-my-1" (click)="showList($event)">
                <ion-text class="tw-block tw-px-1">
                  Full List
                  <ion-icon class="ion-md-icon-pf-arrow2 tw-ml-2 tw-h-3 tw-w-6 tw-text-xs"></ion-icon>
                </ion-text>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>

      <ng-container *ngIf="showResults">
        <ion-item *ngFor="let item of searchResults" class="ion-no-padding">
          <ion-grid class="ion-no-padding tw-py-1">
            <ion-row class="lg:tw-flex lg:tw-flex-col">
              <ion-col size="8" size-lg="12" class="ion-no-padding">
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col class="ion-no-padding tw-w-0 tw-truncate">
                      <ion-text class="ion-text-uppercase tw-mb-0 tw-mt-3 tw-text-sm">
                        {{ item.address }} {{ item.unit }}
                      </ion-text>
                    </ion-col>
                  </ion-row>

                  <ion-row>
                    <ion-col class="ion-no-padding">
                      <ion-text class="ion-text-uppercase tw-text-xs">
                        {{ item.city }}, {{ item.state }} {{ item.zip }}
                      </ion-text>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>

              <ion-col size="4" size-lg="12" class="ion-no-padding tw-flex tw-flex-col">
                <ion-button
                  fill="clear"
                  class="smd-profile-link ion-text-uppercase tw-my-1 tw-self-end lg:tw-self-start"
                  (click)="viewDetails(item)"
                >
                  <ion-text class="tw-flex tw-px-1">
                    Profile
                    <ion-icon class="ion-md-icon-pf-arrow2 tw-ml-2 tw-h-3 tw-w-6 tw-text-xs"></ion-icon>
                  </ion-text>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ng-container>
    </ion-list>
  </ion-card-content>
</ion-card>
