import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Purchases } from '@awesome-cordova-plugins/purchases/ngx';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { PurchasesError } from 'cordova-plugin-purchases';
import { StripeService } from 'ngx-stripe';
import { from, of } from 'rxjs';
import { catchError, concatMap, delay, finalize, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import { ADMIN_PORTAL_CONFIG, containerName, REVENUE_CAT_PUBLIC_KEY } from '../../../environments/environment';
import { adminPortalEndpoints } from '../../constants/admin-portal';
import { MonetizationPaymentRedirectTypes } from '../../enums/monetization';
import {
  MonetizationRevenueCatWebOfferings,
  MonetizationRevenueCatWebPurchaserInfo,
  MonetizationStripeCheckoutSession,
  MonetizationStripeProduct,
  MonetizationStripeSubscription,
  VirtualCurrencyPurchaseResponse
} from '../../typings/monetization';

import { ApiService } from '../../common/services/api/api.service';
import { LoadingService } from '../../common/services/loading/loading.service';
import { MonetizationService } from '../../common/services/monetization/monetization.service';
import { PlatformDetectService } from '../../common/services/platform-detect/platform-detect.service';

import * as fromMonetizationActions from '../actions/monetization.actions';
import * as parseActions from '../actions/parse/parse-implementation';
import * as appParseAction from '../actions/parse/parse-implementation';
import * as fromAppConfigSelectors from '../selectors/app-config';
import * as fromAppConfig from '../selectors/app-config';
import * as fromAppSettingsSelectors from '../selectors/app-settings';
import * as fromAppSettings from '../selectors/app-settings';
import * as fromMonetizationSelectors from '../selectors/monetization.selectors';

@Injectable()
export class MonetizationEffects {
  loadPurchaserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadPurchaserInfo),
      withLatestFrom(this.platformDetectService.getIsMobileSubscription()),
      map((results) => {
        let action: Action = fromMonetizationActions.loadWebPurchaserInfo();
        if (results[1]) {
          action = fromMonetizationActions.loadMobilePurchaserInfo();
        }
        return action;
      })
    )
  );

  loadMobilePurchaserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadMobilePurchaserInfo),
      switchMap(() => {
        const loadingInstanceID = uuidV4();
        this.loadingService.setLoading(loadingInstanceID, true);
        return from(this.purchases.getCustomerInfo()).pipe(
          map((customerInfo) => fromMonetizationActions.loadMobilePurchaserInfoSuccess({ customerInfo })),
          finalize(() => {
            this.loadingService.setLoading(loadingInstanceID, false);
          }),
          catchError((purchasesError: PurchasesError) => {
            const error = new Error(purchasesError.message);
            error.name = purchasesError.readableErrorCode;
            error.stack = purchasesError.underlyingErrorMessage;
            this.monetizationService.handleError({
              error,
              message: `Failed to load purchaser information${this.monetizationService.getPurchasesErrorMessageDetails(
                purchasesError
              )}.`,
              subHeader: 'RevenueCat Initialization'
            });
            return of(fromMonetizationActions.loadMobilePurchaserInfoFailure({ error }));
          })
        );
      })
    )
  );

  loadWebPurchaserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadWebPurchaserInfo),
      withLatestFrom(
        this.store.select(fromAppConfigSelectors.getGeneralInfoAuthData).pipe(map((authData) => authData?.userObjectId))
      ),
      switchMap((results) => {
        const loadingInstanceID = uuidV4();
        this.loadingService.setLoading(loadingInstanceID, true);
        return this.http
          .get(`https://api.revenuecat.com/v1/subscribers/${results[1]}`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${REVENUE_CAT_PUBLIC_KEY}`,
              'Content-Type': 'application/json'
            }
          })
          .pipe(
            map((purchaserInfo: MonetizationRevenueCatWebPurchaserInfo) =>
              fromMonetizationActions.loadWebPurchaserInfoSuccess({
                purchaserInfo
              })
            ),
            finalize(() => {
              this.loadingService.setLoading(loadingInstanceID, false);
            }),
            catchError((error) => {
              this.monetizationService.handleError({
                error,
                message: `Failed to load purchaser information (${error.message}).`,
                subHeader: 'RevenueCat Initialization'
              });
              return of(fromMonetizationActions.loadWebPurchaserInfoFailure({ error }));
            })
          );
      })
    )
  );

  loadOfferingsInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadOfferingsInfo),
      withLatestFrom(this.platformDetectService.getIsMobileSubscription()),
      map((results) => {
        let action: Action = fromMonetizationActions.loadWebOfferingsInfo();
        if (results[1]) {
          action = fromMonetizationActions.loadMobileOfferingsInfo();
        }
        return action;
      })
    )
  );

  loadMobileOfferingsInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadMobileOfferingsInfo),
      withLatestFrom(this.store.select(fromAppConfig.getInAppProducts)),
      switchMap((results) => {
        const loadingInstanceID = uuidV4();
        this.loadingService.setLoading(loadingInstanceID, true);

        return from(this.purchases.getOfferings()).pipe(
          map((purchasesOfferings) =>
            fromMonetizationActions.loadMobileOfferingsInfoSuccess({ inAppProducts: results[1], purchasesOfferings })
          ),
          finalize(() => {
            this.loadingService.setLoading(loadingInstanceID, false);
          }),
          catchError((purchasesError: PurchasesError) => {
            const error = new Error(purchasesError.message);
            error.name = purchasesError.readableErrorCode;
            error.stack = purchasesError.underlyingErrorMessage;
            this.monetizationService.handleError({
              error,
              message: `Failed to load offerings information${this.monetizationService.getPurchasesErrorMessageDetails(
                purchasesError
              )}.`,
              subHeader: 'RevenueCat Initialization'
            });
            return of(fromMonetizationActions.loadMobileOfferingsInfoFailure({ error }));
          })
        );
      })
    )
  );

  loadWebOfferingsInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadWebOfferingsInfo),
      withLatestFrom(
        this.store
          .select(fromAppConfigSelectors.getGeneralInfoAuthData)
          .pipe(map((authData) => authData?.userObjectId)),
        this.store.select(fromAppConfig.getInAppProducts)
      ),
      switchMap((results) => {
        const loadingInstanceID = uuidV4();
        this.loadingService.setLoading(loadingInstanceID, true);
        return this.http
          .get(`https://api.revenuecat.com/v1/subscribers/${results[1]}/offerings`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${REVENUE_CAT_PUBLIC_KEY}`,
              'Content-Type': 'application/json',
              'X-Platform': 'stripe'
            }
          })
          .pipe(
            map((offerings: MonetizationRevenueCatWebOfferings) =>
              fromMonetizationActions.loadWebOfferingsInfoSuccess({
                inAppProducts: results[2],
                offerings
              })
            ),
            catchError((error) => {
              this.monetizationService.handleError({
                error,
                message: `Failed to load offerings information (${error.message}).`,
                subHeader: 'RevenueCat Initialization'
              });
              return of(fromMonetizationActions.loadWebOfferingsInfoFailure(error));
            }),
            finalize(() => {
              this.loadingService.setLoading(loadingInstanceID, false);
            })
          );
      })
    )
  );

  loadPrimarySubscriptionPackagePlatformProductDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadPrimarySubscriptionPackagePlatformProductDetails),
      withLatestFrom(this.platformDetectService.getIsMobileSubscription()),
      concatMap((results) => {
        const actions: Action[] = [
          fromMonetizationActions.loadPrimarySubscriptionPackageCommonPlatformProductDetails()
        ];
        if (results[1]) {
          actions.push(fromMonetizationActions.loadPrimarySubscriptionPackageMobilePlatformProductDetails());
        } else {
          actions.push(fromMonetizationActions.loadPrimarySubscriptionPackageWebPlatformProductDetails());
        }
        return actions;
      })
    )
  );

  loadPrimarySubscriptionPackageCommonPlatformProductDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadPrimarySubscriptionPackageCommonPlatformProductDetails),
      withLatestFrom(
        this.store.select(fromMonetizationSelectors.selectPrimarySubscriptionPackagePlatformProductIdentifier)
      ),
      switchMap((results) =>
        this.apiService
          .sendRequest({
            method: 'GET',
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/Subscriptions/Details/${results[1]}`
          })
          .pipe(
            map((data) =>
              fromMonetizationActions.loadPrimarySubscriptionPackageCommonPlatformProductDetailsSuccess({
                description: data.description
              })
            ),
            catchError((error) => {
              this.monetizationService.handleError({
                error,
                message: `Failed to load product details (${error.message}).`,
                subHeader: 'Product Information'
              });
              return of(
                fromMonetizationActions.loadPrimarySubscriptionPackageCommonPlatformProductDetailsFailure(error)
              );
            })
          )
      )
    )
  );

  loadPrimarySubscriptionPackageWebPlatformProductDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadPrimarySubscriptionPackageWebPlatformProductDetails),
      withLatestFrom(
        this.store.select(fromMonetizationSelectors.selectPrimarySubscriptionPackagePlatformProductIdentifier)
      ),
      switchMap((results) =>
        this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              body: {
                ids: [results[1]]
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/Stripe/Products`
          })
          .pipe(
            map((monetizationStripeProducts: MonetizationStripeProduct[]) => {
              if (!monetizationStripeProducts.length) {
                throw new Error('Product not found');
              }

              return fromMonetizationActions.loadPrimarySubscriptionPackageWebPlatformProductDetailsSuccess({
                monetizationStripeProduct: monetizationStripeProducts[0]
              });
            }),
            catchError((error) => {
              this.monetizationService.handleError({
                error,
                message: `Failed to load Stripe product details (${error.message}).`,
                subHeader: 'Stripe Product Information'
              });
              return of(
                fromMonetizationActions.loadPrimarySubscriptionPackageWebPlatformProductDetailsFailure({ error })
              );
            })
          )
      )
    )
  );

  purchaseSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.purchaseSubscription),
      withLatestFrom(this.platformDetectService.getIsMobileSubscription()),
      map(([payload, isMobile]) => {
        let action: Action = fromMonetizationActions.purchaseWebSubscription({
          monetizationPackage: payload.monetizationPackage,
          redirectType: payload.redirectType
        });
        if (isMobile) {
          action = fromMonetizationActions.purchaseMobileSubscription({
            monetizationPackage: payload.monetizationPackage
          });
        }
        return action;
      })
    )
  );

  purchaseMobileSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.purchaseMobileSubscription),
      switchMap((action) => {
        const loadingInstanceID = uuidV4();
        this.loadingService.setLoading(loadingInstanceID, true);
        return from(this.purchases.purchaseProduct(action.monetizationPackage.platformProductIdentifier)).pipe(
          switchMap((response) => [
            fromMonetizationActions.purchaseMobileSubscriptionSuccess({ customerInfo: response.customerInfo }),
            fromMonetizationActions.loadBadgerBucks({
              internalIdentifier: action.monetizationPackage.internalIdentifier
            })
          ]),
          finalize(() => {
            this.loadingService.setLoading(loadingInstanceID, false);
          }),
          catchError((error) => {
            if (!error.userCancelled) {
              this.monetizationService.handleError({
                error,
                message: `Failed to subscribe the user${this.monetizationService.getPurchasesErrorMessageDetails(
                  error
                )}.`,
                subHeader: 'Subscription Failed'
              });
            }
            return of(fromMonetizationActions.purchaseMobileSubscriptionFailure({ error }));
          })
        );
      })
    )
  );

  purchaseWebSubscription$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMonetizationActions.purchaseWebSubscription),
        withLatestFrom(
          this.store.select(fromAppConfigSelectors.getAuthDataUserObjectId),
          this.store.select(fromAppSettingsSelectors.getAppSettingsUserEmail)
        ),
        switchMap(([payload, clientReferenceId, customerEmail]) =>
          this.apiService
            .sendRequest({
              method: 'POST',
              requestOptions: {
                body: {
                  clientReferenceId,
                  container: containerName,
                  customerEmail,
                  mode: 'subscription',
                  priceId: payload.monetizationPackage.product.price.id,
                  uiCancelUrl: `${location.protocol}//${window.location.host}/#/payment/${payload.redirectType}/redirect/${MonetizationPaymentRedirectTypes.Cancel}`,
                  uiSuccessUrl: `${location.protocol}//${window.location.host}/#/payment/${payload.redirectType}/redirect/${MonetizationPaymentRedirectTypes.Success}`
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/Stripe/Checkout/Sessions`
            })
            .pipe(
              concatMap((stripeCheckoutSession: MonetizationStripeCheckoutSession) =>
                this.stripeService.redirectToCheckout({ sessionId: stripeCheckoutSession.id })
              ),
              catchError((error) => {
                this.monetizationService.handleError({
                  error,
                  message: `Failed to perform purchase (${error.message}).`,
                  subHeader: 'Stripe Checkout'
                });
                return of(
                  fromMonetizationActions.purchaseWebSubscriptionFailure({
                    error
                  })
                );
              })
            )
        )
      ),
    {
      dispatch: false
    }
  );

  cancelWebSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.cancelWebSubscription),
      switchMap(({ id }) =>
        this.apiService
          .sendRequest({
            method: 'DELETE',
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/Stripe/Subscriptions/${id}`
          })
          .pipe(
            concatMap(() => [
              fromMonetizationActions.cancelWebSubscriptionSuccess(),
              fromMonetizationActions.loadWebSubscriptions()
            ]),
            catchError((error) => {
              this.monetizationService.handleError({
                error,
                message: `Failed to cancel subscription (${error.message}).`,
                subHeader: 'Stripe Cancel Subscription'
              });
              return of(fromMonetizationActions.cancelWebSubscriptionFailure(error));
            })
          )
      )
    )
  );

  loadWebSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadWebSubscriptions),
      withLatestFrom(this.store.select(fromAppSettingsSelectors.getAppSettingsUserEmail)),
      switchMap((results) =>
        this.apiService
          .sendRequest({
            method: 'GET',
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/Stripe/Subscriptions/${results[1]}`
          })
          .pipe(
            map((stripeSubscriptions: MonetizationStripeSubscription[]) =>
              fromMonetizationActions.loadWebSubscriptionsSuccess({
                stripeSubscriptions
              })
            ),
            catchError((error) => {
              this.monetizationService.handleError({
                error,
                message: `Failed to load Stripe subscriptions (${error.message}).`,
                subHeader: 'Stripe Subscriptions List'
              });
              return of(fromMonetizationActions.loadWebSubscriptionsFailure(error));
            })
          )
      )
    )
  );

  purchaseProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.purchaseProduct),
      withLatestFrom(this.platformDetectService.getIsMobileSubscription()),
      map(([payload, isMobile]) => {
        let action: Action = fromMonetizationActions.purchaseWebProduct({
          monetizationPackage: payload.monetizationPackage,
          redirectType: payload.redirectType
        });
        if (isMobile) {
          action = fromMonetizationActions.purchaseMobileProduct({
            monetizationPackage: payload.monetizationPackage
          });
        }
        return action;
      })
    )
  );

  purchaseMobileProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.purchaseMobileProduct),
      switchMap((action) => {
        const loadingInstanceID = uuidV4();
        this.loadingService.setLoading(loadingInstanceID, true);
        return from(this.purchases.purchaseProduct(action.monetizationPackage.platformProductIdentifier)).pipe(
          switchMap((response) => [
            fromMonetizationActions.purchaseMobileProductSuccess({ customerInfo: response.customerInfo }),
            fromMonetizationActions.loadBadgerBucks({
              internalIdentifier: action.monetizationPackage.internalIdentifier
            })
          ]),
          finalize(() => {
            this.loadingService.setLoading(loadingInstanceID, false);
          }),
          catchError((error) => {
            if (!error.userCancelled) {
              this.monetizationService.handleError({
                error,
                message: `Failed to purchase the product${this.monetizationService.getPurchasesErrorMessageDetails(
                  error
                )}.`,
                subHeader: 'Subscription Failed'
              });
            }
            return of(fromMonetizationActions.purchaseMobileProductFailure({ error }));
          })
        );
      })
    )
  );

  purchaseWebProduct$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMonetizationActions.purchaseWebProduct),
        withLatestFrom(
          this.store.select(fromAppConfigSelectors.getAuthDataUserObjectId),
          this.store.select(fromAppSettingsSelectors.getAppSettingsUserEmail)
        ),
        delay(500),
        switchMap(([payload, clientReferenceId, customerEmail]) =>
          this.apiService
            .sendRequest({
              method: 'POST',
              requestOptions: {
                body: {
                  clientReferenceId,
                  container: containerName,
                  customerEmail,
                  mode: 'payment',
                  priceId: payload.monetizationPackage.product.price.id,
                  uiCancelUrl: `${location.protocol}//${window.location.host}/#/payment/${payload.redirectType}/redirect/${MonetizationPaymentRedirectTypes.Cancel}`,
                  uiSuccessUrl: `${location.protocol}//${window.location.host}/#/payment/${payload.redirectType}/redirect/${MonetizationPaymentRedirectTypes.Success}`
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/Stripe/Checkout/Sessions`
            })
            .pipe(
              concatMap((stripeCheckoutSession: MonetizationStripeCheckoutSession) =>
                this.stripeService.redirectToCheckout({ sessionId: stripeCheckoutSession.id })
              ),
              catchError((error) => {
                this.monetizationService.handleError({
                  error,
                  message: `Failed to perform purchase (${error.message}).`,
                  subHeader: 'Stripe Checkout'
                });
                return of(
                  fromMonetizationActions.purchaseWebProductFailure({
                    error
                  })
                );
              })
            )
        )
      ),
    {
      dispatch: false
    }
  );

  loadStoreProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadStoreProducts),
      withLatestFrom(this.platformDetectService.getIsMobileSubscription()),
      map((results) => {
        let action: Action = fromMonetizationActions.loadWebStoreProducts();
        if (results[1]) {
          action = fromMonetizationActions.loadMobileStoreProducts();
        }
        return action;
      })
    )
  );

  loadWebStoreProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadWebStoreProducts),
      withLatestFrom(
        this.store.select(fromMonetizationSelectors.selectStoreProductIdentifiers),
        this.store.select(fromAppConfig.getInAppProducts),
        this.store.select(fromAppConfig.getAppInfoConsolidatedParseAppKey),
        this.store.select(fromAppSettings.getAppSettingsUserEmail)
      ),
      switchMap(([_action, productIds, inAppProducts, consolidatedParseAppKey, userEmail]) =>
        this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              body: {
                ids: productIds
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/Stripe/Products`
          })
          .pipe(
            switchMap((stripeProducts: MonetizationStripeProduct[]) => [
              new parseActions.GetRegisteredUser({
                appName: consolidatedParseAppKey,
                email: userEmail
              }),
              fromMonetizationActions.loadWebStoreProductsSuccess({ inAppProducts, stripeProducts })
            ]),
            catchError((error) => of(fromMonetizationActions.loadWebStoreProductsFailure({ error })))
          )
      )
    )
  );

  loadMobileStoreProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadMobileStoreProducts),
      withLatestFrom(
        this.store.select(fromAppConfig.getAppInfoConsolidatedParseAppKey),
        this.store.select(fromAppSettings.getAppSettingsUserEmail)
      ),
      switchMap(([_action, consolidatedParseAppKey, userEmail]) => [
        new parseActions.GetRegisteredUser({
          appName: consolidatedParseAppKey,
          email: userEmail
        }),
        fromMonetizationActions.loadMobileStoreProductsSuccess()
      ])
    )
  );

  loadProductDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadProductDetails),
      withLatestFrom(this.platformDetectService.getIsMobileSubscription()),
      map((results) => {
        const productId = results[0].productId;
        let action: Action = fromMonetizationActions.loadWebProductDetails({ productId });
        if (results[1]) {
          action = fromMonetizationActions.loadMobileProductDetails({ productId });
        }
        return action;
      })
    )
  );

  loadWebProductDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadWebProductDetails),
      withLatestFrom(this.store.select(fromMonetizationSelectors.selectStoreProductData)),
      switchMap((results) =>
        this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              body: {
                ids: [results[0].productId]
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/Stripe/Products`
          })
          .pipe(
            switchMap((monetizationStripeProducts: MonetizationStripeProduct[]) => {
              if (!monetizationStripeProducts.length) {
                throw new Error('Product not found');
              }

              return [
                fromMonetizationActions.loadWebProductDetailsSuccess({
                  monetizationStripeProduct: monetizationStripeProducts[0],
                  storeProductsData: results[1]
                })
              ];
            }),
            catchError((error) => {
              this.monetizationService.handleError({
                error,
                message: `Failed to load Stripe product details (${error.message}).`,
                subHeader: 'Stripe Product Information'
              });
              return of(fromMonetizationActions.loadWebProductDetailsFailure({ error }));
            })
          )
      )
    )
  );

  loadBadgerBucks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadBadgerBucks),
      withLatestFrom(
        this.store
          .select(fromAppConfigSelectors.getGeneralInfoAuthData)
          .pipe(map((authData) => authData?.userObjectId)),
        this.store.select(fromAppConfig.getAppInfoParseUrlType),
        this.store.select(fromAppConfig.getAppInfoConsolidatedParseAppKey),
        this.store.select(fromAppSettings.getAppSettingsUserEmail)
      ),
      switchMap(([_payload, userId, parseUrlType, consolidatedParseAppKey, userEmail]) =>
        this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${userId}/Purchase/BADGER_BUCKS_1000`
          })
          .pipe(
            switchMap(() => [
              fromMonetizationActions.loadBadgerBucksSuccess(),
              new parseActions.GetRegisteredUser({
                appName: consolidatedParseAppKey,
                email: userEmail
              })
            ]),
            catchError((error) => {
              this.monetizationService.handleError({
                error,
                message: `Failed to update Badger Bucks (${error.message}).`,
                subHeader: 'Badger Bucks Update'
              });
              return of(fromMonetizationActions.loadBadgerBucksFailure(error));
            })
          )
      )
    )
  );

  loadBadgerBucksSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMonetizationActions.loadBadgerBucksSuccess),
        withLatestFrom(
          this.store
            .select(fromAppConfigSelectors.getGeneralInfoAuthData)
            .pipe(map((authData) => authData?.userObjectId)),
          this.platformDetectService.getIsMobileSubscription(),
          this.store.select(fromMonetizationSelectors.selectIsRevenueCatUserLoggedIn)
        ),
        switchMap((results) =>
          from(this.monetizationService.updateMonetizationConfiguration(results[1], results[2], results[3])).pipe(
            catchError((error) => {
              this.monetizationService.handleError({
                error,
                message: `Failed to setup payments (${error.message}).`,
                subHeader: 'RevenueCat Initialization'
              });
              return of(error);
            })
          )
        )
      ),
    {
      dispatch: false
    }
  );

  getVirtualCurrencyPreview$ = createEffect(() =>
    this.actions$.pipe(ofType(fromMonetizationActions.loadVirtualCurrencyPreview)).pipe(
      withLatestFrom(
        this.store.select(fromMonetizationSelectors.selectInAppPurchasesFarmPurchase),
        this.store.select(fromAppConfig.getAuthDataUserObjectId),
        this.store.select(fromAppConfig.getAppInfoParseUrlType)
      ),
      switchMap(([payload, viewedFarm, userId, parseUrlType]) =>
        this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              body: {
                farm: {
                  id: viewedFarm.farmId,
                  propertyIds: payload.propertyIds
                }
              },
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${userId}/VirtualCurrencyPurchase/PHONES_AND_EMAILS/Preview`
          })
          .pipe(
            switchMap((result) => [
              fromMonetizationActions.loadVirtualCurrencyPreviewSuccess({
                preview: result
              })
            ]),
            catchError(() => of(fromMonetizationActions.loadVirtualCurrencyPreviewFailure()))
          )
      )
    )
  );

  getVirtualCurrencyPurchase$ = createEffect(() =>
    this.actions$.pipe(ofType(fromMonetizationActions.loadVirtualCurrencyPurchase)).pipe(
      withLatestFrom(
        this.store.select(fromMonetizationSelectors.selectInAppPurchasesFarmPurchase),
        this.store.select(fromAppConfig.getAuthDataUserObjectId),
        this.store.select(fromAppConfig.getAppInfoParseUrlType),
        this.store.select(fromAppConfig.getAppInfoConsolidatedParseAppKey),
        this.store.select(fromAppSettings.getAppSettingsUserEmail)
      ),
      switchMap(([payload, viewedFarm, userId, parseUrlType, consolidatedParseAppKey, userEmail]) =>
        this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              body: {
                farm: {
                  id: viewedFarm.farmId,
                  propertyIds: payload.propertyIds
                }
              },
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${userId}/VirtualCurrencyPurchase/PHONES_AND_EMAILS`
          })
          .pipe(
            switchMap((response: VirtualCurrencyPurchaseResponse) => [
              fromMonetizationActions.loadVirtualCurrencyPurchaseSuccess(response),
              new appParseAction.GetRegisteredUser({ appName: consolidatedParseAppKey, email: userEmail })
            ]),
            catchError(() => of(fromMonetizationActions.loadVirtualCurrencyPurchaseFailure()))
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private http: HttpClient,
    private loadingService: LoadingService,
    private monetizationService: MonetizationService,
    private platformDetectService: PlatformDetectService,
    private purchases: Purchases,
    private store: Store,
    private stripeService: StripeService
  ) {}
}
