import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { from, Observable, of } from 'rxjs';

import { appBuildNumber } from '../../../environments/environment';
import { DeviceData } from '../../typings/device';

@Injectable()
export class AppDeviceProvider {
  constructor(
    private device: Device,
    private appVersion: AppVersion,
    private platform: Platform
  ) {}

  getInfo() {
    let observable: Observable<DeviceData> = of(null);

    if (this.platform.is('cordova')) {
      observable = from(
        this.appVersion.getVersionNumber().then((version: string) => {
          return {
            appVersion: `${version}(${appBuildNumber})`,
            deviceDimensions: this.device.model,
            deviceType: this.device.model,
            os: this.device.platform.toLocaleUpperCase(),
            osVersion: this.device.version
          };
        })
      );
    }

    return observable;
  }

  getDeviceId() {
    let deviceUuid = '';

    if (this.platform.is('cordova')) {
      deviceUuid = this.device.uuid;
    }

    return deviceUuid;
  }
}
