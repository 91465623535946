import { Component, Input } from '@angular/core';

@Component({
  selector: 'smd-form-loading',
  templateUrl: './form-loading.component.html'
})
export class FormLoadingComponent {
  @Input() isLoading = false;
  @Input() isInvalid = false;

  constructor() {}
}
