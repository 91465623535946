import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PhoneFormatService {
  formatValue(value: string | number) {
    let result = value?.toString() || '';
    result = result?.replace(/[^0-9]/g, '');

    if (result) {
      result = result.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
    }

    return result;
  }
}
