<ion-row *ngIf="isInvalid && !isLoading">
  <ion-col size-md="6" offset-md="3" size-lg="4" offset-lg="4" class="ion-margin-top tw-pl-4">
    <ion-text class="tw-mt-8 tw-block tw-text-center tw-text-xl tw-text-gray-500">
      The form is not available!
    </ion-text>
  </ion-col>
</ion-row>

<ion-row *ngIf="isLoading && !isInvalid">
  <ion-col size-md="6" offset-md="3" size-lg="4" offset-lg="4" class="ion-margin-top tw-pl-4">
    <ion-text class="tw-mt-8 tw-block tw-text-center tw-text-xl tw-text-gray-500">Loading form...</ion-text>
  </ion-col>
</ion-row>
