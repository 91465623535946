import { Action } from '@ngrx/store';

import { AppSettingsSalesInfo } from '../../../typings/app';

export enum SalesInfoActionTypes {
  GetSalesInformation = '[Sales Info] Get Sales Information',
  GetSalesInformationSuccess = '[Sales Info] Get Sales Information Success',
  ResetSalesInformation = '[Sales Info] Reset Sales Information'
}

export class GetSalesInformation implements Action {
  readonly type = SalesInfoActionTypes.GetSalesInformation;

  constructor() {}
}
export class GetSalesInformationSuccess implements Action {
  readonly type = SalesInfoActionTypes.GetSalesInformationSuccess;

  constructor(public payload: AppSettingsSalesInfo) {}
}
export class ResetSalesInformation implements Action {
  readonly type = SalesInfoActionTypes.ResetSalesInformation;

  constructor() {}
}

export type SalesInfoActions = GetSalesInformation | GetSalesInformationSuccess | ResetSalesInformation;
