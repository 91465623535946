import { Action } from '@ngrx/store';

export enum AppSupportActionTypes {
  GetSupportEmail = '[App Support] Get Support Email'
}

export class GetSupportEmail implements Action {
  readonly type = AppSupportActionTypes.GetSupportEmail;

  constructor(public payload: string) {}
}

export type AppSupportActions = GetSupportEmail;
