import { Injectable, NgZone } from '@angular/core';

import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { STANDARD_PIN_SVG } from '../../../constants/map.constants';

import * as fromSearchTypesActions from '../../store/actions/property-search/search-types';
import * as fromAppConfig from '../../store/selectors/app-config';
import * as fromPropertyProfile from '../../store/selectors/property-data';
import * as fromSearchTypes from '../../store/selectors/property-search';

import { PfHelperService } from '../pf-helper-service.service';

@Injectable()
export class LongPressManager {
  private start: number;
  private end: number;
  private longPress: boolean;
  private longPressMarker: google.maps.Marker = null;
  private markerAddressFound = new BehaviorSubject<{ address: string; zip: string }>(null);

  constructor(
    private zone: NgZone,
    private store: Store<fromSearchTypes.State | fromAppConfig.State | fromPropertyProfile.State>,
    private pfHelper: PfHelperService
  ) {}

  getMarkerAddressFoundObservable() {
    return this.markerAddressFound.asObservable();
  }

  public clearPin() {
    this.zone.run(() => {
      if (this.longPressMarker) {
        this.longPressMarker.setMap(null);
        this.longPressMarker = null;
        this.setSearchValue(null);
      }
    });
  }

  init(mapObj: google.maps.Map) {
    google.maps.event.addListener(mapObj, 'click', (event) => {
      this.clearPin();

      if (this.longPress) {
        this.store.dispatch(new fromSearchTypesActions.ResetSearchTypes());
        this.longPressMarker = new google.maps.Marker({
          animation: google.maps.Animation.DROP,
          draggable: true,
          icon: {
            scaledSize: new google.maps.Size(27, 45),
            url: `/assets/svg/map-markers/${STANDARD_PIN_SVG.blue}`
          },
          map: mapObj,
          position: {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
          }
        });

        this.getMarkerAddress(this.longPressMarker);

        google.maps.event.addListener(this.longPressMarker, 'dragend', () => {
          this.setSearchValue(null);
          this.getMarkerAddress(this.longPressMarker);
        });
      }
    });

    google.maps.event.addListener(mapObj, 'mousedown', () => {
      this.start = new Date().getTime();
    });

    google.maps.event.addListener(mapObj, 'mouseup', () => {
      this.end = new Date().getTime();
      this.longPress = this.end - this.start >= 500;
    });
  }

  getMarkerAddress(marker) {
    const geocoder = new google.maps.Geocoder();
    const that = this; // Year 2000 called; it wants the nonsense hack back! o_O

    geocoder.geocode(
      { location: marker.getPosition() },
      (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status === google.maps.GeocoderStatus.OK) {
          that.setSearchValue({
            address: results[0].formatted_address,
            zip: that.pfHelper.findObjectByPropInCollection(
              results[0].address_components,
              'types',
              'postal_code',
              'long_name'
            )
          });
        } else {
          alert('Cannot determine address at this location');
        }
      }
    );
  }

  private setSearchValue(val: { address: string; zip: string }) {
    this.markerAddressFound.next(val);
    this.store.dispatch(new fromSearchTypesActions.SetSearchValue(val?.address || ''));
  }
}
