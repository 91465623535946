import { Pipe, PipeTransform } from '@angular/core';

import { CurrencyFormatService } from '../services/number-formatter/currency-format.service';
import { DateFormatService } from '../services/number-formatter/date-format.service';
import { NumberFormatService } from '../services/number-formatter/number-format.service';
import { PhoneFormatService } from '../services/number-formatter/phone-format.service';

@Pipe({
  name: 'smdNumberFormatter'
})
export class NumberFormatterPipe implements PipeTransform {
  constructor(
    private phoneFormatService: PhoneFormatService,
    private dateFormatService: DateFormatService,
    private numberFormatService: NumberFormatService,
    private currencyFormatService: CurrencyFormatService
  ) {}

  transform(value: string | number, dataType: string, formatRule?: string) {
    let result = '';
    let number: number;

    switch (dataType) {
      case 'phone':
        result = this.phoneFormatService.formatValue(value);
        break;

      case 'date':
        result = this.dateFormatService.formatValue(value, formatRule);
        break;

      case 'number':
        result = this.numberFormatService.formatValue(value);
        break;

      case 'currency':
        result = this.currencyFormatService.formatValue(value?.toString());
        break;

      case 'currency-no-decimal':
        result = this.currencyFormatService.formatValue(value?.toString(), '1.0');
        break;

      case 'percent-display':
        result = value?.toString() || '';
        result = `${value}%`;
        break;

      case 'time':
        number = Number(value);
        if (!isNaN(number)) {
          result = `${number} ${formatRule}${number === 1 ? '' : 's'}`;
        }
        break;

      default:
        break;
    }

    return result;
  }
}
