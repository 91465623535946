import * as appParseActions from '../../actions/parse/parse-implementation';

export interface State {
  error: Error;
  pending: boolean;
  succeeded: boolean;
}

export const initialState: State = {
  error: null,
  pending: false,
  succeeded: false
};

export function reducer(state = initialState, action: appParseActions.ParseActions) {
  switch (action.type) {
    case appParseActions.ParseActionTypes.GetRegisteredUser: {
      return {
        ...state,
        error: null,
        pending: true,
        succeeded: false
      };
    }

    case appParseActions.ParseActionTypes.GetRegisteredUserSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
        succeeded: true
      };
    }

    case appParseActions.ParseActionTypes.GetRegisteredUserFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }

    case appParseActions.ParseActionTypes.CreateUser: {
      return {
        ...state,
        error: null,
        pending: true,
        succeeded: false
      };
    }

    case appParseActions.ParseActionTypes.CreateUserSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
        succeeded: true
      };
    }

    case appParseActions.ParseActionTypes.CreateUserFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }

    case appParseActions.ParseActionTypes.ResetParseCallsState: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
