import { createFeatureSelector, createSelector } from '@ngrx/store';

import { mapFeatureKey, State } from '../reducers/map.reducer';

const selectMapFeature = createFeatureSelector<State>(mapFeatureKey);

export const selectMapCenterSetting = createSelector(selectMapFeature, (state: State) => state.isMapCentered);

export const selectMapIcons = createSelector(selectMapFeature, (state: State) => state.icons);

export const selectSavedFarmLocation = createSelector(selectMapFeature, (state: State) => state.location.savedFarm);

export const selectDrawMode = createSelector(selectMapFeature, (state: State) => state.drawMode);
