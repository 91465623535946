import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import { BehaviorSubject, of } from 'rxjs';
import { concatMap, filter, throttleTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loadingMap: BehaviorSubject<Map<string, boolean>> = new BehaviorSubject<Map<string, boolean>>(
    new Map<string, boolean>()
  );

  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private loadingController: LoadingController) {
    let loader: HTMLIonLoadingElement;

    this.loading
      .pipe(
        throttleTime(300, undefined, { leading: true, trailing: true }),
        concatMap(async (show): Promise<void> => {
          if (show && !loader) {
            loader = await this.loadingController.create({
              message: 'Please wait...'
            });
            await loader.present();
          } else if (!show && loader) {
            await loader.dismiss();
            loader = null;
          }
        })
      )
      .subscribe();

    this.loadingMap
      .pipe(
        throttleTime(300, undefined, { leading: true, trailing: true }),
        concatMap((loadingMap) => of(!!loadingMap.size)),
        filter((loading) => this.loading.value !== loading)
      )
      .subscribe((loading) => {
        this.loading.next(loading);
      });
  }

  setLoading(id: string, loading: boolean) {
    if (!id) {
      throw new Error('The request id must be provided in order for LoadingService to properly work.');
    }

    const loadingMap = this.loadingMap.value;

    if (loading) {
      loadingMap.set(id, loading);
    } else if (loadingMap.has(id)) {
      loadingMap.delete(id);
    }

    this.loadingMap.next(loadingMap);
  }
}
