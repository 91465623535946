import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { MonetizationStoreProduct } from '../../typings/monetization';

@Directive({
  selector: '[smdStoreProductLabel]'
})
export class StoreProductLabelDirective implements OnInit {
  @Input('smdStoreProductLabel') product: Partial<MonetizationStoreProduct>;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit() {
    let text = '';
    let color = '';

    if (this.product.isSubscription) {
      text = this.product.isActive ? 'Subscribed' : 'Not Subscribed';
      color = this.product.isActive ? 'success' : 'medium';
    } else {
      text = this.product.isPurchased ? 'Purchased' : 'Not Purchased';
      color = this.product.isPurchased ? 'success' : 'medium';
    }

    if (!this.product.isAvailable) {
      text = this.product.isPurchased ? text : 'Not Available';
      color = this.product.isPurchased ? color : 'danger';
    }

    this.renderer.setAttribute(this.el.nativeElement, 'color', color);
    this.el.nativeElement.innerHTML = text;
  }
}
