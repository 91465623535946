<ng-container *ngIf="calculatorData; else noDataTemplate">
  <ion-card *ngIf="report" class="ion-no-margin tw-m-4 lg:tw-mx-0">
    <ion-card-header
      class="ion-text-uppercase tw-px-4 tw-py-2 tw-text-xs tw-font-bold tw-text-gray-500 md:tw-text-base"
    >
      Payment summary
    </ion-card-header>

    <ion-card-content class="tw-px-4 tw-py-2">
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col size="12" size-md="5">
            <div class="tw-flex tw-items-center">
              <ion-icon [ngClass]="['tw-text-5xl', 'tw-h-12', 'tw-w-12', report.summary.icon]"></ion-icon>

              <div class="tw-ml-4">
                <h2 class="tw-text-xs md:tw-text-sm">{{ report.summary.label }}:</h2>
                <ion-text class="tw-text-base tw-font-bold tw-text-gray-800 md:tw-text-lg">
                  {{ report.summary.value | smdNumberFormatter: report.summary.format }}
                </ion-text>
              </div>
            </div>

            <hr class="tw-my-3 tw-border-gray-300 md:tw-hidden" />
          </ion-col>

          <ion-col size="12" size-md="7" class="md:tw-border-l md:tw-border-gray-400 md:tw-pl-4">
            <smd-doughnut-chart [options]="chartOptions" [data]="chartData"></smd-doughnut-chart>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>

  <ion-card *ngIf="summaryData" class="ion-no-margin tw-m-4 lg:tw-mx-0">
    <ion-card-header
      class="ion-text-uppercase tw-px-4 tw-py-2 tw-text-xs tw-font-bold tw-text-gray-500 md:tw-text-base"
    >
      Summary
    </ion-card-header>

    <ion-card-content class="tw-px-4 tw-py-2">
      <ion-grid class="ion-no-padding">
        <ion-row *ngFor="let summaryEntry of summaryData; let even = even" [ngClass]="{ 'tw-bg-gray-100': even }">
          <ion-col>
            <ion-text>{{ summaryEntry.label }}</ion-text>
          </ion-col>
          <ion-col class="tw-block tw-text-right">
            <ion-text>
              {{ summaryEntry.value | smdNumberFormatter: summaryEntry.format : summaryEntry.formatRule }}
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>

  <ion-card *ngIf="paymentBreakdownData" class="ion-no-margin tw-m-4 lg:tw-mx-0">
    <ion-card-header
      class="ion-text-uppercase tw-px-4 tw-py-2 tw-text-xs tw-font-bold tw-text-gray-500 md:tw-text-base"
    >
      Payment Breakdown
    </ion-card-header>

    <ion-card-content class="tw-px-4 tw-py-2">
      <ion-grid class="ion-no-padding">
        <ion-row
          *ngFor="let paymentBreakdownEntry of paymentBreakdownData; let even = even"
          [ngClass]="{ 'tw-bg-gray-100': even }"
        >
          <ion-col>
            <ion-text>{{ paymentBreakdownEntry.label }}</ion-text>
          </ion-col>
          <ion-col class="tw-block tw-text-right">
            <ion-text>
              {{ paymentBreakdownEntry.value | smdNumberFormatter: paymentBreakdownEntry.format }}
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>

  <div *ngIf="!isMobileView" class="tw-flex tw-flex-col">
    <ion-button color="primary" class="tw-my-4 tw-self-end" (click)="sendReport()"> Download </ion-button>
  </div>
</ng-container>

<ng-template #noDataTemplate>
  <div class="tw-px-4 lg:tw-px-0">
    <smd-the-oops-component></smd-the-oops-component>
  </div>
</ng-template>
