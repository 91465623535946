import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';

import { Store } from '@ngrx/store';
import { isBoolean } from 'lodash-es';
import { filter, take } from 'rxjs/operators';

import { RightButton } from '../../../../typings/header';
import { RightClickNavigation } from '../../../../typings/navigation';

import { GenericComponent } from '../../generic/generic.component';

import * as fromAppConfig from '../../../../store/selectors/app-config';
import * as fromSearchTypes from '../../../../store/selectors/property-search';

import { ContainerService } from '../../../services/container/container.service';
import { LayoutService } from '../../../services/layout/layout.service';

@Component({
  selector: 'smd-page-layout',
  styleUrls: ['./page-layout.component.scss'],
  templateUrl: './page-layout.component.html'
})
export class PageLayoutComponent extends GenericComponent implements OnInit {
  isMobileView = true;
  expandContactCard = false;
  hideContactCard = true;
  logoUrl = '';
  brandName = '';

  @Input() hasHeaderContent = false;
  @Input() hideMobileHeader = false;
  @Input() hideHeader = false;
  @Input() hideFooter = false;
  @Input() goBackToPage: string;
  @Input() pageTitle = '';
  @Input() headerType = 'withTitle';
  @Input() pageSubTitle = '';
  @Input() hasBackNavigation = false;
  @Input() backNavigationText: string;
  @Input() hasIcon = false;
  @Input() rightIcons: RightClickNavigation[];
  @Input() rightButtons?: RightButton[];
  @Input() closeIcon = false;

  @Output() headerRightButtonClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() headerLeftButtonClick: EventEmitter<PointerEvent | MouseEvent> = new EventEmitter<
    PointerEvent | MouseEvent
  >();

  constructor(
    private ngZone: NgZone,
    private store: Store<fromSearchTypes.State>,
    private layoutService: LayoutService,
    private containerService: ContainerService
  ) {
    super();
  }

  ngOnInit() {
    this.addUniqueSubscription(
      'isMobileViewSubscription',
      this.layoutService.getMobileViewFlag().subscribe((isMobileView) => {
        this.ngZone.run(() => {
          this.isMobileView = isMobileView;
        });
      })
    );

    this.addUniqueSubscription(
      'containerSettingsSubscription',
      this.containerService
        .getContainerSettings()
        .pipe(filter((containerSettings) => !!containerSettings))
        .subscribe((containerSettings) => {
          this.ngZone.run(() => {
            this.brandName = containerSettings.brandName;
          });
        })
    );

    this.addUniqueSubscription(
      'logoUrlSubscription',
      this.store
        .select(fromAppConfig.getGeneralInfoAppInfoLogoUrl)
        .pipe(
          filter((logoUrl) => this.logoUrl !== logoUrl),
          take(1)
        )
        .subscribe((logoUrl) => {
          this.ngZone.run(() => {
            this.logoUrl = logoUrl;
          });
        })
    );

    this.addUniqueSubscription(
      'hideContactCardSubscription',
      this.store
        .select(fromAppConfig.getRepDataHideContactCard)
        .pipe(filter((value) => isBoolean(value)))
        .subscribe((hideContactCard) => {
          this.ngZone.run(() => {
            this.hideContactCard = hideContactCard;
          });
        })
    );

    this.addUniqueSubscription(
      'contactCardToggleSubscription',
      this.layoutService.getContactCardToggle().subscribe((expand) => {
        this.ngZone.run(() => {
          this.expandContactCard = expand;
        });
      })
    );
  }

  onRightButtonClick(componentName: string) {
    this.headerRightButtonClick.emit(componentName);
  }

  onLeftButtonClick(event: PointerEvent | MouseEvent) {
    this.headerLeftButtonClick.emit(event);
  }

  goHome() {
    this.layoutService.goHome();
  }
}
