import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { MonetizationStoreProduct } from '../../typings/monetization';

@Directive({
  selector: '[smdStoreProductImage]'
})
export class StoreProductImageDirective implements OnInit {
  @Input('smdStoreProductImage') product: Partial<MonetizationStoreProduct>;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit() {
    const path = '/assets/svg/store/';
    const fileName = this.product.isSubscription ? 'store-subscription.svg' : 'store-in-app-purchase.svg';
    this.renderer.setAttribute(this.el.nativeElement, 'src', `${path}${fileName}`);
  }
}
