import {
  AffordabilityData,
  AffordabilityReportData,
  AmortizationData,
  AmortizationReportData,
  PitiData,
  PitiReportData
} from '../../../typings/calculator';

import * as calculatorsActions from '../../actions/calculators/calculators-and-reports';

export interface State {
  affordabilityData: AffordabilityReportData | null;
  amortizationData: AmortizationReportData | null;
  error: Error;
  pdfData: Blob | null;
  pending: boolean;
  pitiData: PitiReportData | null;
  rawCalculatorData: AffordabilityData | AmortizationData | PitiData | null;
  selectedCalculator: string | null;
  succeeded: boolean;
}

export const initialState: State = {
  affordabilityData: null,
  amortizationData: null,
  error: null,
  pdfData: null,
  pending: false,
  pitiData: null,
  rawCalculatorData: null,
  selectedCalculator: null,
  succeeded: false
};

export function reducer(state = initialState, action: calculatorsActions.CalculatorsReportsActions) {
  switch (action.type) {
    case calculatorsActions.CalculatorsReportsActionTypes.SetPdfReportData: {
      return {
        ...state,
        error: null,
        pending: true,
        succeeded: false
      };
    }
    case calculatorsActions.CalculatorsReportsActionTypes.GetPdfReportDataSuccess: {
      return {
        ...state,
        error: null,
        pdfData: action.payload,
        pending: false,
        succeeded: true
      };
    }
    case calculatorsActions.CalculatorsReportsActionTypes.GetPdfReportDataFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }
    case calculatorsActions.CalculatorsReportsActionTypes.ResetPdfReportData: {
      return {
        ...state,
        error: null,
        pdfData: null,
        pending: false,
        succeeded: false
      };
    }

    case calculatorsActions.CalculatorsReportsActionTypes.SetSelectedCalculator: {
      return {
        ...state,
        selectedCalculator: action.payload
      };
    }
    case calculatorsActions.CalculatorsReportsActionTypes.ResetSelectedCalculator: {
      return {
        ...state,
        selectedCalculator: null
      };
    }

    case calculatorsActions.CalculatorsReportsActionTypes.SetPitiData: {
      return {
        ...state,
        pitiData: action.payload
      };
    }
    case calculatorsActions.CalculatorsReportsActionTypes.ResetPitiData: {
      return {
        ...state,
        pitiData: null
      };
    }

    case calculatorsActions.CalculatorsReportsActionTypes.SetAffordabilityData: {
      return {
        ...state,
        affordabilityData: action.payload
      };
    }
    case calculatorsActions.CalculatorsReportsActionTypes.ResetAffordabilityData: {
      return {
        ...state,
        affordabilityData: null
      };
    }

    case calculatorsActions.CalculatorsReportsActionTypes.GetAmortizationDataSuccess: {
      return {
        ...state,
        amortizationData: action.payload
      };
    }
    case calculatorsActions.CalculatorsReportsActionTypes.ResetAmortizationData: {
      return {
        ...state,
        amortizationData: null
      };
    }

    case calculatorsActions.CalculatorsReportsActionTypes.SetRawCalculatorData: {
      return {
        ...state,
        rawCalculatorData: action.payload
      };
    }

    case calculatorsActions.CalculatorsReportsActionTypes.ResetRawCalculatorData: {
      return {
        ...state,
        rawCalculatorData: null
      };
    }

    default: {
      return state;
    }
  }
}

export const getPdfBlobData = (state: State) => state.pdfData;

export const getSelectedCalculator = (state: State) => state.selectedCalculator;
export const getPitiData = (state: State) => state.pitiData;
export const getAffordabilityData = (state: State) => state.affordabilityData;
export const getAmortizationData = (state: State) => state.amortizationData;
export const getRawCalculatorData = (state: State) => state.rawCalculatorData;
