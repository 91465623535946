import { CustomerInfo, PRODUCT_CATEGORY, PurchasesOfferings } from '@awesome-cordova-plugins/purchases';
import { Action, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import {
  cloneDeep,
  defaultsDeep,
  each,
  filter as lodashFilter,
  find,
  forEach,
  has,
  isEmpty,
  map,
  values
} from 'lodash-es';
import moment from 'moment';

import { ENV } from '../../../environments/environment';
import { paymentPlanRules } from '../../constants/monetization';
import { MonetizationFarmPropertiesPhoneAndEmailsInAppPurchaseTypes } from '../../enums/monetization';
import {
  MonetizationInAppPurchasesProducts,
  MonetizationPackage,
  MonetizationRepCodeInAppPurchase,
  MonetizationRevenueCatStoreProduct,
  MonetizationRevenueCatWebOfferingItem,
  MonetizationRevenueCatWebPurchaserInfo,
  MonetizationStoreProduct,
  MonetizationStripeSubscription,
  MonetizationViewedProduct,
  VirtualCurrencyPreviewData
} from '../../typings/monetization';

import * as fromMonetizationActions from '../actions/monetization.actions';

export const monetizationFeatureKey = 'monetization';

export interface MonetizationState {
  data: {
    inAppPurchases: {
      farmPurchase: {
        farmId: string | null;
        isEnabled: boolean;
        isMade: boolean;
        phoneAndEmail: {
          isPurchaseCompleted: boolean;
          isPurchaseSuccess: boolean;
          preview: VirtualCurrencyPreviewData;
        };
      };
      isEnabled: boolean;
      products: MonetizationInAppPurchasesProducts | null;
    };
    isEnabled: boolean;
    isLoaded: boolean;
    isOfferingsLoaded: boolean;
    isPurchaserInfoLoaded: boolean;
    isRevenueCatUserLoggedIn: boolean;
    mobile: {
      isPaymentLoading: boolean;
      isPaymentSuccess: boolean;
      offerings: PurchasesOfferings | null;
      purchaserInfo: CustomerInfo | null;
    };
    store: {
      isEnabled: boolean;
      isProductDataLoaded: boolean;
      productData: Partial<MonetizationStoreProduct>[] | null;
      storeProductIdentifiers: string[] | null;
    };
    subscriptions: {
      primary: {
        accessAlias: string | null;
        entitlement: string | null;
        isActive: boolean;
        isEnabled: boolean;
        offeringsIdentifier: string | null;
        package: MonetizationPackage;
      };
    };
    viewedProduct: {
      data: MonetizationViewedProduct | null;
      isLoading: boolean;
    };
    web: {
      offerings: MonetizationRevenueCatWebOfferingItem[] | null;
      purchaserInfo: MonetizationRevenueCatWebPurchaserInfo | null;
      subscriptions: {
        isLoading: boolean;
        list: MonetizationStripeSubscription[] | null;
      };
    };
  };
}

export const initialState: MonetizationState = {
  data: {
    inAppPurchases: {
      farmPurchase: {
        farmId: null,
        isEnabled: false,
        isMade: false,
        phoneAndEmail: {
          isPurchaseCompleted: false,
          isPurchaseSuccess: false,
          preview: {
            badgerBucks: {
              available: 0,
              cost: 0
            },
            canPurchase: false,
            isLoaded: false
          }
        }
      },
      isEnabled: false,
      products: null
    },
    isEnabled: false,
    isLoaded: false,
    isOfferingsLoaded: false,
    isPurchaserInfoLoaded: false,
    isRevenueCatUserLoggedIn: false,
    mobile: {
      isPaymentLoading: false,
      isPaymentSuccess: false,
      offerings: null,
      purchaserInfo: null
    },
    store: {
      isEnabled: false,
      isProductDataLoaded: false,
      productData: null,
      storeProductIdentifiers: null
    },
    subscriptions: {
      primary: {
        accessAlias: null,
        entitlement: null,
        isActive: false,
        isEnabled: false,
        offeringsIdentifier: null,
        package: {
          identifier: null,
          internalIdentifier: null,
          platformProductIdentifier: null,
          product: {
            isCommonDataLoaded: false,
            isPlatformDataLoaded: false,
            name: '',
            price: {
              displayValue: '',
              isLoaded: false
            },
            time: {
              displayValue: ''
            }
          }
        }
      }
    },
    viewedProduct: {
      data: null,
      isLoading: false
    },
    web: {
      offerings: null,
      purchaserInfo: null,
      subscriptions: {
        isLoading: false,
        list: null
      }
    }
  }
};

export function reducer(state: MonetizationState, action: Action) {
  return privateReducer(state, action);
}

const privateReducer = createReducer(
  cloneDeep(initialState),
  on(fromMonetizationActions.loadProductsSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.data.inAppPurchases.products = null;
      draft.data.inAppPurchases.isEnabled = false;
      if (!isEmpty(action.products.inAppPurchases)) {
        draft.data.inAppPurchases.products = {};
        each(action.products.inAppPurchases, (inAppPurchase) => {
          draft.data.inAppPurchases.products[inAppPurchase.identifier] = {
            storeIdentifiers: inAppPurchase.storeIdentifiers
          };
        });
        draft.data.inAppPurchases.isEnabled = true;
      }
      draft.data.subscriptions.primary.package.internalIdentifier = action.products.subscriptionPlan;
      draft.data.subscriptions.primary.isEnabled = false;
      draft.data.subscriptions.primary.entitlement = initialState.data.subscriptions.primary.entitlement;
      draft.data.subscriptions.primary.offeringsIdentifier =
        initialState.data.subscriptions.primary.offeringsIdentifier;
      draft.data.subscriptions.primary.accessAlias = initialState.data.subscriptions.primary.accessAlias;
      const primarySubscriptionPlanRules =
        paymentPlanRules.find((plan) => plan.alias === action.products.subscriptionPlan) || null;
      if (primarySubscriptionPlanRules) {
        draft.data.subscriptions.primary.isEnabled = true;
        draft.data.subscriptions.primary.entitlement = primarySubscriptionPlanRules.entitlement;
        draft.data.subscriptions.primary.offeringsIdentifier = primarySubscriptionPlanRules.offering;
        draft.data.subscriptions.primary.accessAlias =
          primarySubscriptionPlanRules.accessAlias ?? primarySubscriptionPlanRules.alias;
      }
      draft.data.isEnabled =
        draft.data.subscriptions.primary.isEnabled || draft.data.inAppPurchases.isEnabled || draft.data.store.isEnabled;
    })
  ),
  on(fromMonetizationActions.loadWebPurchaserInfoSuccess, (state, action) =>
    produce(state, (draft) => {
      if (!draft.data.isPurchaserInfoLoaded) {
        draft.data.isPurchaserInfoLoaded = true;
        draft.data.isLoaded = draft.data.isOfferingsLoaded;
      }
      draft.data.web.purchaserInfo = action.purchaserInfo;
      draft.data.subscriptions.primary.isActive = initialState.data.subscriptions.primary.isActive;
      if (draft.data.subscriptions.primary.package.internalIdentifier) {
        const entitlementsList = action.purchaserInfo?.subscriber?.entitlements || {};
        const activeEntitlement = entitlementsList[draft.data.subscriptions.primary.entitlement];
        draft.data.subscriptions.primary.isActive = initialState.data.subscriptions.primary.isActive;
        if (activeEntitlement) {
          const checkExpiry = moment().isBefore(activeEntitlement.expires_date);
          const graceDate = activeEntitlement.grace_period_expires_date;
          const checkGrace = !!graceDate && moment().isBefore(graceDate);
          draft.data.subscriptions.primary.isActive = checkExpiry || checkGrace;
        }
      }
    })
  ),
  on(fromMonetizationActions.loadWebPurchaserInfoFailure, (state) =>
    produce(state, (draft) => {
      draft.data.isPurchaserInfoLoaded = initialState.data.isPurchaserInfoLoaded;
      draft.data.subscriptions.primary.isActive = initialState.data.subscriptions.primary.isActive;
      draft.data.web.purchaserInfo = initialState.data.web.purchaserInfo;
    })
  ),
  on(fromMonetizationActions.loadWebOfferingsInfoSuccess, (state, action) =>
    produce(state, (draft) => {
      const storeProductIdentifiers: string[] = [];

      if (!draft.data.isOfferingsLoaded) {
        draft.data.isOfferingsLoaded = true;
        draft.data.isLoaded = draft.data.isPurchaserInfoLoaded;
      }
      draft.data.web.offerings = action.offerings.offerings;
      draft.data.subscriptions.primary.package.identifier = initialState.data.subscriptions.primary.package.identifier;
      draft.data.subscriptions.primary.package.platformProductIdentifier =
        initialState.data.subscriptions.primary.package.platformProductIdentifier;
      if (draft.data.subscriptions.primary.offeringsIdentifier) {
        const offering = find(action.offerings.offerings, (item) => {
          return item.identifier === draft.data.subscriptions.primary.offeringsIdentifier;
        });

        if (offering) {
          const primarySubscriptionPackage = offering?.packages[0];
          if (primarySubscriptionPackage) {
            draft.data.subscriptions.primary.package.identifier = primarySubscriptionPackage.identifier;
            draft.data.subscriptions.primary.package.platformProductIdentifier =
              primarySubscriptionPackage.platform_product_identifier;

            storeProductIdentifiers.push(primarySubscriptionPackage.platform_product_identifier);
          }
        }
      }

      forEach(action.offerings.offerings, (offering) => {
        if (ENV.production && offering.identifier?.toLowerCase().includes('_test')) {
          return false;
        }

        forEach(offering.packages, (offeringPackage) => {
          const platformProductIdentifier = offeringPackage.platform_product_identifier;

          const check: MonetizationRepCodeInAppPurchase = find(
            action.inAppProducts.inAppPurchases,
            (inAppPurchase) =>
              !!find(
                inAppPurchase.storeIdentifiers,
                (storeIdentifier) => storeIdentifier === platformProductIdentifier && inAppPurchase.visibleInStore
              )
          );

          if (check) {
            storeProductIdentifiers.push(platformProductIdentifier);
          }
        });
      });

      draft.data.store.storeProductIdentifiers = storeProductIdentifiers;
    })
  ),
  on(fromMonetizationActions.loadWebOfferingsInfoFailure, (state) =>
    produce(state, (draft) => {
      draft.data.isOfferingsLoaded = initialState.data.isOfferingsLoaded;
      draft.data.web.offerings = initialState.data.web.offerings;
    })
  ),
  on(fromMonetizationActions.loadPrimarySubscriptionPackageCommonPlatformProductDetails, (state) =>
    produce(state, (draft) => {
      draft.data.subscriptions.primary.package.product.description =
        initialState.data.subscriptions.primary.package.product.description;
      draft.data.subscriptions.primary.package.product.isCommonDataLoaded =
        initialState.data.subscriptions.primary.package.product.isCommonDataLoaded;
      draft.data.subscriptions.primary.package.product.isPlatformDataLoaded =
        initialState.data.subscriptions.primary.package.product.price.isLoaded;
    })
  ),
  on(fromMonetizationActions.loadPrimarySubscriptionPackageCommonPlatformProductDetailsSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.data.subscriptions.primary.package.product.description = action.description;
      draft.data.subscriptions.primary.package.product.isCommonDataLoaded = true;
      draft.data.subscriptions.primary.package.product.isPlatformDataLoaded =
        draft.data.subscriptions.primary.package.product.price.isLoaded;
    })
  ),
  on(fromMonetizationActions.loadPrimarySubscriptionPackageWebPlatformProductDetails, (state) =>
    produce(state, (draft) => {
      draft.data.subscriptions.primary.package.product.name =
        initialState.data.subscriptions.primary.package.product.name;
      draft.data.subscriptions.primary.package.product.price.displayValue =
        initialState.data.subscriptions.primary.package.product.price.displayValue;
      draft.data.subscriptions.primary.package.product.time.displayValue =
        initialState.data.subscriptions.primary.package.product.time.displayValue;
      draft.data.subscriptions.primary.package.product.price.id =
        initialState.data.subscriptions.primary.package.product.price.id;
      draft.data.subscriptions.primary.package.product.isPlatformDataLoaded =
        initialState.data.subscriptions.primary.package.product.isPlatformDataLoaded;
      draft.data.subscriptions.primary.package.product.price.isLoaded =
        initialState.data.subscriptions.primary.package.product.price.isLoaded;
    })
  ),
  on(fromMonetizationActions.loadPrimarySubscriptionPackageWebPlatformProductDetailsSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.data.subscriptions.primary.package.product.name = action.monetizationStripeProduct.name;
      if (action.monetizationStripeProduct?.prices.length) {
        const priceData = action.monetizationStripeProduct.prices.find((price) => price.active);
        if (priceData) {
          let USDollar = new Intl.NumberFormat('en-US', {
            currency: (priceData.currency ?? 'USD').toUpperCase(),
            style: 'currency'
          });
          if (priceData.unit_amount) {
            draft.data.subscriptions.primary.package.product.price.displayValue = USDollar.format(
              priceData.unit_amount / 100
            );
          }
          if (priceData.type === 'recurring') {
            const period = moment.duration(priceData.recurring.interval_count, priceData.recurring.interval);
            if (period.isValid()) {
              draft.data.subscriptions.primary.package.product.time.displayValue = `per ${period.asMonths()} month${period.asMonths() > 1 ? 's' : ''}`;
            }
          }
          draft.data.subscriptions.primary.package.product.price.id =
            priceData?.id || initialState.data.subscriptions.primary.package.product.price.id;
          draft.data.subscriptions.primary.package.product.price.isLoaded = true;
          draft.data.subscriptions.primary.package.product.isPlatformDataLoaded =
            draft.data.subscriptions.primary.package.product.isCommonDataLoaded;
        }
      }
    })
  ),
  on(fromMonetizationActions.loadWebSubscriptions, (state) =>
    produce(state, (draft) => {
      draft.data.web.subscriptions.isLoading = true;
    })
  ),
  on(fromMonetizationActions.loadWebSubscriptionsSuccess, (state, payload) =>
    produce(state, (draft) => {
      draft.data.web.subscriptions.list = payload.stripeSubscriptions;
      draft.data.web.subscriptions.isLoading = false;
    })
  ),
  on(fromMonetizationActions.loadWebSubscriptionsFailure, (state) =>
    produce(state, (draft) => {
      draft.data.web.subscriptions.list = null;
      draft.data.web.subscriptions.isLoading = false;
    })
  ),
  on(fromMonetizationActions.setInAppPurchasesCurrentFarm, (state, action) =>
    produce(state, (draft) => {
      draft.data.inAppPurchases.farmPurchase.isEnabled = false;
      draft.data.inAppPurchases.farmPurchase.isMade = false;

      each(values(MonetizationFarmPropertiesPhoneAndEmailsInAppPurchaseTypes), (value) => {
        draft.data.inAppPurchases.farmPurchase.farmId = action.farm.id;
        if (has(action.farm, `inAppPurchases.${value}`)) {
          if (action.farm.inAppPurchases[value].isPurchased) {
            draft.data.inAppPurchases.farmPurchase.isMade = true;
          }
          if (draft.data.inAppPurchases.isEnabled && has(draft.data.inAppPurchases.products, value)) {
            draft.data.inAppPurchases.farmPurchase.isEnabled = true;
          }
        }
      });
    })
  ),
  on(fromMonetizationActions.restoreMonetization, (state, action) =>
    produce(state, (draft) => {
      if (!isEmpty(action.state) && has(action.state, 'data')) {
        draft.data = cloneDeep(action.state.data);

        if (!has(draft.data, 'store')) {
          draft.data.store = initialState.data.store;
        }

        if (!has(draft.data, 'viewedProduct')) {
          draft.data.viewedProduct = initialState.data.viewedProduct;
        }

        if (!has(draft.data.inAppPurchases.farmPurchase, 'phoneAndEmail')) {
          draft.data.inAppPurchases.farmPurchase.phoneAndEmail =
            initialState.data.inAppPurchases.farmPurchase.phoneAndEmail;
        }
      }
    })
  ),
  on(fromMonetizationActions.loadMobilePurchaserInfoSuccess, (state, action) =>
    produce(state, (draft) => {
      if (!draft.data.isPurchaserInfoLoaded) {
        draft.data.isPurchaserInfoLoaded = true;
        draft.data.isLoaded = draft.data.isOfferingsLoaded;
      }
      draft.data.mobile.purchaserInfo = action.customerInfo;
      draft.data.subscriptions.primary.isActive = initialState.data.subscriptions.primary.isActive;
      if (draft.data.subscriptions.primary.package.internalIdentifier) {
        const entitlementsList = action.customerInfo?.entitlements.active || {};
        const activeEntitlement = entitlementsList[draft.data.subscriptions.primary.entitlement];
        draft.data.subscriptions.primary.isActive = initialState.data.subscriptions.primary.isActive;
        if (activeEntitlement) {
          draft.data.subscriptions.primary.isActive = activeEntitlement.isActive;
        }
      }
    })
  ),
  on(fromMonetizationActions.loadMobilePurchaserInfoFailure, (state) =>
    produce(state, (draft) => {
      draft.data.isPurchaserInfoLoaded = initialState.data.isPurchaserInfoLoaded;
      draft.data.subscriptions.primary.isActive = initialState.data.subscriptions.primary.isActive;
      draft.data.mobile.purchaserInfo = initialState.data.mobile.purchaserInfo;
    })
  ),
  on(fromMonetizationActions.loadMobileOfferingsInfoSuccess, (state, action) =>
    produce(state, (draft) => {
      const storeProductIdentifiers: string[] = [];
      const productData: Partial<MonetizationStoreProduct>[] = [];

      if (!draft.data.isOfferingsLoaded) {
        draft.data.isOfferingsLoaded = true;
        draft.data.isLoaded = draft.data.isPurchaserInfoLoaded;
      }
      draft.data.mobile.offerings = action.purchasesOfferings;
      draft.data.subscriptions.primary.package.identifier = initialState.data.subscriptions.primary.package.identifier;
      draft.data.subscriptions.primary.package.platformProductIdentifier =
        initialState.data.subscriptions.primary.package.platformProductIdentifier;
      if (draft.data.subscriptions.primary.offeringsIdentifier) {
        const offering = find(
          action.purchasesOfferings.all,
          (item) => item.identifier === draft.data.subscriptions.primary.offeringsIdentifier
        );
        if (offering) {
          const primarySubscriptionPackage = offering?.availablePackages[0];
          if (primarySubscriptionPackage) {
            draft.data.subscriptions.primary.package.identifier = primarySubscriptionPackage.identifier;
            draft.data.subscriptions.primary.package.platformProductIdentifier =
              primarySubscriptionPackage.product.identifier;
            draft.data.subscriptions.primary.package.product.name = primarySubscriptionPackage.product.title;
            draft.data.subscriptions.primary.package.product.price.displayValue =
              primarySubscriptionPackage.product.priceString;
            draft.data.subscriptions.primary.package.product.time.displayValue =
              initialState.data.subscriptions.primary.package.product.time.displayValue;
            const period = moment.duration(primarySubscriptionPackage.product.subscriptionPeriod);
            if (period.isValid()) {
              draft.data.subscriptions.primary.package.product.time.displayValue = `per ${period.asMonths()} month${period.asMonths() > 1 ? 's' : ''}`;
            }
            draft.data.subscriptions.primary.package.product.price.isLoaded = true;
            draft.data.subscriptions.primary.package.product.isPlatformDataLoaded =
              draft.data.subscriptions.primary.package.product.isCommonDataLoaded;

            storeProductIdentifiers.push(primarySubscriptionPackage.product.identifier);
            productData.push({
              description: primarySubscriptionPackage.product.description,
              identifier: offering.identifier,
              internalIdentifier: draft.data.subscriptions.primary.package.internalIdentifier,
              isActive:
                draft.data.mobile.purchaserInfo.entitlements.active[draft.data.subscriptions.primary.entitlement]
                  ?.isActive,
              isAvailable: true,
              isConsumable: false,
              isSubscription: true,
              name: primarySubscriptionPackage.product.title,
              platformProductIdentifier: primarySubscriptionPackage.product.identifier,
              price: {
                displayValue: primarySubscriptionPackage.product.priceString,
                isLoaded: true
              },
              time: primarySubscriptionPackage.product.introPrice
                ? {
                    displayValue: `per ${period.asMonths()} month${period.asMonths() > 1 ? 's' : ''}`
                  }
                : null
            });
          }
        }
      }

      const offeringIdentifiers = Object.keys(action.purchasesOfferings.all);

      forEach(offeringIdentifiers, (identifier) => {
        const offering = action.purchasesOfferings.all[identifier];

        if (ENV.production && identifier.toLowerCase().includes('_test')) {
          return false;
        }

        forEach(offering.availablePackages, (offeringPackage) => {
          const platformProductIdentifier = offeringPackage.product.identifier;

          const check: MonetizationRepCodeInAppPurchase = find(
            action.inAppProducts.inAppPurchases,
            (inAppPurchase) =>
              !!find(
                inAppPurchase.storeIdentifiers,
                (storeIdentifier) => storeIdentifier === platformProductIdentifier && inAppPurchase.visibleInStore
              )
          );

          if (check) {
            const offeringProduct = offeringPackage.product as MonetizationRevenueCatStoreProduct;
            const isSubscription = offeringProduct.productCategory === PRODUCT_CATEGORY.SUBSCRIPTION;
            const isPurchased = !!draft.data.mobile.purchaserInfo.entitlements.active[offering.identifier];
            const period = moment.duration(offeringProduct.subscriptionPeriod);

            storeProductIdentifiers.push(offeringProduct.identifier);
            productData.push({
              description: offeringProduct.description,
              identifier: offering.identifier,
              internalIdentifier: check.identifier,
              isAvailable: true,
              isConsumable: check.consumable,
              isPurchased,
              isSubscription,
              name: offeringProduct.title,
              platformProductIdentifier: offeringProduct.identifier,
              price: {
                displayValue: offeringProduct.priceString,
                isLoaded: true
              },
              time: offeringProduct.introPrice
                ? {
                    displayValue: `per ${period.asMonths()} month${period.asMonths() > 1 ? 's' : ''}`
                  }
                : null
            });
          }
        });
      });

      draft.data.store.storeProductIdentifiers = storeProductIdentifiers;
      draft.data.store.productData =
        lodashFilter(productData, (product) => product.isAvailable || (!product.isAvailable && product.isPurchased)) ||
        [];
      draft.data.store.isProductDataLoaded = true;
      draft.data.isEnabled = !!productData.length;
    })
  ),
  on(fromMonetizationActions.loadMobileOfferingsInfoFailure, (state) =>
    produce(state, (draft) => {
      draft.data.isOfferingsLoaded = initialState.data.isOfferingsLoaded;
      draft.data.mobile.offerings = initialState.data.mobile.offerings;
    })
  ),
  on(fromMonetizationActions.purchaseMobileSubscription, (state) =>
    produce(state, (draft) => {
      draft.data.mobile.isPaymentLoading = true;
      draft.data.mobile.isPaymentSuccess = false;
    })
  ),
  on(fromMonetizationActions.purchaseMobileSubscriptionSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.data.mobile.isPaymentLoading = false;
      draft.data.mobile.isPaymentSuccess = true;
      draft.data.mobile.purchaserInfo = action.customerInfo;
      draft.data.subscriptions.primary.isActive = initialState.data.subscriptions.primary.isActive;
      if (draft.data.subscriptions.primary.package.internalIdentifier) {
        const entitlementsList = action.customerInfo?.entitlements.active || {};
        const activeEntitlement = entitlementsList[draft.data.subscriptions.primary.entitlement];
        draft.data.subscriptions.primary.isActive = initialState.data.subscriptions.primary.isActive;
        if (activeEntitlement) {
          draft.data.subscriptions.primary.isActive = activeEntitlement.isActive;
        }
      }
    })
  ),
  on(fromMonetizationActions.purchaseMobileSubscriptionFailure, (state) =>
    produce(state, (draft) => {
      draft.data.mobile.isPaymentLoading = false;
      draft.data.mobile.isPaymentSuccess = false;
    })
  ),
  on(fromMonetizationActions.purchaseMobileProductSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.data.mobile.purchaserInfo = action.customerInfo;
      draft.data.subscriptions.primary.isActive = initialState.data.subscriptions.primary.isActive;
      if (draft.data.subscriptions.primary.package.internalIdentifier) {
        const entitlementsList = action.customerInfo?.entitlements?.all || {};
        const activeEntitlement = entitlementsList[draft.data.subscriptions.primary.entitlement];
        draft.data.subscriptions.primary.isActive = initialState.data.subscriptions.primary.isActive;
        if (activeEntitlement) {
          draft.data.subscriptions.primary.isActive = activeEntitlement.isActive;
        }
      }
    })
  ),
  on(fromMonetizationActions.loadStoreProducts, (state) =>
    produce(state, (draft) => {
      draft.data.store.isProductDataLoaded = false;
    })
  ),
  on(fromMonetizationActions.loadWebStoreProductsSuccess, (state, action) =>
    produce(state, (draft) => {
      const data: Partial<MonetizationStoreProduct>[] = [];

      if (state.data.subscriptions.primary.accessAlias) {
        data.push({
          identifier: state.data.subscriptions.primary.entitlement,
          internalIdentifier: state.data.subscriptions.primary.accessAlias,
          isActive: state.data.subscriptions.primary.isActive,
          isConsumable: false,
          isSubscription: true,
          platformProductIdentifier: state.data.subscriptions.primary.package.platformProductIdentifier
        });
      }

      forEach(draft.data.web.offerings, (offering) => {
        if (ENV.production && offering.identifier?.toLowerCase().includes('_test')) {
          return false;
        }

        forEach(offering.packages, (offeringPackage) => {
          const platformProductIdentifier = offeringPackage.platform_product_identifier;

          const check: MonetizationRepCodeInAppPurchase = find(
            action.inAppProducts.inAppPurchases,
            (inAppPurchase) =>
              !!find(
                inAppPurchase.storeIdentifiers,
                (storeIdentifier) => storeIdentifier === platformProductIdentifier && inAppPurchase.visibleInStore
              )
          );

          if (check) {
            data.push({
              identifier: offering.identifier,
              internalIdentifier: check.identifier,
              isConsumable: check.consumable,
              isPurchased: !!state.data.web.purchaserInfo.subscriber.non_subscriptions[platformProductIdentifier],
              isSubscription: false,
              platformProductIdentifier
            });
          }
        });
      });

      const productData = map(
        map(action.stripeProducts, (product): Partial<MonetizationStoreProduct> => {
          const priceData = find(product.prices, (price) => price.active);

          const USDollar = new Intl.NumberFormat('en-US', {
            currency: (priceData.currency ?? 'USD').toUpperCase(),
            style: 'currency'
          });
          const period = moment.duration(priceData.recurring?.interval_count, priceData.recurring?.interval);

          return {
            description: product.description,
            name: product.name,
            platformProductIdentifier: product.id,
            price: {
              displayValue: USDollar.format(priceData?.unit_amount / 100),
              id: priceData?.id || null,
              isLoaded: !!priceData
            },
            time:
              priceData?.type === 'recurring'
                ? {
                    displayValue: `per ${period.asMonths()} month${period.asMonths() > 1 ? 's' : ''}`
                  }
                : null
          };
        }),
        (product): Partial<MonetizationStoreProduct> =>
          defaultsDeep(
            product,
            data.find((item) => item.platformProductIdentifier === product.platformProductIdentifier) || {},
            {
              isAvailable:
                product.isConsumable || ((!product.isConsumable || product.isSubscription) && !product.isPurchased)
            }
          )
      );

      draft.data.store.productData =
        lodashFilter(productData, (product) => product.isAvailable || (!product.isAvailable && product.isPurchased)) ||
        [];
      draft.data.store.isProductDataLoaded = true;
      draft.data.isEnabled = !!draft.data.store.productData.length;
    })
  ),
  on(fromMonetizationActions.loadWebStoreProductsFailure, (state) =>
    produce(state, (draft) => {
      draft.data.store.isProductDataLoaded = false;
    })
  ),
  on(fromMonetizationActions.loadMobileStoreProductsSuccess, (state) =>
    produce(state, (draft) => {
      draft.data.store.isProductDataLoaded = true;
    })
  ),
  on(fromMonetizationActions.loadMobileStoreProductsFailure, (state) =>
    produce(state, (draft) => {
      draft.data.store.isProductDataLoaded = false;
    })
  ),
  on(fromMonetizationActions.loadProductDetails, (state) =>
    produce(state, (draft) => {
      draft.data.viewedProduct.isLoading = true;
    })
  ),
  on(fromMonetizationActions.loadWebProductDetailsSuccess, (state, action) =>
    produce(state, (draft) => {
      const stripeProduct = action.monetizationStripeProduct;
      const priceData = find(stripeProduct.prices, (price) => price.active);
      const storeProduct = find(
        action.storeProductsData,
        (storeProduct) => stripeProduct.id === storeProduct.platformProductIdentifier
      );

      const USDollar = new Intl.NumberFormat('en-US', {
        currency: (priceData.currency ?? 'USD').toUpperCase(),
        style: 'currency'
      });
      const period = moment.duration(priceData.recurring?.interval_count, priceData.recurring?.interval);

      if (storeProduct) {
        draft.data.viewedProduct.data = {
          identifier: storeProduct.identifier,
          internalIdentifier: storeProduct.internalIdentifier,
          isConsumable: storeProduct.isConsumable,
          isSubscription: storeProduct?.isSubscription || false,
          platformProductIdentifier: stripeProduct.id,
          product: {
            description: stripeProduct.description,
            isCommonDataLoaded: true,
            isPlatformDataLoaded: true,
            name: stripeProduct.name,
            price: {
              displayValue: USDollar.format(priceData?.unit_amount / 100),
              id: priceData?.id || null,
              isLoaded: !!priceData
            },
            time:
              priceData?.type === 'recurring'
                ? {
                    displayValue: `per ${period.asMonths()} month${period.asMonths() > 1 ? 's' : ''}`
                  }
                : null
          },
          ...(storeProduct.isSubscription
            ? { isActive: storeProduct.isActive }
            : { isPurchased: storeProduct.isPurchased })
        };
      } else {
        draft.data.viewedProduct.data = null;
      }

      draft.data.viewedProduct.isLoading = false;
    })
  ),
  on(fromMonetizationActions.loadWebProductDetailsFailure, (state) =>
    produce(state, (draft) => {
      draft.data.viewedProduct.data = null;
      draft.data.viewedProduct.isLoading = false;
    })
  ),
  on(fromMonetizationActions.loadMobileProductDetails, (state, action) =>
    produce(state, (draft) => {
      const product = find(
        draft.data.store.productData,
        (product) => action.productId === product.platformProductIdentifier
      );

      if (product) {
        draft.data.viewedProduct.data = {
          identifier: product.identifier,
          internalIdentifier: product.internalIdentifier,
          isConsumable: product.isConsumable,
          isSubscription: product.isSubscription,
          platformProductIdentifier: product.platformProductIdentifier,
          product: {
            description: product.description,
            isCommonDataLoaded: true,
            isPlatformDataLoaded: true,
            name: product.name,
            price: {
              displayValue: product.price.displayValue,
              id: product.price.id,
              isLoaded: true
            },
            time: product.time
          },
          ...(product.isSubscription ? { isActive: product.isActive } : { isPurchased: product.isPurchased })
        };
      } else {
        draft.data.viewedProduct.data = null;
      }
      draft.data.viewedProduct.isLoading = false;
    })
  ),
  on(fromMonetizationActions.setIsRevenueCatUserLoggedIn, (state, action) =>
    produce(state, (draft) => {
      draft.data.isRevenueCatUserLoggedIn = action.isRevenueCatUserLoggedIn;
    })
  ),
  on(fromMonetizationActions.loadVirtualCurrencyPreview, (state) =>
    produce(state, (draft) => {
      draft.data.inAppPurchases.farmPurchase.phoneAndEmail.isPurchaseCompleted = false;
      draft.data.inAppPurchases.farmPurchase.phoneAndEmail.isPurchaseSuccess = false;
    })
  ),
  on(fromMonetizationActions.loadVirtualCurrencyPreviewSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.data.inAppPurchases.farmPurchase.phoneAndEmail.preview.badgerBucks.cost = action.preview.badgerBucks.cost;
      draft.data.inAppPurchases.farmPurchase.phoneAndEmail.preview.badgerBucks.available =
        action.preview.badgerBucks.available;
      draft.data.inAppPurchases.farmPurchase.phoneAndEmail.preview.canPurchase = action.preview.canPurchase;
      draft.data.inAppPurchases.farmPurchase.phoneAndEmail.preview.isLoaded = true;
    })
  ),
  on(fromMonetizationActions.loadVirtualCurrencyPreviewFailure, (state) =>
    produce(state, (draft) => {
      draft.data.inAppPurchases.farmPurchase.phoneAndEmail.preview.badgerBucks.cost = 0;
      draft.data.inAppPurchases.farmPurchase.phoneAndEmail.preview.badgerBucks.available = 0;
      draft.data.inAppPurchases.farmPurchase.phoneAndEmail.preview.canPurchase = false;
      draft.data.inAppPurchases.farmPurchase.phoneAndEmail.preview.isLoaded = false;
    })
  ),
  on(fromMonetizationActions.loadVirtualCurrencyPurchaseSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.data.inAppPurchases.farmPurchase.phoneAndEmail.isPurchaseCompleted = true;
      draft.data.inAppPurchases.farmPurchase.phoneAndEmail.isPurchaseSuccess = action.success;
    })
  ),
  on(fromMonetizationActions.loadVirtualCurrencyPurchaseFailure, (state) =>
    produce(state, (draft) => {
      draft.data.inAppPurchases.farmPurchase.phoneAndEmail.isPurchaseCompleted = false;
      draft.data.inAppPurchases.farmPurchase.phoneAndEmail.isPurchaseSuccess = false;
    })
  ),
  on(fromMonetizationActions.resetVirtualCurrencyPreview, (state) =>
    produce(state, (draft) => {
      draft.data.inAppPurchases.farmPurchase.phoneAndEmail =
        initialState.data.inAppPurchases.farmPurchase.phoneAndEmail;
    })
  )
);
