import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { of, throwError } from 'rxjs';
import { catchError, concatMap, finalize } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import { loaderIgnoreKey } from '../../../constants/loading';

import { LoadingService } from './loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(
    private loadingService: LoadingService,
    private ngZone: NgZone
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const ignoreLoader = request.headers.get(loaderIgnoreKey);
    if (ignoreLoader !== 'true') {
      const id = uuidV4();
      this.loadingService.setLoading(id, true);
      return next.handle(request).pipe(
        concatMap((event) => {
          if (event instanceof HttpResponse) {
            this.ngZone.run(() => {
              this.loadingService.setLoading(id, false);
            });
          }
          return of(event);
        }),
        catchError((error) => {
          this.ngZone.run(() => {
            this.loadingService.setLoading(id, false);
          });
          return throwError(error);
        }),
        finalize(() => {
          this.ngZone.run(() => {
            this.loadingService.setLoading(id, false);
          });
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
