import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';

import ContactsX, { ContactX, ContactXPermissionResult } from 'cordova-plugin-contacts-x';

import { LoggerService } from '../logger/logger.service';
import { PlatformDetectService } from '../platform-detect/platform-detect.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  private isMobilePlatform = true;

  constructor(
    private platform: Platform,
    private alertController: AlertController,
    private platformDetectService: PlatformDetectService,
    private loggerService: LoggerService
  ) {
    this.platformDetectService.getIsMobileSubscription().subscribe((isMobilePlatform) => {
      this.isMobilePlatform = isMobilePlatform;
    });
  }

  async addContact(contact: ContactX) {
    try {
      if (this.isMobilePlatform) {
        await this.platform.ready();
        let { write } = await this.contactHasPermission();

        if (!write) {
          write = (await this.contactRequestPermission()).write;
          if (!this.platform.is('ios')) {
            write = (await this.contactRequestWritePermission()).write;
          }
        }

        if (write) {
          await this.contactSave(contact);
          await this.showAlert('The contact was successfully added.');
        } else {
          await this.showAlert(
            'The contact could not be added. The Application needs permission to access your Contacts.'
          );
        }
      }
    } catch (error) {
      await this.showAlert('The contact could not be added due to a error.');
      this.loggerService.logPapertrailError(error);
    }
  }

  private async showAlert(message: string) {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      buttons: [
        {
          role: 'cancel',
          text: 'Ok'
        }
      ],
      message: message
    });

    await alert.present();
  }

  private contactHasPermission() {
    return new Promise<ContactXPermissionResult>((resolve, reject) => {
      ContactsX.hasPermission(resolve, reject);
    });
  }

  private contactRequestPermission() {
    return new Promise<ContactXPermissionResult>((resolve, reject) => {
      ContactsX.requestPermission(resolve, reject);
    });
  }

  private contactRequestWritePermission() {
    return new Promise<ContactXPermissionResult>((resolve, reject) => {
      ContactsX.requestWritePermission(resolve, reject);
    });
  }

  private contactSave(contact: ContactX) {
    return new Promise<ContactX>((resolve, reject) => {
      ContactsX.save(contact, resolve, reject);
    });
  }
}
