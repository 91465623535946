import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ADMIN_PORTAL_CONFIG } from '../../../environments/environment';
import { adminPortalEndpoints } from '../../constants/admin-portal';

import { ApiService } from '../../common/services/api/api.service';
import { SecureStorageService } from '../../common/services/secure-storage/secure-storage.service';

import * as fromConfigActions from '../actions/config.actions';
import { configFeatureKey } from '../reducers/config.reducer';

@Injectable()
export class ConfigEffects {
  loadConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromConfigActions.loadConfig),
      mergeMap(() =>
        this.apiService
          .sendRequest({
            method: 'GET',
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.systemConfigurations}/Mobile`
          })
          .pipe(
            map((data) => fromConfigActions.loadConfigSuccess(data)),
            catchError((error) =>
              of(
                fromConfigActions.loadConfigFailure({
                  error: `Failed to connect to our services (${error.message})`
                })
              )
            )
          )
      )
    )
  );

  loadConfigSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromConfigActions.loadConfigSuccess),
        map((data) =>
          this.secureStorageService.updateInternalStorage({
            [configFeatureKey]: {
              term: {
                description: data.term.description,
                revisionDate: data.term.revisionDate,
                version: data.term.version
              }
            }
          })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private secureStorageService: SecureStorageService
  ) {}
}
