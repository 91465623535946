<div class="smd-pagination-wrapper tw-flex tw-w-full" *ngIf="!isMobileKeyboardOpened">
  <ion-button
    fill="clear"
    class="ion-no-padding ion-text-uppercase tw-my-auto tw-flex-auto"
    [disabled]="!hasPrev"
    (click)="prev()"
  >
    <ion-text color="light">Prev</ion-text>
  </ion-button>

  <div class="tw-flex tw-flex-auto tw-items-center tw-text-white">
    <ion-text class="tw-flex-auto tw-text-right">{{ current }}</ion-text>
    <ion-text class="tw-flex-auto tw-text-center"> of </ion-text>
    <ion-text class="tw-flex-auto">{{ lastItem }}</ion-text>
  </div>

  <ion-button
    fill="clear"
    class="ion-no-padding ion-text-uppercase tw-my-auto tw-flex-auto"
    [disabled]="!hasNext"
    (click)="next()"
  >
    <ion-text color="light">Next</ion-text>
  </ion-button>
</div>
