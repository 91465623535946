export enum DrawFarmModes {
  Polygon = 'polygon',
  Radius = 'radius'
}

export enum ReportSummaryTabType {
  FarmDetails = 'farmDetails',
  ListView = 'listView',
  MapView = 'mapView'
}
