import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import * as fromAppConfigActions from './store/actions/app-config/general-info';
import * as fromAppConfig from './store/selectors/app-config/index';
import * as fromAppConfigSelectors from './store/selectors/app-config/index';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  repCode: string;

  constructor(
    private platform: Platform,
    private store: Store<fromAppConfig.State>
  ) {
    this.store
      .select(fromAppConfigSelectors.getGeneralInfoAuthData)
      .pipe(filter((authData) => !!authData?.repCode))
      .subscribe((authData) => {
        this.repCode = authData.repCode;
      });

    this.platform.ready().then(() => {
      this.platform.resume.subscribe(() => {
        this.updateRepData();
      });
    });
  }

  updateRepData() {
    if (this.repCode) {
      this.store.dispatch(
        new fromAppConfigActions.GetAppSettingsData({
          repCode: this.repCode
        })
      );
    }
  }
}
