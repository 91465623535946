import { Action } from '@ngrx/store';

import { ProfileHistoryItem, PropertyProfileData, PropertyReport } from '../../../typings/property-profile';
import { AppUser, UserFarmAlertSettings } from '../../../typings/user';
import { CreateFarmData, FarmData, FarmPropertyAlert, PropertyData } from '../../../typings/walking-farm';

export enum ParseActionTypes {
  CreateFarmReport = '[Parse] Create Farm Report',
  CreateFarmReportFail = '[Parse] Create Farm Report Fail',
  CreateFarmReportSuccess = '[Parse] Create Farm Report Success',
  CreateUser = '[Parse] Create User',
  CreateUserFail = '[Parse] Create User Fail',
  CreateUserSuccess = '[Parse] Create User Success',
  DeleteFarmReport = '[Parse] Delete Farm Report',
  DeleteFarmReportFail = '[Parse] Delete Farm Report Fail',
  DeleteFarmReportSuccess = '[Parse] Delete Farm Report Success',
  DeletePropertyProfile = '[Parse] Delete Property Profile',
  DeletePropertyProfileFail = '[Parse] Delete Property Profile Fail',
  DeletePropertyProfileSuccess = '[Parse] Delete Property Profile Success',
  GetFarmAlertSettings = '[Parse] Get Farm Alert Settings',
  GetFarmAlertSettingsFail = '[Parse] Get Farm Alert Settings Fail',
  GetFarmAlertSettingsSuccess = '[Parse] Get Farm Alert Settings Success',
  GetFarmAlertsForFarm = '[Parse] Get Farm Alerts For a Farm',
  GetFarmAlertsForFarmFail = '[Parse] Get Farm Alerts For a Farm Fail',
  GetFarmAlertsForFarmSuccess = '[Parse] Get Farm Alerts For a Farm Success',
  GetFarmReport = '[Parse] Get Farm Report',
  GetFarmReportFail = '[Parse] Get Farm Report Fail',
  GetFarmReportSuccess = '[Parse] Get Farm Report Success',
  GetProfileHistory = '[Parse] Get Profile History',
  GetProfileHistoryFail = '[Parse] Get Profile History Fail',
  GetProfileHistorySuccess = '[Parse] Get Profile History Success',
  GetPropertyProfile = '[Parse] Get Property Profile',
  GetPropertyProfileFail = '[Parse] Get Property Profile Fail',
  GetPropertyProfileSuccess = '[Parse] Get Property Profile Success',
  GetRegisteredUser = '[Parse] Get Registered User',
  GetRegisteredUserFail = '[Parse] Get Registered User Fail',
  GetRegisteredUserSuccess = '[Parse] Get Registered User Success',
  GetSavedFarmReports = '[Parse] Get Saved Farm Reports',
  GetSavedFarmReportsFail = '[Parse] Get Saved Farm Reports Fail',
  GetSavedFarmReportsSuccess = '[Parse] Get Saved Farm Reports Success',
  GetUser = '[Parse] Get User',
  GetUserFail = '[Parse] Get User Fail',
  GetUserSuccess = '[Parse] Get User Success',
  MarkFarmAlertAsRead = '[Parse] Mark Farm Alert As Read',
  MarkFarmAlertAsReadFail = '[Parse] Mark Farm Alert As Read Fail',
  MarkFarmAlertAsReadSuccess = '[Parse] Mark Farm Alert As Read Success',
  MarkMultipleFarmAlertsAsRead = '[Parse] Mark Multiple Farm Alerts As Read',
  MarkMultipleFarmAlertsAsReadFail = '[Parse] Mark Multiple Farm Alerts As Read Fail',
  MarkMultipleFarmAlertsAsReadSuccess = '[Parse] Mark Multiple Farm Alerts As Read Success',
  ResetParseCallsState = '[Parse] Reset Parse Calls State',
  SavePropertyProfile = '[Parse] Save Property Profile',
  SavePropertyProfileFail = '[Parse] Save Property Profile Fail',
  SavePropertyProfileSuccess = '[Parse] Save Property Profile Success',
  ToggleFarmAlertSubscription = '[Parse] Toggle Farm Alert Subscription',
  ToggleFarmAlertSubscriptionFail = '[Parse] Toggle Farm Alert Subscription Fail',
  ToggleFarmAlertSubscriptionSuccess = '[Parse] Toggle Farm Alert Subscription Success',
  UpdateFarmReportProperties = '[Parse] Update Farm Report Properties',
  UpdateFarmReportPropertiesFail = '[Parse] Update Farm Report Properties Fail',
  UpdateFarmReportPropertiesSuccess = '[Parse] Update Farm Report Properties Success',
  UpdateUser = '[Parse] Update User',
  UpdateUserFail = '[Parse] Update User Fail',
  UpdateUserFarmAlertSettings = '[Parse] Update User Farm Alert Settings',
  UpdateUserFarmAlertSettingsFail = '[Parse] Update User Farm Alert Settings Fail',
  UpdateUserFarmAlertSettingsSuccess = '[Parse] Update User Farm Alert Settings Success',
  UpdateUserInstallationId = '[Parse] Update User Installation Id',
  UpdateUserInstallationIdFail = '[Parse] Update User Installation Id Fail',
  UpdateUserInstallationIdSuccess = '[Parse] Update User Installation Id Success',
  UpdateUserSuccess = '[Parse] Update User Success',
  UpdateUserToken = '[Parse] Update User Token',
  UpdateUserTokenFail = '[Parse] Update User Token Fail',
  UpdateUserTokenSuccess = '[Parse] Update User Token Success'
}

export class GetPropertyProfile implements Action {
  readonly type = ParseActionTypes.GetPropertyProfile;
  constructor(public payload: string) {}
}
export class GetPropertyProfileSuccess implements Action {
  readonly type = ParseActionTypes.GetPropertyProfileSuccess;
  constructor(public payload: PropertyReport) {}
}
export class GetPropertyProfileFail implements Action {
  readonly type = ParseActionTypes.GetPropertyProfileFail;
  constructor(public payload: Error) {}
}

export class SavePropertyProfile implements Action {
  readonly type = ParseActionTypes.SavePropertyProfile;
  constructor(public payload: { d: PropertyProfileData }) {}
}
export class SavePropertyProfileSuccess implements Action {
  readonly type = ParseActionTypes.SavePropertyProfileSuccess;
  constructor(public payload: { changes: Partial<ProfileHistoryItem>; id: string }) {}
}
export class SavePropertyProfileFail implements Action {
  readonly type = ParseActionTypes.SavePropertyProfileFail;
  constructor(public payload: Error) {}
}

export class GetProfileHistory implements Action {
  readonly type = ParseActionTypes.GetProfileHistory;
  constructor() {}
}
export class GetProfileHistorySuccess implements Action {
  readonly type = ParseActionTypes.GetProfileHistorySuccess;
  constructor(public payload: ProfileHistoryItem[]) {}
}
export class GetProfileHistoryFail implements Action {
  readonly type = ParseActionTypes.GetProfileHistoryFail;
  constructor(public payload: Error) {}
}

export class DeletePropertyProfile implements Action {
  readonly type = ParseActionTypes.DeletePropertyProfile;
  constructor(public payload: string) {}
}
export class DeletePropertyProfileSuccess implements Action {
  readonly type = ParseActionTypes.DeletePropertyProfileSuccess;
  constructor(public payload: string) {}
}
export class DeletePropertyProfileFail implements Action {
  readonly type = ParseActionTypes.DeletePropertyProfileFail;
  constructor(public payload: { error: Error; id: string }) {}
}

export class GetFarmReport implements Action {
  readonly type = ParseActionTypes.GetFarmReport;
  constructor(public payload: string) {}
}
export class GetFarmReportSuccess implements Action {
  readonly type = ParseActionTypes.GetFarmReportSuccess;
  constructor(public payload: FarmData) {}
}
export class GetFarmReportFail implements Action {
  readonly type = ParseActionTypes.GetFarmReportFail;
  constructor(public payload: Error) {}
}

export class GetSavedFarmReports implements Action {
  readonly type = ParseActionTypes.GetSavedFarmReports;
  constructor() {}
}
export class GetSavedFarmReportsSuccess implements Action {
  readonly type = ParseActionTypes.GetSavedFarmReportsSuccess;
  constructor(public payload: FarmData[]) {}
}
export class GetSavedFarmReportsFail implements Action {
  readonly type = ParseActionTypes.GetSavedFarmReportsFail;
  constructor(public payload: Error) {}
}

export class CreateFarmReport implements Action {
  readonly type = ParseActionTypes.CreateFarmReport;
  constructor(public payload: CreateFarmData) {}
}
export class CreateFarmReportSuccess implements Action {
  readonly type = ParseActionTypes.CreateFarmReportSuccess;
  constructor(public payload: { changes: Partial<FarmData>; id: string }) {}
  d;
}
export class CreateFarmReportFail implements Action {
  readonly type = ParseActionTypes.CreateFarmReportFail;
  constructor(public payload: Error) {}
}

export class DeleteFarmReport implements Action {
  readonly type = ParseActionTypes.DeleteFarmReport;
  constructor(public payload: string) {}
}
export class DeleteFarmReportSuccess implements Action {
  readonly type = ParseActionTypes.DeleteFarmReportSuccess;
  constructor(public payload: string) {}
}
export class DeleteFarmReportFail implements Action {
  readonly type = ParseActionTypes.DeleteFarmReportFail;
  constructor(
    public payload: {
      error: Error;
      farmReportId: string;
    }
  ) {}
}

export class UpdateFarmReportProperties implements Action {
  readonly type = ParseActionTypes.UpdateFarmReportProperties;
  constructor(public payload: { farmId: string; properties: PropertyData[] }) {}
}
export class UpdateFarmReportPropertiesSuccess implements Action {
  readonly type = ParseActionTypes.UpdateFarmReportPropertiesSuccess;
  constructor() {}
}
export class UpdateFarmReportPropertiesFail implements Action {
  readonly type = ParseActionTypes.UpdateFarmReportPropertiesFail;
  constructor(public payload: Error) {}
}

export class GetFarmAlertsForFarm implements Action {
  readonly type = ParseActionTypes.GetFarmAlertsForFarm;
  constructor(public payload: string) {}
}
export class GetFarmAlertsForFarmSuccess implements Action {
  readonly type = ParseActionTypes.GetFarmAlertsForFarmSuccess;
  constructor(public payload: FarmPropertyAlert[]) {}
}
export class GetFarmAlertsForFarmFail implements Action {
  readonly type = ParseActionTypes.GetFarmAlertsForFarmFail;
  constructor(public payload: Error) {}
}

export class MarkFarmAlertAsRead implements Action {
  readonly type = ParseActionTypes.MarkFarmAlertAsRead;
  constructor(public payload: string) {}
}
export class MarkFarmAlertAsReadSuccess implements Action {
  readonly type = ParseActionTypes.MarkFarmAlertAsReadSuccess;
  constructor(public payload: string) {}
}
export class MarkFarmAlertAsReadFail implements Action {
  readonly type = ParseActionTypes.MarkFarmAlertAsReadFail;
  constructor(public payload: Error) {}
}

export class MarkMultipleFarmAlertsAsRead implements Action {
  readonly type = ParseActionTypes.MarkMultipleFarmAlertsAsRead;
  constructor(public payload: string[]) {}
}
export class MarkMultipleFarmAlertsAsReadSuccess implements Action {
  readonly type = ParseActionTypes.MarkMultipleFarmAlertsAsReadSuccess;
  constructor(public payload: string[]) {}
}
export class MarkMultipleFarmAlertsAsReadFail implements Action {
  readonly type = ParseActionTypes.MarkMultipleFarmAlertsAsReadFail;
  constructor(public payload: Error) {}
}

export class ToggleFarmAlertSubscription implements Action {
  readonly type = ParseActionTypes.ToggleFarmAlertSubscription;
  constructor(public payload: { farmObjectId: string; hasFarmAlerts: boolean }) {}
}
export class ToggleFarmAlertSubscriptionSuccess implements Action {
  readonly type = ParseActionTypes.ToggleFarmAlertSubscriptionSuccess;
  constructor(public payload: FarmData) {}
}
export class ToggleFarmAlertSubscriptionFail implements Action {
  readonly type = ParseActionTypes.ToggleFarmAlertSubscriptionFail;
  constructor(public payload: Error) {}
}

export class GetUser implements Action {
  readonly type = ParseActionTypes.GetUser;
  constructor(public payload: { appName: string; email: string }) {}
}
export class GetUserSuccess implements Action {
  readonly type = ParseActionTypes.GetUserSuccess;
  constructor(public payload: AppUser) {}
}
export class GetUserFail implements Action {
  readonly type = ParseActionTypes.GetUserFail;
  constructor(public payload: Error) {}
}

export class GetRegisteredUser implements Action {
  readonly type = ParseActionTypes.GetRegisteredUser;
  constructor(public payload: { appName: string; email: string }) {}
}
export class GetRegisteredUserSuccess implements Action {
  readonly type = ParseActionTypes.GetRegisteredUserSuccess;
  constructor(public payload: Partial<AppUser>) {}
}
export class GetRegisteredUserFail implements Action {
  readonly type = ParseActionTypes.GetRegisteredUserFail;
  constructor(public payload: string) {}
}

export class CreateUser implements Action {
  readonly type = ParseActionTypes.CreateUser;
  constructor() {}
}
export class CreateUserSuccess implements Action {
  readonly type = ParseActionTypes.CreateUserSuccess;
  constructor(public payload: { id: string }) {}
}
export class CreateUserFail implements Action {
  readonly type = ParseActionTypes.CreateUserFail;
  constructor(public payload: Error) {}
}

export class UpdateUser implements Action {
  readonly type = ParseActionTypes.UpdateUser;
  constructor(public payload: { company: string; firstName: string; lastName: string; phone: string }) {}
}
export class UpdateUserSuccess implements Action {
  readonly type = ParseActionTypes.UpdateUserSuccess;
  constructor(public payload: Partial<AppUser>) {}
}
export class UpdateUserFail implements Action {
  readonly type = ParseActionTypes.UpdateUserFail;
  constructor(public payload: Error) {}
}

export class UpdateUserInstallationId implements Action {
  readonly type = ParseActionTypes.UpdateUserInstallationId;
  constructor(public payload: { installationId: string }) {}
}
export class UpdateUserInstallationIdSuccess implements Action {
  readonly type = ParseActionTypes.UpdateUserInstallationIdSuccess;
  constructor(public payload: string) {}
}
export class UpdateUserInstallationIdFail implements Action {
  readonly type = ParseActionTypes.UpdateUserInstallationIdFail;
  constructor(public payload: Error) {}
}

export class UpdateUserToken implements Action {
  readonly type = ParseActionTypes.UpdateUserToken;
  constructor(public payload: string) {}
}
export class UpdateUserTokenSuccess implements Action {
  readonly type = ParseActionTypes.UpdateUserTokenSuccess;
  constructor(public payload: string) {}
}
export class UpdateUserTokenFail implements Action {
  readonly type = ParseActionTypes.UpdateUserTokenFail;
  constructor(public payload: Error) {}
}

export class ResetParseCallsState implements Action {
  readonly type = ParseActionTypes.ResetParseCallsState;
  constructor() {}
}

export class GetFarmAlertSettings implements Action {
  readonly type = ParseActionTypes.GetFarmAlertSettings;

  constructor() {}
}
export class GetFarmAlertSettingsSuccess implements Action {
  readonly type = ParseActionTypes.GetFarmAlertSettingsSuccess;

  constructor(public payload: UserFarmAlertSettings) {}
}
export class GetFarmAlertSettingsFail implements Action {
  readonly type = ParseActionTypes.GetFarmAlertSettingsFail;

  constructor(public payload: Error) {}
}

export class UpdateUserFarmAlertSettings implements Action {
  readonly type = ParseActionTypes.UpdateUserFarmAlertSettings;

  constructor(public payload: UserFarmAlertSettings) {}
}
export class UpdateUserFarmAlertSettingsSuccess implements Action {
  readonly type = ParseActionTypes.UpdateUserFarmAlertSettingsSuccess;

  constructor() {}
}
export class UpdateUserFarmAlertSettingsFail implements Action {
  readonly type = ParseActionTypes.UpdateUserFarmAlertSettingsFail;

  constructor(public payload: Error) {}
}

export type ParseActions =
  | GetPropertyProfile
  | GetPropertyProfileSuccess
  | GetPropertyProfileFail
  | SavePropertyProfile
  | SavePropertyProfileSuccess
  | SavePropertyProfileFail
  | GetProfileHistory
  | GetProfileHistorySuccess
  | GetProfileHistoryFail
  | DeletePropertyProfile
  | DeletePropertyProfileSuccess
  | DeletePropertyProfileFail
  | GetFarmReport
  | GetFarmReportSuccess
  | GetFarmReportFail
  | GetSavedFarmReports
  | GetSavedFarmReportsSuccess
  | GetSavedFarmReportsFail
  | CreateFarmReport
  | CreateFarmReportSuccess
  | CreateFarmReportFail
  | DeleteFarmReport
  | DeleteFarmReportSuccess
  | DeleteFarmReportFail
  | UpdateFarmReportProperties
  | UpdateFarmReportPropertiesSuccess
  | UpdateFarmReportPropertiesFail
  | GetFarmAlertsForFarm
  | GetFarmAlertsForFarmSuccess
  | GetFarmAlertsForFarmFail
  | MarkFarmAlertAsRead
  | MarkFarmAlertAsReadSuccess
  | MarkFarmAlertAsReadFail
  | MarkMultipleFarmAlertsAsRead
  | MarkMultipleFarmAlertsAsReadSuccess
  | MarkMultipleFarmAlertsAsReadFail
  | ToggleFarmAlertSubscription
  | ToggleFarmAlertSubscriptionSuccess
  | ToggleFarmAlertSubscriptionFail
  | GetRegisteredUser
  | GetRegisteredUserSuccess
  | GetRegisteredUserFail
  | GetUser
  | GetUserSuccess
  | GetUserFail
  | CreateUser
  | CreateUserSuccess
  | CreateUserFail
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserFail
  | UpdateUserInstallationId
  | UpdateUserInstallationIdSuccess
  | UpdateUserInstallationIdFail
  | UpdateUserToken
  | UpdateUserTokenSuccess
  | UpdateUserTokenFail
  | ResetParseCallsState
  | UpdateUserFarmAlertSettings
  | UpdateUserFarmAlertSettingsSuccess
  | UpdateUserFarmAlertSettingsFail
  | GetFarmAlertSettings
  | GetFarmAlertSettingsSuccess
  | GetFarmAlertSettingsFail;
