<ng-container>
  <ng-content></ng-content>

  <agm-map
    #agmMap
    *ngIf="mapLocation && zoomValue"
    class="tw-h-full"
    [latitude]="mapLocation.lat"
    [longitude]="mapLocation.lng"
    [zoom]="zoomValue"
    [minZoom]="8"
    [mapTypeId]="mapType"
    [disableDefaultUI]="true"
    [scrollwheel]="scrollwheel"
    [clickableIcons]="false"
    [gestureHandling]="'greedy'"
    [styles]="styles"
    (zoomChange)="zoomValueChanged($event)"
    (mapReady)="onMapReady($event)"
    (mapClick)="onMapClick($event)"
    (idle)="onMapIdle()"
  >
    <agm-marker-cluster
      *ngIf="mapMarkers?.length"
      [zoomOnClick]="false"
      [averageCenter]="true"
      [gridSize]="100"
      [maxZoom]="15"
      [minimumClusterSize]="30"
      imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m"
    >
      <agm-marker
        *ngFor="let marker of mapMarkers; let i = index; trackBy: identifyMarker"
        [iconUrl]="$any(marker?.icon || defaultIcon)"
        [latitude]="marker?.latitude"
        [longitude]="marker?.longitude"
        [markerClickable]="hasClickableMarkers"
        [openInfoWindow]="hasClickableMarkers"
        [zIndex]="100 + i"
        [animation]="'DROP'"
        [opacity]="markerOpacity"
        (markerClick)="onMarkerClick(infoWindow, $event, marker, i)"
      ></agm-marker>

      <agm-info-window #infoWindow *ngIf="hasClickableMarkers" [disableAutoPan]="true">
        <div class="tw-flex tw-flex-col tw-pt-1" *ngIf="selectedMarker?.propertyInfo">
          <ion-text class="tw-block tw-text-sm tw-text-gray-700 tw-w-5/6">
            {{ selectedMarker.propertyInfo.address }}
          </ion-text>

          <div class="tw-mt-2 tw-flex tw-gap-3">
            <ion-text
              *ngIf="!isSavedFarm"
              class="smd-lead-status tw-block tw-w-32 tw-rounded-lg tw-p-0.5 tw-text-center tw-text-sm"
              [ngClass]="leadStatusList[selectedMarker?.propertyInfo?.status]?.cssClass || ''"
            >
              {{ leadStatusList[selectedMarker?.propertyInfo?.status].label }}
            </ion-text>

            <ion-select
              *ngIf="isSavedFarm"
              [interface]="selectInterface"
              [interfaceOptions]="{ header: ' ' }"
              [value]="selectedMarker.propertyInfo.status"
              [ngClass]="[
                'smd-lead-status-select tw-h-8 tw-w-40 tw-rounded',
                leadStatusList[selectedMarker.propertyInfo.status]?.cssClass || ''
              ]"
              (click)="onLeadStatusSelectClick($event, selectedMarker.propertyInfo.id)"
              (ionChange)="onLeadStatusChange($event, selectedMarker.propertyInfo.id)"
            >
              <ion-select-option
                *ngFor="let optionKey of leadStatusKeys"
                [value]="optionKey"
                [class]="leadStatusList[optionKey]?.cssClass || ''"
              >
                {{ leadStatusList[optionKey].label }}
              </ion-select-option>
            </ion-select>

            <div class="tw-flex tw-flex-1 tw-flex-col tw-self-center">
              <ion-button
                color="primary"
                fill="solid"
                class="ion-no-padding tw-h-8 tw-w-24 tw-self-end"
                (click)="goToFarmDetails(selectedMarker?.propertyInfo)"
              >
                <ion-text color="light" class="tw-flex tw-gap-1.5">
                  <span class="ion-text-uppercase tw-text-xs"> Details </span>
                  <ion-icon
                    color="light"
                    class="ion-md-icon-pf-arrow2 tw-h-3 tw-w-6 tw-self-center tw-text-xs"
                  ></ion-icon>
                </ion-text>
              </ion-button>
            </div>
          </div>
        </div>
      </agm-info-window>
    </agm-marker-cluster>

    <agm-marker
      *ngIf="userBeacon"
      [iconUrl]="$any(userBeacon.icon)"
      [latitude]="userBeacon.location.latitude"
      [longitude]="userBeacon.location.longitude"
      [zIndex]="1101"
    ></agm-marker>
  </agm-map>
</ng-container>
