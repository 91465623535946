export const fileConfigs = {
  blob: {
    dataType: 'blob'
  },
  csv: {
    dataType: 'csv',
    encoding: 'charset=utf-8',
    fileExtension: '.csv',
    fileType: 'text/csv'
  },
  pdf: {
    dataType: 'pdf',
    encoding: 'base64',
    fileExtension: '.pdf',
    fileType: 'application/pdf'
  },
  plainText: {
    dataType: 'plaintext',
    encoding: 'charset=utf-8',
    fileType: (fileName: string) =>
      fileName.indexOf('.json') > -1 ? fileConfigs.plainText.textJsonType : fileConfigs.plainText.textPlainType,
    textJsonType: 'text/json',
    textPlainType: 'text/plain'
  }
};
