import { Component, NgZone } from '@angular/core';

import { FormItem } from '../../../../typings/form';

@Component({
  selector: 'smd-form-error-messages',
  templateUrl: './form-error-messages.component.html'
})
export class FormErrorMessagesComponent {
  private fieldDataValue: FormItem;

  errorMessage = '';

  set fieldData(data: FormItem) {
    this.fieldDataValue = data;
    if (data && data.name) {
      this.setErrorMessage();
    }
  }

  get fieldData() {
    return this.fieldDataValue;
  }

  constructor(private ngZone: NgZone) {}

  private setErrorMessage() {
    const fieldErrors = this.fieldData.fieldControl?.errors;
    const fieldLabel = this.fieldData.label || 'Field';

    if (fieldErrors) {
      this.ngZone.run(() => {
        switch (true) {
          case !!fieldErrors.required:
            this.errorMessage = `${fieldLabel} is required.`;
            break;

          case !!fieldErrors.pattern:
            this.errorMessage = this.fieldData.errorMsg?.pattern;
            break;

          case !!fieldErrors.email:
            this.errorMessage = `${fieldLabel} is not a valid email address.`;
            break;

          case !!fieldErrors.maxlength:
            this.errorMessage = `${fieldLabel} must be of maximum ${fieldErrors.maxlength.requiredLength} characters.`;
            break;

          case !!fieldErrors.minlength:
            this.errorMessage = `${fieldLabel} must be of minimum ${fieldErrors.minlength.requiredLength} characters.`;
            break;

          case !!fieldErrors.min:
            this.errorMessage = `${fieldLabel} must not be less than ${this.fieldData.minValue}.`;
            break;

          case !!fieldErrors.max:
            this.errorMessage = `${fieldLabel} must not exceed ${this.fieldData.maxValue}.`;
            break;

          case !!fieldErrors.notEqual:
            this.errorMessage = `${this.fieldData.equalityOperator} and ${fieldLabel} must be the same.`;
            break;

          default:
            break;
        }
      });
    }
  }
}
