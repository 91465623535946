import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { containerName } from '../../../../environments/environment';
import { ContainerSettings } from '../../../typings/app';

@Injectable({
  providedIn: 'root'
})
export class ContainerService {
  private containerSettings = new BehaviorSubject<ContainerSettings>(null);

  constructor() {}

  init() {
    this.handleContainerResources();
  }

  getContainerSettings() {
    return this.containerSettings.asObservable();
  }

  private setContainerSettings(settings: ContainerSettings) {
    this.containerSettings.next(settings);
  }

  private handleContainerResources() {
    const defaultLogoUrl = containerName
      ? `../../../assets/img/${containerName}-logo.svg`
      : '../../../assets/img/smartdirect-logo.svg';

    switch (containerName) {
      case 'ort':
        this.setContainerSettings({
          brandName: 'ORTouch',
          containerName: 'ort',
          defaultLogoUrl
        });
        break;
      case 'ftp':
        this.setContainerSettings({
          brandName: 'Fidelity TitleForce Pro',
          containerName: 'ftp',
          defaultLogoUrl
        });
        break;
      case 'trg':
        this.setContainerSettings({
          brandName: 'Data On The Go',
          containerName: 'trg',
          defaultLogoUrl
        });
        break;
      case 'ltp':
        this.setContainerSettings({
          brandName: 'LT Touch Pro',
          containerName: 'ltp',
          defaultLogoUrl
        });
        break;
      case 'wfg':
        this.setContainerSettings({
          brandName: 'OnTheGo',
          containerName: 'wfg',
          defaultLogoUrl
        });
        break;
      default:
        this.setContainerSettings({
          brandName: 'SmartDirect®',
          containerName: 'smartdirect',
          defaultLogoUrl
        });
        break;
    }
  }
}
