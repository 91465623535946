import { Action } from '@ngrx/store';

import {
  AppInfoData,
  AuthData,
  InternalStorageData,
  ReferralCodeDataModel,
  RepDataProvider,
  ResetKeysData,
  UserAcceptedTerms
} from '../../../typings/app';
import { DeviceData } from '../../../typings/device';
import { TermsToAccept } from '../../../typings/terms';

export enum GeneralConfigActionTypes {
  AcceptNewFarmKeyTerms = '[General Config Info] Accept New Farm Key Terms To Accept',
  AcceptNewPropertyKeyTerms = '[General Config Info] Accept New Property Key Terms To Accept',
  GetAppSettingsData = '[General Config Info] Get App Settings Data',
  GetAppSettingsDataFail = '[General Config Info] Get App Settings Data Fail',
  GetAppSettingsDataSuccess = '[General Config Info] Get App Settings Data Success',
  GetDeviceData = '[General Config Info] Get Device Data',
  GetDeviceDataFail = '[General Config Info] Get Device Data Fail',
  GetDeviceDataSuccess = '[General Config Info] Get Device Data Success',
  GetPropertyFarmKey = '[General Config Info] Get Property Farm Key',
  GetPropertyFarmKeyFail = '[General Config Info] Get Property Farm Key Fail',
  GetPropertyFarmKeySuccess = '[General Config Info] Get Property Farm Key Success',
  GetPropertyProfileKey = '[General Config Info] Get Property Profile Key',
  GetPropertyProfileKeyFail = '[General Config Info] Get Property Profile Key Fail',
  GetPropertyProfileKeySuccess = '[General Config Info] Get Property Profile Key Success',
  GetUserAcceptedTermsForFarmData = '[General Config Info] Get User Accepted Terms For Farm Data',
  GetUserAcceptedTermsForFarmDataFail = '[General Config Info] Get User Accepted Terms For Farm Data Fail',
  GetUserAcceptedTermsForFarmDataSuccess = '[General Config Info] Get User Accepted Terms For Farm Data Success',
  GetUserAcceptedTermsForPropertyData = '[General Config Info] Get User Accepted Terms For Property Data',
  GetUserAcceptedTermsForPropertyDataFail = '[General Config Info] Get User Accepted Terms For Property Data Fail',
  GetUserAcceptedTermsForPropertyDataSuccess = '[General Config Info] Get User Accepted Terms For Property Data Success',
  PaymentsFlowLoadedFail = '[General Config Info] The Payments flow has failed to load!',
  PaymentsFlowLoadedSuccess = '[General Config Info] The Payments flow has been loaded successfully!',
  PaymentsFlowShouldRefresh = '[General Config Info] Should refresh payments flow!',
  ResetAppInfo = '[General Config Info] Reset App Info',
  ResetDeviceData = '[General Config Info] Reset Device Data',
  ResetGeneralInfo = '[General Config Info] Reset General Info',
  ResetGeneralInfoErrors = '[General Config Info] Reset errors',
  ResetKeys = '[General Config Info] Reset Keys',
  ResetNewFarmKeyTerms = '[General Config Info] Reset New Farm Key Terms To Accept',
  ResetNewPropertyKeyTerms = '[General Config Info] Reset New Property Key Terms To Accept',
  SaveDataInInternalStorage = '[General Config Info] Save Data In Internal Storage',
  SetAppInfo = '[General Config Info] Set App Info',
  SetRepDataProvider = '[General Config Info] Set Rep Data Provider',
  SetSubscriptionPlan = '[General Config Info] Set Subscription Plan',
  ShouldSaveDataInInternalStorage = '[General Config Info] Should Save Data In Internal Storage',
  UpdateAuthData = '[General Config Info] Update Auth Data',
  UpdateCurrentAppIcon = '[General Config Info] Update Current App Icon',
  UpdateDeviceData = '[General Config Info] Update Device Data',
  UpdateGeneralInfoFromInternalStorage = '[General Config Info] Update Data From Internal Storage',
  UpdatePassword = '[General Config Info] Update Password',
  ValidateUpdateAuthData = '[General Config Info] Validate Update Auth Data',
  ValidateUpdateAuthDataSuccess = '[General Config Info] Validate Update Auth Data Successfully'
}

export class SetAppInfo implements Action {
  readonly type = GeneralConfigActionTypes.SetAppInfo;

  constructor(public payload: AppInfoData) {}
}

export class UpdatePassword implements Action {
  readonly type = GeneralConfigActionTypes.UpdatePassword;

  constructor(public payload: string) {}
}

export class ValidateUpdateAuthData implements Action {
  readonly type = GeneralConfigActionTypes.ValidateUpdateAuthData;

  constructor(public payload: Partial<AuthData>) {}
}
export class ValidateUpdateAuthDataSuccess implements Action {
  readonly type = GeneralConfigActionTypes.ValidateUpdateAuthDataSuccess;
  constructor() {}
}
export class UpdateAuthData implements Action {
  readonly type = GeneralConfigActionTypes.UpdateAuthData;

  constructor(public payload: Partial<AuthData>) {}
}

export class UpdateCurrentAppIcon implements Action {
  readonly type = GeneralConfigActionTypes.UpdateCurrentAppIcon;

  constructor(public payload: string) {}
}
export class ResetAppInfo implements Action {
  readonly type = GeneralConfigActionTypes.ResetAppInfo;

  constructor() {}
}

export class GetDeviceData implements Action {
  readonly type = GeneralConfigActionTypes.GetDeviceData;

  constructor() {}
}
export class GetDeviceDataSuccess implements Action {
  readonly type = GeneralConfigActionTypes.GetDeviceDataSuccess;

  constructor(public payload: Partial<DeviceData>) {}
}
export class GetDeviceDataFail implements Action {
  readonly type = GeneralConfigActionTypes.GetDeviceDataFail;

  constructor(public payload: Error) {}
}
export class UpdateDeviceData implements Action {
  readonly type = GeneralConfigActionTypes.UpdateDeviceData;

  constructor(public payload: Partial<DeviceData>) {}
}
export class ResetDeviceData implements Action {
  readonly type = GeneralConfigActionTypes.ResetDeviceData;

  constructor() {}
}

export class GetPropertyProfileKey implements Action {
  readonly type = GeneralConfigActionTypes.GetPropertyProfileKey;

  constructor(public payload: { withKeyRefresh: boolean } = { withKeyRefresh: false }) {}
}
export class GetPropertyProfileKeySuccess implements Action {
  readonly type = GeneralConfigActionTypes.GetPropertyProfileKeySuccess;

  constructor(public payload: { key: string; lastUpdatedTime: number }) {}
}
export class GetPropertyProfileKeyFail implements Action {
  readonly type = GeneralConfigActionTypes.GetPropertyProfileKeyFail;

  constructor(public payload: Error) {}
}

export class GetPropertyFarmKey implements Action {
  readonly type = GeneralConfigActionTypes.GetPropertyFarmKey;

  constructor(public payload: { withKeyRefresh: boolean } = { withKeyRefresh: false }) {}
}
export class GetPropertyFarmKeySuccess implements Action {
  readonly type = GeneralConfigActionTypes.GetPropertyFarmKeySuccess;

  constructor(public payload: { key: string; lastUpdatedTime: number }) {}
}
export class GetPropertyFarmKeyFail implements Action {
  readonly type = GeneralConfigActionTypes.GetPropertyFarmKeyFail;

  constructor(public payload: Error) {}
}

export class GetUserAcceptedTermsForPropertyData implements Action {
  readonly type = GeneralConfigActionTypes.GetUserAcceptedTermsForPropertyData;

  constructor(public payload: UserAcceptedTerms) {}
}
export class GetUserAcceptedTermsForPropertyDataSuccess implements Action {
  readonly type = GeneralConfigActionTypes.GetUserAcceptedTermsForPropertyDataSuccess;

  constructor(public payload: { key: string; lastUpdatedTime: number }) {}
}
export class GetUserAcceptedTermsForPropertyDataFail implements Action {
  readonly type = GeneralConfigActionTypes.GetUserAcceptedTermsForPropertyDataFail;

  constructor(public payload: Error) {}
}

export class GetUserAcceptedTermsForFarmData implements Action {
  readonly type = GeneralConfigActionTypes.GetUserAcceptedTermsForFarmData;

  constructor(public payload: UserAcceptedTerms) {}
}
export class GetUserAcceptedTermsForFarmDataSuccess implements Action {
  readonly type = GeneralConfigActionTypes.GetUserAcceptedTermsForFarmDataSuccess;

  constructor(public payload: { key: string; lastUpdatedTime: number }) {}
}
export class GetUserAcceptedTermsForFarmDataFail implements Action {
  readonly type = GeneralConfigActionTypes.GetUserAcceptedTermsForFarmDataFail;

  constructor(public payload: { html: string; version: number }) {}
}

export class AcceptNewPropertyKeyTerms implements Action {
  readonly type = GeneralConfigActionTypes.AcceptNewPropertyKeyTerms;

  constructor(public payload: TermsToAccept) {}
}

export class AcceptNewFarmKeyTerms implements Action {
  readonly type = GeneralConfigActionTypes.AcceptNewFarmKeyTerms;

  constructor(public payload: TermsToAccept) {}
}

export class ResetNewPropertyKeyTerms implements Action {
  readonly type = GeneralConfigActionTypes.ResetNewPropertyKeyTerms;

  constructor() {}
}

export class ResetNewFarmKeyTerms implements Action {
  readonly type = GeneralConfigActionTypes.ResetNewFarmKeyTerms;

  constructor() {}
}

export class SetRepDataProvider implements Action {
  readonly type = GeneralConfigActionTypes.SetRepDataProvider;

  constructor(public payload: RepDataProvider) {}
}
export class GetAppSettingsData implements Action {
  readonly type = GeneralConfigActionTypes.GetAppSettingsData;

  constructor(
    public payload: {
      newAuthData?: {
        password: string;
        repCode: string;
        username: string;
      };
      refreshUser?: boolean;
      repCode: string;
    }
  ) {}
}

export class GetAppSettingsDataSuccess implements Action {
  readonly type = GeneralConfigActionTypes.GetAppSettingsDataSuccess;

  constructor(public payload: ReferralCodeDataModel) {}
}
export class GetAppSettingsDataFail implements Action {
  readonly type = GeneralConfigActionTypes.GetAppSettingsDataFail;

  constructor(public payload: Error) {}
}

export class SetSubscriptionPlan implements Action {
  readonly type = GeneralConfigActionTypes.SetSubscriptionPlan;

  constructor(public payload: string) {}
}

export class ResetGeneralInfo implements Action {
  readonly type = GeneralConfigActionTypes.ResetGeneralInfo;

  constructor() {}
}

export class ResetGeneralInfoErrors implements Action {
  readonly type = GeneralConfigActionTypes.ResetGeneralInfoErrors;

  constructor() {}
}

export class ResetKeys implements Action {
  readonly type = GeneralConfigActionTypes.ResetKeys;

  constructor(public payload: { propertyFarm: ResetKeysData; propertyProfile: ResetKeysData }) {}
}

export class ShouldSaveDataInInternalStorage implements Action {
  readonly type = GeneralConfigActionTypes.ShouldSaveDataInInternalStorage;

  constructor() {}
}
export class SaveDataInInternalStorage implements Action {
  readonly type = GeneralConfigActionTypes.SaveDataInInternalStorage;

  constructor(public payload?: Partial<AuthData>) {}
}
export class UpdateGeneralInfoFromInternalStorage implements Action {
  readonly type = GeneralConfigActionTypes.UpdateGeneralInfoFromInternalStorage;

  constructor(public payload: Partial<InternalStorageData>) {}
}

export class PaymentsFlowLoadedSuccess implements Action {
  readonly type = GeneralConfigActionTypes.PaymentsFlowLoadedSuccess;

  constructor() {}
}
export class PaymentsFlowLoadedFail implements Action {
  readonly type = GeneralConfigActionTypes.PaymentsFlowLoadedFail;

  constructor(public payload: Error) {}
}
export class PaymentsFlowShouldRefresh implements Action {
  readonly type = GeneralConfigActionTypes.PaymentsFlowShouldRefresh;

  constructor() {}
}

export type GeneralConfigAction =
  | SetAppInfo
  | UpdatePassword
  | ValidateUpdateAuthData
  | UpdateAuthData
  | UpdateCurrentAppIcon
  | ResetAppInfo
  | GetDeviceData
  | GetDeviceDataSuccess
  | GetDeviceDataFail
  | UpdateDeviceData
  | ResetDeviceData
  | GetPropertyProfileKey
  | GetPropertyProfileKeySuccess
  | GetPropertyProfileKeyFail
  | GetPropertyFarmKey
  | GetPropertyFarmKeySuccess
  | GetPropertyFarmKeyFail
  | GetUserAcceptedTermsForPropertyData
  | GetUserAcceptedTermsForPropertyDataSuccess
  | GetUserAcceptedTermsForPropertyDataFail
  | GetUserAcceptedTermsForFarmData
  | GetUserAcceptedTermsForFarmDataSuccess
  | GetUserAcceptedTermsForFarmDataFail
  | AcceptNewPropertyKeyTerms
  | AcceptNewFarmKeyTerms
  | ResetNewPropertyKeyTerms
  | ResetNewFarmKeyTerms
  | SetRepDataProvider
  | SetSubscriptionPlan
  | GetAppSettingsData
  | GetAppSettingsDataSuccess
  | GetAppSettingsDataFail
  | ResetGeneralInfo
  | ResetGeneralInfoErrors
  | ResetKeys
  | ShouldSaveDataInInternalStorage
  | SaveDataInInternalStorage
  | UpdateGeneralInfoFromInternalStorage
  | PaymentsFlowLoadedSuccess
  | PaymentsFlowLoadedFail
  | PaymentsFlowShouldRefresh;
