import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { adminPortalDependencies } from '../../../constants/admin-portal';

import * as fromConfigActions from '../../../store/actions/config.actions';
import { State as ConfigData } from '../../../store/reducers/config.reducer';
import * as fromConfigSelectors from '../../../store/selectors/config.selectors';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private error: string | null = null;
  private alert: HTMLIonAlertElement;
  private showFailModal = false;

  constructor(
    private store: Store,
    private alertController: AlertController
  ) {
    this.store.select(fromConfigSelectors.selectConfigLoadError).subscribe(async (error) => {
      if (!error && !!this.alert) {
        this.alert.remove();
      }

      this.error = error;
    });

    this.store
      .select(fromConfigSelectors.selectConfig)
      .pipe(
        filter((config: ConfigData) => !config.pending),
        distinctUntilChanged()
      )
      .subscribe((config: ConfigData) => {
        this.loaded.next(!!config.loaded);
      });
  }

  init() {
    return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
      let isInitial = true;

      this.loaded.subscribe(async (loaded) => {
        if (isInitial) {
          isInitial = false;
          this.store.dispatch(fromConfigActions.loadConfig());
        } else if (!loaded) {
          await this.showFailed();
        }

        subscriber.next(loaded);
      });
    });
  }

  async showFailed() {
    if (!this.showFailModal) {
      this.showFailModal = true;

      this.alert = await this.alertController.create({
        backdropDismiss: false,
        buttons: [
          {
            cssClass: 'primary',
            handler: () => {
              this.showFailModal = false;
              this.store.dispatch(fromConfigActions.loadConfig());
            },
            role: 'cancel',
            text: 'Retry'
          }
        ],
        header: adminPortalDependencies.error.systemConfigurations.title,
        message: this.error ? this.error : adminPortalDependencies.error.systemConfigurations.message,
        subHeader: adminPortalDependencies.error.systemConfigurations.subtitle
      });

      await this.alert.present();
    }
  }
}
