import { EntityAdapter, EntityState } from '@ngrx/entity';

interface ViewLoadingState {
  error: unknown;
  loaded: boolean;
  loading: boolean;
}

export interface ViewState<TData> extends EntityState<TData>, ViewLoadingState {
  selectedId: string | null;
}

export function initState<TData>(adapter: EntityAdapter<TData>) {
  return adapter.getInitialState({
    error: null,
    loaded: false,
    loading: false,
    selectedId: null
  });
}
