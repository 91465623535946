import * as fromFarmReportsActions from '../../actions/walking-farm/farm-report-details';

export interface State {
  docPdfData: Blob | null;
  error: Error;
  pending: boolean;
  succeeded: boolean;
}

export const initialState: State = {
  docPdfData: null,
  error: null,
  pending: false,
  succeeded: false
};

export function reducer(state = initialState, action: fromFarmReportsActions.FarmReportDetailsActions) {
  switch (action.type) {
    case fromFarmReportsActions.FarmReportDetailsActionTypes.ExportPdfInAveryLabels: {
      return {
        ...state,
        error: null,
        pending: true,
        succeeded: false
      };
    }

    case fromFarmReportsActions.FarmReportDetailsActionTypes.ExportPdfInAveryLabelsSuccess: {
      return {
        ...state,
        docPdfData: action.payload,
        error: null,
        pending: false,
        succeeded: true
      };
    }

    case fromFarmReportsActions.FarmReportDetailsActionTypes.ResetExportPdfInAveryLabels: {
      return initialState;
    }

    case fromFarmReportsActions.FarmReportDetailsActionTypes.ExportPdfInAveryLabelsFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getAveryLabelsPdfDocData = (state: State) => state.docPdfData;
