import { Component, NgZone, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { debounce } from 'lodash-es';
import { filter } from 'rxjs/operators';

import { CalculatorType } from '../../../../enums/calculators';
import { AmortizationReportData, ScheduleItem, SummaryDataItem } from '../../../../typings/calculator';
import { ChartDataModel } from '../../../../typings/components';

import { GenericComponent } from '../../generic/generic.component';

import * as calculatorsActions from '../../../../store/actions/calculators/calculators-and-reports';
import * as fromCalculators from '../../../../store/reducers/calculators/calculators-and-reports';
import * as fromCalculatorsData from '../../../../store/selectors/calculators';
import * as fromCalculatorsConfig from '../../../../store/selectors/calculators';

import { CalculatorsProvider } from '../../../../providers/calculators.service';
import { FileService } from '../../../services/file/file.service';
import { LayoutService } from '../../../services/layout/layout.service';
import { CurrencyFormatService } from '../../../services/number-formatter/currency-format.service';

@Component({
  selector: 'smd-amortization-report',
  templateUrl: './amortization-report.component.html'
})
export class AmortizationReportComponent extends GenericComponent implements OnInit {
  pdfName = '';
  summaryData: SummaryDataItem[];
  scheduleData: ScheduleItem[] = [];
  isMobileView = true;
  calculatorData: AmortizationReportData;
  paymentChartData: ChartDataModel;
  pdfDocData: Blob;

  chartOptions = {
    cutout: '80%',
    rotation: 150
  };

  constructor(
    private ngZone: NgZone,
    private store: Store<fromCalculators.State>,
    private calculatorsProvider: CalculatorsProvider,
    private fileService: FileService,
    private layoutService: LayoutService,
    private currencyFormatService: CurrencyFormatService
  ) {
    super();
  }

  ngOnInit() {
    this.addUniqueSubscription(
      'isMobileViewSubscription',
      this.layoutService.getMobileViewFlag().subscribe((isMobileView) => {
        this.ngZone.run(() => {
          this.isMobileView = isMobileView;
        });
      })
    );

    this.addUniqueSubscription(
      'selectedCalculatorSubscription',
      this.store
        .select(fromCalculatorsConfig.getAmortizationData)
        .pipe(filter((calculatorData) => !!calculatorData))
        .subscribe((calculatorData) => {
          this.ngZone.run(() => {
            this.calculatorData = calculatorData;
          });

          this.compileData();
        })
    );

    this.addUniqueSubscription(
      'pdfBlobSubscription',
      this.store
        .select(fromCalculatorsData.getPdfBlobData)
        .pipe(filter((pdfDocData) => !!pdfDocData))
        .subscribe((pdfDocData) => {
          this.pdfDocData = pdfDocData;
          this.openShareDialog(pdfDocData);
        })
    );
  }

  sendReport() {
    this.pdfName = `calculator-amortization-export.pdf`;

    if (this.pdfDocData) {
      this.openShareDialog(this.pdfDocData);
      return;
    }

    debounce(
      () => {
        this.store.dispatch(
          new calculatorsActions.SetPdfReportData({
            calculatorData: this.calculatorData,
            reportType: CalculatorType.Amortization
          })
        );
      },
      500,
      { maxWait: 3000 }
    )();
  }

  private compileData() {
    this.ngZone.run(() => {
      if (this.calculatorData) {
        this.summaryData = [
          {
            format: 'currency',
            label: 'Amount',
            value: this.calculatorData?.principal || 0
          },
          {
            format: 'percent-display',
            label: 'Interest Rate',
            value: this.calculatorData?.interestRate || 0
          },
          {
            format: 'time',
            formatRule: 'year',
            label: 'Term',
            value: this.calculatorData?.term || 0
          }
        ];

        this.scheduleData = [
          {
            entries: this.calculatorData.amortizationAnnualSchedule || [],
            title: 'Yearly Schedule'
          },
          {
            entries: this.calculatorData.amortizationMonthlySchedule || [],
            title: 'Monthly Schedule'
          }
        ];

        const initialGraphicData = [this.calculatorData.principalPercentage, this.calculatorData.interestPercentage];
        const initialGraphicLabels = ['Principal', 'Interest'];
        const checkedPaymentSummaryData = this.calculatorsProvider.computePaymentGraphicData(
          initialGraphicData,
          initialGraphicLabels
        );

        if (checkedPaymentSummaryData) {
          this.paymentChartData = {
            chartData: {
              data: checkedPaymentSummaryData.graphicData,
              labels: checkedPaymentSummaryData.graphicLabels
            },
            hasCustomLegend: true,
            legend: {
              data: [
                {
                  label: initialGraphicLabels[0],
                  value: `${this.formatAsCurrency(this.calculatorData.principal)} (${
                    this.calculatorData.principalPercentage
                  }%)`
                },
                {
                  label: initialGraphicLabels[1],
                  value: `${this.formatAsCurrency(this.calculatorData.interest)} (${
                    this.calculatorData.interestPercentage
                  }%)`
                }
              ]
            }
          };
        }
      }
    });
  }

  private formatAsCurrency(value: number | string) {
    return this.currencyFormatService.formatValue(value.toString());
  }

  private async openShareDialog(pdfDocData: Blob) {
    const fileUrl = await this.fileService.writeBlobToFs(pdfDocData, this.pdfName);

    if (fileUrl) {
      this.fileService.openNativeShareSheet(fileUrl, this.pdfName);
    }
  }
}
