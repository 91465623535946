<div class="tw-h-14 tw-border-b tw-border-gray-300 tw-bg-white">
  <ion-grid class="tw-h-full">
    <ion-row class="tw-h-full">
      <ion-col size="3" size-md="2">
        <ng-container *ngIf="hasBackNavigation || hasCloseIcon">
          <ion-button class="tw-h-full" fill="clear" (click)="triggerLeftButton($event)">
            <ion-icon
              *ngIf="hasIcon"
              class="tw-text-gray-700"
              [class]="hasBackNavigation ? 'icon-pf-back-arrow' : 'icon-cross-rounded'"
            ></ion-icon>

            <ion-text *ngIf="!hasIcon" class="tw-text-gray-700">
              {{ backNavigationText }}
            </ion-text>
          </ion-button>
        </ng-container>
      </ion-col>

      <ion-col size="6" size-md="8" class="tw-relative tw-flex tw-h-full">
        <ion-title class="ion-no-padding ion-text-uppercase tw-w-full">
          <ion-text
            *ngIf="title"
            class="tw-block tw-overflow-hidden tw-text-ellipsis tw-text-center tw-text-xs tw-font-bold tw-text-gray-700"
          >
            {{ title }}
          </ion-text>

          <ion-text
            *ngIf="subTitle"
            class="tw-block tw-overflow-hidden tw-text-ellipsis tw-text-center tw-text-xs tw-text-gray-700"
          >
            {{ subTitle }}
          </ion-text>
        </ion-title>
      </ion-col>

      <ion-col size="3" size-md="2" class="tw-flex tw-flex-col">
        <ion-buttons *ngIf="rightIcons?.length" class="tw-flex tw-flex-1 tw-justify-end">
          <ion-button
            *ngFor="let icon of rightIcons"
            class="ion-no-padding tw-w-9"
            (click)="triggerRightButton(icon.componentName)"
          >
            <ion-icon class="tw-text-gray-700" [ngClass]="icon.class" [name]="icon.iconName"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-buttons *ngIf="rightButtons && rightButtons.length > 0" class="tw-flex tw-flex-1 tw-justify-end">
          <ion-button
            *ngFor="let button of rightButtons"
            class="ion-text-uppercase tw-text-gray-700"
            (click)="triggerRightButton(button.componentName)"
            [disabled]="button.isDisabled"
          >
            {{ button.buttonText }}
          </ion-button>
        </ion-buttons>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
