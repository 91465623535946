export const STANDARD_PIN_SVG = {
  blue: 'pin-standard-blue.svg',
  grey: 'pin-standard-grey.svg',
  red: 'pin-standard-red.svg'
};

export const COLOR_PIN_SVG = {
  lead: 'color-pin-lead-status-lead.svg',
  notContacted: 'color-pin-lead-status-not-contacted.svg',
  notHome: 'color-pin-lead-status-not-home.svg',
  notInterested: 'color-pin-lead-status-not-interested.svg',
  onMarket: 'color-pin-lead-status-on-market.svg',
  prospect: 'color-pin-lead-status-prospect.svg'
};

export const COLOR_PIN_WITH_ALERT_SVG = {
  lead: 'color-pin-with-alert-lead.svg',
  notContacted: 'color-pin-with-alert-not-contacted.svg',
  notHome: 'color-pin-with-alert-not-home.svg',
  notInterested: 'color-pin-with-alert-not-interested.svg',
  onMarket: 'color-pin-with-alert-on-market.svg',
  prospect: 'color-pin-with-alert-prospect.svg'
};

export const POLYGON_POINT_MARKER_SVG = {
  fullColor: 'polygon-point-marker-full-color.svg',
  transparent: 'polygon-point-marker-transparent.svg'
};

export const PROPERTY_OCCUPANCY_MARKER_SVG = {
  occupied: 'pin-owner-occupied.svg',
  occupiedWithAlert: 'pin-with-alert-owner-occupied.svg',
  unoccupied: 'pin-owner-unoccupied.svg',
  unoccupiedWithAlert: 'pin-with-alert-owner-unoccupied.svg'
};
