import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';

import { DrawFarmModes } from '../../enums/walking-farm';
import { MapIcon } from '../../typings/map';

import * as fromMapActions from '../actions/map.actions';

export const mapFeatureKey = 'mapView';

export interface State {
  drawMode: DrawFarmModes.Polygon | DrawFarmModes.Radius;
  icons: MapIcon;
  isMapCentered: boolean;
  location: {
    savedFarm: google.maps.LatLngLiteral | null;
  };
}

export const initialState: State = {
  drawMode: DrawFarmModes.Polygon,
  icons: {
    url: {
      compassDisabled: '/assets/svg/map-center-disabled.svg',
      compassEnabled: '/assets/svg/map-center-enabled.svg'
    }
  },
  isMapCentered: true,
  location: {
    savedFarm: null
  }
};

export function reducer(state, action) {
  return privateReducer(state, action);
}

const privateReducer = createReducer(
  cloneDeep(initialState),
  on(fromMapActions.resetMapCenterSetting, (state) => ({
    ...state,
    isMapCentered: cloneDeep(initialState).isMapCentered
  })),
  on(fromMapActions.setMapCenterSetting, (state, action) => ({
    ...state,
    isMapCentered: action.isMapCentered
  })),
  on(fromMapActions.resetSavedFarmLocation, (state) => ({
    ...state,
    location: {
      ...cloneDeep(initialState).location
    }
  })),
  on(
    fromMapActions.setSavedFarmLocation,
    (
      state,
      action: {
        isInitialCoordinates?: boolean;
        savedFarmLocation: google.maps.LatLngLiteral;
      }
    ) => ({
      ...state,
      location: {
        ...state.location,
        savedFarm:
          action.isInitialCoordinates === true && state.location.savedFarm
            ? state.location.savedFarm
            : action.savedFarmLocation
      }
    })
  ),
  on(fromMapActions.setDrawMode, (state, action) => ({
    ...state,
    drawMode: action.drawMode
  }))
);
