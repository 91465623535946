import { markerTypes } from '../../../constants/map';
import { ReportSummaryTabType } from '../../../enums/walking-farm';

import * as reportSummaryAction from '../../actions/walking-farm/report-summary';

export interface State {
  activeTab: ReportSummaryTabType;
  markerType: string;
  reportSummaryListIndex: number;
}

export const initialState: State = {
  activeTab: ReportSummaryTabType.MapView,
  markerType: markerTypes.occupancy,
  reportSummaryListIndex: 0
};

export function reducer(state = initialState, action: reportSummaryAction.ReportSummaryActions) {
  switch (action.type) {
    case reportSummaryAction.ReportSummaryActionTypes.SetActiveTab: {
      return {
        ...state,
        activeTab: action.payload
      };
    }
    case reportSummaryAction.ReportSummaryActionTypes.SetMarkerType: {
      return {
        ...state,
        markerType: action.payload
      };
    }

    case reportSummaryAction.ReportSummaryActionTypes.SetReportSummaryListIndex: {
      return {
        ...state,
        reportSummaryListIndex: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const getMarkerType = (state: State) => state.markerType;
export const getActiveTab = (state: State) => state.activeTab;
export const getReportSummaryListIndex = (state: State) => state.reportSummaryListIndex;
