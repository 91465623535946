<div class="tw-my-3 tw-cursor-pointer" [ngClass]="{ 'tw-px-4': !resetAlignment }">
  <div class="tw-flex tw-items-center" (click)="clickItem()">
    <ion-text *ngIf="!hasLabelTitle" class="tw-flex-auto tw-text-base tw-font-bold tw-text-gray-500">
      {{ itemTitle }}
    </ion-text>

    <ion-label *ngIf="hasLabelTitle" position="stacked" class="tw-flex-auto">
      <ion-text class="tw-text-lg">{{ itemTitle }}</ion-text>
    </ion-label>

    <div *ngIf="hasData" class="tw-flex-auto">
      <ion-icon class="tw-float-right tw-cursor-pointer" [ngClass]="accordionIconName"></ion-icon>
    </div>
  </div>

  <div class="tw-mt-2 tw-cursor-default" [ngClass]="{ 'tw-hidden': !isExpanded, 'tw-block': isExpanded }">
    <ng-content></ng-content>
  </div>
</div>
