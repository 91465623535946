<ion-card *ngIf="propertyProfile" class="tw-m-0 tw-flex tw-h-full tw-flex-col tw-pb-2 md:tw-mx-3">
  <ion-card-header
    class="ion-no-padding ion-text-uppercase tw-px-4 tw-py-2 tw-text-base tw-font-bold tw-text-gray-500 md:tw-text-sm"
  >
    {{ propertyProfile.detailsTitle }}
  </ion-card-header>

  <ion-card-content
    *ngIf="propertyProfile.contentDescription as content; else customContent"
    class="tw-flex tw-flex-1 tw-flex-col tw-px-4 tw-pb-2"
  >
    <ng-container *ngIf="content.descriptionRows && content.descriptionRows.length > 0">
      <div class="tw-mt-2" *ngFor="let item of content.descriptionRows">
        <span *ngIf="item.label" class="tw-block tw-text-gray-500">{{ item.label }}:</span>
        <span *ngIf="item.label || item.value" class="tw-text-gray-700"> {{ item.value | smdDashIfEmpty }}</span>
      </div>
    </ng-container>

    <div class="tw-mt-2 tw-flex tw-flex-1 md:tw-flex-col">
      <div
        *ngIf="content.avgPrice"
        class="tw-flex-1 tw-border-r tw-border-gray-300 tw-pr-4 md:tw-border-none md:tw-px-0"
      >
        <ion-text class="tw-mt-2 tw-block tw-text-sm tw-text-gray-500 md:tw-inline">
          {{ content.avgPrice.label }}:
        </ion-text>

        <ion-text class="tw-text-xl tw-font-bold tw-text-gray-700 md:tw-text-lg">
          {{ content.avgPrice.value | smdNumberFormatter: content.avgPrice.format | smdDashIfEmpty }}
        </ion-text>
        <ion-text *ngIf="content.avgPrice.value" class="tw-text-xl tw-font-bold tw-text-gray-700 md:tw-text-lg">
          {{ content.avgPrice.suffix ? content.avgPrice.suffix : '' }}
        </ion-text>

        <ion-text *ngIf="content.date" class="tw-mt-1 tw-block tw-text-sm tw-font-bold">
          {{ content.date | smdNumberFormatter: 'date' : dateFormatRule }}
        </ion-text>
      </div>

      <div
        *ngIf="content.bedrooms || content.bathrooms"
        class="tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-pl-4 md:tw-block md:tw-flex-none md:tw-px-0"
      >
        <hr class="tw-mb-4 tw-mt-6 tw-hidden tw-border-gray-300 md:tw-block" />

        <div class="tw-flex">
          <div class="tw-flex tw-flex-1 tw-flex-col">
            <ion-icon class="ion-md-icon-bedroom tw-mx-auto tw-h-8 tw-w-8 tw-text-3xl tw-text-gray-400"></ion-icon>
            <ion-text class="tw-text-center tw-text-sm tw-text-gray-700">
              {{ content.bedrooms | smdDashIfEmpty }} Bed
            </ion-text>
          </div>

          <div class="tw-flex tw-flex-1 tw-flex-col">
            <ion-icon
              class="ion-md-icon-bathroom tw-mx-auto tw-h-8 tw-w-8 tw-text-center tw-text-3xl tw-text-gray-400"
            ></ion-icon>
            <ion-text class="tw-text-center tw-text-sm tw-text-gray-700">
              {{ content.bathrooms | smdDashIfEmpty }} BA
            </ion-text>
          </div>
        </div>
      </div>
    </div>
  </ion-card-content>
</ion-card>

<ng-template #customContent>
  <ion-card-content class="tw-flex-1 tw-px-4 tw-pb-2">
    <ng-content></ng-content>
  </ion-card-content>
</ng-template>
