import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';

import { TermData } from '../../typings/terms';

import * as fromConfigActions from '../actions/config.actions';

export const configFeatureKey = 'config';

export interface State {
  error: string | null;
  loaded: boolean | null;
  pending: boolean | null;
  term: TermData;
}

export const initialState: State = {
  error: null,
  loaded: null,
  pending: null,
  term: {
    description: null,
    revisionDate: null,
    version: null
  }
};

export function reducer(state, action) {
  return privateReducer(state, action);
}

const privateReducer = createReducer(
  cloneDeep(initialState),
  on(fromConfigActions.loadConfig, (state) => ({
    ...state,
    error: null,
    loaded: null,
    pending: true
  })),
  on(fromConfigActions.loadConfigSuccess, (state, action) => ({
    ...state,
    loaded: true,
    pending: false,
    term: {
      description: action.term?.description || null,
      revisionDate: action.term?.revisionDate || null,
      version: action.term?.version || null
    }
  })),
  on(fromConfigActions.loadConfigFailure, (state, action) => ({
    ...cloneDeep(initialState),
    error: action.error,
    loaded: false,
    pending: false
  }))
);
