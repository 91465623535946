import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { CalculatorPagePaths } from '../../../enums/calculators';
import { NavTabsItem, NavTabsState, TabElementsOptions } from '../../../typings/layout';

import * as mapConfigAction from '../../../store/actions/app-config/map-data';
import * as appConfigActions from '../../../store/actions/app-config/map-data';
import * as calculatorsActions from '../../../store/actions/calculators/calculators-and-reports';
import * as fromPropertyProfileActions from '../../../store/actions/property-data/property-profile';
import * as fromSearchTypesActions from '../../../store/actions/property-search/search-types';
import * as fromSearchInMapActions from '../../../store/actions/walking-farm/search-in-map';
import * as fromAppConfig from '../../../store/selectors/app-config';
import * as fromCalculatorsTypes from '../../../store/selectors/calculators';
import * as fromMapSelectors from '../../../store/selectors/map.selectors';
import * as fromSearchTypes from '../../../store/selectors/property-search';

import { AppService } from '../../../app.service';
import { PlatformDetectService } from '../platform-detect/platform-detect.service';
import { SearchService } from '../search/search.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private isMobileView = new BehaviorSubject<boolean>(true);
  private isMobilePlatform = true;
  private isAuthenticated = false;

  private isMapCentered = true;
  private navTabDataHandler = new BehaviorSubject<NavTabsItem[]>([]);
  private navTabsStateHandler = new BehaviorSubject<NavTabsState>({
    isTabPageActive: false,
    isVisible: false
  });
  private activeTab: string;

  private lastRepDataRefreshTime = moment();

  contactCardToggleHandler = new BehaviorSubject<boolean>(false);

  constructor(
    private platform: Platform,
    private router: Router,
    private navController: NavController,
    private store: Store<fromSearchTypes.State | fromAppConfig.State | fromCalculatorsTypes.State>,
    private searchService: SearchService,
    private appService: AppService,
    private platformDetectService: PlatformDetectService
  ) {
    this.refreshTabElements({
      enableCalculators: false,
      enableNetSheet: false,
      enableWalkingFarm: false
    });

    this.platformDetectService.getIsMobileSubscription().subscribe((isMobilePlatform) => {
      this.isMobilePlatform = isMobilePlatform;
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      const urlSections = event.url.split('/');
      const pageName = urlSections[urlSections.length - 1];

      if (
        [
          'home',
          'search',
          'walking-farm',
          CalculatorPagePaths.Root.toString(),
          CalculatorPagePaths.NetSheet.toString(),
          'more'
        ].includes(pageName)
      ) {
        this.activeTab = pageName;
        this.setNavTabsState({
          isTabPageActive: true,
          isVisible: true
        });
      } else {
        this.activeTab = '';
        this.setNavTabsState({
          isTabPageActive: false,
          isVisible: false
        });
      }
    });

    this.store
      .select(fromAppConfig.getRepDataSuccess)
      .pipe(
        filter((repDataLoadSuccess) => repDataLoadSuccess),
        switchMap(() =>
          combineLatest(
            this.store.select(fromAppConfig.getRepDataCalculatorsEnabled),
            this.store.select(fromAppConfig.getRepDataIsNetSheetEnabled),
            this.store.select(fromAppConfig.getRepDataWalkingFarmEnabled)
          )
        )
      )
      .subscribe(([enableCalculators, enableNetSheet, enableWalkingFarm]) => {
        this.refreshTabElements({
          enableCalculators,
          enableNetSheet,
          enableWalkingFarm
        });
      });

    this.store.select(fromMapSelectors.selectMapCenterSetting).subscribe((isMapCentered) => {
      this.isMapCentered = isMapCentered;
    });

    this.store.select(fromAppConfig.getIsAuthenticated).subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
    });
  }

  setMobileViewFlag(isMobileView: boolean) {
    this.isMobileView.next(isMobileView);
  }

  getMobileViewFlag() {
    return this.isMobileView.asObservable();
  }

  getNavTabsState() {
    return this.navTabsStateHandler.asObservable();
  }

  setNavTabsState(newState: NavTabsState) {
    this.navTabsStateHandler.next({
      ...this.navTabsStateHandler.value,
      ...newState
    });
  }

  getNavTabsData() {
    return this.navTabDataHandler.asObservable();
  }

  updateNavTabData(route: string[]) {
    const updatedNavTabs = this.navTabDataHandler.value.map((navTabEntry) => {
      navTabEntry.isActive = isEqual(navTabEntry.route, route);
      return navTabEntry;
    });

    this.navTabDataHandler.next(updatedNavTabs);
  }

  getContactCardToggle() {
    return this.contactCardToggleHandler.asObservable();
  }

  checkTabsVisibilityByKeyboard(viewTabLinks: boolean) {
    let showTabs = viewTabLinks;
    const isTabPageActive = this.navTabsStateHandler.value.isTabPageActive || false;

    if (!isTabPageActive && viewTabLinks) {
      showTabs = false;
    }

    return showTabs;
  }

  handleTabNavigation(route: string[]) {
    this.updateNavTabData(route);

    if (this.isMapCentered) {
      this.searchService.updatePropertySearchBarSettings({
        isFilled: false
      });
    }

    this.navController.navigateRoot(['/', 'home', ...route]);
  }

  handleTabsChange(): void {
    if (!this.isMobilePlatform) {
      this.platform.ready().then(() => {
        if (this.lastRepDataRefreshTime.isBefore(moment().subtract(30, 'seconds'))) {
          this.lastRepDataRefreshTime = moment();
          this.appService.updateRepData();
        }
      });
    }
  }

  goHome() {
    if (this.isAuthenticated) {
      this.store.dispatch(new fromSearchInMapActions.ResetSearch());
      this.store.dispatch(new fromSearchTypesActions.ResetSearchTypes());
      this.store.dispatch(new fromPropertyProfileActions.ResetSelectedProfile());

      this.store.dispatch(new mapConfigAction.ResetZoomValue());
      this.store.dispatch(new appConfigActions.ResetLocation());

      this.store.dispatch(new calculatorsActions.ResetSelectedCalculator());
      this.store.dispatch(new calculatorsActions.ResetPitiData());
      this.store.dispatch(new calculatorsActions.ResetAffordabilityData());
      this.store.dispatch(new calculatorsActions.ResetAmortizationData());
      this.store.dispatch(new calculatorsActions.ResetPdfReportData());
    }

    return this.navController.navigateRoot(this.router.createUrlTree(['/', 'home']));
  }

  private refreshTabElements(options: TabElementsOptions) {
    const newTabsData = [
      {
        icon: 'icon-nav-property-search-active',
        isActive: this.activeTab === 'search',
        isSvg: false,
        isVisible: true,
        route: ['search'],
        title: 'Search'
      }
    ];

    if (options.enableWalkingFarm) {
      newTabsData.push({
        icon: 'icon-nav-walking-farm-inactive',
        isActive: this.activeTab === 'walking-farm',
        isSvg: false,
        isVisible: options.enableWalkingFarm,
        route: ['walking-farm'],
        title: 'Walking Farm&#174;'
      });
    }

    if (options.enableCalculators) {
      newTabsData.push({
        icon: !options.enableNetSheet ? 'icon-nav-calculator-inactive' : '/assets/svg/netsheets-icon-dollar.svg',
        isActive: [CalculatorPagePaths.Root.toString(), CalculatorPagePaths.NetSheet].includes(this.activeTab),
        isSvg: options.enableNetSheet,
        isVisible: options.enableCalculators,
        route: !options.enableNetSheet
          ? [CalculatorPagePaths.Root.toString()]
          : [CalculatorPagePaths.Root.toString(), CalculatorPagePaths.NetSheet.toString()],
        title: !options.enableNetSheet ? 'Calculator' : 'Net Sheet'
      });
    }

    newTabsData.push({
      icon: 'icon-nav-more',
      isActive: this.activeTab === 'more',
      isSvg: false,
      isVisible: true,
      route: ['more'],
      title: 'More'
    });

    this.navTabDataHandler.next(newTabsData);
  }
}
