import * as fromFarmReportsActions from '../../actions/walking-farm/farm-report-details';

export interface State {
  error: Error;
  farmReportCsv: string;
  pending: boolean;
  succeeded: boolean;
}

export const initialState: State = {
  error: null,
  farmReportCsv: '',
  pending: false,
  succeeded: false
};

export function reducer(state = initialState, action: fromFarmReportsActions.FarmReportDetailsActions) {
  switch (action.type) {
    case fromFarmReportsActions.FarmReportDetailsActionTypes.ExportCSV: {
      return {
        ...state,
        error: null,
        pending: true,
        succeeded: false
      };
    }

    case fromFarmReportsActions.FarmReportDetailsActionTypes.ExportCSVSuccess: {
      return {
        ...state,
        error: null,
        farmReportCsv: action.payload,
        pending: false,
        succeeded: true
      };
    }

    case fromFarmReportsActions.FarmReportDetailsActionTypes.ResetExportCSV: {
      return initialState;
    }

    case fromFarmReportsActions.FarmReportDetailsActionTypes.ExportCSVFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getFarmReportCsvData = (state: State) => state.farmReportCsv;
