export const netCalcsEndpoints = {
  report: 'Report'
};

export const netCalcsReportsDependencies = {
  apiName: 'net-cals',
  error: {
    reportCalculator: {
      message: 'There was a problem getting the Calculator Report.',
      title: 'Oops!'
    },
    reportProperty: {
      message: 'There was a problem getting the Property Report.',
      title: 'Oops!'
    },
    reportWalkingFarm: {
      message: 'There was a problem getting the Walking Farm Report.',
      title: 'Oops!'
    }
  },
  notification: {
    reportWalkingFarm: {
      message: 'The report has been sent successfully.',
      title: 'Hurray!'
    }
  }
};
