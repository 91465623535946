import { Component, NgZone, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { debounce } from 'lodash-es';
import { filter } from 'rxjs/operators';

import { CalculatorType } from '../../../../enums/calculators';
import {
  AffordabilityReportData,
  AffordabilitySummaryData,
  PaymentBreakdownItem,
  PaymentSummaryData,
  SummaryDataItem
} from '../../../../typings/calculator';
import { ChartDataModel } from '../../../../typings/components';

import { GenericComponent } from '../../generic/generic.component';

import * as calculatorsActions from '../../../../store/actions/calculators/calculators-and-reports';
import * as fromCalculators from '../../../../store/reducers/calculators/calculators-and-reports';
import * as fromCalculatorsConfig from '../../../../store/selectors/calculators';
import * as fromCalculatorsData from '../../../../store/selectors/calculators/index';

import { CalculatorsProvider } from '../../../../providers/calculators.service';
import { FileService } from '../../../services/file/file.service';
import { LayoutService } from '../../../services/layout/layout.service';
import { CurrencyFormatService } from '../../../services/number-formatter/currency-format.service';

@Component({
  selector: 'smd-affordability-report',
  templateUrl: './affordability-report.component.html'
})
export class AffordabilityReportComponent extends GenericComponent implements OnInit {
  pdfName = '';
  summaryData: SummaryDataItem[];
  paymentBreakdownData: PaymentBreakdownItem[];
  isMobileView = true;

  calculatorData: AffordabilityReportData;
  report: AffordabilitySummaryData;
  paymentReport: PaymentSummaryData;
  paymentChartData: ChartDataModel;
  affordabilityChartData: ChartDataModel;
  pdfDocData: Blob;

  chartOptions = {
    cutout: '80%',
    rotation: 150
  };

  constructor(
    private ngZone: NgZone,
    private store: Store<fromCalculators.State>,
    private calculatorsProvider: CalculatorsProvider,
    private fileService: FileService,
    private layoutService: LayoutService,
    private currencyFormatService: CurrencyFormatService
  ) {
    super();
  }

  ngOnInit() {
    this.addUniqueSubscription(
      'isMobileViewSubscription',
      this.layoutService.getMobileViewFlag().subscribe((isMobileView) => {
        this.ngZone.run(() => {
          this.isMobileView = isMobileView;
        });
      })
    );

    this.addUniqueSubscription(
      'selectedCalculatorSubscription',
      this.store
        .select(fromCalculatorsConfig.getAffordabilityData)
        .pipe(filter((calculatorData) => !!calculatorData))
        .subscribe((calculatorData) => {
          this.ngZone.run(() => {
            this.calculatorData = calculatorData;
          });

          this.compileData();
        })
    );

    this.addUniqueSubscription(
      'pdfBlobSubscription',
      this.store
        .select(fromCalculatorsData.getPdfBlobData)
        .pipe(filter((pdfDocData) => !!pdfDocData))
        .subscribe((pdfDocData) => {
          this.pdfDocData = pdfDocData;
          this.openShareDialog(pdfDocData);
        })
    );
  }

  sendReport() {
    this.pdfName = 'calculator-affordability-export.pdf';

    if (this.pdfDocData) {
      this.openShareDialog(this.pdfDocData);
      return;
    }

    debounce(
      () => {
        this.store.dispatch(
          new calculatorsActions.SetPdfReportData({
            calculatorData: this.calculatorData,
            reportType: CalculatorType.Affordability
          })
        );
      },
      500,
      { maxWait: 3000 }
    )();
  }

  private compileData() {
    this.ngZone.run(() => {
      if (this.calculatorData) {
        this.summaryData = [
          {
            format: 'currency',
            label: 'Purchase Price',
            value: this.calculatorData?.purchasePrice || 0
          },
          {
            format: 'currency',
            label: 'Down Payment',
            value: this.calculatorData?.downPayment || 0
          },
          {
            format: 'currency',
            label: 'Mortgage Amount',
            value: this.calculatorData?.mortgageAmount || 0
          },
          {
            format: 'time',
            formatRule: 'year',
            label: 'Mortgage Term',
            value: this.calculatorData?.mortgageTerm || 0
          },
          {
            format: 'percent-display',
            label: 'Interest Rate',
            value: this.calculatorData?.interestRate || 0
          }
        ];

        this.paymentBreakdownData = [
          {
            format: 'currency',
            label: 'Principal & Interest',
            value: this.calculatorData?.principalAndInterest || 0
          },
          {
            format: 'currency',
            label: 'Property Tax',
            value: this.calculatorData?.propertyTax || 0
          },
          {
            format: 'currency',
            label: 'Monthly Homeowners Ins',
            value: this.calculatorData?.monthlyHomeownersInsurance || 0
          },
          {
            format: 'currency',
            label: 'Private Mortgage Insurance',
            value: this.calculatorData?.privateMortgageInsurance || 0
          },
          {
            format: 'currency',
            label: 'Monthly Payment',
            value: this.calculatorData?.monthlyPayment || 0
          }
        ];

        this.report = this.calculatorsProvider.formatAffordabilityReportData(this.calculatorData);

        this.affordabilityChartData = {
          chartData: {
            data: this.report.summary.graphicData,
            labels: this.report.summary.graphicLabels
          },
          hasCustomLegend: true,
          legend: {
            additionalInfo: this.report.summary.additionalInfo,
            data: this.formatChartData(this.report.summary.graphicLegend)
          }
        };

        this.paymentReport = this.calculatorsProvider.formatPaymentReportData(this.calculatorData);

        if (this.paymentReport) {
          this.paymentChartData = {
            chartData: {
              data: this.paymentReport.summary.graphicData,
              labels: this.paymentReport.summary.graphicLabels
            },
            hasCustomLegend: true,
            legend: {
              data: this.formatChartData(this.paymentReport.summary.graphicLegend)
            }
          };
        }
      }
    });
  }

  private formatChartData(data: { label: string; value: number }[]) {
    return data.map((item) => ({
      ...item,
      value: `( ${this.formatAsCurrency(item.value)} )`
    }));
  }

  private formatAsCurrency(value: number | string) {
    return this.currencyFormatService.formatValue(value.toString());
  }

  private async openShareDialog(pdfDocData: Blob) {
    const fileUrl = await this.fileService.writeBlobToFs(pdfDocData, this.pdfName);

    if (fileUrl) {
      this.fileService.openNativeShareSheet(fileUrl, this.pdfName);
    }
  }
}
