import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SearchAddress } from '../../../typings/map';

@Component({
  selector: 'smd-search-results',
  templateUrl: 'search-results.component.html'
})
export class ResultItemComponent {
  @Input() showResults: boolean;
  @Input() showIfMultipleResults: boolean;
  @Input() searchResults: SearchAddress[];
  @Output() buttonClick?: EventEmitter<PointerEvent | MouseEvent> = new EventEmitter<PointerEvent | MouseEvent>();
  @Output() itemSelect?: EventEmitter<SearchAddress> = new EventEmitter<SearchAddress>();

  constructor() {}

  showList(event) {
    this.buttonClick.emit(event);
  }

  viewDetails(item: SearchAddress) {
    this.itemSelect.emit(item);
  }
}
