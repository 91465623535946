<ng-container *ngIf="calculatorData; else noDataTemplate">
  <ion-card class="ion-no-margin tw-m-4 lg:tw-mx-0">
    <ion-card-header
      class="ion-text-uppercase tw-px-4 tw-py-2 tw-text-xs tw-font-bold tw-text-gray-500 md:tw-text-base"
    >
      Payment Summary
    </ion-card-header>

    <ion-card-content class="tw-px-4 tw-py-2">
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col size="12" size-md="5">
            <h2 class="tw-text-xs md:tw-text-sm">You will have a monthly payment of:</h2>
            <ion-text class="tw-block tw-text-base tw-font-bold tw-text-gray-800 md:tw-text-lg">
              {{ calculatorData.monthlyPayment | smdNumberFormatter: 'currency' }}
            </ion-text>

            <hr class="tw-my-3 tw-border-gray-300 md:tw-hidden" />
          </ion-col>

          <ion-col size="12" size-md="7" class="md:tw-border-l md:tw-border-gray-400 md:tw-pl-4">
            <smd-doughnut-chart [options]="chartOptions" [data]="paymentChartData"></smd-doughnut-chart>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-text class="tw-mt-4 tw-block tw-text-xs">
            Note: PMI taxes and insurance are NOT used in this calculation.
          </ion-text>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>

  <ion-card *ngIf="summaryData" class="ion-no-margin tw-m-4 lg:tw-mx-0">
    <ion-card-header
      class="ion-text-uppercase tw-px-4 tw-py-2 tw-text-xs tw-font-bold tw-text-gray-500 md:tw-text-base"
    >
      Summary
    </ion-card-header>

    <ion-card-content class="tw-px-4 tw-py-2">
      <ion-grid class="ion-no-padding">
        <ion-row
          *ngFor="let summaryEntry of summaryData; let even = even"
          class="tw-px-1 tw-py-0.5"
          [ngClass]="{ 'tw-bg-gray-100': even }"
        >
          <ion-col>
            <ion-text>{{ summaryEntry.label }}</ion-text>
          </ion-col>

          <ion-col class="tw-block tw-text-right">
            <ion-text>
              {{ summaryEntry.value | smdNumberFormatter: summaryEntry.format : summaryEntry.formatRule }}
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>

  <ng-container *ngIf="scheduleData">
    <ion-card *ngFor="let scheduleChapter of scheduleData" class="ion-no-margin tw-m-4 lg:tw-mx-0">
      <ion-card-header
        class="ion-text-uppercase tw-px-4 tw-py-2 tw-text-xs tw-font-bold tw-text-gray-500 md:tw-text-base"
      >
        {{ scheduleChapter.title }}
      </ion-card-header>

      <ion-card-content class="tw-flex tw-flex-col tw-overflow-x-auto tw-px-4 tw-py-2">
        <div class="tw-mt-2 tw-flex tw-font-bold">
          <ion-text class="tw-block tw-w-32 tw-shrink-0 tw-bg-gray-300 tw-px-2 tw-py-1"> Payment # </ion-text>
          <ion-text class="tw-block tw-w-40 tw-shrink-0 tw-bg-gray-300 tw-px-2 tw-py-1"> Start Balance </ion-text>
          <ion-text class="tw-block tw-w-40 tw-shrink-0 tw-bg-gray-300 tw-px-2 tw-py-1"> Principal </ion-text>
          <ion-text class="tw-block tw-w-40 tw-shrink-0 tw-bg-gray-300 tw-px-2 tw-py-1"> Interest </ion-text>
          <ion-text class="tw-block tw-w-40 tw-shrink-0 tw-bg-gray-300 tw-px-2 tw-py-1"> End Balance </ion-text>
        </div>

        <div *ngFor="let scheduleEntry of scheduleChapter.entries; let even = even" class="tw-flex">
          <ion-text class="tw-block tw-w-32 tw-shrink-0 tw-px-2 tw-py-1" [ngClass]="{ 'tw-bg-gray-100': even }">
            {{ scheduleEntry.paymentNumber }}
          </ion-text>
          <ion-text class="tw-block tw-w-40 tw-shrink-0 tw-px-2 tw-py-1" [ngClass]="{ 'tw-bg-gray-100': even }">
            {{ scheduleEntry.startBalance | smdNumberFormatter: 'currency' }}
          </ion-text>
          <ion-text class="tw-block tw-w-40 tw-shrink-0 tw-px-2 tw-py-1" [ngClass]="{ 'tw-bg-gray-100': even }">
            {{ scheduleEntry.principal | smdNumberFormatter: 'currency' }}
          </ion-text>
          <ion-text class="tw-block tw-w-40 tw-shrink-0 tw-px-2 tw-py-1" [ngClass]="{ 'tw-bg-gray-100': even }">
            {{ scheduleEntry.interest | smdNumberFormatter: 'currency' }}
          </ion-text>
          <ion-text class="tw-block tw-w-40 tw-shrink-0 tw-px-2 tw-py-1" [ngClass]="{ 'tw-bg-gray-100': even }">
            {{ scheduleEntry.endBalance | smdNumberFormatter: 'currency' }}
          </ion-text>
        </div>
      </ion-card-content>
    </ion-card>
  </ng-container>

  <div *ngIf="!isMobileView" class="tw-flex tw-flex-col">
    <ion-button color="primary" class="tw-my-4 tw-self-end" (click)="sendReport()"> Download </ion-button>
  </div>
</ng-container>

<ng-template #noDataTemplate>
  <div class="tw-px-4 lg:tw-px-0">
    <smd-the-oops-component></smd-the-oops-component>
  </div>
</ng-template>
