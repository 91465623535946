import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrencyFormatService {
  constructor(private currency: CurrencyPipe) {}

  formatValue(value: string | number, formatRule?: string) {
    let result = value?.toString() || '';
    result = result?.replace(/[^0-9.]/g, '');

    if (result) {
      result = this.currency.transform(result, 'USD', 'symbol', formatRule || '1.2-2');
    } else {
      result = 'N/A';
    }

    return result;
  }
}
