export const LEAD_STATUS_LIST = {
  lead: {
    cssClass: 'lead',
    label: 'Lead',
    markerPin: 'lead'
  },
  'not contacted': {
    cssClass: 'not-contacted',
    label: 'Not Contacted',
    markerPin: 'notContacted'
  },
  'not home': {
    cssClass: 'not-home',
    label: 'Not Home',
    markerPin: 'notHome'
  },
  'not interested': {
    cssClass: 'not-interested',
    label: 'Not Interested',
    markerPin: 'notInterested'
  },
  'on market': {
    cssClass: 'on-market',
    label: 'On Market',
    markerPin: 'onMarket'
  },
  prospect: {
    cssClass: 'prospect',
    label: 'Prospect',
    markerPin: 'prospect'
  }
  // Next version?
  // 'likely to sell': {
  //   cssClass: 'likely-to-sell',
  //   label: 'Likely To Sell',
  //   markerPin: 'likelyToSell'
  // }
};
