import { combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAppSupport from '../../reducers/app-settings/app-support';
import * as fromSalesInfo from '../../reducers/app-settings/sales-info';
import * as fromUserProfile from '../../reducers/app-settings/user-profile';

export interface AppSettingsState {
  salesInfo: fromSalesInfo.State;
  support: fromAppSupport.State;
  userProfile: fromUserProfile.State;
}

export interface State {
  appSettings: AppSettingsState;
}

export const reducer = combineReducers({
  salesInfo: fromSalesInfo.reducer,
  support: fromAppSupport.reducer,
  userProfile: fromUserProfile.reducer
});

export const getAppSettingsState = createFeatureSelector<AppSettingsState>('appSettings');

export const getAppSettingsSalesInfoState = createSelector(getAppSettingsState, (state) => state.salesInfo);

export const getAppSettingsSalesInfo = createSelector(getAppSettingsSalesInfoState, fromSalesInfo.getSalesInfo);

export const getAppSettingsUserProfileState = createSelector(getAppSettingsState, (state) => state?.userProfile);

export const getAppSettingsUserProfileData = createSelector(
  getAppSettingsUserProfileState,
  fromUserProfile.getUserData
);
export const getAppSettingsUserEmail = createSelector(getAppSettingsUserProfileState, fromUserProfile.getUserEmail);

export const getFarmAlertSettings = createSelector(
  getAppSettingsUserProfileState,
  fromUserProfile.getFarmAlertSettings
);
export const getFarmAlertSettingsAutoSubscribe = createSelector(
  getAppSettingsUserProfileState,
  fromUserProfile.getFarmAlertSettingsAutoSubscribe
);

export const getFirebaseInstallationIds = createSelector(
  getAppSettingsUserProfileState,
  fromUserProfile.getFirebaseInstallationIds
);

export const getDeviceId = createSelector(getAppSettingsUserProfileState, fromUserProfile.getDeviceId);
export const getBadgerBucks = createSelector(getAppSettingsUserProfileState, fromUserProfile.getBadgerBucks);

export const getAppSettingsUserProfileSucceeded = createSelector(
  getAppSettingsUserProfileState,
  fromUserProfile.getSucceeded
);

export const getAppSettingsUserProfileError = createSelector(getAppSettingsUserProfileState, fromUserProfile.getError);

export const isPasswordExpired = createSelector(getAppSettingsUserProfileState, fromUserProfile.getIfPasswordIsExpired);

export const getInternalStorageAppSettingsData = createSelector(
  getAppSettingsUserProfileData,
  getAppSettingsSalesInfo,
  (userProfileData, salesInfoData) => {
    return {
      salesInfo: salesInfoData,
      user: userProfileData
    };
  }
);

export const getUserIsDeleted = createSelector(getAppSettingsUserProfileState, fromUserProfile.getUserIsDeleted);
