import { Component, HostListener } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { NetsheetConfigService, OrganizationEnum } from '@redshed/netsheet-calculator';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic';
import { isEqual } from 'lodash-es';
import { of, throwError } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { ENV } from '../environments/environment';
import { InternalStorageData } from './typings/app';
import { DeviceData } from './typings/device';

import * as fromAppConfigGeneralInfoActions from './store/actions/app-config/general-info';
import * as generalInfoAction from './store/actions/app-config/general-info';
import * as fromAppConfigActions from './store/actions/app-config/general-info';
import * as fromAppConfigMapDataActions from './store/actions/app-config/map-data';
import * as fromAppSettingsActions from './store/actions/app-settings/user-profile';
import * as fromMapActions from './store/actions/map.actions';
import * as parseActions from './store/actions/parse/parse-implementation';
import { mapFeatureKey } from './store/reducers/map.reducer';
import * as fromAppConfig from './store/selectors/app-config/index';
import * as fromAppSettings from './store/selectors/app-settings/index';

import { PfFirebaseService } from './providers/pf-firebase-service.service';
import { AppDeviceProvider } from './providers/plugins-services/app-version.service';
import { LayoutService } from './common/services/layout/layout.service';
import { LoggerService } from './common/services/logger/logger.service';
import { PlatformDetectService } from './common/services/platform-detect/platform-detect.service';
import { SecureStorageService } from './common/services/secure-storage/secure-storage.service';
import { ThemeService } from './common/services/theme/theme.service';

@Component({
  selector: 'smd-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  private isMobilePlatform = true;
  private shouldUseApp: boolean;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (!this.isMobilePlatform) {
      this.layoutService.setMobileViewFlag(this.platform.width() < 992);
    }
  }

  @HostListener('document:deviceready', ['deviceready'])
  onDeviceReady() {
    this.shouldUseApp = this.platform.is('mobileweb');
  }

  constructor(
    private appDeviceProvider: AppDeviceProvider,
    private firebaseService: PfFirebaseService,
    private layoutService: LayoutService,
    private loggerService: LoggerService,
    private navController: NavController,
    private netSheetConfigService: NetsheetConfigService,
    private platform: Platform,
    private platformDetectService: PlatformDetectService,
    private secureStorageService: SecureStorageService,
    private store: Store<fromAppConfig.State>,
    private themeService: ThemeService
  ) {
    this.platform.ready().then(() => {
      this.onAppInit();

      this.platform.backButton.subscribeWithPriority(10, () => {
        this.navController.back();
      });
    });

    this.platformDetectService.getIsMobileSubscription().subscribe((isMobilePlatform) => {
      this.isMobilePlatform = isMobilePlatform;
    });

    this.layoutService.setMobileViewFlag(this.platform.width() < 992);
  }

  private onAppInit() {
    // Get the geolocation on app start
    this.getLocation();

    // Get app config data
    this.getAppConfig();

    // Collect needed Device info
    this.getDeviceData();

    this.notificationSetup();

    if (this.shouldUseApp) {
      this.navController.navigateRoot(['/', 'app-store']);
    }

    this.checkIfAuthenticated();
  }

  private getLocation() {
    this.store.dispatch(new fromAppConfigMapDataActions.GetInitialLocation());
  }

  private getDeviceData() {
    this.store.dispatch(new fromAppConfigGeneralInfoActions.GetDeviceData());
  }

  private getAppConfig() {
    this.store
      .select(fromAppConfig.getAppInfoColors)
      .pipe(filter((colors) => !!colors))
      .subscribe(
        (colors) => {
          this.netSheetConfigService.setColors(colors.primary, colors.secondary);
          this.themeService.setColors(colors);
        },
        (error) => {
          return throwError(error);
        }
      );

    this.netSheetConfigService.setOrganization(OrganizationEnum.OLD_REPUBLIC_TITLE);

    this.store
      .select(fromAppSettings.getAppSettingsUserProfileData)
      .pipe(filter((userData) => !!userData))
      .subscribe(
        (userInfo) => {
          this.netSheetConfigService.updatePrefill({
            userCompany: userInfo.company,
            userEmail: userInfo.email,
            userFirstName: userInfo.firstName,
            userLastName: userInfo.lastName,
            userPhone: userInfo.phone
          });
        },
        (error) => {
          return throwError(error);
        }
      );
  }

  private notificationSetup() {
    if (this.platform.is('cordova')) {
      this.firebaseService.fetchFirebaseToken().then(() => {
        this.firebaseService.requestPushPermission().then(() =>
          FCM.getInitialPushPayload().then((payload) => {
            if (payload) {
              this.navController.navigateBack(['/', 'home', 'walking-farm'], {
                state: {
                  activeTab: 'savedReports'
                }
              });
            }
          })
        );

        this.firebaseService.onTokenRefresh().subscribe(() => {
          this.firebaseService.updateFireBaseInstallationIdsIfNeeded();
        });
      });
    }
  }

  private checkIfAuthenticated() {
    this.secureStorageService
      .getStorageDataAsObservable('app')
      .pipe(
        distinctUntilChanged((oldValue, newValue) => !isEqual(oldValue, newValue)),
        concatMap((data) => {
          let observable = of<InternalStorageData>(data);

          if (this.platform.is('cordova')) {
            observable = this.appDeviceProvider.getInfo().pipe(
              filter((deviceData: DeviceData) => !!deviceData),
              map((deviceData: DeviceData) => ({
                ...data,
                deviceData: {
                  ...(data?.deviceData || {}),
                  ...deviceData
                }
              }))
            );
          }

          return observable;
        })
      )
      .subscribe((data) => {
        if (data && data.isAuthenticated) {
          this.loggerService.updateAuthData(data.authData);

          this.store.dispatch(new fromAppConfigGeneralInfoActions.UpdateGeneralInfoFromInternalStorage(data));
          this.store.dispatch(new fromAppSettingsActions.UpdateSettingsInfoFromInternalStorage(data));
          this.store.dispatch(new parseActions.UpdateUserToken(data.authData.userObjectId));

          if (data?.authData?.repCode) {
            this.store.dispatch(
              new fromAppConfigActions.GetAppSettingsData({
                repCode: data?.authData?.repCode
              })
            );
          }

          // FIXME: Map Centering Functionality - for now, this will be available only in dev builds
          if (!ENV.production && !!data[mapFeatureKey]) {
            const isMapCentered = data[mapFeatureKey].isMapCentered;
            this.store.dispatch(fromMapActions.setMapCenterSetting({ isMapCentered }));
          }

          if (this.platform.is('cordova')) {
            if (!data.firebaseInstallationIds || data.firebaseInstallationIds.length < 1) {
              this.store.dispatch(new parseActions.GetUser());
            }
          }

          this.store.dispatch(new generalInfoAction.SaveDataInInternalStorage());
        }
      });
  }
}
