import { Pipe, PipeTransform } from '@angular/core';

import { isArray, isEmpty, isNil, isString } from 'lodash-es';

@Pipe({
  name: 'smdNaIfEmpty'
})
export class NaIfEmptyPipe implements PipeTransform {
  transform(value: string) {
    let result = value;

    if (isNil(value) || (isString(value) && isEmpty(value)) || (isArray(value) && isEmpty(value))) {
      result = 'N/A';
    }

    return result;
  }
}
