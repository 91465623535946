import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild
} from '@angular/core';
import { AlertController, IonSearchbar, NavController, Platform } from '@ionic/angular';

import { MapsAPILoader } from '@agm/core';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { debounce } from 'lodash-es';
import { distinctUntilChanged, throttleTime } from 'rxjs/operators';

import { PfLocation } from '../../../typings/app';
import { DropdownItem } from '../../../typings/components';
import { SearchInputData, SelectSearchResultData } from '../../../typings/search';

import { GenericComponent } from '../generic/generic.component';

import { DomManipulationProvider } from '../../../providers/dom-manipulation.service';
import { PfHelperService } from '../../../providers/pf-helper-service.service';
import { PlatformDetectService } from '../../services/platform-detect/platform-detect.service';

@Component({
  selector: 'smd-search-bar',
  styleUrls: ['./search-bar.component.scss'],
  templateUrl: './search-bar.component.html'
})
export class SearchBarComponent extends GenericComponent implements AfterContentInit, AfterViewInit {
  isMobilePlatform = true;

  @Input() placeholder = '';
  @Input() hasOptions = false;
  @Input() forAddressSearch = false;

  @Input() set searchValue(value: string) {
    this.ngZone.run(() => {
      this.searchKey = value?.trim();
    });
  }

  @Output() searchInput: EventEmitter<SearchInputData> = new EventEmitter<SearchInputData>();
  @Output() clearInput: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() searchResultSelect: EventEmitter<SelectSearchResultData> = new EventEmitter<SelectSearchResultData>();

  @ViewChild('searchBar') searchBar: IonSearchbar;

  private geocoder: google.maps.Geocoder;
  private autocomplete: google.maps.places.Autocomplete;
  private autocompleteService: google.maps.places.AutocompleteService;
  private autocompleteItems: PfLocation[] = [];

  searchKey = '';
  showMoreFiltersBtn = true;
  showDropDownMenu = false;

  searchFilters: DropdownItem[] = [
    { componentName: 'by-owner', icon: 'icon-home-search', optionName: 'byOwner', title: 'Search by Owner' },
    { componentName: 'apn', icon: 'icon-atm-search', optionName: 'byApnTpnPin', title: 'Search by Parcel Number' },
    { componentName: 'camera-view', icon: 'icon-camera-view', optionName: 'byCamera', title: 'Camera View' },
    { componentName: 'advanced', icon: 'icon-advanced-search', optionName: 'advanced', title: 'Advanced Search' },
    { componentName: 'recent', icon: 'icon-recent-history', optionName: 'byResent', title: 'Profile History' }
  ];

  constructor(
    private ngZone: NgZone,
    private platform: Platform,
    private keyboard: Keyboard,
    private alertController: AlertController,
    private navController: NavController,
    private mapsAPILoader: MapsAPILoader,
    private pfHelperService: PfHelperService,
    private domHelper: DomManipulationProvider,
    private platformDetectService: PlatformDetectService
  ) {
    super();
  }

  ngAfterContentInit() {
    this.addUniqueSubscription(
      'isMobilePlatformSubscription',
      this.platformDetectService
        .getIsMobileSubscription()
        .pipe(distinctUntilChanged(), throttleTime(300, undefined, { leading: true, trailing: true }))
        .subscribe((isMobilePlatform) => {
          this.ngZone.run(() => {
            this.isMobilePlatform = isMobilePlatform;
            if (isMobilePlatform) {
              this.searchFilters = this.searchFilters.filter(
                (dropdownItemElement) => dropdownItemElement.title?.toLowerCase() !== 'camera view'
              );
            }
          });
        })
    );
  }

  ngAfterViewInit() {
    if (this.forAddressSearch) {
      this.setupGoogleAutocomplete();
    }
  }

  toggleSearchFilters(event) {
    event.stopPropagation();
    event.preventDefault();

    this.ngZone.run(() => {
      this.showDropDownMenu = !this.showDropDownMenu;
    });

    if (this.platform.is('cordova')) {
      this.keyboard.hide();
    }
  }

  triggerDropdownAction(event: DropdownItem) {
    this.ngZone.run(() => {
      this.showDropDownMenu = false;
    });

    if (event.componentName) {
      this.navController.navigateRoot(['/', 'home', 'search', event.componentName]);
    }
  }

  hideDropdown() {
    this.showDropDownMenu = false;
  }

  onChange(event) {
    this.ngZone.run(() => {
      this.searchKey = event.detail?.value?.trim() || '';
      this.showMoreFiltersBtn = !this.searchKey;
    });

    if (this.searchKey) {
      if (!this.forAddressSearch) {
        this.searchInput.emit({ key: this.searchKey });
      } else {
        debounce(this.updateSearchResults, 1000, { maxWait: 3000 });
      }

      event.stopPropagation();
    } else {
      this.onClear(event);
    }
  }

  onClear(event) {
    event?.stopPropagation();
    event?.preventDefault();

    this.hideDropdown();

    if (!event || event.type !== 'ionInput') {
      this.clearInput.emit(true);
    }
  }

  onFocus() {
    this.domHelper.removeScrollContentBottomMargin();
  }

  onBlur() {
    this.domHelper.resetScrollContentBottomMargin();
  }

  private setupGoogleAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
      this.autocompleteService = new google.maps.places.AutocompleteService();
      this.searchBar.getInputElement().then((input) => {
        this.autocomplete = new google.maps.places.Autocomplete(input, {
          componentRestrictions: { country: 'us' },
          types: ['address']
        });
        this.autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            const place = this.autocomplete.getPlace();

            if (!place.geometry) {
              this.showAlert(place);
            } else {
              this.selectSearchResult(place);
            }
          });
        });
      });
    });
  }

  private selectSearchResult(placeResult: google.maps.places.PlaceResult) {
    this.searchResultSelect.emit({
      address: placeResult.name,
      centered: true,
      fullAddress: placeResult.formatted_address,
      location: {
        latitude: placeResult.geometry.location.lat(),
        longitude: placeResult.geometry.location.lng()
      },
      searchElement: this.searchBar,
      zip: this.getZip(placeResult)
    });
  }

  private updateSearchResults() {
    const predictionsArray: google.maps.places.AutocompletePrediction[] = [];

    this.autocompleteService.getPlacePredictions({ input: this.searchKey }, (predictions) => {
      if (predictions) {
        this.autocompleteItems = [];
        predictions.forEach((prediction) => {
          predictionsArray.push(prediction);
        });
        this.convertResultToLocations(predictionsArray);
      }
    });
  }

  private convertResultToLocations(results) {
    results.forEach((item) => {
      this.geocoder.geocode({ placeId: item.place_id }, (geoResults) => {
        if (geoResults[0]) {
          this.autocompleteItems.push({
            latitude: geoResults[0].geometry.location.lat(),
            longitude: geoResults[0].geometry.location.lng()
          });
        }
      });
    });

    this.searchInput.emit({ key: this.searchKey, results: this.autocompleteItems });
  }

  private getZip(item) {
    return this.pfHelperService.findObjectByPropInCollection(
      item.address_components,
      'types',
      'postal_code',
      'long_name'
    );
  }

  async showAlert(place: google.maps.places.PlaceResult) {
    const alert = await this.alertController.create({
      buttons: ['Ok'],
      header: 'Search Error',
      subHeader: `No details available for: ${place.name}`
    });
    await alert.present();
  }
}
