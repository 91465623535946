import { STANDARD_PIN_SVG } from '../../../../constants/map.constants';
import { PfLocation } from '../../../typings/app';

import * as fastFarmAction from '../../actions/walking-farm/fast-farm';

export interface State {
  marker: PfLocation;
  radius: number;
}

export const initialState: State = {
  marker: {
    icon: {
      scaledSize: {
        height: 45,
        width: 27
      },
      url: `/assets/svg/map-markers/${STANDARD_PIN_SVG.red}`
    },
    latitude: null,
    longitude: null
  },
  radius: 0.1
};

export function reducer(state = initialState, action: fastFarmAction.FastFarmActions) {
  switch (action.type) {
    case fastFarmAction.FastFarmActionTypes.GetMapMarker: {
      return {
        ...state,
        marker: {
          ...state.marker,
          ...action.payload
        }
      };
    }

    case fastFarmAction.FastFarmActionTypes.ResetMapMarker: {
      return {
        ...state,
        marker: initialState.marker
      };
    }

    case fastFarmAction.FastFarmActionTypes.SetRadius: {
      return {
        ...state,
        radius: action.payload
      };
    }

    case fastFarmAction.FastFarmActionTypes.ResetRadius: {
      return {
        ...state,
        radius: initialState.radius
      };
    }

    default: {
      return state;
    }
  }
}

export const getFastFarmMarker = (state: State) => state.marker;
export const getFastFarmRadius = (state: State) => state.radius;
