import { PfLocation } from '../../../typings/app';

import * as walkingFarmSearchInMapActions from '../../actions/walking-farm/search-in-map';

export interface State {
  markers: PfLocation[];
  searchKey: string;
  searchLocation: PfLocation | null;
}

export const initialState: State = {
  markers: [],
  searchKey: '',
  searchLocation: null
};

export function reducer(state = initialState, action: walkingFarmSearchInMapActions.WalkingFarmSearchInMapAction) {
  switch (action.type) {
    case walkingFarmSearchInMapActions.WalkingFarmSearchInMapActionTypes.SetSearchValue: {
      return {
        ...state,
        searchKey: action.payload
      };
    }

    case walkingFarmSearchInMapActions.WalkingFarmSearchInMapActionTypes.SetMarkers: {
      return {
        ...state,
        markers: action.payload
      };
    }

    case walkingFarmSearchInMapActions.WalkingFarmSearchInMapActionTypes.ResetMarkers: {
      return {
        ...state,
        markers: []
      };
    }

    case walkingFarmSearchInMapActions.WalkingFarmSearchInMapActionTypes.SetInitialLocation: {
      return {
        ...state,
        searchLocation: action.payload
      };
    }

    case walkingFarmSearchInMapActions.WalkingFarmSearchInMapActionTypes.ResetSearch: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getMarkers = (state: State) => state.markers;
export const getSearchKey = (state: State) => state.searchKey;
