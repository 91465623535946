import { isEqual } from 'lodash-es';

import { mapLocationData } from '../../../constants/map';

import * as appConfigActions from '../../actions/app-config/map-data';

export interface State {
  currentLocation: google.maps.LatLngLiteral;
  error: Error;
  initialLocation: google.maps.LatLngLiteral;
  mapType: 'hybrid' | 'roadmap' | 'satellite' | 'terrain';
  pending: boolean;
  succeeded: boolean;
  zoomValue: number;
}

export const initialState: State = {
  currentLocation: null,
  error: null,
  initialLocation: mapLocationData.initial,
  mapType: 'hybrid',
  pending: false,
  succeeded: false,
  zoomValue: 15
};

export function reducer(state = initialState, action: appConfigActions.MapDataAction) {
  switch (action.type) {
    case appConfigActions.MapDataActionTypes.ChangeMapType: {
      const mapTypes = {
        0: 'hybrid',
        1: 'roadmap',
        2: 'satellite',
        3: 'terrain'
      };
      const i = parseInt(Object.keys(mapTypes).find((k) => mapTypes[k] === state.mapType));
      const newMapTypeIndex = i + 1 > 3 ? 0 : i + 1;

      return {
        ...state,
        mapType: mapTypes[newMapTypeIndex]
      };
    }

    case appConfigActions.MapDataActionTypes.ChangeZoomValue: {
      return {
        ...state,
        zoomValue: action.payload
      };
    }

    case appConfigActions.MapDataActionTypes.ResetZoomValue: {
      return {
        ...state,
        zoomValue: initialState.zoomValue
      };
    }

    case appConfigActions.MapDataActionTypes.GetInitialLocation: {
      return {
        ...state,
        error: null,
        pending: true,
        succeeded: false
      };
    }
    case appConfigActions.MapDataActionTypes.GetInitialLocationSuccess: {
      return {
        ...state,
        currentLocation: action.payload,
        error: null,
        initialLocation: action.payload,
        pending: false,
        succeeded: true
      };
    }
    case appConfigActions.MapDataActionTypes.GetInitialLocationFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }
    case appConfigActions.MapDataActionTypes.ResetLocation: {
      return {
        ...state,
        currentLocation: state.initialLocation,
        error: null,
        pending: false,
        succeeded: false
      };
    }
    case appConfigActions.MapDataActionTypes.UpdateLocation: {
      const data = {
        ...state,
        error: null,
        pending: false,
        succeeded: false
      };

      if (!isEqual(state.currentLocation, action.payload)) {
        data.currentLocation = {
          lat: action.payload.lat,
          lng: action.payload.lng
        };
      }

      return data;
    }

    default: {
      return state;
    }
  }
}

export const getMapType = (state: State) => state.mapType;
export const getZoomValue = (state: State) => state.zoomValue;
export const getLocation = (state: State) => state.currentLocation || state.initialLocation;
