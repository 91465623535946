import { Action } from '@ngrx/store';

import { ReportSummaryTabType } from '../../../enums/walking-farm';

export enum ReportSummaryActionTypes {
  SetActiveTab = '[ReportSummary] Set Active Tab',
  SetMarkerType = '[ReportSummary] Set Marker Type',
  SetReportSummaryListIndex = '[ReportSummary] Get Report Summary list index'
}

export class SetActiveTab implements Action {
  readonly type = ReportSummaryActionTypes.SetActiveTab;
  constructor(public payload: ReportSummaryTabType) {}
}

export class SetMarkerType implements Action {
  readonly type = ReportSummaryActionTypes.SetMarkerType;
  constructor(public payload: string) {}
}

export class SetReportSummaryListIndex implements Action {
  readonly type = ReportSummaryActionTypes.SetReportSummaryListIndex;
  constructor(public payload: number) {}
}

export type ReportSummaryActions = SetActiveTab | SetMarkerType | SetReportSummaryListIndex;
