<ion-item lines="none" class="ion-no-padding ion-text-uppercase tw-w-auto tw-flex tw-h-11" [ngClass]="classes">
  <div class="tw-flex tw-w-full tw-cursor-pointer tw-my-auto" (click)="clickItem($event)">
    <ng-content></ng-content>

    <div *ngIf="hasArrow" class="tw-flex tw-flex-1 tw-justify-end">
      <ion-text class="tw-self-center tw-text-gray-700" *ngIf="arrowText">{{ arrowText }}</ion-text>
      <ion-icon
        class="ion-md-icon-pf-arrow2 tw-ml-4 tw-h-3.5 tw-w-6 tw-self-center tw-text-sm tw-text-gray-700"
      ></ion-icon>
    </div>
  </div>
</ion-item>
