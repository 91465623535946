import { Action } from '@ngrx/store';

import { PfLocation } from '../../../typings/app';

export enum WalkingFarmSearchInMapActionTypes {
  ResetMarkers = '[Walking Farm Search] Reset Markers',
  ResetSearch = '[Walking Farm Search] Reset Search',
  SetInitialLocation = '[Walking Farm Search] Set Initial Location',
  SetMarkers = '[Walking Farm Search] Set Markers',
  SetSearchValue = '[Walking Farm Search] Set Search Value'
}

export class SetSearchValue implements Action {
  readonly type = WalkingFarmSearchInMapActionTypes.SetSearchValue;

  constructor(public payload: string) {}
}

export class ResetSearch implements Action {
  readonly type = WalkingFarmSearchInMapActionTypes.ResetSearch;

  constructor() {}
}

export class SetInitialLocation implements Action {
  readonly type = WalkingFarmSearchInMapActionTypes.SetInitialLocation;

  constructor(public payload: PfLocation) {}
}
export class SetMarkers implements Action {
  readonly type = WalkingFarmSearchInMapActionTypes.SetMarkers;

  constructor(public payload: PfLocation[]) {}
}
export class ResetMarkers implements Action {
  readonly type = WalkingFarmSearchInMapActionTypes.ResetMarkers;

  constructor() {}
}

export type WalkingFarmSearchInMapAction =
  | SetSearchValue
  | SetInitialLocation
  | ResetSearch
  | SetMarkers
  | ResetMarkers;
