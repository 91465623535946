import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[smdClickOutside]'
})
export class ClickOutsideDirective {
  constructor(private elementRef: ElementRef) {}

  @Output()
  clickOutside = new EventEmitter();

  @HostListener('document:click', ['$event.target'])
  @HostListener('document:touchstart', ['$event.target'])
  onClick(targetElement) {
    let clickedInside: boolean = this.elementRef.nativeElement.contains(targetElement);

    if (targetElement.classList.contains('dropdown-item-text')) {
      clickedInside = true;
    }

    if (!clickedInside) {
      this.clickOutside.emit(null);
    }
  }
}
