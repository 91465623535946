import { REP_DATA_RESPONSE_PROVIDER_ID } from '../../../../constants/main.constants';
import { FilterPfCheckbox, FilterRange, OwnerStatus, PropertyTypes } from '../../../typings/walking-farm';

import * as generalInfoActions from '../../actions/app-config/general-info';
import * as filtersActions from '../../actions/walking-farm/walking-farm-filters';

export interface State {
  area: {
    data: FilterRange;
    range: FilterRange;
  };
  bathrooms: {
    list: FilterPfCheckbox[];
    selected: string[];
  };
  bedrooms: {
    list: FilterPfCheckbox[];
    selected: string[];
  };
  ownerStatus: OwnerStatus;
  propertyTypes: {
    list: PropertyTypes[];
    selected: string[];
  };
  salesYears: number;
  savedFarmListIndex?: number;
}

export const initialState: State = {
  area: {
    data: { lower: 0, upper: 5000 },
    range: { lower: 0, upper: 5000 }
  },
  bathrooms: {
    list: [{ value: '1' }, { value: '2' }, { value: '3' }, { value: '4+' }],
    selected: []
  },
  bedrooms: {
    list: [{ value: '1' }, { value: '2' }, { value: '3' }, { value: '4' }, { value: '5+' }],
    selected: []
  },
  ownerStatus: {
    data: [
      { title: 'All', value: 'all' },
      { title: 'Owner Occupied', value: 'occupied' },
      { title: 'Non Owner Occupied', value: 'unoccupied' }
    ],
    selectedTab: 'all'
  },
  propertyTypes: {
    list: [
      {
        label: 'Single Family Residential',
        values: ['1000', '1001', '1008', '1009', '1013', '1999']
      },
      {
        label: 'Residential Vacant Land',
        values: ['8001']
      },
      {
        label: 'Condominium Unit',
        values: ['1004']
      },
      {
        label: 'Mobile/Manufactured Home',
        values: ['1006', '1016']
      },
      {
        label: 'Townhouse/Rowhouse',
        values: ['1002', '1007']
      },
      {
        label: 'Duplex',
        values: ['1101']
      },
      {
        label: 'Triplex',
        values: ['1102']
      },
      {
        label: 'Fourplex',
        values: ['1103']
      },
      {
        label: 'MultiFamily',
        values: ['1110']
      },
      {
        label: 'Seasonal, Vacation Residence',
        values: ['1012']
      },
      {
        label: 'Zero Lot Line',
        values: ['1003', '1014']
      },
      {
        label: 'Cooperative',
        values: ['1005']
      }
    ],
    selected: []
  },
  salesYears: 0,
  savedFarmListIndex: 0
};

export function reducer(
  state = initialState,
  action: filtersActions.FiltersActions | generalInfoActions.GeneralConfigAction
) {
  switch (action.type) {
    case filtersActions.FiltersActionTypes.SelectPropertyTypes: {
      return {
        ...state,
        propertyTypes: {
          ...state.propertyTypes,
          selected: action.payload
        }
      };
    }

    case filtersActions.FiltersActionTypes.SetFilters: {
      return {
        ...state,
        area: action.payload.area || state.area,
        bathrooms: {
          ...state.bathrooms,
          selected: action.payload.selectedBathrooms || state.bathrooms.selected
        },
        bedrooms: {
          ...state.bedrooms,
          selected: action.payload.selectedBedrooms || state.bedrooms.selected
        },
        ownerStatus: {
          ...state.ownerStatus,
          selectedTab: action.payload.selectedTab || state.ownerStatus.selectedTab
        },
        salesYears: action.payload.salesYears || state.salesYears
      };
    }

    case filtersActions.FiltersActionTypes.ResetPropertyTypes: {
      return {
        ...state,
        propertyTypes: {
          ...state.propertyTypes,
          selected: []
        }
      };
    }

    case filtersActions.FiltersActionTypes.ResetFilters: {
      return {
        area: {
          ...state.area,
          range: initialState.area.range
        },
        bathrooms: {
          ...state.bathrooms,
          selected: []
        },
        bedrooms: {
          ...state.bedrooms,
          selected: []
        },
        ownerStatus: { ...initialState.ownerStatus },
        propertyTypes: {
          ...state.propertyTypes,
          selected: []
        },
        salesYears: initialState.salesYears,
        savedFarmListIndex: state.savedFarmListIndex
      };
    }

    case filtersActions.FiltersActionTypes.SetSavedFarmListIndex: {
      return {
        ...state,
        savedFarmListIndex: action.payload
      };
    }

    case generalInfoActions.GeneralConfigActionTypes.GetAppSettingsDataSuccess: {
      const providerId = action.payload.provider.name;

      return {
        ...state,
        propertyTypes: {
          ...state.propertyTypes,
          list:
            providerId === REP_DATA_RESPONSE_PROVIDER_ID
              ? [
                  ...initialState.propertyTypes.list,
                  ...[
                    {
                      label: 'Single Family Residential w/ADU',
                      values: ['1022', '1023']
                    }
                  ]
                ]
              : [...initialState.propertyTypes.list]
        }
      };
    }

    default: {
      return state;
    }
  }
}

export const getFiltersOwnerStatus = (state: State) => state.ownerStatus;
export const getFiltersSalesYears = (state: State) => state.salesYears;
export const getFiltersPropertyTypes = (state: State) => state.propertyTypes.list;
export const getFiltersPropertyTypesSelected = (state: State) => state.propertyTypes.selected;
export const getFiltersBedrooms = (state: State) => state.bedrooms.list;
export const getFiltersBedroomsSelected = (state: State) => state.bedrooms.selected;
export const getFiltersBathroomsSelected = (state: State) => state.bathrooms.selected;
export const getFiltersArea = (state: State) => state.area;
export const getSavedFarmListIndex = (state: State) => state.savedFarmListIndex;
