import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';

import { AgmInfoWindow, AgmMap, AgmMarker } from '@agm/core';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { from as fromPromise } from 'rxjs';
import { filter, map, throttleTime } from 'rxjs/operators';

import { LEAD_STATUS_LIST } from '../../../../constants/lead-status.constants';
import { STANDARD_PIN_SVG } from '../../../../constants/map.constants';
import { mapLocationData } from '../../../constants/map';
import { PfLocation, PropertyInfo } from '../../../typings/app';
import {
  BoundaryData,
  InitEventService,
  LeadStatusClick,
  LeadStatusSelect,
  MapDrawnPoint,
  MapMarkerPin,
  MapReadyEvent
} from '../../../typings/map';

import { GenericComponent } from '../generic/generic.component';

import * as fromAppConfigActions from '../../../store/actions/app-config/map-data';
import * as mapConfigAction from '../../../store/actions/app-config/map-data';
import * as fromMapActions from '../../../store/actions/map.actions';
import * as fromAppConfig from '../../../store/selectors/app-config/index';
import * as fromMapSelectors from '../../../store/selectors/map.selectors';

import { GeocodeService } from '../../../providers/geocode-helper.service';
import { WalkOrderManagerProvider } from '../../../providers/map-utils/walk-order-manager';
import { LayoutService } from '../../services/layout/layout.service';
import { PolygonService } from '../../services/map/polygon.service';
import { RadiusService } from '../../services/map/radius.service';
import { PlatformDetectService } from '../../services/platform-detect/platform-detect.service';

@Component({
  selector: 'smd-map',
  styleUrls: ['./map.component.scss'],
  templateUrl: './map.component.html'
})
export class MapComponent extends GenericComponent implements OnInit {
  private isMobilePlatform = true;
  private prevOpenedInfoWindow: AgmInfoWindow;
  private selectedMarkerIndex: number;
  private isMapCentered = true;
  private radiusInMeters: number;
  private isMapReady = false;

  private isDataStyleVisible = false;

  /**
   * Whether it is the first time the application records the current map's location based on marker.
   */
  private isFirstMapLoading = true;

  /**
   * Certain event for agm-map trigger when map is initialized.
   * This should not happen until the first location has been drawn.
   */
  private isMapEventActive = false;

  googleMapsObject: google.maps.Map;
  defaultIcon: MapMarkerPin = {
    scaledSize: {
      height: 45,
      width: 27
    },
    url: `/assets/svg/map-markers/${STANDARD_PIN_SVG.red}`
  };
  mapMarkers: PfLocation[];

  mapLocation: google.maps.LatLngLiteral;
  styles: google.maps.MapTypeStyle[] = [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }]
    }
  ];
  mapType: 'HYBRID' | 'ROADMAP' | 'SATELLITE' | 'TERRAIN';
  zoomValue: number;
  selectedMarker: PfLocation;
  leadStatusList = LEAD_STATUS_LIST;
  leadStatusKeys = Object.keys(LEAD_STATUS_LIST);
  selectInterface = 'alert';

  userBeacon: {
    icon: google.maps.Icon;
    location: MapDrawnPoint;
  };

  @Input() zoomControl = false;
  @Input() streetViewControl = false;
  @Input() scaleControl = false;
  @Input() scrollwheel = true;
  @Input() hasClickableMarkers = false;
  @Input() isRadiusMode = false;
  @Input() isWalkOrderMode = false;
  @Input() isDrawMode = false;
  @Input() canDraw = true;
  @Input() isSavedFarm = false;
  @Input() shouldSaveMapLocation = true;
  @Input() hasManualLocation = false;
  @Input() markerOpacity = 1;
  @Input() boundaryData: BoundaryData;

  @Input() set markers(markers: PfLocation[]) {
    this.ngZone.run(() => {
      this.mapMarkers = markers;
    });

    if (this.isRadiusMode && this.isMapReady) {
      this.initRadiusMode();
    }
  }

  @Input()
  set radius(value: number) {
    this.radiusInMeters = value;

    if (this.isRadiusMode) {
      this.updateCircleRadius();
    }
  }

  @Input() set showDataStyle(check: boolean) {
    if (this.googleMapsObject) {
      this.googleMapsObject.data.setStyle({
        fillOpacity: 0,
        strokeColor: '#ff0000',
        strokeOpacity: 0.8,
        strokeWeight: 2.5,
        visible: check
      });
    }

    this.isDataStyleVisible = check;
  }

  @Output() polygonInit = new EventEmitter<InitEventService<PolygonService>>();
  @Output() polygonPointAdded = new EventEmitter<google.maps.LatLng>();
  @Output() circleInit = new EventEmitter<InitEventService<RadiusService>>();
  @Output() walkOrderInit = new EventEmitter<InitEventService<WalkOrderManagerProvider>>();
  @Output() leadStatusSelectClick = new EventEmitter<LeadStatusClick>();
  @Output() leadStatusSelectChange = new EventEmitter<LeadStatusSelect>();
  @Output() mapReadyEmitter = new EventEmitter<MapReadyEvent>();

  @ViewChild('agmMap') agmMap: AgmMap;
  @ViewChild('infoWindow') infoWindow: AgmInfoWindow;

  constructor(
    private ngZone: NgZone,
    private geolocation: Geolocation,
    private polygonService: PolygonService,
    private radiusService: RadiusService,
    private walkOrderManager: WalkOrderManagerProvider,
    private geocoderService: GeocodeService,
    private store: Store<fromAppConfig.State>,
    private navController: NavController,
    private platformDetectService: PlatformDetectService,
    private layoutService: LayoutService
  ) {
    super();
  }

  ngOnInit() {
    this.addUniqueSubscription(
      'isMobilePlatformSubscription',
      this.platformDetectService.getIsMobileSubscription().subscribe((isMobilePlatform) => {
        this.isMobilePlatform = isMobilePlatform;
      })
    );

    this.addUniqueSubscription(
      'isMobileViewSubscription',
      this.layoutService.getMobileViewFlag().subscribe((isMobileView) => {
        this.ngZone.run(() => {
          this.selectInterface = isMobileView ? 'action-sheet' : 'alert';
        });
      })
    );

    this.addUniqueSubscription(
      'mapCenterSettingSubscription',
      this.store.select(fromMapSelectors.selectMapCenterSetting).subscribe((isMapCentered) => {
        this.isMapCentered = isMapCentered;
      })
    );

    this.addUniqueSubscription(
      'mapTypeSubscription',
      this.store.select(fromAppConfig.getMapDataMapType).subscribe((mapType) => {
        this.ngZone.run(() => {
          this.mapType = mapType as unknown as 'HYBRID' | 'ROADMAP' | 'SATELLITE' | 'TERRAIN';
        });
      })
    );

    this.addUniqueSubscription(
      'currentZoomValueSubscription',
      this.store.select(fromAppConfig.getMapDataZoomValue).subscribe((zoomValue) => {
        this.ngZone.run(() => {
          this.zoomValue = zoomValue;
        });
      })
    );

    this.addUniqueSubscription(
      'userBeaconSubscription',
      fromPromise(this.geolocation.getCurrentPosition())
        .pipe(filter((position) => !!position?.coords))
        .subscribe(
          (position) => {
            this.ngZone.run(() => {
              this.userBeacon = {
                icon: {
                  scaledSize: new google.maps.Size(32, 32),
                  url: '/assets/svg/map-markers/pin-user-beacon.svg'
                },
                location: {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude
                }
              };
            });
          },
          () => {}
        )
    );

    this.handleMapLocationSubscription();
  }

  onMapReady(googleMap: google.maps.Map) {
    this.googleMapsObject = googleMap;
    this.geocoderService.reset();

    if (!this.isMobilePlatform) {
      this.googleMapsObject.setOptions({
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
        }
      });
    } else {
      this.googleMapsObject.setOptions({
        zoomControl: false
      });
    }

    this.initDrawMode();
    this.initRadiusMode();
    this.iniWalkOrderMode();

    if (this.isMapCentered) {
      this.centerLocationOnMarkers();
    }

    this.isMapReady = true;

    this.mapReadyEmitter.emit({ map: this.googleMapsObject });

    this.handleBoundaryData();
  }

  onMapIdle() {
    if (this.isMapEventActive && !this.isDrawMode && !this.isWalkOrderMode) {
      this.saveMapLocation();
    }

    if (!this.shouldSaveMapLocation) {
      this.shouldSaveMapLocation = true;
    }
  }

  onMapClick(event: google.maps.MouseEvent | google.maps.IconMouseEvent) {
    if (this.hasClickableMarkers) {
      this.resetInfoWindow();
    }

    if (this.isDrawMode && this.canDraw) {
      const latLng = event?.latLng;
      this.polygonService.insertPoint(latLng?.lat(), latLng?.lng());

      this.polygonPointAdded.emit(latLng);
    }
  }

  onMarkerClick(infoWindow: AgmInfoWindow, agmMarker: AgmMarker, marker: PfLocation, markerIndex: number) {
    if (infoWindow && this.hasClickableMarkers) {
      if (this.prevOpenedInfoWindow) {
        this.prevOpenedInfoWindow.close();
      }

      this.selectedMarker = marker;
      this.selectedMarkerIndex = markerIndex;

      infoWindow.hostMarker = agmMarker;
      infoWindow.open();
      this.prevOpenedInfoWindow = infoWindow;
    }
  }

  zoomValueChanged(newZoomValue: number) {
    if (this.zoomValue !== newZoomValue) {
      this.store.dispatch(new mapConfigAction.ChangeZoomValue(newZoomValue));
    }
  }

  goToFarmDetails(property: PropertyInfo) {
    this.resetInfoWindow();

    this.navController.navigateRoot(['/', 'home', 'walking-farm', 'property-details'], {
      state: { index: this.selectedMarkerIndex, property }
    });
  }

  resetInfoWindow() {
    if (this.prevOpenedInfoWindow) {
      this.prevOpenedInfoWindow.close();
    }

    this.prevOpenedInfoWindow = null;
    this.selectedMarker = null;
  }

  refreshMap(recenter: boolean) {
    this.agmMap?.triggerResize(recenter);
  }

  resetMapZoom() {
    this.store.dispatch(new mapConfigAction.ResetZoomValue());
  }

  resetMapLocation() {
    if (
      this.mapLocation?.lat !== mapLocationData.initial.lat ||
      this.mapLocation?.lng !== mapLocationData.initial.lng
    ) {
      this.store.dispatch(new mapConfigAction.ResetLocation());
      this.resetMapZoom();
      this.refreshMap(false);
    }
  }

  centerLocationOnMarkers(markers?: PfLocation[]) {
    if (!this.hasManualLocation) {
      this.ngZone.run(() => {
        const markersList = markers || this.mapMarkers;
        if (this.agmMap && markersList?.length) {
          if (this.isRadiusMode) {
            this.agmMap.latitude = markersList[0].latitude;
            this.agmMap.longitude = markersList[0].longitude;
          } else {
            this.agmMap.fitBounds = this.getMarkerBounds(markersList);
          }

          this.refreshMap(true);
        }
      });
    }
  }

  changeLocationToMarkers(markers?: PfLocation[]) {
    this.ngZone.run(() => {
      const markersList = markers || this.mapMarkers;
      if (this.agmMap && markersList?.length) {
        const bounds = this.getMarkerBounds(markersList);
        const mapCenterLocation = bounds.getCenter();

        const newLocation = {
          lat: mapCenterLocation.lat(),
          lng: mapCenterLocation.lng()
        };

        if (!isEqual(this.mapLocation, newLocation)) {
          this.store.dispatch(new fromAppConfigActions.UpdateLocation(newLocation));
          this.store.dispatch(new fromAppConfigActions.ResetZoomValue());
        }
      }
    });
  }

  saveMapLocation() {
    let latitude = mapLocationData.initial.lat;
    let longitude = mapLocationData.initial.lng;
    let mapCenterLocation: google.maps.LatLng;

    if (this.mapMarkers?.length && this.isFirstMapLoading) {
      this.isFirstMapLoading = false;

      const bounds = this.getMarkerBounds(this.mapMarkers);
      mapCenterLocation = bounds.getCenter();
    } else if (this.googleMapsObject) {
      mapCenterLocation = this.googleMapsObject.getCenter();
    }

    if (mapCenterLocation) {
      latitude = mapCenterLocation.lat();
      longitude = mapCenterLocation.lng();

      if (this.shouldSaveMapLocation) {
        const coordinates = {
          lat: latitude,
          lng: longitude
        };

        this.store.dispatch(
          this.isSavedFarm
            ? fromMapActions.setSavedFarmLocation({
                savedFarmLocation: coordinates
              })
            : new fromAppConfigActions.UpdateLocation(coordinates)
        );
      }
    }
  }

  onLeadStatusSelectClick(event, propertyId: string) {
    this.leadStatusSelectClick.emit({
      event,
      propertyId
    });
  }

  onLeadStatusChange(event, propertyId: string) {
    this.leadStatusSelectChange.emit({
      event,
      isMapOpen: true,
      propertyId
    });
  }

  identifyMarker(index: number, marker: PfLocation) {
    return marker.propertyInfo?.id;
  }

  initRadiusMode() {
    if (this.isRadiusMode && this.googleMapsObject && this.mapMarkers?.length) {
      this.radiusService.clearCircle();
      this.radiusService.setMapObj(this.googleMapsObject);
      this.radiusService.initializeCircle(this.mapMarkers[0].latitude, this.mapMarkers[0].longitude);

      this.updateCircleRadius();

      this.circleInit.emit({ map: this.googleMapsObject, service: this.radiusService });
    }
  }

  private initDrawMode() {
    if (this.isDrawMode && this.googleMapsObject) {
      this.polygonService.setMapObj(this.googleMapsObject);
      this.polygonService.initializePolygon();
      this.polygonInit.emit({ map: this.googleMapsObject, service: this.polygonService });
    }
  }

  private updateCircleRadius() {
    if (this.mapMarkers?.length) {
      this.radiusService.setCircleRadius(this.radiusInMeters);
    }
  }

  private iniWalkOrderMode() {
    if (this.isWalkOrderMode && this.googleMapsObject) {
      this.walkOrderManager.setMapObj(this.googleMapsObject);
      this.walkOrderManager.setMarkers(this.mapMarkers);
      this.walkOrderInit.emit({ map: this.googleMapsObject, service: this.walkOrderManager });
    }
  }

  private getMarkerBounds(mapMarkers: PfLocation[]) {
    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();

    if (mapMarkers?.length) {
      for (const marker of mapMarkers) {
        if (marker?.latitude && marker?.longitude) {
          bounds.extend(new google.maps.LatLng(marker.latitude, marker.longitude));
        }
      }
    }

    return bounds;
  }

  private handleMapLocationSubscription() {
    let observable = this.store.select(fromAppConfig.getMapDataLocation);

    if (this.isSavedFarm) {
      observable = this.store.select(fromMapSelectors.selectSavedFarmLocation);
    }

    this.addUniqueSubscription(
      'mapLocationSubscription',
      observable
        .pipe(
          filter((location: google.maps.LatLngLiteral) => !!(location?.lat && location?.lng)),
          filter((location: google.maps.LatLngLiteral) => !isEqual(location, this.mapLocation)),
          map((location: google.maps.LatLngLiteral) => ({
            lat: location.lat,
            lng: location.lng
          })),
          throttleTime(300, undefined, { leading: true, trailing: true })
        )
        .subscribe((location: google.maps.LatLngLiteral) => {
          this.ngZone.run(() => {
            this.mapLocation = {
              lat: location.lat,
              lng: location.lng
            };

            this.isMapEventActive = true;
          });
        })
    );
  }

  private handleBoundaryData() {
    if (this.boundaryData) {
      this.googleMapsObject.data.addGeoJson(this.boundaryData);
      this.googleMapsObject.data.setStyle({
        visible: this.isDataStyleVisible
      });
    }
  }
}
