export enum DefaultColors {
  DANGER = '#EE3C37',
  DARK = '#363949',
  LIGHT = '#FFFFFF',
  MEDIUM = '#6E6D6D',
  PRIMARY = '#003b3f',
  SECONDARY = '#b0b1c1',
  SUCCESS = '#018626',
  WARN = '#EC9F24'
}
